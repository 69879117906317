import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of, timer } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import awsconfig from "../../../../../../../config/aws-exports";

@Injectable({
  providedIn: "root",
})
export class HeartBeatService {
  url = awsconfig.aws_cloud_logic_custom[0].heartbeatEndpoint;
  emptyResponse: ApplicationMode = {
    mode: "",
    messageEn: "",
    messageFr: "",
    titleEn: "",
    titleFr: "",
    version: "",
  };
  constructor(private httpClient: HttpClient) {}

  public getApplicationMode() {
    return timer(0, 30000).pipe(
      switchMap(() => {
        return this.httpClient
          .get<ApplicationMode>(this.url, { params: { c: Date.now() } })
          .pipe(
            catchError((error) => {
              return of(this.emptyResponse);
            })
          );
      })
    );
  }
}
export interface ApplicationMode {
  mode: string;
  messageEn: string;
  messageFr: string;
  titleEn: string;
  titleFr: string;
  version: string;
}
