import { Action } from "@ngrx/store";
import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";

export const SET_APPLICATION = "SET_APPLICATIONS";

export class SetSelectedApplication implements Action {
  readonly type = SET_APPLICATION;
  constructor(public payload: Case) {}
}

export type SelectedApplicationActions = SetSelectedApplication;
