import { CasesCountReducer } from "@pr-applicant/app/store/CasesCount/CasesCount.reducer";
import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";

import * as SelectedApplicationActions from "./selectedApplication.actions";

const initialselecteApplication: Case = {} as any as Case;

export function selectedApplicationReducer(
  state: Case = initialselecteApplication,
  action: SelectedApplicationActions.SelectedApplicationActions
) {
  switch (action.type) {
    case SelectedApplicationActions.SET_APPLICATION:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
