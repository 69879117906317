import { Component, Input } from "@angular/core";

export type headingSemanticType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

@Component({
  selector: "lib-copy-block",
  template: `
    <ng-container *ngIf="heading">
      <ng-container [ngSwitch]="headingTag || 'h2'">
        <h1 class="emphasis" *ngSwitchCase="'h1'">{{ heading }}</h1>
        <h2 class="emphasis" *ngSwitchCase="'h2'">{{ heading }}</h2>
        <h3 class="emphasis" *ngSwitchCase="'h3'">{{ heading }}</h3>
        <h4 class="emphasis" *ngSwitchCase="'h4'">{{ heading }}</h4>
        <h5 class="emphasis" *ngSwitchCase="'h5'">{{ heading }}</h5>
        <h6 class="emphasis" *ngSwitchCase="'h6'">{{ heading }}</h6>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="subheading">
      <ng-container [ngSwitch]="headingTag || 'h2'">
        <h2 class="emphasis" *ngSwitchCase="'h1'">{{ subheading }}</h2>
        <h3 class="emphasis" *ngSwitchCase="'h2'">{{ subheading }}</h3>
        <h4 class="emphasis" *ngSwitchCase="'h3'">{{ subheading }}</h4>
        <h5 class="emphasis" *ngSwitchCase="'h4'">{{ subheading }}</h5>
        <h6 class="emphasis" *ngSwitchCase="'h5'">{{ subheading }}</h6>
      </ng-container>
    </ng-container>

    <div [innerHTML]="body"></div>
  `,
  styles: [
    `
      :host {
        display: block;
        margin-block-end: 1rem;
      }
    `,
  ],
})
export class CopyBlockComponent {
  @Input() headingTag?: headingSemanticType;
  @Input() heading?: string;
  @Input() subheading?: string;
  @Input() body: string;

  constructor() {}
}
