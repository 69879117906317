<div class="section-container">
  <h2 class="emphasis">{{ "RENEWAL.FORM.IMM5444.PAGE4.1" | translate }}</h2>
  <div class="address-table__container">
    <div *ngIf="!addressHistory || addressHistory?.length === 0">
      <p class="no-results-text">
        {{ "RENEWAL.FORM.TABLE_NO_DATA" | translate }}
      </p>
    </div>
    <table *ngIf="addressHistory?.length > 0" class="table address-table">
      <thead>
        <tr>
          <th class="address-table__from">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.FROM" | translate }}
          </th>
          <th class="address-table__to">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.TO" | translate }}
          </th>
          <th>
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.ADDRESS" | translate }}
          </th>
          <th class="address-table__city">
            {{ "RENEWAL.FORM.CITY_TOWN_LABEL" | translate }}
          </th>
          <th class="address-table__province">
            {{ "RENEWAL.FORM.PROVINCE_STATE_LABEL" | translate }}
          </th>
          <th class="address-table__country">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.COUNTRY" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of addressHistory; let i = index">
          <!-- From -->
          <td class="address-table__body address-table__from">
            {{ item.from | date : "yyyy/MM/dd" }}
          </td>
          <!-- To -->
          <td class="address-table__body address-table__to">
            {{ item.to | date : "yyyy/MM/dd" }}
          </td>
          <!-- Address -->
          <td class="address-table__body">
            {{ item.address }}
          </td>
          <!-- City -->
          <td class="address-table__body address-table__city">
            {{ item.cityOrTown }}
          </td>
          <!-- Province -->
          <td class="address-table__body address-table__province">
            {{ item.province }}
          </td>
          <!-- Country -->
          <td class="address-table__body address-table__country">
            {{ item.countryOrTerritory }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="section-container">
  <h2 class="emphasis">{{ "RENEWAL.FORM.IMM5444.PAGE4.2" | translate }}</h2>
  <div class="address-table__container">
    <div *ngIf="!workEducationHistory || workEducationHistory?.length === 0">
      <p class="no-results-text">
        {{ "RENEWAL.FORM.TABLE_NO_DATA" | translate }}
      </p>
    </div>
    <table *ngIf="workEducationHistory?.length > 0" class="table address-table">
      <thead>
        <tr>
          <th class="address-table__from">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.FROM" | translate }}
          </th>
          <th class="address-table__to">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.TO" | translate }}
          </th>
          <th>
            {{ "RENEWAL.FORM.IMM5444.PAGE4.EXPERIENCE_TABLE.NAME" | translate }}
          </th>
          <th class="address-table__city">
            {{
              "RENEWAL.FORM.IMM5444.PAGE4.EXPERIENCE_TABLE.ACTIVITY" | translate
            }}
          </th>
          <th class="address-table__province">
            {{ "RENEWAL.FORM.CITY_TOWN_LABEL" | translate }}
          </th>
          <th class="address-table__country">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.COUNTRY" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of workEducationHistory; let i = index">
          <!-- From -->
          <td class="address-table__body address-table__from">
            {{ item.from | date : "yyyy/MM/dd" }}
          </td>
          <!-- To -->
          <td class="address-table__body address-table__to">
            {{ item.to | date : "yyyy/MM/dd" }}
          </td>
          <!-- Name -->
          <td class="address-table__body">
            {{ item.employerName }}
          </td>
          <!-- Activity -->
          <td class="address-table__body address-table__city">
            {{ item.activity }}
          </td>
          <!-- City -->
          <td class="address-table__body address-table__province">
            {{ item.cityOrTown }}
          </td>
          <!-- Country -->
          <td class="address-table__body address-table__country">
            {{ item.countryOrTerritory }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="section-container">
  <h2 class="emphasis">{{ "RENEWAL.FORM.IMM5444.PAGE5.5" | translate }}</h2>
  <!-- Absence Table -->
  <div class="absence-table__container">
    <div class="assessed-period-title">
      {{ "RENEWAL.FORM.IMM5444.PAGE5.5_LABEL" | translate }}
    </div>
    <div class="assessed-period-group">
      <div class="assessed-period-from">
        <div>
          {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.FROM" | translate }}:
        </div>
        <div class="assessed-period-value">
          {{ assessedFrom | date : "yyyy/MM/dd" }}
        </div>
      </div>
      <div class="assessed-period-to">
        <div>
          {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.TO" | translate }}:
        </div>
        <div class="assessed-period-value">
          {{ assessedTo | date : "yyyy/MM/dd" }}
        </div>
      </div>
    </div>

    <div class="outside-type-container">
      <div class="outside-type-group">
        <div class="outside-type-label">
          {{ "RENEWAL.FORM.IMM5444.PAGE5.5_GENERATED_L2" | translate }}
        </div>
        <div class="outside-type-value">
          {{ calculateTotalDays() }}
        </div>
      </div>
      <div class="outside-type-group">
        <div class="outside-type-label">
          {{ "RENEWAL.FORM.IMM5444.PAGE5.5_GENERATED_L3" | translate }}
        </div>
        <div class="outside-type-value">
          {{ totalDaysCalculator(true) }}
        </div>
      </div>
      <div class="outside-type-group">
        <div class="outside-type-label">
          {{ "RENEWAL.FORM.IMM5444.PAGE5.5_GENERATED_L4" | translate }}
        </div>
        <div class="outside-type-value">
          {{ totalDaysCalculator(false) }}
        </div>
      </div>
    </div>

    <div *ngIf="!timeSpentOutsideCa || timeSpentOutsideCa?.length === 0">
      <p class="no-results-text">
        {{ "RENEWAL.FORM.TABLE_NO_DATA" | translate }}
      </p>
    </div>
    <table *ngIf="timeSpentOutsideCa?.length > 0" class="table absence-table">
      <thead>
        <tr>
          <th class="absence-table__from">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.FROM" | translate }}
          </th>
          <th class="absence-table__to">
            {{ "RENEWAL.FORM.IMM5444.PAGE4.ADDRESS_TABLE.TO" | translate }}
          </th>
          <th class="absence-table__location">
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.LOCATION" | translate }}
          </th>
          <th class="absence-table__reason">
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.REASON" | translate }}
          </th>
          <th class="absence-table__other">
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.OTHER" | translate }}
          </th>
          <th class="absence-table__days">
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.DAYS" | translate }}
          </th>
          <th class="absence-table__days"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of timeSpentOutsideCa; let i = index">
          <!-- From -->
          <td class="absence-table__body absence-table__from">
            {{ item.from | date : "yyyy/MM/dd" }}
          </td>
          <!-- To -->
          <td class="absence-table__body absence-table__to">
            {{ item.to | date : "yyyy/MM/dd" }}
          </td>
          <!-- Location -->
          <td class="absence-table__body absence-table__location">
            {{ item.location }}
          </td>
          <!-- Reason -->
          <td class="absence-table__body absence-table__reason">
            {{ item.reason }}
          </td>
          <!-- Other -->
          <td class="absence-table__body absence-table__other">
            {{ item.otherReason }}
          </td>
          <!-- Days -->
          <td class="absence-table__body absence-table__days total">
            {{ calculateDays(item.from, item.to) }}
          </td>
          <td class="absence-table__body absence-table__days"></td>
        </tr>
        <tr>
          <td class="empty-cell"></td>
          <td class="empty-cell"></td>
          <td class="empty-cell"></td>
          <td class="empty-cell"></td>
          <td class="empty-cell"></td>
          <td class="total">{{ calculateTotalDays() }}</td>
          <td class="total">TOTAL</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="section-container">
  <h2 class="emphasis">{{ "RENEWAL.FORM.IMM5444.PAGE5.6" | translate }}</h2>
  <div class="relationship-table__container">
    <div *ngIf="!spouseParentDetails || spouseParentDetails?.length === 0">
      <p class="no-results-text">
        {{ "RENEWAL.FORM.TABLE_NO_DATA" | translate }}
      </p>
    </div>
    <table
      *ngIf="spouseParentDetails?.length > 0"
      class="table relationship-table"
    >
      <thead>
        <tr>
          <th>
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.FAMILY" | translate }}
          </th>
          <th>
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.GIVEN" | translate }}
          </th>
          <th>
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.RELATIONSHIP" | translate }}
          </th>
          <th>
            {{ "RENEWAL.FORM.IMM5444.PAGE5.TABLE.STATUS" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of spouseParentDetails; let i = index">
          <!-- Family Name -->
          <td class="relationship-table__body">
            {{ item.familyName }}
          </td>
          <!-- Given Name -->
          <td class="relationship-table__body">
            {{ item.givenName }}
          </td>
          <!-- Relation -->
          <td class="relationship-table__body">
            {{ item.relationship }}
          </td>
          <!-- Status -->
          <td class="relationship-table__body">
            {{ item.status }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
