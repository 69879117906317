import { Document } from "../../../core/models/document.model";
import {
  Case,
  PhaseStatus,
  CaseStatus,
} from "@pr-applicant/app/shared/case-module/case.model";
import { Lob } from "../../../core/models/lob.model";

import * as CaseActions from "./case.actions";
import { IGroupMember } from "../../services/psr/psr.service";

export const initialState: Case = {
  id: "",
  caseStatusId: 6,
  updatedAt: "",
  identityId: null,
  uci: "",
  cognitoApplicantId: null,
  cognitoCaseWorkerId: null,
  applicationNumber: null,
  applicationName: "",
  createdAt: "",
  surname: "",
  emailAddress: "",
  dob: "",
  addressApproved: null,
  clientLanded: null,
  phaseId: null,
  lobId: null,
  submittedAt: null,
  readOnly: false,
  members: [],
  representativeId: null,
  representativeConfirmationTimestamp: null,
  paWillSignWebform: null,
};

export function caseReducer(
  state: Case = initialState,
  action: CaseActions.CaseActions
): Case {
  switch (action.type) {
    case CaseActions.SET_CASE_BY_EMAIL:
      return {
        ...state,
        ...action.payload,
      };

    case CaseActions.UPDATE_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };

    case CaseActions.UPDATE_DECLARATION:
      return {
        ...state,
        ...action.payload,
      };

    case CaseActions.UPDATE_DESIGNATED_ENTITY:
      const commCert = action.payload.commCert;
      const designatedEntityId = action.payload.designatedEntityId;
      const hasCommCert = action.payload.hasCommCert;

      return {
        ...state,
        commCert,
        designatedEntityId,
        hasCommCert,
      };

    //  todo refactor duplicate documents/caseDocuments properties here
    case CaseActions.SET_DOCUMENT_BY_CASE_ID:
      const caseDocuments = action.payload.caseDocuments;
      return {
        ...state,
        caseDocuments,
        documents: caseDocuments,
      };

    case CaseActions.RESET_CASE_INFO:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
