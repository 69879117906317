export const documentTypes = {
  photo: {
    id: 1,
  },
  copr: {
    id: 2,
  },
  address: {
    id: 3,
  },
  proofOfPayment: {
    id: 4,
  },
  travelDocumentsAndPassports: {
    id: 5,
  },
  confirmationOfNomination: {
    id: 6,
  },
  proofOfLanguageProficiency: {
    id: 7,
  },
  identityAndCivilTravelDocuments: {
    id: 8,
  },
  childrenInformation: {
    id: 9,
  },
  policeCertificateAndClearance: {
    id: 10,
  },
  photoRequirements: {
    id: 11,
  },
  letterOfExplanation: {
    id: 12,
  },
  translationAffidavit: {
    id: 13,
  },
  Other: {
    id: 1000,
  },
  imm0008: {
    id: 15,
  },
  imm0008Dependent: {
    id: 16,
  },
  imm5406: {
    id: 28,
  },
  imm5562: {
    id: 37,
  },
  imm5669: {
    id: 41,
  },
  declarationSignature: {
    id: 40,
  },
  wetSignature: {
    id: 305,
  },
  declarationSignatureWith5669: {
    id: 301,
  },
  documentChecklist: {
    id: 51,
  },
  renewalDeclaration: {
    id: 92,
  },
};

export const webformPdfTypes = [
  documentTypes.imm0008.id,
  documentTypes.imm5406.id,
  documentTypes.imm5562.id,
  documentTypes.imm5669.id,
  documentTypes.declarationSignature.id,
];

export const uploadFileTypes = {
  photo: {
    types: ["image/png", "image/jpg", "image/jpeg"],
  },
  proofOfPayment: {
    types: [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/docx",
    ],
  },
  supportingDocuments: {
    types: [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/docx",
    ],
  },
  applicationForms: {
    types: ["application/pdf"],
  },
};

export const documentSizes = {
  photo: {
    maxFileSize: 5000000,
    megaFileConversion: 1000000,
  },
  forms: {
    maxFileSize: 4194304,
    megaFileConversion: 1048576,
  },
};

export const requiredDocsForSubmission = [
  documentTypes.imm0008.id,
  documentTypes.proofOfPayment.id,
  documentTypes.imm5669.id,
  documentTypes.declarationSignature.id,
  documentTypes.imm5406.id,
];

export const emppRequiredDocsForSubmission = [
  documentTypes.imm0008.id,
  documentTypes.imm5669.id,
  documentTypes.declarationSignature.id,
  documentTypes.imm5406.id,
];

export const DOC_LIMITS = {
  INTAKE_REP_APPLICATIONS: 4000,
  INTAKE_APPLICATIONS: 4000,
  PDF_FORM_ATTACHMENTS: 3,
  SUPPORTING_DOCS: 10,
  OTHER_SUPPORTING_DOCS: 10,
  PDF_FORM_ATTACHMENTS_RENEWAL: 50,
  SUPPORTING_DOCS_RENEWAL: 100,
  OTHER_SUPPORTING_DOCS_RENEWAL: 25,
  IMM0008: {
    MAX_DEPENDANTS: 50,
    VIEW_DEPENDANTS_BARCODE_LIMIT: 5,
    PREV_SPOUSES: 1,
    PREV_COUNTRIES: 2,
  },
  FAMILY_TABLE_MEMBERS: 50,
  IMM5406: {
    SIBLING_MAX: 100,
    CHILDREN: 100,
  },
  IMM5669: {
    EDUCATION_MAX: 100,
    PERSONAL_DETAILS_LIMIT: 100,
    MEMBERSHIP_LIMIT: 100,
    GOVERNMENT_POSITIONS_LIMIT: 100,
    MILITARY_POSITIONS_LIMIT: 100,
    ADDRESSES_LIMIT: 100,
  },
  IMM5562: {
    TRIP_MAX: 200,
  },
  MEMBER_DOCS: {
    DEFAULT: 1,
    OTHER: 10,
  },
};

// replace 9999 with DB id's
export const renewalDocTypes = {
  IMM5444: {
    id: 70,
  },
  IMM5644: {
    id: 71,
  },
  IMM5476: {
    id: 72,
  },
  IMM5475: {
    id: 73,
  },
  IMM5524: {
    id: 74,
  },
  IMM5627: {
    id: 75,
  },
  IRM0002: {
    id: 76,
  },
  IRM0004: {
    id: 77,
  },
  IRM0005: {
    id: 78,
  },
  OriginalValidPassport: {
    id: 79,
  },
  PhotocopiesPreviousPassports: {
    id: 80,
  },
  PhotocopiesSupportingDocumentsResidencyObligation: {
    id: 81,
  },
  SupplementaryTravelLog: {
    id: 82,
  },
  JobOpportunity: {
    id: 83,
  },
  WorkRelatedCurrentJob: {
    id: 84,
  },
  YourOwnSeriousIllness: {
    id: 85,
  },
  DeathOfFamilyMember: {
    id: 86,
  },
  SeriousIllnessOfFamilyMember: {
    id: 87,
  },
  CrisisEmergencyVulnerable: {
    id: 88,
  },
  SeriousIllnessOrDeathOfFamilyMember: {
    id: 89,
  },
  LostOrStolenOnTemporaryTravelOutsideCanada: {
    id: 90,
  },
  Other: {
    id: 91,
  },
  RenewalDeclarationSignature: {
    id: 92,
  },
  UploadFrontPhoto: {
    id: 93,
  },
  UploadBackPhoto: {
    id: 94,
  },
  PhotocopyPrimaryIdentityDocument: {
    id: 95,
  },
  PhotocopyOfOriginalPRCard: {
    id: 96,
  },
  ProofOfResidencyRequirements: {
    id: 97,
  },
  PhotocopyOfBirthOrLegalGuardianship: {
    id: 98,
  },
  SupportingLegalDocumentationForNameChange: {
    id: 99,
  },
  SupportingDocumentsHumanitarianCompassionateGrounds: {
    id: 100,
  },
  TravelDocumentsAndPassports: {
    id: 101,
  },
  ChildrenInformation: {
    id: 102,
  },
  PoliceCertificatesAndClearances: {
    id: 103,
  },
  Photos: {
    id: 104,
  },
  TranslationAndAffidavit: {
    id: 105,
  },
};

export const renewalWebformPdfTypes = [
  renewalDocTypes.IMM5444.id,
  renewalDocTypes.RenewalDeclarationSignature.id,
];

export const renewalUrgencyDocTypes = [
  renewalDocTypes.JobOpportunity.id,
  renewalDocTypes.WorkRelatedCurrentJob.id,
  renewalDocTypes.YourOwnSeriousIllness.id,
  renewalDocTypes.DeathOfFamilyMember.id,
  renewalDocTypes.SeriousIllnessOfFamilyMember.id,
  renewalDocTypes.CrisisEmergencyVulnerable.id,
  renewalDocTypes.SeriousIllnessOrDeathOfFamilyMember.id,
  renewalDocTypes.LostOrStolenOnTemporaryTravelOutsideCanada.id,
  renewalDocTypes.Other.id,
];

export const IMM5444_FORM_NAME = {
  PRTD_lob: {
    en: "Application for a Permanent Resident Travel Document (PRTD) (mandatory)",
    fr: "Demande d’un titre de voyage pour résident permanent (TVRP) (obligatoire)",
  },
  PRCR_lob: {
    en: "Application for a Permanent Resident Card (PR card) (mandatory)",
    fr: "Demande de carte de résident permanent (Carte RP) (obligatoire)",
  },
};
