import { DependentInfoFlattenedItem } from "../barcodes.model";
import {
  PropertyType,
  DateConversionOption,
} from "../../../core/models/data-retrieval.model";

export const barcode4Config: DependentInfoFlattenedItem[] = [
  {
    // 3 - this is the Barcode field order #
    //     in the BarcodeOrdering_For_IMM0008_06_2018 attached to https://ddcmtl.atlassian.net/browse/IPI-1014
    index: 0,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "givenNameOfSpouse",
    },
    targetField: "spouseGivenName",
  },
  {
    // 4
    index: 0,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "previouslyMarriedOrCommonLaw",
    },
    targetField: "previouslyMarried",
  },
  {
    // 5
    index: 0,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "previousSpouseFamilyName",
    },
    targetField: "previousSpouseFamilyName",
  },
  {
    // 6
    index: 0,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "previousSpouseGivenName",
    },
    targetField: "previousSpouseGivenName",
  },
  {
    // 7
    index: 0,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "typeOfRelationship",
    },
    targetField: "typeOfRelationship",
  },
  {
    // 8
    index: 0,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "startDateOfRelationship",
    },
    targetField: "startDateOfRelationship",
  },
  {
    // 9
    index: 0,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Date, sourceField: "endDateOfRelationship" },
    targetField: "endDateOfRelationship",
  },
  {
    // 10
    index: 0,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "validPassport" },
    targetField: "validPassport",
  },
  {
    // 11
    index: 0,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "passportNumber" },
    targetField: "passportNumber",
  },
  {
    // 12
    index: 0,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "countryOfIssue" },
    targetField: "countryOfIssue",
  },
  {
    // 13
    index: 0,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Date, sourceField: "issueDate" },
    targetField: "issueDate",
  },
  {
    // 14
    index: 0,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Date, sourceField: "expiryDate" },
    targetField: "expiryDate",
  },
  {
    // 15
    index: 0,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "usingTaiwanesePassportForTrip",
    },
    targetField: "usingTaiwanesePassportForTrip",
  },
  {
    // 16
    index: 0,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "usingIsraeliPassportForTrip",
    },
    targetField: "usingIsraeliPassportForTrip",
  },
  {
    // 17
    index: 0,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "nationalIdentityDocument",
    },
    targetField: "nationalIdentityDocument",
  },
  {
    // 18
    index: 0,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "nationalIdentityNumber",
    },
    targetField: "nationalIdentityNumber",
  },
  {
    // 19
    index: 0,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "countryOfIssue" },
    targetField: "nationalIdentityCountryOfIssue",
  },
  {
    // 20
    index: 0,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: { type: PropertyType.Date, sourceField: "issueDate" },
    targetField: "nationalIdentityIssueDate",
  },
  {
    // 21
    index: 0,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: { type: PropertyType.Date, sourceField: "expiryDate" },
    targetField: "nationalIdentityExpiryDate",
  },
  {
    // 22
    index: 0,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "educationLevel" },
    targetField: "educationLevel",
  },
  {
    // 23
    index: 0,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "numberOfYear" },
    targetField: "numberOfYear",
  },
  {
    // 24
    index: 0,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "currentOccupation",
    },
    targetField: "currentOccupation",
  },
  {
    // 25
    index: 0,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "intendedOccupation",
    },
    targetField: "intendedOccupation",
  },
  {
    // 26
    index: 0,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "nativeLanguage" },
    targetField: "nativeLanguage",
  },
  {
    // 28: 28 and 28 are swapped in order
    index: 0,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "preferredLanguage",
    },
    targetField: "preferredLanguage",
  },
  {
    // 27
    index: 0,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "language" },
    targetField: "language",
  },
  {
    // 29
    index: 0,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "takenTest" },
    targetField: "takenTest",
  },
  {
    // 30
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "familyName" },
    targetField: "familyName",
  },
  {
    // 31
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "givenName" },
    targetField: "givenName",
  },
  {
    // 32
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "usedOtherName" },
    targetField: "usedOtherName",
  },
  {
    // 33
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "otherFamilyName" },
    targetField: "otherFamilyName",
  },
  {
    // 34
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "otherGivenName" },
    targetField: "otherGivenName",
  },
  {
    // 35
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "uci" },
    targetField: "uci",
  },
  {
    // 36
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "sex" },
    targetField: "sex",
  },
  {
    // 37
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "heightInCM" },
    targetField: "heightInCM",
  },
  {
    // 38
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "eyeColour" },
    targetField: "eyeColour",
  },
];
