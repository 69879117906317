<div
  *ngIf="rows && rows.length > 0 && columnHeadings"
  class="webform-view-table"
>
  <h2 class="emphasis" *ngIf="heading">{{ heading | translate }}</h2>

  <div class="webform-view-table__container">
    <table class="table">
      <thead>
        <tr>
          <th class="webform-view-table__formName">
            {{ columnHeadings.formName | translate }}
          </th>
          <th class="webform-view-table__fieldset">
            {{ columnHeadings.fieldsetLegend | translate }}
          </th>
          <th class="webform-view-table__field">
            {{ columnHeadings.fieldLabel | translate }}
          </th>
          <th class="webform-view-table__data">
            {{ columnHeadings.data | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <!-- Form Name-->
          <td class="webform-view-table__formName">
            <p [innerHTML]="row.formName"></p>
            <p
              *ngIf="row.formName === '' || row.formName === null"
              class="visually-hidden"
            >
              {{
                "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.EMPTY_CELL.ARIA_LABEL"
                  | translate
              }}
            </p>
          </td>

          <!-- Fieldset Legend-->
          <td class="webform-view-table__fieldset">
            <p [innerHTML]="row.fieldsetLegend"></p>
            <p
              *ngIf="row.fieldsetLegend === '' || row.fieldsetLegend === null"
              class="visually-hidden"
            >
              {{
                "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.EMPTY_CELL.ARIA_LABEL"
                  | translate
              }}
            </p>
          </td>

          <!-- Field Label-->
          <td class="webform-view-table__field">
            {{ row.fieldLabel }}
            <p
              *ngIf="row.fieldLabel === '' || row.fieldLabel === null"
              class="visually-hidden"
            >
              {{
                "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.EMPTY_CELL.ARIA_LABEL"
                  | translate
              }}
            </p>
          </td>

          <!-- Form Data-->
          <td class="webform-view-table__formName">
            {{ row.data }}
            <p
              *ngIf="row.data === '' || row.data === null"
              class="visually-hidden"
            >
              {{
                "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.EMPTY_CELL.ARIA_LABEL"
                  | translate
              }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
