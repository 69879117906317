import { FormSelectOption } from "lib";

export const selectionOptions: FormSelectOption[] = [
  {
    value: null,
    text: {
      en: "Choose Status",
      fr: "Aucun statut assigné",
    },
  },
  {
    value: true,
    text: {
      en: "Approved",
      fr: "Approuvé",
    },
  },
  {
    value: false,
    text: {
      en: "Rejected",
      fr: "Rejeté",
    },
  },
];
export const CASE_ADDRESS_CT = "ADDRESS";
export const CASE_PHOTO_CT = "PHOTO";
