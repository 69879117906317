import { DependentInfoFlattenedItem } from "../barcodes.model";
import {
  PropertyType,
  DateConversionOption,
} from "../../../core/models/data-retrieval.model";

export const barcode5Config: DependentInfoFlattenedItem[] = [
  {
    // 3 - this is the Barcode field order #
    //     in the BarcodeOrdering_For_IMM0008_06_2018 attached to https://ddcmtl.atlassian.net/browse/IPI-1014
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "dob",
      dateConversionOption: DateConversionOption.Year,
    },
    targetField: "dobYear",
  },
  {
    // 4
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "dob",
      dateConversionOption: DateConversionOption.Month,
    },
    targetField: "dobMonth",
  },
  {
    // 5
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "dob",
      dateConversionOption: DateConversionOption.Day,
    },
    targetField: "dobDay",
  },
  {
    // 6
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "cityOfBirth" },
    targetField: "cityOfBirth",
  },
  {
    // 7
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "countryOfBirth" },
    targetField: "countryOfBirth",
  },
  {
    // 8
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "citizenship1" },
    targetField: "citizenship1",
  },
  {
    // 9
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "citizenship2" },
    targetField: "citizenship2",
  },
  {
    // 10
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "relationshipToPA" },
    targetField: "relationshipToPA",
  },
  {
    // 11
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "otherRelationshipToPA",
    },
    targetField: "otherRelationshipToPA",
  },
  {
    // 12
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "accompanyingPA" },
    targetField: "accompanyingPA",
  },
  {
    // 13
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "reasonNotAccompanying",
    },
    targetField: "reasonNotAccompanying",
  },
  {
    // 14
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "dependantType" },
    targetField: "dependantType",
  },
  {
    // 15
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Date, sourceField: "dateOfLastEntry" },
    targetField: "dateOfLastEntry",
  },
  {
    // 16
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "placeOfLastEntry" },
    targetField: "placeOfLastEntry",
  },
  {
    // 17
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: { type: PropertyType.Primitive, sourceField: "country" },
    },
    targetField: "CORCountry",
  },
  {
    // 18
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "immigrationStatus",
      },
    },
    targetField: "CORImmigrationStatus",
  },
  {
    // 19
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "otherImmigrationStatus",
      },
    },
    targetField: "COROtherImmigrationStatus",
  },
  {
    // 20
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Date,
        sourceField: "startDateOfImmigrationStatus",
      },
    },
    targetField: "CORCountryStartDateOfImmigrationStatus",
  },
  {
    // 21
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Date,
        sourceField: "endDateOfImmigrationStatus",
      },
    },
    targetField: "CORCountryEndDateOfImmigrationStatus",
  },
  {
    // 22
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "hasPreviousCountries",
    },
    targetField: "hasPreviousCountries",
  },
  {
    // 23
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 0,
        sourceField: "country",
      },
    },
    targetField: "PCOR1Country",
  },
  {
    // 24
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 0,
        sourceField: "immigrationStatus",
      },
    },
    targetField: "PCOR1ImmigrationStatus",
  },
  {
    // 25
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 0,
        sourceField: "otherImmigrationStatus",
      },
    },
    targetField: "PCOR1OtherImmigrationStatus",
  },
  {
    // 26
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Date,
        index: 0,
        sourceField: "startDateOfImmigrationStatus",
      },
    },
    targetField: "PCOR1StartDateOfImmigrationStatus",
  },
  {
    // 27
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Date,
        index: 0,
        sourceField: "endDateOfImmigrationStatus",
      },
    },
    targetField: "PCOR1EndDateOfImmigrationStatus",
  },
  {
    // 28
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 1,
        sourceField: "country",
      },
    },
    targetField: "PCOR2Country",
  },
  {
    // 29
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 1,
        sourceField: "immigrationStatus",
      },
    },
    targetField: "PCOR2ImmigrationStatus",
  },
  {
    // 30
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 1,
        sourceField: "otherImmigrationStatus",
      },
    },
    targetField: "PCOR2OtherImmigrationStatus",
  },
  {
    // 31
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Date,
        index: 1,
        sourceField: "startDateOfImmigrationStatus",
      },
    },
    targetField: "PCOR2StartDateOfImmigrationStatus",
  },
  {
    // 32
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Date,
        index: 1,
        sourceField: "endDateOfImmigrationStatus",
      },
    },
    targetField: "PCOR2EndDateOfImmigrationStatus",
  },
  {
    // 33
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "maritalStatus" },
    targetField: "currentMarritalStatus",
  },
  {
    // 34
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "dateOfMarriageOrCommonLaw",
    },
    targetField: "currentMarriageDate",
  },
  {
    // 35
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "familyNameOfSpouse",
    },
    targetField: "spouseFamilyName",
  },
  {
    // 36
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "givenNameOfSpouse",
    },
    targetField: "spouseGivenName",
  },
  {
    // 37
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "previouslyMarriedOrCommonLaw",
    },
    targetField: "previouslyMarried",
  },
  {
    // 38
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "previousSpouseFamilyName",
    },
    targetField: "previousSpouseFamilyName",
  },
  {
    // 39
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "previousSpouseGivenName",
    },
    targetField: "previousSpouseGivenName",
  },
  {
    // 40
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "typeOfRelationship",
    },
    targetField: "typeOfRelationship",
  },
  {
    // 41
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "startDateOfRelationship",
    },
    targetField: "startDateOfRelationship",
  },
  {
    // 42
    index: 1,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Date, sourceField: "endDateOfRelationship" },
    targetField: "endDateOfRelationship",
  },
  {
    // 43
    index: 1,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "validPassport" },
    targetField: "validPassport",
  },
  {
    // 44
    index: 1,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "passportNumber" },
    targetField: "passportNumber",
  },
  {
    // 45
    index: 1,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "countryOfIssue" },
    targetField: "countryOfIssue",
  },
  {
    // 46
    index: 1,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Date, sourceField: "issueDate" },
    targetField: "issueDate",
  },
  {
    // 47
    index: 1,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: { type: PropertyType.Date, sourceField: "expiryDate" },
    targetField: "expiryDate",
  },
  {
    // 48
    index: 1,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "usingTaiwanesePassportForTrip",
    },
    targetField: "usingTaiwanesePassportForTrip",
  },
  {
    // 49
    index: 1,
    type: PropertyType.Object,
    sourceField: "passportDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "usingIsraeliPassportForTrip",
    },
    targetField: "usingIsraeliPassportForTrip",
  },
];
