import { Injectable } from "@angular/core";
import {
  DataRetrievalItem,
  DateConversionOption,
  PropertyType,
} from "../models/data-retrieval.model";
import { RouteLocalizerService } from "../../routing/route-localizer.service";

@Injectable({
  providedIn: "root",
})
export class DataRetrievalService {
  constructor() {}

  public getPrimitiveBarcodeItem(obj: any, propertyName: string): string {
    return this.stringify(
      obj === undefined || obj === null ? obj : obj[propertyName]
    );
  }

  public getObjectBarcodeItem(
    obj: any,
    propertyName: string,
    subfield: DataRetrievalItem
  ): string {
    const val = obj && obj[propertyName];
    if (subfield.type === PropertyType.Primitive) {
      return this.getPrimitiveBarcodeItem(val, subfield.sourceField);
    } else if (subfield.type === PropertyType.Object && subfield.subfield) {
      return this.getObjectBarcodeItem(
        val,
        subfield.sourceField,
        subfield.subfield
      );
    } else if (subfield.type === PropertyType.Date) {
      return this.getDateBarcodeItem(
        val,
        subfield.sourceField,
        subfield.dateConversionOption
      );
    } else if (subfield.type === PropertyType.Array && subfield.subfield) {
      return this.getArrayBarcodeItem(
        val,
        subfield.sourceField,
        subfield.subfield
      );
    }
    return "";
  }

  public getDateBarcodeItem(
    obj: any,
    propertyName: string,
    dateConversionOption?: DateConversionOption
  ): string {
    if (obj === undefined || obj === null) {
      return this.getPrimitiveBarcodeItem(obj, propertyName);
    }
    const val = obj[propertyName];
    if (!dateConversionOption) {
      if (val) {
        return this.parseDate(val.split("T")[0]);
      } else {
        return "";
      }
    }
    return this.parseDate(val, dateConversionOption);
  }

  public getArrayBarcodeItem(
    obj: any,
    propertyName: string,
    subfield: DataRetrievalItem
  ): string {
    const index = subfield.index;
    const val = obj && obj[propertyName];
    if (Array.isArray(val) && index !== undefined) {
      if (subfield.type === PropertyType.Primitive) {
        return this.getPrimitiveBarcodeItem(val[index], subfield.sourceField);
      } else if (subfield.type === PropertyType.Object && subfield.subfield) {
        return this.getObjectBarcodeItem(
          val[index],
          subfield.sourceField,
          subfield.subfield
        );
      } else if (subfield.type === PropertyType.Date) {
        return this.getDateBarcodeItem(
          val[index],
          subfield.sourceField,
          subfield.dateConversionOption
        );
      }
    }
    return "";
  }

  /**
   * Returns the formatted date string, or year/month/day depending on the option passed into this function
   * @param date date string in the format of yyyy/MM/dd
   * @param dateConversionOption option to specify the year/month/day to be returned
   */
  private parseDate(
    date: string,
    dateConversionOption?: DateConversionOption
  ): string {
    if (date) {
      if (dateConversionOption) {
        const dateArray = date.split("/");
        switch (dateConversionOption) {
          case DateConversionOption.Year:
            return dateArray[0];
          case DateConversionOption.Month:
            return dateArray[1].replace(/^0+/, "");
          case DateConversionOption.Day:
            return dateArray[2].replace(/^0+/, "");
        }
      }
      return date.split("-").join("/");
    }
    return "";
  }

  private stringify(val?: string | boolean | null): string {
    if (val === undefined || val === null) {
      return "";
    }
    return val.toString();
  }
}
