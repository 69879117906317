import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "lib-layout-col",
  templateUrl: "./layout-col.component.html",
  styleUrls: ["./layout-col.component.scss"],
})
export class LayoutColComponent implements OnInit {
  constructor() {}

  @Input() gap: String;
  @Input() flex: String;

  ngOnInit(): void {}
}
