import { Component, Input, OnInit } from "@angular/core";
import { LovService } from "lib";

@Component({
  selector: "prcw-webform-view-imm5444-sub-tables-view",
  templateUrl: "./webform-view-imm5444-sub-tables-view.component.html",
  styleUrls: ["./webform-view-imm5444-sub-tables-view.component.scss"],
})
export class Webform5444SubTablesViewComponent implements OnInit {
  @Input()
  formData: any;

  @Input()
  lang: string;

  addressHistory: any[];
  workEducationHistory: any[];

  timeSpentOutsideCa: any[];
  spouseParentDetails: any[];

  assessedFrom: Date;
  assessedTo: Date;
  lists: any;

  constructor(private lovService: LovService) {}

  ngOnInit(): void {
    if (!!this.formData) {
      const form = this.formData?.form;

      this.addressHistory = form.personalHistory.addressHistory;
      this.workEducationHistory = form.personalHistory.workHistory;
      this.timeSpentOutsideCa = form.residencyObligation.travelHistory;
      this.spouseParentDetails = form.residencyObligation.travelCompanions;
      this.assessedFrom = form.residencyObligation.travelAssessmentPeriod.from;
      this.assessedTo = form.residencyObligation.travelAssessmentPeriod.to;
      this.translateData();
    }
  }

  translateData() {
    this.addressHistory.forEach((address) => {
      address.countryOrTerritory = this.translate(
        address.countryOrTerritory,
        this.lovService.lovs.country,
        this.lang
      );
    });
    this.workEducationHistory.forEach((work) => {
      work.countryOrTerritory = this.translate(
        work.countryOrTerritory,
        this.lovService.lovs.country,
        this.lang
      );
    });
    this.timeSpentOutsideCa.forEach((outsideTime) => {
      outsideTime.reason = this.translate(
        outsideTime.reason,
        this.lovService.lovs.reasonsForAbsence5444,
        this.lang
      );
    });
    this.spouseParentDetails.forEach((family) => {
      family.relationship = this.translate(
        family.relationship,
        this.lovService.lovs.relationship5444,
        this.lang
      );
      family.status = this.translate(
        family.status,
        this.lovService.lovs.statusInCanada5444,
        this.lang
      );
    });
  }

  translate(key: string, lov: Array<any>, lang: any) {
    if (lov && Array.isArray(lov)) {
      const match = lov.find(
        (item) => item.value === (key === null ? key : key.toString())
      );
      if (match) {
        return match.text[lang];
      }
    }
    return "";
  }

  calculateDays(from: Date, to: Date) {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    let utcFrom = 0;
    let utcTo = 0;
    let diffDays = 0;
    const assessedFrom = new Date(this.assessedFrom);
    const assessedTo = new Date(this.assessedTo);
    const utcToday = Date.UTC(
      assessedTo.getFullYear(),
      assessedTo.getMonth(),
      assessedTo.getDate()
    );
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    if (assessedFrom > toDate) {
      return 0;
    } else {
      if (assessedFrom > fromDate) {
        utcFrom = Date.UTC(
          assessedFrom.getFullYear(),
          assessedFrom.getMonth(),
          assessedFrom.getDate() - 1
        );
      } else {
        utcFrom = Date.UTC(
          fromDate.getFullYear(),
          fromDate.getMonth(),
          fromDate.getDate() - 1
        );
      }
    }
    if (assessedTo < fromDate) {
      return 0;
    } else {
      if (assessedTo > toDate) {
        utcTo = Date.UTC(
          toDate.getFullYear(),
          toDate.getMonth(),
          toDate.getDate()
        );
      } else {
        utcTo = Date.UTC(
          assessedTo.getFullYear(),
          assessedTo.getMonth(),
          assessedTo.getDate()
        );
      }
    }
    if (utcTo === utcToday) {
      diffDays = Math.floor((utcTo - utcFrom) / _MS_PER_DAY) - 1;
    } else {
      diffDays = Math.floor((utcTo - utcFrom) / _MS_PER_DAY) - 2;
    }
    if (diffDays < 0) {
      return 0;
    } else {
      return diffDays;
    }
  }

  calculateTotalDays() {
    let totalDays = 0;
    this.timeSpentOutsideCa?.forEach((travel) => {
      totalDays += this.calculateDays(travel.from, travel.to);
    });
    return totalDays;
  }

  totalDaysCalculator(totalDyasInABC: boolean) {
    let totalDays2 = 0;
    let totalDays3 = 0;
    this.timeSpentOutsideCa?.forEach((trip) => {
      const reason = trip.reason;
      switch (reason) {
        case "A":
        case "B":
        case "C":
          totalDays2 += this.calculateDays(trip.from, trip.to);
          break;
        case "Other":
          totalDays3 += this.calculateDays(trip.from, trip.to);
          break;
      }
    });
    if (totalDyasInABC) {
      return totalDays2;
    }
    return totalDays3;
  }
}
