<div>
  <h1 class="title emphasis">
    {{ "RESET.EMAIL.PAGE_TITLE" | translate }}
  </h1>
  <p class="desc">{{ "RESET.EMAIL.FORM_DESCRIPTION" | translate }}</p>
</div>
<div class="wrapper">
  <div class="form">
    <h2 class="emphasis form-title">
      {{ "RESET.EMAIL.FORM_TITLE" | translate }}
    </h2>
    <form [formGroup]="emailForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <lib-form-input
        identifier="email"
        type="email"
        [formControl]="emailForm.controls.email"
        [label]="'RESET.EMAIL.FORM_INPUT_LABEL' | translate"
        required="true"
      >
        <span
          *ngIf="
            emailForm.controls.email.touched &&
            emailForm.controls.email.errors?.email
          "
        >
          {{ "LOGIN.ERRORS.INVALID_EMAIL" | translate }}
        </span>
        <span
          *ngIf="
            emailForm.controls.email.touched &&
            emailForm.controls.email.errors?.required
          "
        >
          {{ "LOGIN.ERRORS.REQUIRED_EMAIL" | translate }}
        </span>
      </lib-form-input>

      <button
        [disabled]="!emailForm.valid"
        class="form-submit btn btn-primary"
        type="submit"
      >
        {{ "RESET.EMAIL.FORM_SUBMIT" | translate }}
      </button>
    </form>
  </div>
</div>
