import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import routePaths from "../../../../routing/route-paths";

@Component({
  selector: "prcw-reset-password-page",
  templateUrl: "./reset-password-page.component.html",
  styleUrls: ["./reset-password-page.component.scss"],
})
export class ResetPasswordPageComponent implements OnInit {
  public isFirstLogin = false;

  public emailFromUrl: string;
  public currentLang: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routeLocalizer: RouteLocalizerService
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("RESET.TITLE_FORGOT_PASSWORD");
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    /* If email/usernameID doesnt exist in the link, route them back to the password recovery page or login page */
    this.route.queryParams.subscribe((params) => {
      this.emailFromUrl = params.email;
    });

    if (this.emailFromUrl === undefined) {
      const url = `/${this.currentLang}/${
        this.currentLang === "fr"
          ? routePaths.fr.FORGOT_PASSWORD
          : routePaths.en.FORGOT_PASSWORD
      }`;
      this.router.navigate([url]);
    }
  }
}
