import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ModalService } from "../../services/modal/modal.service";
import { IIconConfig, DSSizes } from "ircc-ds-angular-component-library";

@Component({
  selector: "lib-modal",
  styleUrls: ["./modal.component.scss"],
  templateUrl: "./modal.component.html",
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  @Input() public title: string;
  @Input() public deleteModal: boolean;

  public trashIcon: IIconConfig = {
    FA_keywords: "fa-regular fa-trash",
    size: DSSizes.small,
  };

  private element: HTMLElement;
  private modalInitiator: HTMLElement | null;

  /* optional focus specifier used in modal content,
    if focus should be set somewhere other than first focusable element */
  @ContentChild("modalFocus") modalFocus?: ElementRef;

  @Output() public escape: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    /* if id exists, add instance to modals array */
    if (this.id) {
      this.modalService.add(this);
    }
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
  }

  public get bodyId(): string {
    return `${this.id}__body`;
  }

  public open(): void {
    this.element.style.display = "flex";
    document.body.style.overflow = "hidden";
    this.modalInitiator = document.activeElement as HTMLElement;
    if (this.modalFocus) {
      this.modalFocus.nativeElement.focus();
    } else {
      this.firstFocusableChild.focus();
    }
  }

  public close(): void {
    this.element.style.display = "none";
    document.body.style.overflow = "auto";
    this.modalInitiator?.focus();
    this.modalInitiator = null;
  }

  private get allFocusableChildren(): HTMLElement[] {
    const focusable = Array.from<HTMLElement>(
      this.element.querySelectorAll(
        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      )
    );
    return focusable.filter((el: HTMLElement) => !el.hasAttribute("disabled"));
  }

  private get firstFocusableChild(): HTMLElement {
    return this.allFocusableChildren[0];
  }

  private get lastFocusableChild(): HTMLElement {
    return this.allFocusableChildren[this.allFocusableChildren.length - 1];
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(e: KeyboardEvent): void {
    /* trap focus in modal */
    const tabOnly = (e.key === "Tab" || e.code === "9") && !e.shiftKey;
    const shiftTab = (e.key === "Tab" || e.code === "9") && e.shiftKey;
    if (e.target === this.firstFocusableChild && shiftTab) {
      e.preventDefault();
      this.lastFocusableChild.focus();
    }
    if (e.target === this.lastFocusableChild && tabOnly) {
      e.preventDefault();
      this.firstFocusableChild.focus();
    }

    /* close on 'escape', and notify parent */
    if (e.key === "Escape") {
      this.close();
      this.escape.emit();
    }
  }
}
