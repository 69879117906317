<h1 class="emphasis">{{ "CREATE_CASE.PAGE_HEADING" | translate }}</h1>

<form [formGroup]="caseForm" autocomplete="off">
  <fieldset>
    <h2 class="emphasis">
      {{ "CREATE_CASE.FORM_HEADING_ACCOUNT" | translate }}
    </h2>
    <!-- email -->
    <lib-form-input
      identifier="emailAddress"
      [formControl]="caseForm.controls.emailAddress"
      [label]="'CREATE_CASE.FORM_LABELS.EMAIL' | translate"
      required="true"
      [helpText]="'CREATE_CASE.FORM_VERIFY_EMAIL' | translate"
    >
      <span
        *ngIf="
          caseForm.get('emailAddress')?.touched &&
          caseForm.get('emailAddress')?.hasError('required')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          caseForm.get('emailAddress')?.touched &&
          caseForm.get('emailAddress')?.hasError('email')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.EMAIL" | translate }}
      </span>
      <span *ngIf="caseForm.get('emailAddress')?.touched && createCaseError">
        {{ "CREATE_CASE.FORM_ERRORS.CREATE" | translate }}
      </span>
    </lib-form-input>
  </fieldset>

  <fieldset *ngIf="showClientDetailsForm" id="caseDetailsForm">
    <h2 class="emphasis">{{ "CREATE_CASE.FORM_HEADING_CASE" | translate }}</h2>

    <!-- OFFICE -->
    <lib-form-select
      identifier="office"
      class="office-dropdown"
      data-cy="confirmation-office-dropdown"
      widthMd="true"
      [formControl]="caseForm.controls.office"
      [label]="'CREATE_CASE.FORM_LABELS.OFFICE' | translate"
      [options]="officeOptions"
      [required]="true"
    >
      <span
        *ngIf="
          caseForm.get('office')?.touched &&
          caseForm.get('office')?.hasError('required')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>

    <div class="form__caseDetails__section">
      <p
        [innerHTML]="'CREATE_CASE.FORM_LABELS.CLIENT_IS_PA_LABEL' | translate"
      ></p>
      <div class="checkbox-control">
        <input
          value="true"
          id="isPrincipalApplicant"
          name="isPrincipalApplicant"
          [formControl]="caseForm.controls.isPrincipalApplicant"
          type="checkbox"
          tabindex="0"
        />
        <label
          [innerHTML]="
            'CREATE_CASE.FORM_LABELS.CLIENT_IS_PA_CHECKBOX' | translate
          "
          for="isPrincipalApplicant"
        ></label>
      </div>
    </div>

    <!-- Client has already landed? (Copr Exists already) -->
    <div class="form__caseDetails__section">
      <p [innerHTML]="'CREATE_CASE.FORM_LANDING' | translate"></p>
      <div class="checkbox-control">
        <input
          value="true"
          id="clientLanded"
          name="clientLanded"
          [formControl]="caseForm.controls.clientLanded"
          type="checkbox"
          tabindex="0"
        />
        <label
          [innerHTML]="'CREATE_CASE.FORM_LABELS.LANDED' | translate"
          for="clientLanded"
        ></label>
      </div>
    </div>
  </fieldset>
  <fieldset *ngIf="showClientDetailsForm" id="caseDetailsForm">
    <!-- Account / Client details -->
    <legend>{{ "CREATE_CASE.FORM_ACCOUNT" | translate }}</legend>

    <!-- UCI -->
    <lib-form-input
      identifier="uci"
      [formControl]="caseForm.controls.uci"
      [label]="'CREATE_CASE.FORM_LABELS.UCI' | translate"
      required="true"
    >
      <span
        *ngIf="
          caseForm.get('uci')?.touched &&
          caseForm.get('uci')?.hasError('required')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          caseForm.get('uci')?.touched &&
          caseForm.get('uci')?.hasError('maxlength')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
      </span>
      <span
        *ngIf="
          caseForm.get('uci')?.touched &&
          caseForm.get('uci')?.hasError('onlyNumbers')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.ONLY_NUMBERS" | translate }}
      </span>
    </lib-form-input>

    <!-- application # -->
    <lib-form-input
      identifier="applicationNumber"
      [formControl]="caseForm.controls.applicationNumber"
      [label]="'CREATE_CASE.FORM_LABELS.APPLICATION_NUM' | translate"
      required="true"
    >
      <span
        *ngIf="
          caseForm.get('applicationNumber')?.touched &&
          caseForm.get('applicationNumber')?.hasError('required')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          caseForm.get('applicationNumber')?.touched &&
          caseForm.get('applicationNumber')?.hasError('maxlength')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
      </span>
    </lib-form-input>

    <!-- surname -->
    <lib-form-input
      identifier="surname"
      [formControl]="caseForm.controls.surname"
      [label]="'CREATE_CASE.FORM_LABELS.SURNAME' | translate"
      required="true"
    >
      <span
        *ngIf="
          caseForm.get('surname')?.touched &&
          caseForm.get('surname')?.hasError('required')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          caseForm.get('surname')?.touched &&
          caseForm.get('surname')?.hasError('maxlength')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          caseForm.get('surname')?.touched &&
          caseForm.get('surname')?.hasError('pattern')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.INVALID_CHARACTERS" | translate : max100 }}
      </span>
    </lib-form-input>

    <!-- given name -->
    <lib-form-input
      identifier="givenName"
      [formControl]="caseForm.controls.givenName"
      [label]="'CREATE_CASE.FORM_LABELS.GIVEN_NAME' | translate"
    >
      <span
        *ngIf="
          caseForm.get('givenName')?.touched &&
          caseForm.get('givenName')?.hasError('maxlength')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          caseForm.get('givenName')?.touched &&
          caseForm.get('givenName')?.hasError('pattern')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.INVALID_CHARACTERS" | translate : max100 }}
      </span>
    </lib-form-input>

    <!-- DOB -->
    <lib-form-input
      identifier="dob"
      [formControl]="caseForm.controls.dob"
      [label]="'CREATE_CASE.FORM_LABELS.DOB' | translate"
      maxlength="10"
      required="true"
    >
      <span
        *ngIf="
          caseForm.get('dob')?.touched &&
          caseForm.get('dob')?.hasError('required')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          caseForm.get('dob')?.touched &&
          caseForm.get('dob')?.hasError('wildcardDate')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.DATE" | translate }}
      </span>
      <span
        *ngIf="
          caseForm.get('dob')?.touched &&
          caseForm.get('dob')?.hasError('pastDate')
        "
      >
        {{ "CREATE_CASE.FORM_ERRORS.DOB_PAST" | translate }}
      </span>
    </lib-form-input>

    <div class="form__caseDetails__confirmText">
      <h2 class="emphasis">{{ "CREATE_CASE.CONFIRM_HEADING" | translate }}</h2>
      <p>{{ "CREATE_CASE.CONFIRM_TEXT" | translate }}</p>
    </div>
  </fieldset>

  <lib-layout-row gap="one-and-half">
    <a
      *ngIf="!showClientDetailsForm"
      [routerLink]="
        routeLocalizer.get(currentLang, 'CONFIRMATION_CASES', '../../')
      "
      category="secondary"
      tabindex="0"
    >
      {{ "CREATE_CASE.BACK_LINK" | translate }}
    </a>
    <a
      *ngIf="showClientDetailsForm"
      (click)="handleToggleForm(false)"
      category="secondary"
      tabindex="0"
    >
      {{ "CREATE_CASE.BACK_LINK" | translate }}
    </a>
    <button
      type="button"
      category="primary"
      *ngIf="!showClientDetailsForm"
      (click)="handleToggleForm(true)"
      [disabled]="
        caseForm.get('emailAddress')?.hasError('required') ||
        caseForm.get('emailAddress')?.hasError('email')
      "
    >
      {{ "CREATE_CASE.VERIFY_BUTTON" | translate }}
    </button>
    <button
      type="submit"
      category="primary"
      *ngIf="showClientDetailsForm"
      (click)="createCase()"
      [disabled]="!caseForm.valid || isCaseSubmitted"
    >
      {{ "CREATE_CASE.SUBMIT_BUTTON" | translate }}
    </button>
  </lib-layout-row>
</form>

<lib-modal
  [id]="accountExistedModalId"
  [title]="'CREATE_CASE.ACCOUNT_EXISTED_MODAL.TITLE' | translate"
>
  <ng-container modalBody>
    <p class="subheading">
      {{ "CREATE_CASE.ACCOUNT_EXISTED_MODAL.DESCRIPTION_P1" | translate }}
    </p>
    <p>
      {{ "CREATE_CASE.ACCOUNT_EXISTED_MODAL.DESCRIPTION_P2" | translate }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" (click)="navigateToNextStep()">
      {{ "CREATE_CASE.ACCOUNT_EXISTED_MODAL.CONTINUE" | translate }}
    </button>
    <button category="secondary" (click)="closeAccountExistedModal()">
      {{ "CREATE_CASE.ACCOUNT_EXISTED_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
