<div
  class="form-input"
  aria-live="assertive"
  [ngClass]="{ 'no-margin-bottom': eraseMarginBottom }"
>
  <label class="form-input__label" [for]="identifier">
    <div class="form-input__label-info">
      <div *ngIf="required && !hideRequired" class="form-input__required-icon">
        <lib-icon-chooser icon="asterisk"></lib-icon-chooser>
      </div>
      <div class="form-input__shift">
        <span [ngClass]="{ 'form-input__label-span-no-wrap': allowOverflow }">{{
          label
        }}</span>
        <!-- <span *ngIf="required && !hideRequired" class="form-input__required">
          ({{ requiredText }})
        </span> -->
      </div>
    </div>
    <div
      *ngIf="helpText !== ''"
      class="form-input__help-text"
      [id]="identifier + '-help-text'"
      [innerHTML]="helpText"
    ></div>
  </label>

  <div class="form-input__field-container" *ngIf="editable">
    <input
      [ngClass]="{ password: originalType === 'password' }"
      class="form-input__field"
      [type]="type"
      #formInput
      [id]="identifier"
      [name]="identifier"
      [formControl]="ngControl.control"
      [value]="value | safeTrim"
      [placeholder]="placeholder"
      (input)="onChange($event.target.value)"
      (blur)="handleBlur($event)"
      [attr.aria-placeholder]="ariaPlaceHolder"
      [required]="required"
      [attr.aria-required]="required"
      [autocomplete]="autocomplete"
    />
    <button
      *ngIf="originalType === 'password'"
      class="form-input__password-hide"
      category="plain"
      type="button"
      (click)="togglePasswordHide()"
      [id]="identifier + 'Peek'"
      [attr.aria-label]="peekButtonLabel"
    >
      <img
        class="form-input__password-hide-btn"
        [src]="hidePasswordImg"
        alt=""
      />
    </button>
  </div>

  <!-- <ng-container *ngIf="!showEditableInput"> -->
  <!-- <span class="plaintext-field">
      {{ ngControl?.control?.value }}
    </span>
    <span *ngIf="!ngControl?.control?.value" class="visually-hidden">
      {{ noDataText }}
    </span> -->
  <div class="form-input__field-container" *ngIf="!editable">
    <input
      class="form-input__field"
      [type]="type"
      #formInput
      [value]="value | safeTrim"
      [attr.disabled]="true"
      [required]="required"
      [attr.aria-required]="required"
    />
  </div>
  <!-- </ng-container> -->

  <div
    *ngIf="
      (ngControl.errors && ngControl.touched && validateOn === 'blur') ||
      (ngControl.errors && validateOn === 'change')
    "
    class="form-input__errors"
  >
    <ng-content>
      <!-- error spans -->
    </ng-content>
  </div>
</div>
