import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "prcw-submission-time-section",
  templateUrl: "./submission-time.component.html",
  styleUrls: [
    "../client-information-section/client-information-section.component.scss",
  ],
})
export class SubmissionTimeComponent implements OnInit {
  @Input() submittedTime: string | null;
  public formattedSubmissionTime = "";

  ngOnInit() {
    this.formattedSubmissionTime = this.submittedTime?.replace(/\s/, "T") || "";
  }
}
