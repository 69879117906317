import { PropertyType } from "../../models/data-retrieval.model";
import { ConfigTableData } from "../webform-view-table.model";

export const imm0008PAConfigs: ConfigTableData = {
  heading: "INTAKE.IMM0008.TABLE_TITLES.PRIMARY_APPLICANT",
  fields: [
    /* ----------- Application Details ----------- */
    // Number of family members
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceLabels: {
        formName: "INTAKE.IMM0008.PAGES.APPLICATION_DETAILS.SUBHEADING",
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.FAMILY_MEMBERS.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.FAMILY_MEMBERS.NUMBERS_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "familyMembers" },
    },
    // Correspondence
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceList: "preferenceLanguage",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.CORRESPONDENCE_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "correspondence" },
    },
    // Interview
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceList: "contactLanguage",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.INTERVIEW_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "interview" },
    },
    // Interpreter requested
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.INTERPRETER_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "interpreterRequested",
      },
    },
    // Province/Territory
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceList: "provinceAbbrev",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LOCATION.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LOCATION.PROVINCE_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "province" },
    },
    // City/Town
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceList: "city",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LOCATION.CITY_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "city" },
    },
    // Certificat de Sélection du Québec (CSQ)
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.LEGEND",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "receivedCSQ" },
    },
    // CSQ Number
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.NUMBER_LEGEND",
        fieldLabel: "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.NUMBER_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "csqNumber" },
    },
    // CSQ Date
    {
      type: PropertyType.Object,
      sourceField: "applicationDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.DATE_LEGEND",
        fieldLabel: "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.DATE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "dateAppliedForCSQ",
      },
    },

    /* ----------- Personal Details ----------- */
    // Full name - Family name
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        formName: "INTAKE.IMM0008.PAGES.PERSONAL_DETAILS.SUBHEADING",
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.SURNAME_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "familyName" },
    },
    // Full name - Given name(s)
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.GIVEN_NAME_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "givenName" },
    },
    // Have you ever used any other name
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.NICKNAME.LEGEND",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "usedOtherName" },
    },
    // Other name - Family name(s)
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.SURNAME_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "otherFamilyName",
      },
    },
    // Other name - Given name(s)
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.GIVEN_NAME_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "otherGivenName" },
    },
    // UCI
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.UCI.LEGEND",
        fieldLabel: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.UCI.UCI_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "uci" },
    },
    // Physical characteristics - Sex
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "gender",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.LEGEND",
        fieldLabel: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.SEX_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "sex" },
    },
    // Physical characteristics - Eye color
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "eyeColor",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.EYE_COLOUR_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "eyeColour" },
    },
    // Physical characteristics - Height
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.HEIGHT_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "heightInCM" },
    },
    // Birth information - DOB
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.DOB_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "dob" },
    },
    // Birth information - Place of birth
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.CITY_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "cityOfBirth" },
    },
    // Birth information - Country of birth
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "countryOfBirth",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.COUNTRY_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "countryOfBirth" },
    },
    // Citizenship(s) - Country 1
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "countryOfCitizenship",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.COUNTRY_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "citizenship1" },
    },
    // Citizenship(s) - Country 2
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "countryOfCitizenship",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.COUNTRY_2_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "citizenship2" },
    },
    // Current country of residence - Country
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "country",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.COUNTRY_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "currentCountry",
        subfield: { type: PropertyType.Primitive, sourceField: "country" },
      },
    },
    // Current country of residence - Status
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "immigrationStatus",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.IMMIGRATION_STATUS_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "currentCountry",
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "immigrationStatus",
        },
      },
    },
    // Current country of residence - Start date
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.START_DATE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "currentCountry",
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "startDateOfImmigrationStatus",
        },
      },
    },
    // Current country of residence - End date
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.END_DATE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "currentCountry",
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "endDateOfImmigrationStatus",
        },
      },
    },
    // Current country of residence - Other status
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.OTHER_DETAILS_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "currentCountry",
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "otherImmigrationStatus",
        },
      },
    },
    // Date of your last entry to Canada
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.DATE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "dateOfLastEntry",
      },
    },
    // Place of your last entry to Canada
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.PLACE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "placeOfLastEntry",
      },
    },
    // Previous countries of residence
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PREV_COUNTRIES.LEGEND",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "hasPreviousCountries",
      },
    },
    // Provide pervious countries
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.LEGEND",
      },
    },
    // Provide Previous countries of residence
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: "previousCountries",
        complexSubfield: [
          {
            // country
            type: PropertyType.Primitive,
            sourceField: "country",
            complexSourceList: ["personalDetails", "country"],
            sourceLabels: {
              fieldsetLegend:
                "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.COUNTRY_LEGEND",
              fieldLabel:
                "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.COUNTRY_LABEL",
            },
          },
          {
            // Immigration Status
            type: PropertyType.Primitive,
            sourceField: "immigrationStatus",
            complexSourceList: ["personalDetails", "immigrationStatus"],
            sourceLabels: {
              fieldLabel:
                "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.IMMIGRATION_STATUS_LABEL",
            },
          },
          {
            // Start date
            type: PropertyType.Primitive,
            sourceField: "startDateOfImmigrationStatus",
            sourceLabels: {
              fieldLabel:
                "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.START_DATE_LABEL",
            },
          },
          {
            // End date
            type: PropertyType.Primitive,
            sourceField: "endDateOfImmigrationStatus",
            sourceLabels: {
              fieldLabel:
                "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.END_DATE_LABEL",
            },
          },
          {
            // Other status
            type: PropertyType.Primitive,
            sourceField: "otherImmigrationStatus",
            sourceLabels: {
              fieldLabel:
                "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.OTHER_DETAILS_LABEL",
            },
          },
        ],
      },
    },
    // Current marital status
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "maritalStatus",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_STATUS.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_STATUS.MARITAL_STATUS_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "maritalStatus" },
    },
    // Provide the date on which you were married
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_DATE.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_DATE.DATE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "dateOfMarriageOrCommonLaw",
      },
    },
    // Provide name of current partner - Family name
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.SURNAME_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "familyNameOfSpouse",
      },
    },
    // Provide name of current partner - Given name
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.GIVEN_NAME_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "givenNameOfSpouse",
      },
    },
    // Have you previously been married
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PREV_RELATIONSHIP.LEGEND",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "previouslyMarriedOrCommonLaw",
      },
    },
    // Provide Previous relationship - Family name
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.SURNAME_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "previousSpouseFamilyName",
      },
    },
    // Provide Previous relationship - Given name
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.GIVEN_NAME_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "previousSpouseGivenName",
      },
    },
    // Provide Previous relationship - Date of birth
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.DOB_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "previousSpouseDob",
      },
    },
    // Provide Previous relationship - Type of relationship
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceList: "maritalStatusHistory",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.RELATIONSHIP_TYPE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "typeOfRelationship",
      },
    },
    // Provide Previous relationship - Start date
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.START_DATE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "startDateOfRelationship",
      },
    },
    // Provide Previous relationship - End date
    {
      type: PropertyType.Object,
      sourceField: "personalDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.END_DATE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "endDateOfRelationship",
      },
    },
    /* ----------- Address/Contact Details ----------- */
    // Current Mailing Address - P.O. Box
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        formName: "INTAKE.IMM0008.PAGES.ADDRESS.SUBHEADING",
        fieldsetLegend: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.LEGEND",
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POBOX_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "postOfficeBox",
        },
      },
    },
    // Current Mailing Address - Apt./Unit
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.APTUNIT_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "apartmentUnit",
        },
      },
    },
    // Current Mailing Address - Street Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNUM_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "streetNumber" },
      },
    },
    // Current Mailing Address - Street Name
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNAME_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "streetName" },
      },
    },
    // Current Mailing Address - City/Town
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.CITY_TOWN_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "city" },
      },
    },
    // Current Mailing Address - Country
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "country",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.COUNTRY_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "country" },
      },
    },
    // Current Mailing Address - Province/State
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "provinceAbbrev",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.PROVINCE_STATE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "province" },
      },
    },
    // Current Mailing Address - Postal Code
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POSTAL_CODE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "postalCode" },
      },
    },
    // Current Mailing Address - District
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.DISTRICT_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "mailingAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "district" },
      },
    },
    // Residential address same as mailing address?
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.ADDRESS.SAME_ADDRESS.LEGEND",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "residentialSameAsMailingAddress",
      },
    },
    // Residential Address - Apt./Unit
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.ADDRESS.RESIDENTIAL_ADDRESS.LEGEND",
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.APTUNIT_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "apartmentUnit",
        },
      },
    },
    // Residential Address - Street Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNUM_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "streetNumber" },
      },
    },
    // Residential Address - Street Name
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNAME_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "streetName" },
      },
    },
    // Residential Address - City/Town
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.CITY_TOWN_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "city" },
      },
    },
    // Residential Address - Country
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "country",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.COUNTRY_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "country" },
      },
    },
    // Residential Address - Province/State
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "provinceAbbrev",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.PROVINCE_STATE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "province" },
      },
    },
    // Residential Address - Postal Code
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POSTAL_CODE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "postalCode" },
      },
    },
    // Residential Address - District
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.DISTRICT_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "residentialAddress",
        subfield: { type: PropertyType.Primitive, sourceField: "district" },
      },
    },
    // Primary Telephone Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "canadaUSAOrOtherList",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.PRIMARY_LEGEND",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "primaryTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "canadaOrUSA" },
      },
    },
    // Primary Telephone Number - Type
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "phoneType",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.TYPE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "primaryTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "type" },
      },
    },
    // Primary Telephone Number - Country Code
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_CODE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "primaryTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "countryCode" },
      },
    },
    // Primary Telephone Number - Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.NUMBER_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "primaryTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "number" },
      },
    },
    // Primary Telephone Number - Extension
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.EXTENSION_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "primaryTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "extension" },
      },
    },
    // Alternate Telephone Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "canadaUSAOrOtherList",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.ALTERNATE_LEGEND",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "alternateTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "canadaOrUSA" },
      },
    },
    // Alternate Telephone Number - Type
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "phoneType",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.TYPE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "alternateTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "type" },
      },
    },
    // Alternate Telephone Number - Country Code
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_CODE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "alternateTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "countryCode" },
      },
    },
    // Alternate Telephone Number - Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.NUMBER_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "alternateTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "number" },
      },
    },
    // Alternate Telephone Number - Extension
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.EXTENSION_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "alternateTelephone",
        subfield: { type: PropertyType.Primitive, sourceField: "extension" },
      },
    },
    // Fax Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "canadaUSAOrOtherList",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.FAX_LEGEND",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "faxNumber",
        subfield: { type: PropertyType.Primitive, sourceField: "canadaOrUSA" },
      },
    },
    // Fax Number - Country Code
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_CODE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "faxNumber",
        subfield: { type: PropertyType.Primitive, sourceField: "countryCode" },
      },
    },
    // Fax Number - Number
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.NUMBER_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "faxNumber",
        subfield: { type: PropertyType.Primitive, sourceField: "number" },
      },
    },
    // Fax Number - Extension
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.EXTENSION_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: "faxNumber",
        subfield: { type: PropertyType.Primitive, sourceField: "extension" },
      },
    },
    // Are you using email of contact?
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.ADDRESS.VERIFY_CONTACT.LEGEND",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "contactUsingEmailAddress",
      },
    },
    // Contact email address
    {
      type: PropertyType.Object,
      sourceField: "contactDetails",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM0008.FORMS.ADDRESS.CONTACT_EMAIL.LEGEND",
        fieldLabel: "INTAKE.IMM0008.FORMS.ADDRESS.CONTACT_EMAIL.EMAIL_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "emailAddress" },
    },
    /* ----------- Passport Details ----------- */
    // Do you have passport?
    {
      type: PropertyType.Object,
      sourceField: "passportDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        formName: "INTAKE.IMM0008.PAGES.PASSPORT_DETAILS.SUBHEADING",
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.VALID_PASSPORT.LEGEND",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "validPassport" },
    },
    // Passport/travel document number
    {
      type: PropertyType.Object,
      sourceField: "passportDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.DOCUMENT_NUMBER_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "passportNumber" },
    },
    // Passport/travel document - Country of issue
    {
      type: PropertyType.Object,
      sourceField: "passportDetails",
      sourceList: "countryOfIssue",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.COUNTRY_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "countryOfIssue" },
    },
    // Passport/travel document - Issue date
    {
      type: PropertyType.Object,
      sourceField: "passportDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.ISSUE_DATE_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "issueDate" },
    },
    // Passport/travel document - Expiry date
    {
      type: PropertyType.Object,
      sourceField: "passportDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.EXPIRY_DATE_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "expiryDate" },
    },
    // Passport/travel document - Using Taiwanese Passport
    {
      type: PropertyType.Object,
      sourceField: "passportDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.TAIWAN_PASSPORT.LEGEND",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "usingTaiwanesePassportForTrip",
      },
    },
    // Passport/travel document - Using Israeli Passport
    {
      type: PropertyType.Object,
      sourceField: "passportDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.ISRAEL_PASSPORT.LEGEND",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "usingIsraeliPassportForTrip",
      },
    },
    /* ----------- National Identity Details ----------- */
    // Do you have National Identity document?
    {
      type: PropertyType.Object,
      sourceField: "nationalIdentityDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        formName: "INTAKE.IMM0008.PAGES.NATIONAL_IDENTITY_DOCUMENT.SUB_HEADING",
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_CHECK.LEGEND",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "nationalIdentityDocument",
      },
    },
    // National identity document - Number
    {
      type: PropertyType.Object,
      sourceField: "nationalIdentityDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_NUMBER.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_NUMBER.LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "nationalIdentityNumber",
      },
    },
    // National identity document - Country of Issue
    {
      type: PropertyType.Object,
      sourceField: "nationalIdentityDetails",
      sourceList: "countryOfIssue",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.COUNTRY_ISSUE.LEGEND",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "countryOfIssue" },
    },
    // National identity document - Issue date
    {
      type: PropertyType.Object,
      sourceField: "nationalIdentityDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.ISSUE_DATE.LEGEND",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "issueDate" },
    },
    // National identity document - Expiry date
    {
      type: PropertyType.Object,
      sourceField: "nationalIdentityDetails",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.EXPIRY_DATE.LEGEND",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "expiryDate" },
    },
    /* ----------- Education/occupation detail ----------- */
    // Education information - level of education
    {
      type: PropertyType.Object,
      sourceField: "educationOccupationDetails",
      sourceList: "educationLevel",
      sourceLabels: {
        formName: "INTAKE.IMM0008.PAGES.EDUCATION_OCCUPATION.HEADING",
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.HIGHEST_LEVEL_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "educationLevel" },
    },
    // Education information - years of education
    {
      type: PropertyType.Object,
      sourceField: "educationOccupationDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.NUMBERS_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "numberOfYear" },
    },
    // Occupation information - Current occupation
    {
      type: PropertyType.Object,
      sourceField: "educationOccupationDetails",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.CURRENT_OCCUPATION_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "currentOccupation",
      },
    },
    // Occupation information - Intended occupation
    {
      type: PropertyType.Object,
      sourceField: "educationOccupationDetails",
      sourceLabels: {
        fieldLabel:
          "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.INTENDED_OCCUPATION_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "intendedOccupation",
      },
    },
    /* ----------- Language detail ----------- */
    // Native language/mother tongue
    {
      type: PropertyType.Object,
      sourceField: "languageDetails",
      sourceList: "contactLanguage",
      sourceLabels: {
        formName: "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.HEADING",
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.NATIVE_LANGUAGE.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.NATIVE_LANGUAGE.NATIVE_LANGUAGE_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "nativeLanguage" },
    },
    // communication Language
    {
      type: PropertyType.Object,
      sourceField: "languageDetails",
      sourceList: "officialLanguage",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL.LANGUAGE_LABEL",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "language" },
    },
    // In which language are you most at ease
    {
      type: PropertyType.Object,
      sourceField: "languageDetails",
      sourceList: "preferenceLanguage",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL_PREFERENCE.LEGEND",
        fieldLabel:
          "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL_PREFERENCE.LANGUAGE_LABEL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "preferredLanguage",
      },
    },
    // Have you taken test
    {
      type: PropertyType.Object,
      sourceField: "languageDetails",
      sourceList: "yesOrNoList",
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.LANGUAGE_TEST.LEGEND",
      },
      subfield: { type: PropertyType.Primitive, sourceField: "takenTest" },
    },
  ],
};
