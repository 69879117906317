<div class="form-radio">
  <div class="form-radio__label">
    <div class="form-radio__label-info">
      <span *ngIf="required" class="form-radio__required-icon">
        <lib-icon-chooser icon="asterisk"></lib-icon-chooser>
      </span>
      <span class="form-radio__shift">
        {{ legend }}
        <!-- <span *ngIf="required" class="form-radio__required"
        >({{ requiredText }})</span
      > -->
      </span>
    </div>
    <div
      *ngIf="helpText !== ''"
      class="form-radio__help-text"
      [innerHTML]="helpText"
    ></div>
  </div>

  <ng-container *ngIf="options && editable">
    <div class="form-radio__buttons">
      <div
        *ngFor="let option of options; index as i"
        class="form-radio__options"
      >
        <input
          type="radio"
          class="form-radio__option"
          [id]="option.id"
          [name]="nameVal"
          [attr.name]="nameVal"
          [value]="option.value"
          [formControl]="ngControl.control"
          [required]="required"
          [attr.aria-required]="required"
        />
        <label class="form-radio__option-label" [for]="option.id">
          {{ option.label | translate }}
        </label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!options && editable">
    <div class="form-radio__buttons">
      <div class="form-radio__options">
        <!-- double name attribute is required because of angular bug. See https://github.com/angular/angular/issues/11757 -->
        <input
          type="radio"
          class="form-radio__option"
          [id]="firstId"
          [name]="nameVal"
          [attr.name]="nameVal"
          [value]="firstValue"
          [formControl]="ngControl.control"
          [required]="required"
          [attr.aria-required]="required"
        />
        <label [for]="firstId">
          {{ firstLabel }}
        </label>
      </div>
      <div class="form-radio__options">
        <input
          type="radio"
          class="form-radio__option"
          [id]="secondId"
          [name]="nameVal"
          [attr.name]="nameVal"
          [value]="secondValue"
          [formControl]="ngControl.control"
          [required]="required"
          [attr.aria-required]="required"
          [attr.aria-disabled]="!editable"
        />
        <label class="form-radio__option-label" [for]="secondId">
          {{ secondLabel }}
        </label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="options && !editable">
    <div class="form-radio__buttons">
      <div
        *ngFor="let option of options; index as i"
        class="form-radio__options"
      >
        <input
          type="radio"
          class="form-radio__option"
          [id]="option.id"
          [name]="nameVal"
          [attr.name]="nameVal"
          [value]="option.value"
          [required]="required"
          [attr.aria-required]="required"
          [disabled]="true"
          [attr.disabled]="true"
          [formControl]="ngControl.control"
        />
        <label class="form-radio__option-label" [for]="option.id">
          {{ option.label | translate }}
        </label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!options && !editable">
    <div class="form-radio__buttons">
      <div class="form-radio__options">
        <!-- double name attribute is required because of angular bug. See https://github.com/angular/angular/issues/11757 -->
        <input
          type="radio"
          class="form-radio__option"
          [id]="firstId"
          [name]="nameVal"
          [attr.name]="nameVal"
          [value]="firstValue"
          [required]="required"
          [attr.aria-required]="required"
          [disabled]="true"
          [attr.disabled]="true"
          [formControl]="ngControl.control"
        />
        <label [for]="firstId">
          {{ firstLabel }}
        </label>
      </div>
      <div class="form-radio__options">
        <input
          type="radio"
          class="form-radio__option"
          [id]="secondId"
          [name]="nameVal"
          [attr.name]="nameVal"
          [value]="secondValue"
          [required]="required"
          [attr.aria-required]="required"
          [disabled]="true"
          [attr.disabled]="true"
          [formControl]="ngControl.control"
        />
        <label class="form-radio__option-label" [for]="secondId">
          {{ secondLabel }}
        </label>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="ngControl.errors && ngControl.touched && editable"
    class="form-radio__error"
  >
    <ng-content>
      <!-- error spans -->
    </ng-content>
  </div>
</div>
