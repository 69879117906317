<!--Site Header -->
<header class="header jds-escape">
  <ng-content select="[skipLinks]"></ng-content>
  <div class="container">
    <a [routerLink]="['/']" class="heading">
      {{ "HEADER.TITLE" | translate }}
    </a>
    <div class="options">
      <button
        class="lang btn-unstyled"
        category="plain"
        (click)="onChangedLang()"
      >
        <span class="visually-hidden" [lang]="'HEADER.TOGGLE_LANG' | translate">
          {{ "HEADER.TOGGLE_SR_TEXT" | translate }}
        </span>
        <span class="lang-mobile" aria-hidden="true">
          {{ "HEADER.LANGUAGE_TOGGLE_MOBILE" | translate }}
        </span>
        <span
          class="lang-desktop"
          aria-hidden="true"
          [lang]="'HEADER.TOGGLE_LANG' | translate"
        >
          {{ "HEADER.LANGUAGE_TOGGLE_DESKTOP" | translate }}
        </span>
      </button>
      <span
        class="vertical-line"
        [ngStyle]="{ display: loggedIn ? '' : 'none' }"
      ></span>
      <button
        type="button"
        (click)="logout()"
        class="sign-out"
        [ngStyle]="{ display: loggedIn ? '' : 'none' }"
      >
        {{ "HEADER.SIGN_OUT.LABEL" | translate }}
      </button>
    </div>
  </div>
</header>
