import json5406OtherMaritalStatusList from "./lists/5406OtherMaritalStatusList.json";
import json5406PartnerMaritalStatusList from "./lists/5406PartnerMaritalStatusList.json";
import json5406PrincipalMaritalStatusList from "./lists/5406PrincipalMaritalStatusList.json";
import AbleCommunicateEnglishOrFrenchList from "./lists/AbleCommunicateEnglishOrFrenchList.json";
import ApplyingCategoryList from "./lists/ApplyingCategoryList.json";
import ApplyingProgramList from "./lists/ApplyingProgramList.json";
import CityList from "./lists/CityList.json";
import ContactLanguageList from "./lists/ContactLanguageList.json";
import CorrespondenceItemForList from "./lists/CorrespondenceItemForList.json";
import CorrespondenceItemList from "./lists/CorrespondenceItemList.json";
import CountryList from "./lists/CountryList.json";
import CountryOfBirthCitList from "./lists/CountryOfBirthCitList.json";
import CountryOfBirthList from "./lists/CountryOfBirthList.json";
import CountryOfCitizenshipList from "./lists/CountryOfCitizenshipList.json";
import CountryOfIssueList from "./lists/CountryOfIssueList.json";
import CountryOfLastPermanentResidentList from "./lists/CountryOfLastPermanentResidentList.json";
import CountryTravelDocumentList from "./lists/CountryTravelDocumentList.json";
import DependantTypeList from "./lists/DependantTypeList.json";
import DesignatedEntityList from "./lists/DesignatedEntity.json";
import EducationLevelList from "./lists/EducationLevelList.json";
import EducationStatusList from "./lists/EducationStatusList.json";
import ExpensesPaidBySPList from "./lists/ExpensesPaidBySPList.json";
import EyeColorList from "./lists/EyeColorList.json";
import FamilyMemberRelationshipToPAList from "./lists/FamilyMemberRelationshipToPAList.json";
import FieldOfStudyList from "./lists/FieldOfStudyList.json";
import GenderCitList from "./lists/GenderCitList.json";
import GenderMelList from "./lists/GenderMelList.json";
import ImmigrationCategoryPRList from "./lists/ImmigrationCategoryPRList.json";
import ImmigrationStatusList from "./lists/ImmigrationStatusList.json";
import InterviewLanguageList from "./lists/InterviewLanguageList.json";
import LevelOfStudyList from "./lists/LevelOfStudyList.json";
import MaritalStatusHistoryList from "./lists/MaritalStatusHistoryList.json";
import MaritalStatusList from "./lists/MaritalStatusList.json";
import MilitaryServiceEndReasonList from "./lists/MilitaryServiceEndReasonList.json";
import NationalOccupationCodeList from "./lists/NationalOccupationCodeList.json";
import OfficeList from "./lists/OfficeList.json";
import OfficialLanguageList from "./lists/OfficialLanguageList.json";
import PhoneTypeList from "./lists/PhoneTypeList.json";
import PhoneTypeTRVList from "./lists/PhoneTypeTRVList.json";
import PreferenceLanguageList from "./lists/PreferenceLanguageList.json";
import ProvinceAbbrevList from "./lists/ProvinceAbbrevList.json";
import RelationshipSponsorToPAList from "./lists/RelationshipSponsorToPAList.json";
import RelationshipToPAList from "./lists/RelationshipToPAList.json";
import RelationshipToSponsorList from "./lists/RelationshipToSponsorList.json";
import SponsorStatusInCanadaList from "./lists/SponsorStatusInCanadaList.json";
import StateAbbrevList from "./lists/StateAbbrevList.json";
import StudyWorkPermitTypeList from "./lists/StudyWorkPermitTypeList.json";
import TypeOfCareList from "./lists/TypeOfCareList.json";
import VisaOfficeList from "./lists/VisaOfficeList.json";
import VisitPurposeList from "./lists/VisitPurposeList.json";
import VisitPurposeOriginalList from "./lists/VisitPurposeOriginalList.json";
import WorkPermitTypeInLandList from "./lists/WorkPermitTypeInLandList.json";
import WorkPermitTypeList from "./lists/WorkPermitTypeList.json";
import json5444ApplicantSituationPRCList from "./lists/5444ApplicantSituationPRCList.json";
import json5444ApplicantSituationPRTDList from "./lists/5444ApplicantSituationPRTDList.json";
import json5444EyeColorList from "./lists/5444EyeColorList.json";
import json5444GenderCitList from "./lists/5444GenderCitList.json";
import json5444GuardianList from "./lists/5444GuardianList.json";
import json5444LossTypeList from "./lists/5444LossTypeList.json";
import json5444MaritalStatusList from "./lists/5444MaritalStatusList.json";
import json5444ReasonsForAbsenceList from "./lists/5444ReasonsForAbsenceList.json";
import json5444RelationshipList from "./lists/5444RelationshipList.json";
import json5444StatusInCanadaList from "./lists/5444StatusInCanadaList.json";
import ProvinceList from "./lists/ProvinceList.json";
import TrueFalseList from "./lists/TrueFalseList.json";
import json5444ForeignCountryList from "./lists/5444ForeignCountryList.json";
import ReferralPartnerList from "./lists/ReferralPartnerList.json";
import ReferralPartnerCaseworkerList from "./lists/ReferralPartnerCaseworkerList.json";

export const combinedLists = {
  json5406OtherMaritalStatusList,
  json5406PartnerMaritalStatusList,
  json5406PrincipalMaritalStatusList,
  AbleCommunicateEnglishOrFrenchList,
  ApplyingCategoryList,
  ApplyingProgramList,
  CityList,
  ContactLanguageList,
  CorrespondenceItemForList,
  CorrespondenceItemList,
  CountryList,
  CountryOfBirthCitList,
  CountryOfBirthList,
  CountryOfCitizenshipList,
  CountryOfIssueList,
  CountryOfLastPermanentResidentList,
  CountryTravelDocumentList,
  DependantTypeList,
  DesignatedEntityList,
  EducationLevelList,
  EducationStatusList,
  ExpensesPaidBySPList,
  EyeColorList,
  FamilyMemberRelationshipToPAList,
  FieldOfStudyList,
  GenderCitList,
  GenderMelList,
  ImmigrationCategoryPRList,
  ImmigrationStatusList,
  InterviewLanguageList,
  LevelOfStudyList,
  MaritalStatusHistoryList,
  MaritalStatusList,
  MilitaryServiceEndReasonList,
  NationalOccupationCodeList,
  OfficialLanguageList,
  OfficeList,
  PhoneTypeList,
  PhoneTypeTRVList,
  PreferenceLanguageList,
  ProvinceAbbrevList,
  ProvinceList,
  RelationshipSponsorToPAList,
  RelationshipToPAList,
  RelationshipToSponsorList,
  SponsorStatusInCanadaList,
  StateAbbrevList,
  StudyWorkPermitTypeList,
  TypeOfCareList,
  VisaOfficeList,
  VisitPurposeList,
  VisitPurposeOriginalList,
  WorkPermitTypeInLandList,
  WorkPermitTypeList,
  json5444ApplicantSituationPRCList,
  json5444ApplicantSituationPRTDList,
  json5444EyeColorList,
  json5444GenderCitList,
  json5444GuardianList,
  json5444LossTypeList,
  json5444MaritalStatusList,
  json5444ReasonsForAbsenceList,
  json5444RelationshipList,
  json5444StatusInCanadaList,
  json5444ForeignCountryList,
  TrueFalseList,
  ReferralPartnerList,
  ReferralPartnerCaseworkerList,
};
