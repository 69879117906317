<h2 class="emphasis">
  {{ "INTAKE.INTAKE_CASE_DETAILS.ASSIGNMENT_STATUS_HEADING" | translate }}
</h2>
<lib-layout-row gap="one-and-half">
  <lib-form-input
    identifier="caseworkerId"
    [formControl]="assignmentGroup.controls.caseworkerId"
    [label]="'INTAKE.INTAKE_CASE_DETAILS.ASSIGNMENT_LABEL' | translate"
    [editable]="false"
  ></lib-form-input>
  <lib-form-input
    identifier="caseStatusDescription"
    [formControl]="assignmentGroup.controls.caseStatusDescription"
    [label]="'INTAKE.APPLICATION_STATUS' | translate"
    [editable]="false"
  ></lib-form-input
></lib-layout-row>
