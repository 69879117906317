import { AccordionItemComponent } from "./components/accordion-item/accordion-item.component";
import { AlertComponent } from "./components/alert/alert.component";
import { BttnsPaginatorComponent } from "./components/bttns-paginator/bttns-paginator.component";
import { CaseStatusBoxComponent } from "./components/case-status-box/case-status-box.component";
import { CasesTableLibComponent } from "./components/cases-table/cases-table-lib.component";
import { ChangeBrowserPageComponent } from "./components/change-browser-page/change-browser-page.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { CommonModule } from "@angular/common";
import { CopyBlockComponent } from "./components/copy-block/copy-block.component";
import { DatepickerComponent } from "./components/datepicker/datepicker.component";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { DropZoneComponent } from "./components/drop-zone/drop-zone.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { FormAutocompleteComponent } from "./components/form-autocomplete/form-autocomplete.component";
import { FormCheckboxComponent } from "./components/form-checkbox/form-checkbox.component";
import { FormInputComponent } from "./components/form-input/form-input.component";
import { FormRadioComponent } from "./components/form-radio/form-radio.component";
import { FormSelectComponent } from "./components/form-select/form-select.component";
import { FormTextareaComponent } from "./components/form-textarea/form-textarea.component";
import { ImagePreviewComponent } from "./components/image-preview/image-preview.component";
import { LabelPillComponent } from "./components/label-pill/label-pill.component";
import { LibDropZoneDirective } from "./directives/dropZone.directive";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatNativeDateModule } from "@angular/material/core";
import { MobileAccordionItemComponent } from "./components/mobile-accordion-item/mobile-accordion-item.component";
import { MobileCasesTableLibComponent } from "./components/mobile-cases-table/mobile-cases-table.component";
import { ModalComponent } from "./components/modal/modal.component";
import { NavigationButtonsComponent } from "./components/navigation-buttons/navigation-buttons.component";
import { NavigationWarningComponent } from "./components/navigation-warning/navigation-warning.component";
import { NgModule } from "@angular/core";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPhotoEditorComponent } from "./components/ngx-photo-editor/ngx-photo-editor.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { StatusPillComponent } from "./components/status-pill/status-pill.component";
import { BannerBoxComponent } from "./components/banner/banner-box.component";
import { TranslateModule } from "@ngx-translate/core";
import { SafeTrimPipe } from "./pipes/safe-trim.pipe";
import { LayoutColComponent } from "./components/layout-col/layout-col.component";
import { LayoutRowComponent } from "./components/layout-row/layout-row.component";
import { IconChooserComponent } from "./components/icon-chooser/icon-chooser.component";
import { MaintenanceComponent } from "./components/maintenance/maintenance.component";
import { CardComponent } from "./components/card/card.component";
import { CollapseComponent } from "./components/collapse/collapse.component";
import { InformationBoxComponent } from "./components/information-box/information-box.component";
import { FormCustomErrorComponent } from "./components/form-custom-error/form-custom-error.component";
import { FormDatepickerComponent } from "./components/form-datepicker/form-datepicker.component";

import { IrccDsAngularComponentsSharedModule } from "ircc-ds-angular-component-library";
import { InformationAreaComponent } from "./components/information-area/information-area.component";
import { DebugComponent } from "./components/debug/debug.component";

import { NgxMaskModule, IConfig } from "ngx-mask";
import { NotificationBellComponent } from "./components/notification-bell/notification-bell.component";

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AccordionItemComponent,
    AlertComponent,
    CasesTableLibComponent,
    CaseStatusBoxComponent,
    DisclaimerComponent,
    DatepickerComponent,
    ChangeBrowserPageComponent,
    CopyBlockComponent,
    FileUploadComponent,
    FormInputComponent,
    FormTextareaComponent,
    FormRadioComponent,
    FormSelectComponent,
    FormAutocompleteComponent,
    FormCheckboxComponent,
    MobileAccordionItemComponent,
    MobileCasesTableLibComponent,
    ModalComponent,
    SpinnerComponent,
    StatusPillComponent,
    LabelPillComponent,
    NavigationButtonsComponent,
    NavigationWarningComponent,
    BttnsPaginatorComponent,
    LibDropZoneDirective,
    NgxPhotoEditorComponent,
    DropZoneComponent,
    ImagePreviewComponent,
    BannerBoxComponent,
    MaintenanceComponent,
    ClickOutsideDirective,
    SafeTrimPipe,
    InformationBoxComponent,
    FormCustomErrorComponent,
    CollapseComponent,
    LayoutColComponent,
    LayoutRowComponent,
    CardComponent,
    IconChooserComponent,
    InformationBoxComponent,
    FormCustomErrorComponent,
    FormDatepickerComponent,
    InformationAreaComponent,
    DebugComponent,
    NotificationBellComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NgbModalModule,
    TranslateModule.forChild(),
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    NgxMaskModule.forRoot(),
    IrccDsAngularComponentsSharedModule,
  ],
  exports: [
    AccordionItemComponent,
    AlertComponent,
    CasesTableLibComponent,
    CaseStatusBoxComponent,
    DisclaimerComponent,
    DatepickerComponent,
    ChangeBrowserPageComponent,
    CopyBlockComponent,
    FileUploadComponent,
    FormInputComponent,
    FormTextareaComponent,
    FormRadioComponent,
    FormSelectComponent,
    FormAutocompleteComponent,
    FormCheckboxComponent,
    MobileAccordionItemComponent,
    MobileCasesTableLibComponent,
    ModalComponent,
    SpinnerComponent,
    StatusPillComponent,
    LabelPillComponent,
    NavigationButtonsComponent,
    NavigationWarningComponent,
    BttnsPaginatorComponent,
    LibDropZoneDirective,
    NgxPhotoEditorComponent,
    DropZoneComponent,
    BannerBoxComponent,
    MaintenanceComponent,
    ImagePreviewComponent,
    ClickOutsideDirective,
    InformationBoxComponent,
    FormCustomErrorComponent,
    CollapseComponent,
    LayoutColComponent,
    LayoutRowComponent,
    CardComponent,
    IconChooserComponent,
    InformationBoxComponent,
    FormCustomErrorComponent,
    FormDatepickerComponent,
    InformationAreaComponent,
    DebugComponent,
    NotificationBellComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "YYYY/MM/DD",
        },
        display: {
          dateInput: "YYYY/MM/DD",
          monthYearLabel: "MMMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class LibModule {}
