import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "../../../../core/cases-module/models/case.model";
import { Subscription } from "rxjs";

@Component({
  selector: "prcw-rep-info-section",
  templateUrl: "./rep-info.component.html",
  styleUrls: ["./rep-info.component.scss"],
})
export class RepInfoSectionComponent implements OnInit, OnDestroy {
  public lang: string;
  public case: Case;

  // Required Information to be Displayed - Can be required null so had to set type to any
  public name = "";
  public email = "";
  public membershipId = "";
  public governingBody = "";
  public submittedTime = "";

  private subs: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit() {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getCurrentCaseDate();
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public formatDate(field: string) {
    return field?.replace(/\s/, "T");
  }

  private getCurrentCaseDate() {
    this.subs.push(
      this.store.select("intake").subscribe((caseData) => {
        const currentCase = caseData.selectedApplication;
        if (currentCase) {
          this.submittedTime = currentCase.submittedAt;
          const repInfo = currentCase.representative;
          if (repInfo) {
            this.name = repInfo.givenName
              ? `${repInfo.givenName} ${repInfo.familyName}`
              : repInfo.familyName;
            this.email = repInfo.emailAddress;
            this.membershipId = repInfo.membershipId;
            this.governingBody = repInfo.governingBody;
          }
        }
      })
    );
  }
}
