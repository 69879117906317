<lib-form-select
  *ngIf="lobs.length > 1"
  identifier="lobSearch"
  [formControl]="lobFilter"
  [label]="copyKey | translate"
  [options]="lobs"
  (change)="lobFiltered()"
  class="lob-search"
  [useFadingEffect]="true"
></lib-form-select>
<lib-spinner
  [showSpinner]="lobs.length <= 1"
  [id]="'LOAD_CASES'"
  [fullPage]="false"
>
</lib-spinner>
