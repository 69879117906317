import { Component, OnInit } from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";

@Component({
  selector: "prcw-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  public displayMFA = false;
  public mfaContactNumber = "";
  public loading = false;

  constructor(private routeLocalizer: RouteLocalizerService) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("LOGIN.PAGE_TITLE");
  }

  public handleMFA($event: any) {
    const user = $event;
    this.displayMFA = true; // displays the verification code component
    this.mfaContactNumber = user.challengeParam.CODE_DELIVERY_DESTINATION;
  }

  public checkLoadingValue(value: any): void {
    if (value === true) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  }
}
