import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from "../../../../environments/environment";
import * as fromApp from "../../../store/app.reducer";
import { caseStatuses } from "lib";
import { AWSService } from "../../auth-module/services/AWS.service";

const apiVersion = environment.API_VERSION;

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  apiName = environment.API_NAME;

  constructor(
    private store: Store<fromApp.State>,
    private awsService: AWSService
  ) {}

  public async getDocumentByCaseId(caseId?: any): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };

    try {
      const applicantDocuments = await this.awsService.api.get(
        this.apiName,
        path,
        init
      );
      // this.store.dispatch(new ApplicationActions.SetDocumentByCaseId({ caseDocuments: applicantDocuments.data }));
      return applicantDocuments.data;
    } catch (error) {
      throw error;
    }
  }

  // This method is to be used to get an only file by document Type
  // where there is always one files to be uploaded for a selected document Type
  // If you are looking for a file by id for a document type, use getFileByDocumentTypeAndId
  // TO DO: Combine with next function
  public getFileByDocumentType(documentType: number, documents: any): any {
    let file = null;
    const fileOnly = documents?.filter(
      (doc: any) => doc.documentTypeId === documentType
    );

    if (fileOnly?.length > 0) {
      file = fileOnly[0];
    }
    return file;
  }

  // This method is to be used to get all the files by document type
  // If you are looking for only 1 file for a document type, use getFileByDocumentType
  public getFilesByDocumentType(documentType: number, documents: any): any {
    const files = documents?.filter(
      (doc: any) => doc.documentTypeId === documentType
    );

    return files;
  }

  // Use this method if you want to get a file by documentType and documentId when ID exists and this works
  // Best when you know the document type can have multiple files to upload
  public getFileByDocumentTypeAndId(
    documentType: number,
    documents: any,
    documentId: number
  ): any {
    let file = null;
    const fileOnly = documents?.filter(
      (doc: any) => doc.id === documentId && doc.documentTypeId === documentType
    );

    if (fileOnly?.length > 0) {
      file = fileOnly[0];
    }
    return file;
  }

  /*
   * for checking the status on forms and pdf documents
   * grabs the documentApproved status from the document/forms and returns either ready to submit, submitted, incomplete, complete
   */
  public checkDocumentOrFormStatus(
    existingFile: any,
    caseStatusId: number
  ): string {
    if (existingFile) {
      switch (existingFile.documentApproved) {
        // If the document is not approved (rejected), then set the status as incomplete
        case 0:
          return "incomplete";
        // If the document is approved, then set the status as complete
        case 1:
          return "complete";
        // If the document hasn't been reviewed, then check to see if it's submitted
        // Otherwise, check to see if the document is complete or is an uploaded PDF file, then set the status as ready
        // Otherwise, don't set a status (blank)
        case null:
          // eslint-disable-next-line eqeqeq
          return caseStatusId === caseStatuses.intake.submitted.id
            ? "submitted"
            : existingFile.isComplete == 1 ||
              existingFile.documentName.split("/").pop()
            ? "ready"
            : ""; // grabbing last string in the split which is the filename
        default:
          return "";
      }
    }
    return "";
  }

  /*
    Gets the entire form if needed (TBD)
    - Pass the caseId and documentId (no need to pass the documentTypeId)
  */
  public async getFormByDocumentId(
    caseId: string,
    documentId: number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  /*
    Gets a specific section or page from the form
    - Pass the caseId and documentId (no need to pass the documentTypeId)
    - Pass the page as a number, etc 0,1,2 or as the section name ex. 'passportDetails'

    - Returns an object
    To access the values... response.form
    to access the lists for drop downs... response.lists, ex. response.lists.city
  */
  public async getFormByDocumentIdAndPage(
    caseId: string,
    documentId: number,
    page: string | number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?page=${page}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getWebformDataForCaseworkerView(
    caseId: string,
    documentId: number,
    language: string
  ): Promise<any> {
    const path = `/pdf`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
      queryStringParameters: {
        caseId,
        documentId,
        language,
      },
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }
}
