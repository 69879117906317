import * as fromRouter from "@ngrx/router-store";
import { Data, Params } from "@angular/router";
import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import * as fromAuth from "../core/auth-module/store/auth.reducer";
import * as fromCases from "../confirmation-module/store/cases.reducer";
import * as fromIntake from "../intake-module/store/intake-state";

export interface RouterStateUrl {
  url: string;
  params: Params;
  data: Data;
}

export interface State {
  auth: fromAuth.State;
  router: fromRouter.RouterReducerState<any>;
  intake: fromIntake.IntakeState;
  confirmation: fromCases.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.authReducer,
  router: fromRouter.routerReducer,
  intake: fromIntake.intakeReducer,
  confirmation: fromCases.caseReducer,
};

export const getRouterState = createFeatureSelector<
  State,
  fromRouter.RouterReducerState<any>
>("router");

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(getRouterState);
