import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { RejectionInputComponent } from "../../../shared/rejection-input/rejection-input.component";
import { DownloadService } from "../../../shared/services/download.service";
import { State } from "../../../store/app.reducer";
import { CasesService } from "../../../core/cases-module/cases.service";
import * as CasesActions from "../../store/cases.actions";
import { Reason } from "../../models/document-rejection.model";
import { UntypedFormBuilder, FormGroup } from "@angular/forms";
import { FormSelectOption } from "lib";
import { Subscription } from "rxjs";
@Component({
  selector: "prcw-photo-review-page",
  templateUrl: "./photo-review-page.component.html",
  styleUrls: ["./photo-review-page.component.scss"],
})
export class PhotoReviewPageComponent implements OnInit, OnDestroy {
  @ViewChild("photoRejection" /* #name or Type*/, { static: false })
  photoRejection: RejectionInputComponent;

  public lang: string;
  public documentName: string;
  public photo: any;
  public caseId: string;
  private case: any;
  public photoSrc: string | object;
  public photoCoordinates: any;
  public rejectionOptions: Reason[];
  public selectedRejections: any[];
  public canUpdatePhoto = false;
  public canContinue = false;
  private isNavigatingFromCaseDetails = false;
  private downloadUrl: string;

  public photoStatusForm: FormGroup = this.fb.group({
    photoStatus: [null],
  });
  public selectionOptions: FormSelectOption[] = [
    {
      value: null,
      text: {
        en: "Choose Status",
        fr: "Aucun statut assigné",
      },
    },
    {
      value: true,
      text: {
        en: "Approved",
        fr: "Approuvé",
      },
    },
    {
      value: false,
      text: {
        en: "Rejected",
        fr: "Rejeté",
      },
    },
  ];

  private subscriptions: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private casesService: CasesService,
    private route: ActivatedRoute,
    private store: Store<State>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("PHOTO_PREVIEW.PAGE_TITLE");
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getPhotoAndCase();

    // disabling the buttons if the case is not in review or revision
    this.canUpdatePhoto = this.case.caseStatusId !== 1 ? true : false;

    // checking if we are coming from case details and the right case photo
    if (
      window.history.state.from === "caseDetails" &&
      window.history.state.caseId === this.caseId
    ) {
      this.isNavigatingFromCaseDetails = true; // this means check the store instead of DB
    }
    this.getPhotoStatusAndRejection();
    this.watchPhotoStatus();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subsc) => subsc.unsubscribe());
  }

  get photoName(): string {
    const defaultFileExtension = "jpeg";
    return `${this.case.uci}-photo.${defaultFileExtension}`;
  }

  get photoStatus(): boolean | null {
    return this.photoStatusForm.controls.photoStatus.value;
  }

  get isRejectionReasonsEntered(): boolean {
    return this.photoRejection?.isValidInput;
  }

  public setDownloadUrl(url: string) {
    this.downloadUrl = url;
  }

  public downloadImg() {
    const a = document.createElement("a");
    a.href = this.downloadUrl;
    a.download = this.photoName;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(this.downloadUrl);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  public navigateAndUpdateStore() {
    if (this.photoStatus === true) {
      this.store.dispatch(
        new CasesActions.SetPhotoStatus({
          decision: {
            approvedStatus: true,
            rejectionReasons: this.rejectionOptions,
            modified: true,
          },
          caseId: this.caseId,
        })
      );
    } else if (this.photoStatus === false) {
      this.photoRejection.rejectDocument("PHOTO");
    } else {
      this.store.dispatch(
        new CasesActions.SetPhotoStatus({
          decision: {
            approvedStatus: null,
            rejectionReasons: this.rejectionOptions,
            modified: true,
          },
          caseId: this.caseId,
        })
      );
    }
  }

  public watchRejection(): void {
    if (this.photoStatus === true) {
      this.canContinue = true;
    } else if (this.photoStatus === false) {
      if (this.isRejectionReasonsEntered) {
        this.canContinue = true;
      } else {
        this.canContinue = false;
      }
    } else {
      this.canContinue = true;
    }
  }

  private async getPhotoAndCase(): Promise<void> {
    /* get the document info from the data returned by the resolver on this route */
    const routeSubsc$ = this.route.data.subscribe((data) => {
      this.caseId = data.case.id;
      this.case = data.case;
      const photos = data.case.documents.filter(
        (doc: any) => doc.documentTypeId === 1
      );
      this.photo = photos ? photos[0] : "";
    });
    const [fileIdentityId] = this.photo.documentName.split("/");
    const photoName = `${this.case.uci}-photo.jpeg`;
    const { photoLocation, photoLink, coordinates } =
      await this.casesService.getPhoto(this.case);
    this.photoSrc = photoLink;
    this.photoCoordinates = coordinates;
    this.documentName = photoLocation;
    this.subscriptions.push(routeSubsc$);
  }

  private async getPhotoStatusAndRejection() {
    // if coming from case details page, we use the store for the values
    if (this.isNavigatingFromCaseDetails) {
      const storeSubsc$ = this.store
        .select("confirmation")
        .subscribe((confirmation) => {
          if (confirmation.selectedCase?.photo) {
            this.setValueWithData(
              confirmation.selectedCase.photo.approvedStatus
            );
            this.rejectionOptions =
              confirmation.selectedCase.photo.rejectionReasons;
          }
        });
      this.subscriptions.push(storeSubsc$);
    } else {
      // we pull the values from the DB if coming to the link directly (edge case)
      this.setValueWithData(this.photo.documentApproved);
      this.rejectionOptions =
        await this.casesService.getDocumentRejectionReasonsByDocumentId(
          this.caseId,
          this.photo.id
        );
      const addressRejectionOptions =
        await this.casesService.getAddressRejectionReasonsByCaseId(this.caseId);

      // setting case info to store
      this.store.dispatch(
        new CasesActions.SetAddressStatus({
          decision: {
            approvedStatus: this.case.addressApproved,
            rejectionReasons: addressRejectionOptions,
            modified: false,
          },
          caseId: this.caseId,
        })
      );
      this.store.dispatch(
        new CasesActions.SetPhotoStatus({
          decision: {
            approvedStatus: this.photoStatus,
            rejectionReasons: this.rejectionOptions,
            modified: false,
          },
          caseId: this.caseId,
        })
      );
    }
  }

  private setValueWithData(photoStatus: boolean | null | undefined): void {
    this.photoStatusForm.controls.photoStatus.setValue(photoStatus);
  }

  private watchPhotoStatus(): void {
    this.subscriptions.push(
      this.photoStatusForm.controls.photoStatus.valueChanges.subscribe(
        (photoStatus) => {
          if (photoStatus === true) {
            this.canContinue = true;
          } else if (photoStatus === false) {
            if (this.isRejectionReasonsEntered) {
              this.canContinue = true;
            } else {
              this.canContinue = false;
            }
          } else {
            this.canContinue = true;
          }
        }
      )
    );
  }
}
