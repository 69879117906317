import {
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromSearchTerms from "./searchTerms/searchTerms.reducer";
import * as fromSelectedApplication from "./selectedApplication/selectedApplication.reducer";
import * as fromAllApplications from "./allApplications/allApplications.reducer";
import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";

export interface IntakeState {
  searchTerms: fromSearchTerms.SearchTermsState;
  selectedApplication?: Case | undefined;
  allApplications?: fromAllApplications.AllApplicationsState;
}

export const intakeReducers: ActionReducerMap<IntakeState> = {
  searchTerms: fromSearchTerms.searchTermsReducer,
  selectedApplication: fromSelectedApplication.selectedApplicationReducer,
  allApplications: fromAllApplications.allApplicationsReducer,
};

export const intakeReducer = combineReducers({
  searchTerms: fromSearchTerms.searchTermsReducer,
  selectedApplication: fromSelectedApplication.selectedApplicationReducer,
  allApplications: fromAllApplications.allApplicationsReducer,
});

export const getIntakeState = createFeatureSelector<IntakeState>("intake");
export const intakeSearchTerms = createSelector(
  getIntakeState,
  (state: IntakeState) => state.searchTerms
);
export const intakeSelectedApplication = createSelector(
  getIntakeState,
  (state: IntakeState) => state.selectedApplication
);
