<div
  *ngIf="type !== 'blank' && type !== null && type !== undefined"
  [ngClass]="{ 'word-break': wordBreak }"
  class="status-pill"
  class="status-pill--{{ type }}"
>
  {{ text }}
</div>
<div
  *ngIf="type === null || type === undefined"
  class="status-pill status-pill--notStarted"
>
  {{ text }}
</div>
