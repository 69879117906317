import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { ConfirmationTableFilterService } from "@pr-caseworker/app/confirmation-module/services/confirmation-table-filter.service";
import { FormSelectOption, LovService, lovModel } from "lib";
import * as fromApp from "../../../../store/app.reducer";
import { Subscription, distinctUntilChanged } from "rxjs";
import { OnDestroy } from "@angular/core";

@Component({
  selector: "prcw-confirmation-cases-search",
  templateUrl: "./confirmation-cases-search.component.html",
  styleUrls: ["./confirmation-cases-search.component.scss"],
})
export class ConfirmationCasesSearchComponent implements OnInit, OnDestroy {
  public filterForm = new FormGroup({
    isAssigned: new FormControl<boolean | null>(null),
    caseStatusId: new FormControl<string | null | undefined>(null),
    officeId: new FormControl<string | null>(null),
    assignedToMe: new FormControl<boolean | null | undefined>(false),
    cognitoCaseWorkerId: new FormControl<any>(null),
  });
  public searchForm = new FormGroup({
    searchTerm: new FormControl<string | null | undefined>(""),
  });
  public assignementStatuses: FormSelectOption[] = [
    {
      value: true,
      text: {
        en: "Assigned",
        fr: "Assigné",
      },
    },
    {
      value: false,
      text: {
        en: "Unassigned",
        fr: "Non assigné",
      },
    },
  ];

  public offices: lovModel[];

  public statuses = [
    {
      value: "1",
      text: { en: "Initiated", fr: "Initié" },
    },
    {
      value: "2",
      text: { en: "Ready for Review", fr: "Prêt pour l’examen" },
    },
    {
      value: "3",
      text: { en: "Requires Revision", fr: "Nécessite une révision" },
    },
    {
      value: "4",
      text: { en: "Closed and Accepted", fr: "Fermé et accepté" },
    },
    {
      value: "5",
      text: { en: "Closed and Rejected", fr: "Fermé et rejeté" },
    },
  ];
  searchByOptions: FormSelectOption[] = [
    {
      value: "0",
      text: {
        en: "Client email",
        fr: "Courriel du client",
      },
    },
    {
      value: "1",
      text: {
        en: "UCI",
        fr: "l'IUC",
      },
    },
    {
      value: "2",
      text: {
        en: "Application number",
        fr: "Numéro de la demande",
      },
    },
    {
      value: "3",
      text: {
        en: "Caseworker email",
        fr: "Courriel de l'agent",
      },
    },
  ];

  public caseworkerOptions: FormSelectOption[];
  private subscriptions: Subscription[] = [];

  constructor(
    public filterService: ConfirmationTableFilterService,
    private lovService: LovService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit() {
    this.offices = this.lovService.lovs.OfficeList;
    this.filterForm.setValue(this.filterService.selections.value.filters);
    this.searchForm.setValue(this.filterService.selections.value.search);
    this.subscriptions.push(
      this.store.select("auth").subscribe((auth) => {
        if (auth.user?.usersList) {
          this.caseworkerOptions = auth.user.usersList.map((item: any) => ({
            value: item.username,
            text: item.username,
          }));
          this.filterForm.controls.cognitoCaseWorkerId.setValidators([
            this.autocompleteSelectionValidator(this.caseworkerOptions),
          ]);
        }
      })
    );
    this.subscriptions.push(
      this.filterForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (!this.filterForm.valid) return;
          if (
            typeof value.isAssigned === "string" ||
            value.caseStatusId?.includes(":") ||
            value.officeId?.includes(":")
          )
            return;
          this.filterService.selections.next({
            ...this.filterService.selections.value,
            filters: {
              isAssigned: value.isAssigned!,
              caseStatusId: value.caseStatusId!,
              officeId: value.officeId!,
              assignedToMe: value.assignedToMe!,
              cognitoCaseWorkerId: value.cognitoCaseWorkerId?.value || null,
            },
          });
        })
    );
  }

  clearFilters() {
    this.searchForm.setValue(this.filterService.defaultFilters.search);
    this.submitSearchForm();
    this.filterForm.setValue(this.filterService.defaultFilters.filters);
  }

  submitSearchForm() {
    this.filterService.selections.next({
      ...this.filterService.selections.value,
      search: {
        searchTerm: this.searchForm.value.searchTerm!,
      },
    });
  }

  autocompleteSelectionValidator(
    caseworkerList: FormSelectOption[]
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const found = caseworkerList.find(
        (caseworker: FormSelectOption) =>
          caseworker.value === control.value.value
      );
      return found ? null : { selectionIncomplete: true };
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
