<div
  class="form-select"
  [ngClass]="{
    'fading-select': useFadingEffect && !hasFocus,
    'no-margin-bottom': eraseMarginBottom
  }"
  aria-live="assertive"
>
  <label
    class="form-select__label"
    [for]="id"
    (click)="onLabelClick($event)"
    [ngClass]="{ 'form-select__label--visually-hidden': visuallyHiddenLabel }"
    *ngIf="!noLabel"
  >
    <div class="form-select__label-info">
      <span
        *ngIf="required && !hideRequired"
        class="form-select__required-icon"
      >
        <lib-icon-chooser icon="asterisk"></lib-icon-chooser>
      </span>
      <span class="form-select__shift">
        {{ label }}
        <!-- <span *ngIf="required && !hideRequired" class="required">
        ({{ requiredText }})
      </span> -->
      </span>
    </div>
    <div
      *ngIf="helpText !== ''"
      class="form-select__help-text"
      [id]="identifier + '-help-text'"
      [innerHTML]="helpText"
    ></div>
  </label>
  <ng-container *ngIf="editable">
    <div class="form-select__field-container">
      <select
        [id]="id"
        [name]="identifier"
        [formControl]="ngControl.control"
        (input)="onChange($event.target.value); handleOnchanges($event)"
        (click)="onClick($event)"
        (blur)="onBlur($event)"
        (focus)="onFocus()"
        [attr.aria-label]="ariaLabel !== '' ? ariaLabel : label"
        [required]="required"
        [attr.aria-required]="required"
        [ngClass]="{
          'form-select__doc-upload-failed-message':
            isDocUploadFailed !== null && !isDocUploadFailed,
          'form-select__field--disabled': ngControl.disabled
        }"
        class="form-select__field"
      >
        <option
          *ngFor="let option of options; let i = index; trackBy: trackElemnt"
          [ngValue]="option.value"
          [ngClass]="[
            i === 0 ? 'form-select__placeholder' : 'form-select__option',
            isDocUploadFailed !== null && !isDocUploadFailed
              ? 'form-select__doc-upload-failed-message-reverse'
              : '',
            option.value === 95 ? 'form-select__required-opt' : ''
          ]"
        >
          {{ option.text[lang] }}
        </option>
      </select>
      <div
        class="form-select__chevron-container"
        [class.form-select__chevron-container--error]="
          ngControl.errors && ngControl.touched
        "
      >
        <div [class.form-select__chevron]="rotateChevron">
          <i class="fa-thin fa-chevron-down form-select__chevron-icon"></i>
        </div>
      </div>
    </div>
    <div
      *ngIf="ngControl.errors && ngControl.touched"
      class="form-select__error"
    >
      <ng-content>
        <!-- error spans -->
      </ng-content>
    </div>
  </ng-container>

  <ng-container *ngIf="!editable">
    <div class="form-select__field-container">
      <input
        class="form-select__readonly"
        type="text"
        [value]="selectedOptionText"
        [attr.disabled]="true"
        [required]="required"
        [attr.aria-required]="required"
      />
    </div>
  </ng-container>
</div>
