<ng-template
  id="ngx-photo-editor-modal"
  #ngxPhotoEditorContent
  let-modal="modal"
>
  <div
    [style.backgroundColor]="darkTheme ? '#36393E' : '#D8D8D8'"
    class="modal-body"
    style="padding-bottom: 0"
  >
    <h5
      [style.color]="darkTheme ? 'white' : 'black'"
      style="margin-bottom: 16px"
    >
      {{ getModalTitle() }}
    </h5>
    <div
      [style.height]="canvasHeight + 'px'"
      class="ngx-photo-editor-img-container"
    >
      <span class="vertical-guide"></span>
      <img
        #image
        (load)="onImageLoad(image)"
        [src]="url"
        crossorigin="anonymous"
        id="ngx-photo-editor-image"
      />
    </div>
  </div>
  <div
    [style.backgroundColor]="darkTheme ? '#36393E' : '#D8D8D8'"
    class="ngx-photo-editor-custom-modal-footer"
  >
    <div>{{ getHelpText() }}</div>
    <div class="ngx-photo-editor-custom-modal-footer-buttons">
      <button
        (click)="close(true)"
        category="secondary"
        size="small"
        class="ngx-btn-override"
        attr.aria-label="{{ getCancelButtonCopy() }}"
        type="button"
      >
        {{ getCancelButtonCopy() }}
      </button>
      <button
        (click)="export(); close(false)"
        category="primary"
        size="small"
        type="button"
        attr.aria-label="{{ getApplyButtonCopy() }}"
      >
        {{ getApplyButtonCopy() }}
      </button>
    </div>
  </div>
</ng-template>
