import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormSelectOption } from "lib";
import * as IntakeSearchActions from "@pr-caseworker/app/intake-module/store/searchTerms/searchTerms.actions";
import { Store } from "@ngrx/store";
import { LobService } from "../../../../../../../lib/src/lib/services/lob/lob.service";
import { LovService } from "../../../../../../../lib/src/lib/services/lov/lov.service";
import * as fromApp from "../../../../store/app.reducer";
import { distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { valueTextFormatter } from "@pr-caseworker/app/shared/helpers/valueTextFormatter";
import { lovModel } from "projects/lib/src/public-api";
import { CasesService } from "../../cases.service";

@Component({
  selector: "prcw-class-filter",
  templateUrl: "./class-filter.component.html",
  styleUrls: ["./class-filter.component.scss"],
})
export class ClassFilterComponent implements OnInit, OnDestroy {
  public classFilter: FormControl;

  public subcategory: FormSelectOption[] = [];

  public lobFilterValue: string;
  public value: string | null;
  public subscriptions: Subscription[] = [];
  public isHidden: boolean = true;
  @Input() copyKey: string;

  constructor(
    private store: Store<fromApp.State>,
    private lobService: LobService,
    private lovService: LovService,
    private casesService: CasesService
  ) {}

  ngOnInit(): void {
    this.classFilter = new FormControl(null);

    this.subscriptions.push(
      this.classFilter.valueChanges.pipe(distinctUntilChanged()).subscribe()
    );

    this.subscriptions.push(
      this.store
        .select("intake")
        .pipe(distinctUntilChanged())
        .subscribe((intake) => {
          this.lobFilterValue = intake.searchTerms.lobFilterValue!;
          this.classFilter.setValue(intake.searchTerms.classFilterValue);
          this.classFilter.updateValueAndValidity();
          this.isHidden = this.lobFilterValue === null;
          if (this.lobFilterValue === "53") {
            this.subcategory = this.sortReferralOrganization().map((lov) => ({
              value: `${lov.value}`,
              text: { en: lov.text.en, fr: lov.text.fr },
            }));
          } else if (this.lobFilterValue === "12") {
            this.subcategory = this.casesService.designatedEntities.map(
              (de) => ({
                value: `${de.id}`,
                text: { en: de.nameEn, fr: de.nameFr },
              })
            );
          } else {
            this.subcategory = valueTextFormatter(
              this.lobService.fetchSubcategory(+this.lobFilterValue)
            );
          }
        })
    );
  }

  public sortReferralOrganization(): lovModel[] {
    const referralPartnerList =
      this.lovService.lovs.referralPartnerCaseworkerList;
    return referralPartnerList.sort(function (a: lovModel, b: lovModel) {
      let textA = a.text.en.toUpperCase();
      let textB = b.text.en.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  classFiltered() {
    this.store.dispatch(
      new IntakeSearchActions.SetClassFilterValue(this.classFilter.value)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
