<form class="search-form form-control" (ngSubmit)="onSubmit()">
  <lib-form-input
    *ngIf="!searchByCaseWorkerEmail && !searchByApplicationNumber"
    identifier="searchByClient"
    [formControl]="searchByClientField"
    [label]="copyKey | translate"
    [placeholder]="copyKey | translate"
    class="search-form__input search-input-overflow"
  ></lib-form-input>
  <lib-form-autocomplete
    *ngIf="searchByCaseWorkerEmail"
    identifier="searchByCw"
    class="search-form__input"
    [label]="copyKey | translate"
    [options]="cwEmails"
    [formControl]="searchByCwField"
    [placeholder]="copyKey"
    [showChevron]="false"
  >
  </lib-form-autocomplete>

  <lib-form-input
    *ngIf="!searchByCaseWorkerEmail && !searchByClientEmail"
    identifier="searchByApplicationNumber"
    [formControl]="searchByAppNumberField"
    [label]="copyKey | translate"
    [placeholder]="copyKey | translate"
    class="search-form__input search-input-overflow"
  ></lib-form-input>

  <lib-form-select
    identifier="searchFilter"
    [formControl]="searchBy"
    [label]="''"
    [options]="searchByOptions"
    (change)="searchByUpdated()"
    class="status-search"
    [useEmptyOption]="false"
    [useFadingEffect]="true"
    [ariaLabel]="
      'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.EMAIL_SEARCH_ARIA_LABEL' | translate
    "
  ></lib-form-select>
  <button type="submit" class="btn btn-primary search-submit">
    {{ copyKey | translate }}
  </button>
</form>
