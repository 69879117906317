import { Injectable } from "@angular/core";
import { LocalStorage } from "@ng-idle/core";

@Injectable()
export class NgIdleStorage extends LocalStorage {
  constructor() {
    super();
    if (sessionStorage) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this["storage"] = sessionStorage;
    }
  }
}
