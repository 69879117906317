<button
  category="primary"
  (click)="downloadBarcodes()"
  [disabled]="isDownloading"
>
  {{ "INTAKE.IMM0008.BARCODES.DOWNLOAD" | translate }}
</button>

<div class="spinner">
  <lib-spinner [showSpinner]="isDownloading" [fullPage]="false"></lib-spinner>
</div>

<div class="wrapper" *ngIf="!isDownloading">
  <table id="imm0008-barcodes-pg1">
    <!-- Barcodes 1 & 2 -->
    <tr>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[0].text"
          [barcodeId]="barcodesArray[0].id"
        >
        </prcw-barcode>
      </td>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[1].text"
          [barcodeId]="barcodesArray[1].id"
        >
        </prcw-barcode>
      </td>
    </tr>

    <!-- Barcodes 3 & 4 -->
    <tr>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[2].text"
          [barcodeId]="barcodesArray[2].id"
        >
        </prcw-barcode>
      </td>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[3].text"
          [barcodeId]="barcodesArray[3].id"
        >
        </prcw-barcode>
      </td>
    </tr>

    <!-- Barcodes 5 & 6 -->
    <tr>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[4].text"
          [barcodeId]="barcodesArray[4].id"
        >
        </prcw-barcode>
      </td>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[5].text"
          [barcodeId]="barcodesArray[5].id"
        >
        </prcw-barcode>
      </td>
    </tr>

    <!-- Barcodes 7 & 8 -->
    <tr>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[6].text"
          [barcodeId]="barcodesArray[6].id"
        >
        </prcw-barcode>
      </td>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[7].text"
          [barcodeId]="barcodesArray[7].id"
        >
        </prcw-barcode>
      </td>
    </tr>
  </table>

  <table id="imm0008-barcodes-pg2">
    <!-- Barcodes 9 & 10 -->
    <tr>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[8].text"
          [barcodeId]="barcodesArray[8].id"
        >
        </prcw-barcode>
      </td>
      <td>
        <prcw-barcode
          [barCodeData]="barcodesArray[9].text"
          [barcodeId]="barcodesArray[9].id"
        >
        </prcw-barcode>
      </td>
    </tr>
  </table>
</div>
