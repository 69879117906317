import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from "@angular/core";
import { FormSelectOption, ModalService, AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { Case } from "../../../core/cases-module/models/case.model";
import { CasesService } from "../../../core/cases-module/cases.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { ApplicationFormsTableComponent } from "./application-forms-table/application-forms-table.component";
import { Observable, Subscription } from "rxjs";
import { CanComponentDeactivate } from "../../../routing/guards/nav.guard";
import { Router } from "@angular/router";
import { allCaseStatuses, CaseStatusSelect } from "./case-details-models";
import { RoleIds } from "../../../shared/constants/roles";
import * as _ from "lodash";
@Component({
  selector: "prcw-case-list-details-page",
  templateUrl: "./case-details-page.component.html",
  styleUrls: ["./case-details-page.component.scss"],
})
export class CaseListDetailsPageComponent
  implements OnInit, AfterContentChecked, CanComponentDeactivate, OnDestroy
{
  public lang: string;
  public case: Case;
  public caseId: string;
  public navigationModalId: string = "navigationModal";
  public isFormTouched: boolean;
  public isFormSaved: boolean = false;
  public withRep: boolean = false;
  public initiatedBySponsor: boolean = false;
  public initiatedByReferralPartner: boolean = false;
  public documentDownloadInProgress = false;
  private subscriptionsList: Subscription[] = [];

  public canSaveApplication: boolean = true;
  @ViewChild(ApplicationFormsTableComponent, { static: false })
  ApplicationFormsTableComponent: ApplicationFormsTableComponent;

  public applicationReviewForm: FormGroup = this.fb.group({
    applicationStatus: new FormControl({ value: null, disabled: false }),
    repDeclaration: new FormControl({ value: null, disabled: false }),
  });

  public formApplicationStatusOptions: FormSelectOption[] = allCaseStatuses;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private casesService: CasesService,
    private authService: AuthService,
    private store: Store<fromApp.State>,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private router: Router
  ) {}

  @ViewChild(ApplicationFormsTableComponent, { static: false })
  applicationFormsTable: ApplicationFormsTableComponent;

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.routeLocalizer.setTranslatedTitle(
      "INTAKE.CASE_DETAILS_PAGE.PAGE_TITLE"
    );
    this.authService.checkSession();
    this.getStoredCase();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
    // this.toggleApplicationStatusField();
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    this.isFormTouched = this.applicationFormsTable?.caseListForm.touched;

    if (this.isFormTouched && !this.isFormSaved) {
      this.modalService.open(this.navigationModalId);
      return this.modalService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  /* PUBLIC ------------------------------------------------------------------------------- */

  public get displayName(): string {
    return this.case?.emailAddress;
  }

  public get allDocStatusesAreSet(): boolean {
    return this.ApplicationFormsTableComponent?.documentStatusesAreSet;
  }

  public get isAssigned(): boolean {
    return this.case?.cognitoCaseWorkerId !== null;
  }

  public get hasRepresentative(): boolean {
    return this.case && this.case.representativeId ? true : false;
  }

  public disableSaveApplicationBtn(isEditingClientInfoForm: any): void {
    this.canSaveApplication = !isEditingClientInfoForm;
  }

  public saveApplication(): void {
    this.isFormSaved = true;

    //  get selected option from allCaseStatuses array
    const selectedStatusOption = allCaseStatuses.find(
      (option) =>
        option.value ===
        this.applicationReviewForm.controls.applicationStatus.value
    );

    /*
      update the status whether all documents are reviewed or not
      check that values are defined first
    */
    if (selectedStatusOption && selectedStatusOption.statusId) {
      this.updateCaseToStatusId(selectedStatusOption.statusId);
    }
  }

  toggleRepDeclaration(e: Event) {
    e.preventDefault();
  }

  onStartOrFinishDownload(downloadInProgress: boolean) {
    this.documentDownloadInProgress = downloadInProgress;
  }

  /* PRIVATE ------------------------------------------------------------------------------- */

  private get alertSuccessDocuments(): string {
    return this.translate.instant("INTAKE.ALERTS.DOCUMENTS_SAVED");
  }

  private get alertSuccessCaseComplete(): string {
    return this.translate.instant("INTAKE.ALERTS.CASE_SAVED_COMPLETE");
  }

  private get alertSuccessCaseIncomplete(): string {
    return this.translate.instant("INTAKE.ALERTS.CASE_SAVED_INCOMPLETE");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  public get alertInvalidDocError(): string {
    return this.translate.instant("INTAKE.ALERTS.INVALID_DOCS_ERROR");
  }

  private get alertInvalidStatusError(): string {
    return this.translate.instant("INTAKE.ALERTS.INVALID_STATUS_ERROR");
  }

  private alertMessageForUpdatedStatus(newStatusId: string | number): string {
    const updatedToStatus = allCaseStatuses.find(
      (item) => item.statusId === newStatusId
    );
    return (
      this.translate.instant("INTAKE.ALERTS.CASE_STATUS_GENERIC") +
      " " +
      (this.lang === "en"
        ? updatedToStatus?.text?.en
        : updatedToStatus?.text?.fr)
    );
  }

  private getStoredCase(): void {
    this.subscriptionsList.push(
      this.store.select("intake").subscribe((currentCaseData: any) => {
        if (currentCaseData) {
          this.case = _.cloneDeep(currentCaseData.selectedApplication);
          this.setApplicationStatusValue();
        }
      })
    );
  }

  private setApplicationStatusValue(): void {
    this.setFormApplicationStatusOptions(this.case, allCaseStatuses);

    /*
      if not submitted, get the case status and set value
      (if it is submitted, app status field will be default, ie "Select") 
    */
    if (this.case?.caseStatusId !== 7) {
      const appStatusOption = allCaseStatuses.find(
        (item) => item.statusId === this.case?.caseStatusId
      );
      this.applicationReviewForm.controls.applicationStatus.setValue(
        appStatusOption?.value
      );
      this.applicationReviewForm.controls.applicationStatus.updateValueAndValidity();
    }

    /*  
      check if case has a rep so we can show/hide rep section
      set rep declaration checkbox to true if timestamp exists
    */
    if (!!this.case?.representativeId) {
      this.withRep = true;
      this.applicationReviewForm.controls.repDeclaration.setValue(
        !!this.case?.representativeConfirmationTimestamp
      );
    } else {
      this.withRep = false;

      if (
        this.case?.members?.find((member) => {
          return (
            member.emailAddress === this.case?.emailAddress &&
            member.roleId === RoleIds.PRIMARY_SPONSOR
          );
        })
      ) {
        this.initiatedBySponsor = true;
      } else {
        this.initiatedBySponsor = false;
      }
      if (
        this.case?.members?.find((member) => {
          return (
            member.emailAddress === this.case?.emailAddress &&
            member.roleId === RoleIds.REFERAL_PARTNER
          );
        })
      ) {
        this.initiatedByReferralPartner = true;
      }
    }

    this.setFormApplicationStatusOptions(this.case, allCaseStatuses);
  }

  private setFormApplicationStatusOptions(
    caseData: Case,
    allOptions: CaseStatusSelect[]
  ) {
    // that once landing on case details
    let allowedOptionsByIds = allCaseStatuses.map((item) => item.statusId);
    switch (caseData.caseStatusId) {
      // submitted
      case 7: {
        allowedOptionsByIds = [8, 10, 11, 12];
        break;
      }
      // Incomplete, Returned
      case 8: {
        allowedOptionsByIds = [7, 8, 10];
        break;
      }
      // completed
      case 10: {
        allowedOptionsByIds = [7, 8, 10];
        break;
      }
      // RPA-UPLOAD-FAILED
      // status 12 is conditional, based on whether applicationNumber field has value
      case 11: {
        allowedOptionsByIds = [8, 10, 11];
        if (caseData.applicationNumber) {
          allowedOptionsByIds = [...allowedOptionsByIds, 12];
        }
        break;
      }
      // READY-FOR-SUPPORT-DOC-UPLOAD
      case 12: {
        allowedOptionsByIds = [12, 13, 14];
        break;
      }
      //SUPPORTING-DOC-UPLOAD-FAIL
      case 13: {
        allowedOptionsByIds = [8, 10, 13, 14];
        break;
      }
      //READY-FOR-CHECK
      case 14: {
        allowedOptionsByIds = [8, 10, 14];
        break;
      }
      default: {
        allowedOptionsByIds = [...allowedOptionsByIds];
        break;
      }
    }

    //  filter allOptions list to only include allowed options
    this.formApplicationStatusOptions = allOptions.filter(
      (option) => allowedOptionsByIds.indexOf(option.statusId) >= 0
    );
  }

  private toggleApplicationStatusField(): void {
    if (this.allDocStatusesAreSet === false) {
      this.applicationReviewForm.controls.applicationStatus.disable();
      this.applicationReviewForm.controls.applicationStatus.reset();
    } else {
      this.applicationReviewForm.controls.applicationStatus.enable();
    }
  }

  private minimizedDocData(): any[] {
    if (this.ApplicationFormsTableComponent.docsForAPI.length > 0) {
      return this.ApplicationFormsTableComponent.docsForAPI.map((doc) => ({
        id: doc.id,
        documentApproved: doc.documentApproved,
        gcmsUploadStatus: doc.gcmsUploadStatus,
        caseMemberId: doc.caseMemberId,
      }));
    }
    return this.ApplicationFormsTableComponent.docsForAPI;
  }

  private updateCaseToStatusId(newStatusId: string | number): void {
    this.canSaveApplication = false;
    //get gcms doc upload status
    const minimizedDocDataArray = this.minimizedDocData();
    const isAnyFailedDoc = minimizedDocDataArray.find((currentDoc) => {
      if (currentDoc.gcmsUploadStatus === false && newStatusId === 14) {
        return true; // stop searching
      }
      return false;
    });
    //check if any doc has failed and return appropriate error message
    if (isAnyFailedDoc) {
      this.alertService.danger(this.alertInvalidDocError);
      this.canSaveApplication = true;
    } else {
      this.casesService
        .updateIntakeCaseToStatusId(
          this.case.id.toString(),
          this.minimizedDocData(),
          newStatusId
        )
        .then(() => {
          this.router.navigateByUrl(this.lang);
          if (newStatusId === 8) {
            this.alertService.success(this.alertSuccessCaseIncomplete);
          } else {
            this.alertService.success(
              this.alertMessageForUpdatedStatus(newStatusId)
            );
          }
        })
        .catch(() => {
          this.alertService.danger(this.alertInvalidStatusError);
          this.canSaveApplication = true;
        });
    }
  }

  ngOnDestroy() {
    this.subscriptionsList.forEach((subsc) => subsc.unsubscribe());
  }
}
