import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { delay, map, retry, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import * as fromApp from "../../store/app.reducer";
import {
  DocumentForReview,
  Reason,
} from "../../confirmation-module/models/document-rejection.model";
import * as CasesActions from "../../confirmation-module/store/cases.actions";
import { DocumentService } from "../services/document/document.service";
import { AWSService } from "../auth-module/services/AWS.service";
import {
  CaseSearchObject,
  CaseAPIFilters,
  CaseStatus,
  CaseForBulkAssignAPILoad,
  Case,
  ReturnReason,
  DesignatedEntity,
  CommCert,
} from "./models/case.model";
import { FormSelectOption } from "lib";
import { from, Observable, of } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import * as ApplicationActions from "@pr-caseworker/app/intake-module/store/selectedApplication/selectedApplication.actions";
import { UtilityService } from "@pr-caseworker/app/core/auth-module/services/utility.service";
import { AwsSdkService } from "@pr-caseworker/app/shared/services/aws-sdk/aws-sdk.service";

const apiVersion = environment.API_VERSION;
const apiName = environment.API_NAME;

@Injectable()
export class CasesService {
  apiName = environment.API_NAME;

  private username: string | undefined | null;
  public designatedEntities: DesignatedEntity[] = [];
  public commCerts: CommCert[] = [];

  constructor(
    private store: Store<fromApp.State>,
    private documentService: DocumentService,
    private awsService: AWSService,
    private sanitizer: DomSanitizer,
    private utilityService: UtilityService,
    private awsSdkService: AwsSdkService
  ) {
    this.store
      .select("auth")
      .pipe(map((authState) => authState.user))
      .subscribe((user) => {
        if (user !== null) {
          this.username = user.username;
        }
      });
  }

  public statusKeys: any = {
    1: "INITIATED",
    2: "REVIEW",
    3: "REVISION",
    4: "COMPLETED",
    5: "REJECTED",
  };

  public defaults = {
    confirmation: {
      filters: { phaseId: 2 },
      search: ["uci", "applicationNumber"],
      paginationLimit: 50,
    },
    intake: {
      filters: { phaseId: 1, caseStatusId: { not: 6 } },
      search: ["emailAddress", "representative"],
      application: ["applicationNumber"],
      representative: "membershipId",
      paginationLimit: 50,
    },
    renewal: {
      filters: { phaseId: 3, caseStatusId: { not: 15 } },
      paginationLimit: 50,
      application: ["applicationNumber"],
      search: ["emailAddress", "representative"],
    },
  };

  public getStatusLabel(statusId: number): string {
    return `CASE_STATUSES.${this.statusKeys[statusId]}`;
  }

  public checkApplicantStatus(caseStatusId: number): string {
    switch (caseStatusId) {
      case 2:
        return "review";
      case 3:
        return "revision";
      case 4:
        return "accepted";
      default:
        return "initiated";
    }
  }

  private parametrizeURL(
    page?: number,
    sort?: string,
    search?: CaseSearchObject,
    filters?: CaseAPIFilters,
    cognitoCaseWorkerId?: string | null,
    noApplicationNumber?: boolean
  ): string {
    // We can use this because we're not supporting IE: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    const urlParams = new URLSearchParams();
    // Filter looks for an exact match for a key-value pair
    if (filters) {
      this.utilityService.mapFilters(urlParams, filters);
    }
    // Search looks for a partial or full value across multiple fields
    search?.fields.forEach((field) => {
      const joinSearchValue = this.getJoinSearchValue(field);
      joinSearchValue
        ? urlParams.append(
            `search[${field}][${joinSearchValue}]`,
            `${search.value}`
          )
        : urlParams.append(`search[${field}]`, `${search.value}`);
    });
    // &cognitoCaseworkerId=${}
    if (cognitoCaseWorkerId && cognitoCaseWorkerId !== "UNASSIGNED") {
      urlParams.append("cognitoCaseWorkerId", `${cognitoCaseWorkerId}`);
    } else if (cognitoCaseWorkerId && cognitoCaseWorkerId === "UNASSIGNED") {
      urlParams.append("isAssigned", "false");
    }
    if (sort) {
      urlParams.append("sort", `${sort}`);
    } // &sort=${sort}
    if (page) {
      urlParams.append("page", `${page}`);
    } // &page=${page}
    if (noApplicationNumber) {
      urlParams.append("applicationNumber[none]", `${noApplicationNumber}`);
    } // &noApplicationNumber=${noApplicationNumber}
    return urlParams.toString();
  }

  private getJoinSearchValue(field: any) {
    const defaultValues = Object.values(this.defaults);
    for (const elem of defaultValues) {
      if (elem.search.includes(field) && elem.hasOwnProperty(field)) {
        const index = Object.keys(elem).indexOf(field);
        return Object.values(elem)[index];
      }
    }
  }

  public async getAllCases(
    filters?: CaseAPIFilters,
    page?: number,
    sort?: string,
    search?: CaseSearchObject,
    cognitoCaseWorkerId?: string | null,
    noApplicationNumber?: boolean
  ): Promise<any> {
    const urlParams = this.parametrizeURL(
      page,
      sort,
      search,
      filters,
      cognitoCaseWorkerId,
      noApplicationNumber
    );
    const path = `/${apiVersion}/cases?${urlParams}`;

    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const responseData = [];
      const data = await this.awsService.api.get(apiName, path, init);
      const headers = data.headers["x-total-count"];
      responseData.push(data.data, headers);
      const formattedResponseData = this.formatCasesResponseBody(responseData);
      return formattedResponseData;
    } catch (error) {
      throw error;
    }
  }

  public async fetchCaseStatusesForDropDown(
    phaseId: number
  ): Promise<FormSelectOption[]> {
    const fetched = await this.getAllCaseStatuses(phaseId);
    if (fetched.length > 0) {
      return fetched.map((caseStatus) => ({
        value: caseStatus.id,
        text: {
          en: caseStatus.statusEn,
          fr: caseStatus.statusFr,
        },
      }));
    } else {
      return [];
    }
  }

  public async getAllCaseStatuses(phaseId: number): Promise<CaseStatus[]> {
    const path = `/${apiVersion}/case-status?phaseId=${phaseId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(apiName, path, init);
      return response.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  public async doesEmailExist(email: string): Promise<boolean> {
    const path = `/v2/users?emailAddress=${email}`;
    const myInit = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    return this.awsService.api
      .get(apiName, path, myInit)
      .then((res) => {
        return res?.data?.userStatus === "CONFIRMED";
      })
      .catch((err) => {
        if (
          ((err?.response?.data?.message as string) || "").includes(
            "user exists"
          )
        ) {
          return true;
        }
        console.error(err);
        return false;
      });
  }

  // TODO: rename createConfirmationCase() ?
  public async createCase(addedCase: any): Promise<any> {
    const path = `/${apiVersion}/cases`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {
        uci: addedCase.uci,
        applicationNumber: addedCase.applicationNumber,
        surname: addedCase.surname,
        givenName: addedCase.givenName,
        dob: addedCase.dob,
        clientEmail: addedCase.emailAddress,
        caseWorkerEmail: this.username,
        clientLanded: addedCase.clientLanded,
        isPrincipalApplicant: addedCase.isPrincipalApplicant,
        officeId: addedCase.office,
      },
    };
    try {
      // Create case
      const { data } = await this.awsService.api.post(apiName, path, init);
      // Save new case created to store
      const newCase = {
        uci: addedCase.uci,
        surname: addedCase.surname,
        givenName: addedCase.givenName,
      };
      this.store.dispatch(new CasesActions.CreateCase({ newCase }));
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async getClientCasebyCaseId(caseId?: string): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const singleCase = await this.awsService.api.get(apiName, path, init);
      return singleCase.data;
    } catch (error) {
      throw error;
    }
  }

  public async deleteCasebyCaseId(caseId?: string): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const deleteCase = await this.awsService.api.del(apiName, path, init);
      return { caseId };
    } catch (error) {
      throw error;
    }
  }

  // TODO: Rename updateConfirmationCase()?
  public async editClientCase(caseId: string, modifiedCase: any): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {
        uci: modifiedCase.uci,
        applicationNumber: modifiedCase.applicationNumber,
        surname: modifiedCase.surname,
        givenName: modifiedCase.givenName,
        dob: modifiedCase.dob,
        clientLanded: modifiedCase.clientLanded,
        isPrincipalApplicant: modifiedCase.isPrincipalApplicant,
      },
    };
    try {
      const singleCase = await this.awsService.api.put(apiName, path, init);
      return singleCase.message;
    } catch (error) {
      throw error;
    }
  }

  // TODO: move to Documents Service (or other)?
  public async getPhoto(
    selectedCase: any,
    renewal?: boolean,
    docIdentityId?: string
  ): Promise<any> {
    const defaultFileExtension = "jpeg";
    const uci = selectedCase.uci;

    const confirmationPhoto = selectedCase?.documents?.filter(
      (doc: any) => doc.documentTypeId === 1
    )[0];

    let fileNamePrefix = !!renewal
      ? `${selectedCase.id}/front`
      : `${selectedCase.id}/${uci}`;

    if (confirmationPhoto) {
      fileNamePrefix = confirmationPhoto.documentName.slice(
        confirmationPhoto.documentName.indexOf("/") + 1
      );
    }

    let identityId = selectedCase.identityId;

    if (!!docIdentityId) {
      identityId = docIdentityId;
    }
    let photoName = `${fileNamePrefix}-photo.${defaultFileExtension}`;

    if (confirmationPhoto) {
      photoName = confirmationPhoto.documentName.slice(
        confirmationPhoto.documentName.indexOf("/") + 1
      );
    }

    try {
      const photoLocation = `${identityId}/${photoName}`;
      const s3response: any = await this.awsService.storage.get(photoName, {
        identityId,
        download: true,
        cacheControl: "no-cache",
      });
      const blobFile = s3response.Body;
      const previewFile = new File([blobFile], photoName, {
        type: s3response.ContentType,
      });
      const photoLink = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(previewFile)
      );
      const metadata = s3response.Metadata;
      const coordinates = {
        heightCompare: parseFloat(metadata.heightcompare),
        widthCompare: parseFloat(metadata.widthcompare),
        leftDiff: parseFloat(metadata.leftdiff),
        topDiff: parseFloat(metadata.topdiff),
      };
      return { photoName, photoLocation, photoLink, coordinates, previewFile };
    } catch (err) {
      throw err;
    }
  }

  async updatePhotoCoordinates(
    photoName: string,
    identityId: string,
    selectedPhoto: any,
    coordinates: any
  ): Promise<any> {
    const fileType = selectedPhoto.type;
    return of("uploadingPhotoTos3")
      .pipe(
        switchMap(() =>
          this.uploadFileToS3(
            photoName,
            selectedPhoto,
            fileType,
            identityId,
            coordinates
          )
        ),
        retry(2),
        delay(200)
      )
      .toPromise();
  }

  public async updateAddressStatus(
    caseId: string,
    document: DocumentForReview
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/address-status`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: { addressApproved: document.status },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // TODO: Move to Document Service
  public async updateDocumentStatus(
    caseId: string,
    document: DocumentForReview
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${document.id}/status`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: { documentApproved: document.status },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async updateCaseStatus(caseId: string, status: number): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/status`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: { caseStatusId: status },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // TODO: Move to Document Service?
  async getDocumentRejectionReasonsByDocumentId(
    caseId: string,
    documentId: number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}/rejection-reasons`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(apiName, path, init);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  // TODO: Refactor to combine with getDocumentRejectionReasonsByDocumentId()?
  public async getAddressRejectionReasonsByCaseId(
    caseId: string
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/addresses/rejection-reasons`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(apiName, path, init);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  // TODO: Move to Document Service?
  public async updateDocumentRejectionReasonsByDocumentId(
    caseId: string,
    documentId: number,
    rejectionsReasons: Reason[]
  ): Promise<any> {
    const rejectionArrayIds = this.mapArrayToSelectionIds(rejectionsReasons);
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}/rejection-reasons`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {
        selectedRejectionReasons: rejectionArrayIds,
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  updateRenewalDocumentRejectionReasonsByDocumentId(
    caseId: string,
    documentId: number,
    rejectionsReasons: ReturnReason[]
  ): Observable<any> {
    const rejectionArrayIds = this.mapArrayToSelectionIds(
      rejectionsReasons as Reason[]
    );
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}/rejection-reasons`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          body: {
            selectedRejectionReasons: rejectionArrayIds,
          },
          response: true,
        };
        return from(this.awsService.api.put(apiName, path, init));
      }),
      retry(1)
    );
  }

  updateRenewalCaseRejectionReasons(
    caseId: string,
    selectedRejectionReasons: ReturnReason[]
  ) {
    const path = `/${apiVersion}/cases/${caseId}/rejection-reasons`;
    const rejectionArrayIds = this.mapArrayToSelectionIds(
      selectedRejectionReasons as Reason[]
    );
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          body: {
            selectedRejectionReasons: rejectionArrayIds,
          },
          response: true,
        };
        return from(this.awsService.api.put(apiName, path, init));
      }),
      retry(2)
    );
  }

  // TODO: Refactor to combine with updateDocumentRejectionReasonsByDocumentId()?
  async updateAddressRejectionReasonsByCaseId(
    caseId: string,
    rejectionsReasons: Reason[]
  ): Promise<any> {
    const rejectionArrayIds = this.mapArrayToSelectionIds(rejectionsReasons);
    const path = `/${apiVersion}/cases/${caseId}/addresses/rejection-reasons`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {
        selectedRejectionReasons: rejectionArrayIds,
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getIdentityId(caseId: any): Promise<any> {
    const clientCase = await this.getClientCasebyCaseId(caseId);
    // this.store.dispatch(new CasesActions.SetCurrentCase(clientCase));
    return clientCase.identityId;
  }

  // TODO: Move to Document Service?
  public async uploadEcoprFileByCaseId(
    caseId: any,
    fileSelected: any
  ): Promise<any> {
    const defaultFileExtension = "pdf";
    const documentTypeId = 3;
    const clientCase = await this.getClientCasebyCaseId(caseId);
    const fileName = `${clientCase.uci}-eCoPR.${defaultFileExtension}`;
    const fileType = fileSelected.type;
    // if file already exists
    const file = this.documentService.getFileByDocumentType(
      documentTypeId,
      clientCase.documents
    );
    let identityId: any;
    let uploadedFileName = "";
    return of("start upload")
      .pipe(
        switchMap(() => from(this.getIdentityId(caseId))),
        switchMap((identityIdRes) => {
          identityId = identityIdRes;
          return this.uploadFileToS3(
            fileName,
            fileSelected,
            fileType,
            identityId
          );
        }),
        switchMap((res: any) => {
          uploadedFileName = res;
          return from(this.awsService.getToken());
        }),
        switchMap((token) => {
          if (!!uploadedFileName && uploadedFileName !== "") {
            const pathPut = `/${apiVersion}/cases/${caseId}/documents/${
              file ? file.id : ""
            }`;
            const pathPost = `/${apiVersion}/cases/${caseId}/documents/`;
            const init = {
              headers: { Authorization: token },
              body: {
                documentName: `${identityId}/${fileName}`,
                documentTypeId,
              },
            };
            if (file !== null) {
              return from(this.awsService.api.put(apiName, pathPut, init));
            } else {
              return from(this.awsService.api.post(apiName, pathPost, init));
            }
          } else {
            const error = new Error("Could not reach S3 endpoint");
            throw error;
          }
        }),
        retry(2),
        delay(200)
      )
      .toPromise();
  }

  public uploadFileToS3(
    fileName: string,
    fileSelected: any,
    fileType: any,
    identityId: any,
    metadata?: any
  ): Observable<any> {
    return this.awsSdkService.createFileChecksum(fileSelected).pipe(
      switchMap((hash) => {
        return this.awsSdkService.uploadFileToS3(
          fileName,
          fileSelected,
          fileType,
          hash,
          metadata,
          identityId
        );
      }),
      switchMap((putResult: any) => {
        if (!putResult?.uploadedFileName) throw "Could not attempt put on S3";
        return from(
          this.awsService.storage.list(putResult?.uploadedFileName, {
            identityId,
          })
        );
      }),
      map((listResult) => {
        const uploadedFileName = listResult[0]?.key;
        if (uploadedFileName) {
          return uploadedFileName;
        } else {
          const error = new Error("Could not reach S3 endpoint");
          throw error;
        }
      })
    );
  }

  // TODO: Move to Document Service?
  public async removeEcoprFileByCaseId(caseId: any): Promise<any> {
    const defaultFileExtension = "pdf";
    const documentTypeId = 3;
    const clientCase = await this.getClientCasebyCaseId(caseId);
    const fileName = `${clientCase.uci}-eCoPR.${defaultFileExtension}`;
    const file = this.documentService.getFileByDocumentType(
      documentTypeId,
      clientCase.documents
    );
    try {
      const identityId = await this.getIdentityId(caseId);
      return await this.awsService.storage
        .remove(fileName, {
          contentType: defaultFileExtension,
          identityId,
        } as any)
        .then(async (deleted: any) => {
          if (deleted?.$metadata?.httpStatusCode === 204) {
            const path = `/${apiVersion}/cases/${caseId}/documents/${file.id}`;
            const init = {
              headers: { Authorization: await this.awsService.getToken() },
              body: {
                documentName: `${identityId}/${fileName}`,
                documentTypeId,
              },
            };
            const result = await this.awsService.api.del(apiName, path, init);
            return result;
          } else {
            throw new Error(deleted?.$metadata?.httpStatusCode);
          }
        });
    } catch (error) {
      throw error;
    }
  }

  /*
    INTAKE-RELATED
    --------------
    */
  /*method to be used for bulk or even individual assignment as array passed can be lf legth 1*/
  public async bulkAssignIntakeRenewalCases(body: any): Promise<Case[]> {
    const path = `/${apiVersion}/cases`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body,
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      // TO DO ...next line to be changed as per API response...in progress as of now...
      // this.store.dispatch(new ApplicationActions.SetSelectedApplication(response.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  /* Update Intake case (application) status and document statuses */
  /* (See below for convenience methods) */
  public async updateIntakeCase(caseId: string, body: any = {}): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body,
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      this.store.dispatch(
        new ApplicationActions.SetSelectedApplication(response.data)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /* Update Renewal/TD case (application) status and document statuses */
  /* (See below for convenience methods) */
  async updateRenewalCase(caseId: string, body: any = {}): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body,
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /* Assign application: set CW true, for creating "assigned" status */
  public async assignIntakeCase(caseId: string): Promise<any> {
    const apiBody = {
      caseStatusId: 7,
      cognitoCaseWorkerId: this.username,
    };
    return await this.updateIntakeCase(caseId, apiBody);
  }

  /* Un-assign application: set CW to null, for creating "unassigned" status */
  public async unassignIntakeCase(caseId: string): Promise<any> {
    const apiBody = {
      caseStatusId: 7,
      cognitoCaseWorkerId: null,
    };
    return await this.updateIntakeCase(caseId, apiBody);
  }

  // Set application to new StatusId
  public async updateIntakeCaseToStatusId(
    caseId: string,
    reviewedDocs: any[],
    statusId?: string | number
  ): Promise<any> {
    const apiBody = {
      caseStatusId: statusId,
      documents: reviewedDocs,
    };
    return await this.updateIntakeCase(caseId, apiBody);
  }

  // Set application to new StatusId
  updateRenewalCaseStatusAndDocs(
    caseId: string,
    reviewedDocs: any[],
    statusId?: string | number
  ): Observable<any> {
    const apiBody: any = {};

    if (statusId) {
      apiBody.caseStatusId = statusId;
    }

    if (reviewedDocs?.length > 0) {
      apiBody.documents = reviewedDocs;
    }

    return of(true).pipe(
      switchMap(() => from(this.updateRenewalCase(caseId, apiBody))),
      retry(1),
      delay(200)
    );
  }

  public async getAppDocs(
    phaseId: number = 1,
    isUrgent: boolean = false,
    lobId?: number
  ): Promise<any> {
    const urgentPath = isUrgent ? "&isUrgent=true" : "";
    let path = `/${apiVersion}/document-types?`;
    if (!!lobId) {
      path = path + `lobId=${lobId}${urgentPath}`;
    } else {
      path = path + `phaseId=${phaseId}${urgentPath}`;
    }

    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const appForms = await this.awsService.api.get(apiName, path, init);
      return appForms.data;
    } catch (error) {
      throw error;
    }
  }

  public async getAppForms(phaseId: number = 1): Promise<any> {
    const path = `/${apiVersion}/document-types?phaseId=${phaseId}&isForm=true`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const appForms = await this.awsService.api.get(apiName, path, init);
      return appForms.data;
    } catch (error) {
      throw error;
    }
  }

  /* get the reason list for return*/
  public getAllReturnReasons(
    phaseId: number
  ): Observable<{ forms: ReturnReason[] }> {
    const path = `/${apiVersion}/phase-id/${phaseId}/rejection-reasons`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          response: true,
        };
        return from(this.awsService.api.get(this.apiName, path, init));
      }),
      map((data) => {
        const responseData = { forms: data.data };
        return responseData;
      }),
      retry(2)
    );
  }

  /* PRIVATE ------------------------------------------------------------------------------- */

  // since the cases body has changed, revise and restructure array to match the object structure used widely across application.
  // Object coming from API: cases = [0: [cases array], 1: "length"]
  // Object restructure: cases = [0: {cases: [casesarray]}, , 1: "length"]
  private formatCasesResponseBody(responseData: any) {
    if (responseData && responseData.length >= 2) {
      const restructuredCasesArray = JSON.parse(JSON.stringify(responseData)); // deep clone;
      const confirmationCases = responseData[0];
      restructuredCasesArray[0] = { cases: confirmationCases };
      return restructuredCasesArray;
    }
    return responseData;
  }

  private mapArrayToSelectionIds(rejectionsReasons: Reason[]) {
    const selectionIds: { rejectionReasonId: number }[] = [];
    rejectionsReasons.forEach((reason) => {
      if (reason.selected) {
        let rejectionReason = {
          rejectionReasonId: reason.id,
          rejectionDetails: reason?.rejectionDetails,
        };
        selectionIds.push(rejectionReason);
      }
    });
    return selectionIds;
  }

  public getDesignatedEntities() {
    const path = `/${apiVersion}/designated-entities`;
    if (this.designatedEntities.length > 0) return of({});
    return from(this.awsService.getToken()).pipe(
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          response: true,
        };
        return from(this.awsService.api.get(this.apiName, path, init));
      }),
      map((data) => {
        this.designatedEntities = data.data;
      }),
      retry(2)
    );
  }

  public getCommCerts() {
    const path = `/${apiVersion}/comm-certs`;
    if (this.commCerts.length > 0) return of(this.commCerts);
    return from(this.awsService.getToken()).pipe(
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          response: true,
        };
        return from(this.awsService.api.get(this.apiName, path, init));
      }),
      map((data) => {
        this.commCerts = data.data;
        return data.data;
      }),
      retry(2)
    );
  }
}
