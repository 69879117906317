import { Injectable } from "@angular/core";
import { CanActivate, UrlTree, Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LangGuard implements CanActivate {
  private previousUrlLang: string;

  constructor(private router: Router, private translate: TranslateService) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        /* get the lang segment from the previous url */
        this.previousUrlLang = event.url.split("/")[1];
      });
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.previousUrlLang) {
      return this.router.parseUrl(this.previousUrlLang);
    } else {
      const browserLang =
        this.translate.currentLang || this.translate.getBrowserLang();
      return this.router.parseUrl(browserLang!);
    }
  }
}
