import { Injectable, OnInit } from "@angular/core";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { UserGroup } from "lib";
import { filter, mergeWith, switchMap, tap } from "rxjs/operators";
import { Case } from "../../case-module/case.model";
import { initialState } from "../../case-module/store/case.reducer";
import routePaths from "../../../routing/route-paths";

import permissionsJson from "./role-permissions.json";
import {
  AppPrcPrtdState,
  selectedPRCard,
  selectedPRtravelDocument,
} from "@pr-applicant/app/renewal-module/store/prcPrtdState";
import { BehaviorSubject, empty, of } from "rxjs";
import { IGroupMember, PsrService, IPsrDetails } from "../psr/psr.service";

type RoleName =
  | "primarySponsor"
  | "sponsorRep"
  | "sponsorMember"
  | "refugee"
  | "paidRefugeeRep"
  | "unpaidRefugeeRep"
  | "representative"
  | "client"
  | "clientWithRep"
  | "referralPartner"
  | "OYWRepresentative"
  | "OYWClientWithRep"
  | undefined;

interface IRoleNames {
  [roleId: number]: string;
}

const roleNames: IRoleNames = {
  1: "primarySponsor",
  2: "sponsorMember",
  3: "refugee",
  4: "sponsorRep",
  5: "paidRefugeeRep",
  6: "unpaidRefugeeRep",
  7: "representative",
  8: "client",
  9: "clientWithRep",
  10: "primaryContact",
  11: "referralPartner",
  12: "OYWRepresentative",
  13: "OYWClientWithRep",
};

@Injectable({
  providedIn: "root",
})
export class UserService implements OnInit {
  private accountRole: string | undefined;
  private repId: string | undefined;
  private roleToPermissionsMap = permissionsJson;
  private caseHasRep: boolean;
  public caseId: string;
  public _caseRole: RoleName;
  public currentUserEmail?: string;
  public currentMember: IGroupMember;
  private prtdRoute = new RegExp(
    "(/" +
      routePaths.en.RENEWAL_TRAVEL_DOCUMENTS +
      "/|/" +
      routePaths.fr.RENEWAL_TRAVEL_DOCUMENTS +
      "/).*?\\d+",
    "i"
  );
  private prcRoute = new RegExp(
    "(/" +
      routePaths.en.RENEWAL_PERMANENT_RESIDENT_CARD +
      "/|/" +
      routePaths.fr.RENEWAL_PERMANENT_RESIDENT_CARD +
      "/).*?\\d+",
    "i"
  );
  private praRoute = new RegExp(
    "(/" + routePaths.en.INTAKE + "/|/" + routePaths.fr.INTAKE + "/).*?\\d+",
    "i"
  );

  private prConfRoute = new RegExp(
    "(/" +
      routePaths.en.CONFIRMATION_CASES +
      "/|/" +
      routePaths.fr.CONFIRMATION_CASES +
      "/).*?\\d+",
    "i"
  );

  private dataSource = new BehaviorSubject<Case>(initialState);
  public currentCaseObs$ = this.dataSource.asObservable();

  constructor(
    private store: Store<fromApp.State>,
    private renewalStore: Store<AppPrcPrtdState>,
    private router: Router,
    private psrService: PsrService
  ) {
    this.store.select("auth").subscribe((auth) => {
      this.accountRole = auth.user?.userPool;
      this.repId = auth.user?.repId;
      this.currentUserEmail = auth.user?.username;
    });

    // this tracks the active case, the PRA store emits even when browsing within renewal cases
    this.router.events
      .pipe(
        filter(
          (routerEvent: RouterEvent) => routerEvent instanceof NavigationStart
        ),
        switchMap((routerEvent: RouterEvent) => {
          if (this.prcRoute.test(routerEvent.url))
            return this.renewalStore.select(selectedPRCard);
          if (this.prtdRoute.test(routerEvent.url))
            return this.renewalStore.select(selectedPRtravelDocument);
          if (this.praRoute.test(routerEvent.url))
            return this.store.select("selectedCase");
          if (this.prConfRoute.test(routerEvent.url))
            return this.store.select("selectedCase");

          return of({ representativeId: null, id: null, roleId: undefined }); // remove the rep Id when browse off of a case
        }),
        filter((caseState: Case) => !!caseState)
      )
      .subscribe((caseState: Case) => {
        this.setCaseRole(caseState.roleId);
        this.caseHasRep = !!caseState.representativeId;
        this.caseId = caseState.id;
        if (caseState.members) {
          this.currentMember = caseState.members.filter(
            (member) => member.emailAddress === this.currentUserEmail
          )[0];
        }
        this.dataSource.next(this.addPsrIfNeeded(caseState));
      });
  }

  ngOnInit(): void {}

  addPsrIfNeeded(caseState: Case): Case {
    if (this.psrService.isPSR(Number(caseState.lobId))) {
      this.psrService.setMemberStatuses(
        caseState.members,
        caseState.lob.relatedDocumentTypes,
        caseState.documents ? caseState.documents : []
      );

      const psrDetails: IPsrDetails = {
        isPsr: true,
        isG5: this.psrService.isG5(Number(caseState.lobId)),
        has5members: this.psrService.has5members(caseState.members),
        hasMemberDocsComplete: this.psrService.hasMemberDocsComplete(),
        hasCurrentMemberDocsComplete:
          this.psrService.hasCurrentMemberDocsComplete(
            this.currentUserEmail ? this.currentUserEmail : "",
            caseState.members
          ),
      };
      caseState = Object.assign({ psrDetails: psrDetails }, caseState);
    }
    return caseState;
  }

  isClientAccount(): boolean {
    return this.accountRole?.toUpperCase() === UserGroup.Client.toUpperCase();
  }

  isRepAccount(): boolean {
    return this.accountRole?.toUpperCase() === UserGroup.Rep.toUpperCase();
  }

  isRegisteredRep(): boolean {
    return this.repId ? true : false;
  }

  getRole(): "clientWithRep" | "client" | "representative" | null {
    if (!this.accountRole) return null;
    switch (this.accountRole.toUpperCase()) {
      case UserGroup.Client.toUpperCase():
        if (this.caseHasRep) return "clientWithRep";
        return "client";
      case UserGroup.Rep.toUpperCase():
        return "representative";

      default:
        return null;
    }
  }

  getPermissions(): string[] {
    return this.roleToPermissionsMap[
      this.getCaseRole() as keyof typeof permissionsJson
    ];
  }

  public getPermissionsByRoleId(roleId: number): string[] {
    const roleName = roleNames[roleId];
    return this.roleToPermissionsMap[roleName as keyof typeof permissionsJson];
  }

  setCaseRole(id: number | undefined) {
    if (id === undefined) {
      this._caseRole = undefined;
    } else {
      this._caseRole = roleNames[id] as RoleName;
    }
  }

  getCaseRole() {
    return this._caseRole;
  }

  resetCaseRole() {
    this._caseRole = undefined;
  }

  is(role: RoleName): boolean {
    return this.getCaseRole() === role;
  }

  can(action: string): boolean {
    if (!this.getCaseRole()) return false;
    const permissions = this.getPermissions();
    return permissions.includes(action);
  }
}
