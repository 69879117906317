import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Amplify from "aws-amplify";
import awsconfig from "../../../../config/aws-exports";
import { AuthService } from "./core/auth-module/services/auth.service";
import packJSON from "../../../../package.json";
import { Store } from "@ngrx/store";
import * as fromApp from "@pr-caseworker/app/store/app.reducer";
import { first, map, takeWhile } from "rxjs/operators";
import { isEmpty as lIsEmpty } from "lodash";
import { AwsSdkService } from "@pr-caseworker/app/shared/services/aws-sdk/aws-sdk.service";
import { git } from "../environments/git-dump";

Amplify.configure(awsconfig);

@Component({
  selector: "prcw-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  displayChangeBrowser = false;
  alive = true;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private store: Store<fromApp.State>,
    private awsSdkService: AwsSdkService
  ) {}

  ngOnInit(): void {
    console.log("%c Version: " + packJSON.version, "color: red");
    console.log(
      "%c ng version: " + packJSON.dependencies["@angular/core"],
      "color: red"
    );
    if (git?.branch) console.log("%c Branch: " + git.branch, "color: red");
    if (git?.commit)
      console.log(
        "%c Commit: " + (git.commit as String).substring(0, 9),
        "color: red"
      );

    const checkBrowser = navigator.userAgent;
    if (
      checkBrowser.indexOf("MSIE") > 0 ||
      checkBrowser.indexOf("Trident/") > 0
      // uncomment to turn off Edge
      // checkBrowser.indexOf('Edg') > 1
    ) {
      this.displayChangeBrowser = true;
    }

    this.configureLangs();
    this.loadHtmlLang();
    this.authService.checkSession();

    this.store
      .select("auth")
      .pipe(takeWhile(() => this.alive))
      .subscribe((authState) => {
        if (!lIsEmpty(authState.user)) {
          this.awsSdkService.configureAwsSdk();
        }
      });
  }

  private configureLangs(): void {
    this.translate.addLangs(["en", "fr"]);
  }

  private loadHtmlLang() {
    let getLang = "en";
    if (window.location.href.includes("/fr/")) {
      getLang = "fr";
    }
    const head = document.getElementsByTagName("html")[0];
    head.lang = getLang;
    this.translate.use(getLang);
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
