import { AlertService, DOC_LIMITS, documentTypes, renewalDocTypes } from "lib";
import { Component, OnInit } from "@angular/core";
import {
  ConfigMetadata,
  ConfigTableData,
  WebformViewTable,
} from "../webform-view-table.model";

import { ActivatedRoute } from "@angular/router";
import { Document } from "../../models/document.model";
import { DocumentService } from "../../services/document/document.service";
import { LovService } from "lib";
import { RenewalState } from "@pr-caseworker/app/renewal-module/store/renewal-state";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { State } from "../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { WebformViewTableService } from "../webform-view-table.service";
import { first } from "rxjs/operators";

@Component({
  selector: "prcw-webform-view-table-page",
  templateUrl: "./webform-view-table-page.component.html",
  styleUrls: ["./webform-view-table-page.component.scss"],
})
export class WebformViewTablePageComponent implements OnInit {
  public lang: string;
  public isLoading: boolean = true;
  public columnHeadings: WebformViewTable;
  public formTables: { heading: string; tableRows: WebformViewTable[] }[] = [];
  public pageTitleKey: string;
  public formMetadata: ConfigMetadata; // Form meta data from formConfigsFunc
  public imm5669DocType: number = documentTypes.imm5669.id;
  public imm5444DocType: number = renewalDocTypes.IMM5444.id;
  public fullName: string;
  public formData: any; // form data as they come from the API

  protected formConfigsFunc: any; // FormConfig function passed through route
  protected formConfigsTables: ConfigTableData[]; // form tables from formConfigsFunc
  protected caseId: string; // this is the case ID
  protected formDocumentId?: number; // this variable is the documentId for the selected form

  constructor(
    private route: ActivatedRoute,
    private intakeStore: Store<State>,
    protected renewalStore: Store<RenewalState>,
    private documentService: DocumentService,
    protected translate: TranslateService,
    protected formTableService: WebformViewTableService,
    private alertService: AlertService,
    public routeLocalizer: RouteLocalizerService,
    public lovService: LovService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getFormConfigs();
    this.getStoredCase();
    this.getFormData().then(() => {
      this.initTableColumns();
      this.initTables();
    });
  }

  /* ---------- PRIVATE ------------- */

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  protected initPageTitle(): void {
    this.pageTitleKey = this.formMetadata?.pageTitle;
  }

  private initTableColumns(): void {
    const formName = this.translate.instant(
      "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.COLUMNS.CATEGORY"
    );
    const fieldsetLegend = this.translate.instant(
      "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.COLUMNS.QUESTION"
    );
    const fieldLabel = this.translate.instant(
      "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.COLUMNS.SUB_QUESTION"
    );
    const data = this.translate.instant(
      "INTAKE.WEBFORM_VIEW_PAGE.DATA_TABLE.COLUMNS.FORM_DATA"
    );

    this.columnHeadings = { formName, fieldsetLegend, fieldLabel, data };
  }

  /* This method will loop through the formConfigsTables and initialize each table
   * heading and rows. Each loop will push to formTables in { heading, tableRows } object */
  protected initTables(): void {
    if (this.formConfigsTables && this.formData) {
      this.formConfigsTables.forEach((tableConfig: ConfigTableData) => {
        const heading =
          tableConfig.heading &&
          (tableConfig.index !== undefined
            ? this.translate.instant(tableConfig.heading) +
              " " +
              (tableConfig.index + 1)
            : this.translate.instant(tableConfig.heading));
        const tableRows =
          this.formTableService.buildFormTableRows(
            tableConfig,
            this.formData
          ) || [];
        this.formTables.push({
          heading,
          tableRows,
        });
      });
    }
  }

  // This method will get the form configs from the route data!
  // This is a way to reuse this page regardless of the form configs
  private getFormConfigs(): void {
    // Get the page configs from the route Data
    this.route.data.subscribe((data) => {
      if (data?.formConfigs) {
        this.formConfigsFunc = data.formConfigs;
        this.formMetadata = data.formConfigs().metaData;
      }
    });
  }

  // Get the application from the intake store, then find the caseId and formDocumentId
  // based on the formConfigs metaData that will specifiy the documentTypeId
  protected getStoredCase(): void {
    this.intakeStore
      .select("intake")
      .pipe(first())
      .subscribe((intakeData) => {
        if (intakeData && intakeData.selectedApplication) {
          const application = intakeData.selectedApplication;
          this.caseId = application?.id;
          this.initPageTitle();
          const formDocument = application.documents?.find(
            (doc: Document) =>
              doc?.documentTypeId === this.formMetadata?.documentTypeId
          );
          this.formDocumentId = formDocument?.id;
        }
      });
  }

  // This method will use the caseId and formDocumentId to call the fomr from the API
  private async getFormData(): Promise<any> {
    if (this.caseId && this.formDocumentId) {
      try {
        const data = await this.documentService.getFormByDocumentId(
          this.caseId,
          this.formDocumentId
        );
        this.formData = data;
        this.fullName = this.formData.declaration;
        if (!this.formData.lists) this.formData.lists = this.lovService.lovs;

        // Use the number of dependants coming from the formData
        this.setConfigTables();
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    }
    this.isLoading = false;
  }

  // This method will set the config tables to be used for initializing
  // table rows in initTables()
  protected setConfigTables(): void {
    let depNumber = -1; // no dependants available
    if (this.formData?.form?.dependantDetails?.dependants) {
      depNumber = this.formData?.form?.dependantDetails?.dependants.length;
    }
    const data = this.formConfigsFunc(depNumber);
    this.formMetadata = data?.metaData;
    this.formConfigsTables = data?.tables;
  }
}
