import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as fromCasesActions from "./cases.actions";
import { CasesService } from "@pr-caseworker/app/core/cases-module/cases.service";
import { EMPTY, from, of } from "rxjs";
import { AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { RouteLocalizerService } from "@pr-caseworker/app/routing/route-localizer.service";
import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";

@Injectable()
export class CaseEffects {
  public currentLang: string;

  constructor(
    private actions: Actions<fromCasesActions.CaseActions>,
    private translate: TranslateService,
    public routeLocalizer: RouteLocalizerService,
    private router: Router,
    private alertService: AlertService,
    private store: Store<fromApp.State>,
    private caseService: CasesService
  ) {
    this.store.select(fromApp.selectRouteData).subscribe((data) => {
      this.currentLang = data?.lang ? data.lang : "en"; // fix this later
    });
  }

  startLoadCurrentCaseEffect$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromCasesActions.START_LOAD_CURRENT_CASE),
        withLatestFrom(
          this.store
            .select("confirmation")
            .pipe(map((data) => data.selectedCase))
        ),
        switchMap(([action, selectedCase]) => {
          if (
            selectedCase &&
            selectedCase.id.toString() === action.payload.caseId?.toString()
          ) {
            return EMPTY;
          } else {
            const caseId = action?.payload?.caseId;
            return from(this.caseService.getClientCasebyCaseId(caseId)).pipe(
              catchError((err) => of(null)),
              map((data) => {
                if (data) {
                  return new fromCasesActions.SetCurrentCase(data);
                } else {
                  this.routeLocalizer.goTo(
                    this.currentLang,
                    "CONFIRMATION_CASES"
                  );
                  this.alertService.danger(this.alertTechnicalError);
                  return new fromCasesActions.SetCurrentCaseError({
                    caseId,
                    apiLoadingError: "Error when attept to load case from API",
                  });
                }
              })
            );
          }
        })
      ),
    { dispatch: true }
  );

  deleteCurrentCaseEffect$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromCasesActions.START_DELETE_CURRENT_CASE),
        switchMap((action) => {
          const deletedCase = action?.payload.case;
          return from(this.caseService.deleteCasebyCaseId(deletedCase.id)).pipe(
            catchError((err) => of(null)),
            map((data) => {
              this.routeLocalizer.goTo(this.currentLang, "CONFIRMATION_CASES");
              if (data) {
                this.alertService.success(
                  this.delMsgSuccessByCase(deletedCase)
                );
                return new fromCasesActions.DeleteCurrentCase(data);
              } else {
                this.alertService.danger(this.alertTechnicalDeleteError);
                return new fromCasesActions.SetCurrentCaseError({
                  caseId: deletedCase.id,
                  apiLoadingError: "DELETE_ERROR",
                });
              }
            })
          );
        })
      ),
    { dispatch: true }
  );

  private get alertTechnicalError(): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.TECHNICAL_ERROR");
  }
  private get alertTechnicalDeleteError(): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.TECHNICAL_ERROR");
  }

  private delMsgSuccessByCase(caseInput: Case): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.SUCCESS_DELETE_CASE", {
      uci: caseInput.uci,
    });
  }
}
