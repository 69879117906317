import { Component, Input } from "@angular/core";
import html2canvas from "html2canvas";
import { Barcode2D } from "../barcodes.model";
import { jsPDF } from "jspdf";
import { BARCODE_CONFIG } from "../barcode-config/draw-barcodes.config";
import { AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "prcw-barcodes-table",
  templateUrl: "./barcodes-table.component.html",
  styleUrls: ["./barcodes-table.component.scss"],
})
export class BarcodesTableComponent {
  // Inputs
  @Input() public barcodesArray: Barcode2D[] = [];
  @Input() public applicationId: string;
  isDownloading = false;

  constructor(
    private alert: AlertService,
    private translate: TranslateService
  ) {}

  public async downloadBarcodes() {
    this.isDownloading = true;
    // scroll to the top of the page to make sure the PDF saves properly (jsPDF requirement)
    window.scroll(0, 0);

    const barcodeName = `imm0008-barcodes-${this.applicationId}.pdf`;

    // orientation (p) portrait, unit of measurement, page size, compression
    const pdf = new jsPDF("p", "mm", "a4", true);

    // get an array of barcodes as canvas elements by pulling from the html table elements
    const barcodes: HTMLCanvasElement[] = [];
    for (let i = 1; i <= 10; i++) {
      const bc = document.getElementById("bc" + i) as HTMLCanvasElement;
      bc.style.display = "";
      barcodes.push(bc);
    }
    // set the tasks for Html2canvas promises to execute asynchronously
    const tasks = barcodes.map((bc) => html2canvas(bc));
    // we need all the conversions/promises to finish before we save the PDF
    Promise.all(tasks)
      .then((canvases) => {
        // Looping through the barcodes
        canvases.forEach((canvas, bcIndex) => {
          const imgData = canvas.toDataURL("image/png", BARCODE_CONFIG.QUALITY);
          if (bcIndex === BARCODE_CONFIG.BARCODES_PER_PAGE) {
            pdf.addPage("a4", "p"); // add new page for barcode 9 & 10
          }
          const posX =
            BARCODE_CONFIG.MARGIN_X +
            (bcIndex % 2) *
              (BARCODE_CONFIG.BARCODE_WIDTH + BARCODE_CONFIG.GAP_X);
          const posY =
            BARCODE_CONFIG.MARGIN_Y +
            (BARCODE_CONFIG.BARCODE_HEIGHT + BARCODE_CONFIG.GAP_Y) *
              Math.floor((bcIndex % BARCODE_CONFIG.BARCODES_PER_PAGE) / 2);
          // addImage(imageData, format, x, y, width, height, alias, compression, rotation)
          const jsPDFImage = pdf.addImage(
            imgData,
            "PNG",
            posX,
            posY,
            BARCODE_CONFIG.BARCODE_WIDTH,
            BARCODE_CONFIG.BARCODE_HEIGHT
          );
          jsPDFImage.close();
        });
        // Download the constructed PDF
        return pdf.save(barcodeName, { returnPromise: true });
      })
      .then(() => {
        this.resetBarcodeTable(barcodes, pdf);
      })
      .catch((err) => {
        this.resetBarcodeTable(barcodes, pdf);
        this.alert.danger(
          this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
        );
      });
  }

  private resetBarcodeTable(barcodes: HTMLCanvasElement[], pdf: jsPDF): void {
    pdf.close();
    barcodes.forEach((b) => (b.style.display = "none"));
    this.isDownloading = false;
  }
}
