import { Component, Input } from "@angular/core";

@Component({
  selector: "lib-maintenance",
  templateUrl: "./maintenance.component.html"
})
export class MaintenanceComponent {

  constructor() {}
}
