import { Action } from "@ngrx/store";
import { User } from "./auth.reducer";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FIRST_LOGIN_COMPLETE = "FIRST_LOGIN_COMPLETE";
export const LOAD_USERS_LIST = "LOAD_USERS_LIST";

export class Login implements Action {
  readonly type = LOGIN;

  constructor(
    public payload: {
      username: string;
      session: string;
      isFirstLogin: boolean;
      token: string;
      userPool: string;
    }
  ) {}
}

export class FirstLogin implements Action {
  readonly type = FIRST_LOGIN_COMPLETE;
  constructor(
    public payload: {
      user: null;
      successReset: boolean;
    }
  ) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
  constructor(
    public payload: {
      user: null;
      successLogout: boolean;
    }
  ) {}
}
export class LoadUsersList implements Action {
  readonly type = LOAD_USERS_LIST;
  constructor(
    public payload: {
      usersList: Partial<User>[];
    }
  ) {}
}
export type AuthActions = Login | Logout | FirstLogin | LoadUsersList;
