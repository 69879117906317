import {
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ModalService, AlertService } from "lib";
import { DownloadService } from "../../../shared/services/download.service";
import * as fromApp from "../../../store/app.reducer";
import { CasesService } from "../../../core/cases-module/cases.service";
import { DocumentService } from "../../../core/services/document/document.service";

@Component({
  selector: "prcw-case-confirmation-upload",
  templateUrl: "./case-confirmation-upload.html",
  styleUrls: ["./case-confirmation-upload.scss"],
})
@Injectable()
export class CaseConfirmationUploadComponent {
  // Reset upload file to detect any change
  @ViewChild("uploadFile") uploadFile: ElementRef;

  @Input() public caseStatusId: string | number;

  case: any;

  // file size
  private maxFileSize = 1000000;
  private megaFileConversion = 1000000;
  public friendlyMaxFileSize: string;

  // file
  public selectedFile: File | null = null;
  public fileError: string | null;
  public previewUrl: any;
  public previewName = "";

  public ecoprExistsModal: boolean;

  public allowUploadECopr = false;

  // Emit file upload status
  @Output() public ecoprExists: EventEmitter<boolean> = new EventEmitter();
  @Output() public ecoprFileSelected: EventEmitter<any> = new EventEmitter();
  @Output() public removeEcoprError: EventEmitter<any> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private store: Store<fromApp.State>,
    private caseService: CasesService,
    private activatedRoute: ActivatedRoute,
    private downloadService: DownloadService,
    private modalService: ModalService,
    private documentService: DocumentService,
    private alertService: AlertService,
  ) {
    this.init();
  }

  // onInit doesn't work with Injectable, so we "diy" it
  init() {
    this.setFriendlyMaxFileSize();
    this.activatedRoute.data.subscribe((data) => {
      this.case = data.case;
    });
    // Check if a file is already uploaded
    this.getDocumentFromStorage();
    this.allowUploadECopr =
      this.case.addressDeclarationTimestamp === null ? false : true;
  }

  public selectCoprFile(file: File): void {
    if (file.size > this.maxFileSize) {
      this.selectedFile = null;
      this.previewName = "";
      this.getTranslatedError();
      this.ecoprExists.emit(false);
      this.ecoprFileSelected.emit(null);
    } else {
      this.uploadCopr(file);
    }
  }

  public selectCoprFileFromModal(file: File): void {
    if (file.size > this.maxFileSize) {
      this.getTranslatedError();
    } else {
      this.uploadCopr(file);
      this.modalService.close("confirmReplaceCopr");
    }
  }

  private uploadCopr(file: File): void {
    this.fileError = null;
    this.selectedFile = file;
    this.previewName = file.name;
    this.ecoprExists.emit(true);
    this.ecoprFileSelected.emit(file);
    this.setPreviewUrl(file);
  }

  public openModal(id: string): void {
    // Remove the file if it has not been saved in the database
    if (this.ecoprExistsModal === undefined) {
      this.removeFileName();
    } else {
      this.modalService.open(id);
    }
  }

  public closeModal(id: string): void {
    this.modalService.close(id);
  }

  public removeFileName() {
    this.selectedFile = null;
    this.previewName = "";
    this.ecoprExists.emit(false);
    this.ecoprFileSelected.emit(null);
    this.resetUploadFile();
  }

  public async removeFile(): Promise<void> {
    try {
      await this.caseService.removeEcoprFileByCaseId(this.case.id);
      const updatedCase = await this.caseService.getClientCasebyCaseId(
        this.case.id
      );
      this.case = updatedCase;
      this.removeFileName();
    } catch (error) {
      this.removeEcoprError.emit(true);
      this.resetUploadFile();
    }
    this.modalService.close("confirmDeleteCopr");
  }

  public resetUploadFile() {
    // reset upload file to allow change detection
    if (this.uploadFile?.nativeElement) {
      this.uploadFile.nativeElement.value = "";
    }
  }

  private setPreviewUrl(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.previewUrl = reader.result;
    };
  }

  private async getDocumentFromStorage(): Promise<any> {
    const file = this.case?.documents.filter(
      (doc: any) => doc.documentTypeId === 3
    );
    if (file.length > 0) {
      this.previewName = file[0].documentName.split("/")[1];
      // just send the signal at next thick because you techically do this
      // on init
      setTimeout(() => {
        this.ecoprExists.emit(true);
        this.ecoprFileSelected.emit(null);
      }, 1);
      this.ecoprExistsModal = true;
    }
  }

  private setFriendlyMaxFileSize(): void {
    this.friendlyMaxFileSize = `${this.maxFileSize / this.megaFileConversion}`;
  }

  private getTranslatedError(): void {
    this.translate
      .get("CASE_DETAILS.CASE_CONFIRMATION.ERRORS.TOO_BIG", {
        size: this.friendlyMaxFileSize,
      })
      .subscribe((res) => {
        this.fileError = res;
      });
  }

  async downloadCopr() {
    const document = this.documentService.getFileByDocumentType(
      3,
      this.case.documents
    );
    const downloadName = document.documentName.split("/").pop(); // the last string is the filename (incase its in a folder)
    try{
      await this.downloadService.downloadDocument(document.caseId, document.id, downloadName);
    }catch(error){
      this.alertService.danger(this.alertTechnicalError)
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }
}
