import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "lib-layout-row",
  templateUrl: "./layout-row.component.html",
  styleUrls: ["./layout-row.component.scss"],
})
export class LayoutRowComponent implements OnInit {
  constructor() {}
  @Input() gap?: string;
  @Input() mt?: string;
  @Input() mb?: string;
  @Input() justify?: string;
  @Input() align?: string;

  public gapClass: string = this.gap ? "lib-layout-row--" + this.gap : "";
  public mtClass: string = this.mt ? "lib-layout-row--" + this.mt : "";
  public mbClass: string = this.mb ? "lib-layout-row--" + this.mb : "";

  ngOnInit(): void {}
}
