import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "../../../../core/cases-module/models/case.model";
import { Subscription } from "rxjs";
import { RoleIds } from "../../../../shared/constants/roles";
import { LovService } from "../../../../../../../lib/src/lib/services/lov/lov.service";

@Component({
  selector: "prcw-referral-partner-section",
  templateUrl: "./referral-partner.component.html",
  styleUrls: ["./referral-partner.component.scss"],
})
export class ReferralPartnerSectionComponent implements OnInit, OnDestroy {
  public lang: string;
  public case: Case;

  // Required Information to be Displayed - Can be required null so had to set type to any
  public name = "";
  public email = "";
  public referralOrganization = "";
  public submittedTime = "";

  private subs: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private lovService: LovService
  ) {}

  ngOnInit() {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getCurrentCaseDate();
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public formatDate(field: string) {
    return field?.replace(/\s/, "T");
  }
  private getReferralOrgName(orgNumber: number) {
    const referralOrganizationList =
      this.lovService.lovs.referralPartnerCaseworkerList;
    const referralOrganizationObj = referralOrganizationList.filter(
      (org) => org.value === orgNumber.toString()
    );
    const referralOrganizationName =
      this.lang === "fr"
        ? referralOrganizationObj[0].text.fr
        : referralOrganizationObj[0].text.en;
    return referralOrganizationName;
  }

  private getCurrentCaseDate() {
    this.subs.push(
      this.store.select("intake").subscribe((caseData) => {
        console.log(caseData);
        const currentCase = caseData.selectedApplication;
        if (currentCase) {
          this.submittedTime = currentCase.submittedAt;
          const referralPartner = currentCase?.members?.find((member) => {
            return (
              member.emailAddress === currentCase.emailAddress &&
              member.roleId === RoleIds.REFERAL_PARTNER
            );
          });
          if (referralPartner) {
            this.name = referralPartner.givenName
              ? `${referralPartner.givenName} ${referralPartner.familyName}`
              : referralPartner.familyName;
            this.email = referralPartner.emailAddress;
            this.referralOrganization = this.getReferralOrgName(
              currentCase.referralOrganization!
            );
          }
        }
      })
    );
  }
}
