import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  Optional,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "lib-form-radio",
  templateUrl: "./form-radio.component.html",
})
export class FormRadioComponent
  implements ControlValueAccessor, AfterContentInit, OnDestroy
{
  @Input() legend: string;
  @Input() firstId?: string;
  @Input() secondId?: string;

  @Input() firstLabel?: string;
  @Input() secondLabel?: string;

  @Input() firstValue?: string;
  @Input() secondValue?: string;

  @Input() nameVal: string;
  @Input() required: boolean;
  @Input() helpText: string = "";

  @Input() options?: any[];

  @Input() editable?: boolean = true;

  public value: any;
  public disabled?: boolean = false;
  private valueChangesSubsc: Subscription;
  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.ngControl.valueAccessor = this;
  }

  public get requiredText(): string {
    return this.lang === "fr" ? "requis" : "required";
  }

  public get selectedText(): string {
    return this.lang === "fr" ? "sélectionné" : "selected";
  }

  ngAfterContentInit() {
    // Run change detection if the value changes. This method works with the change detection strategy
    // if there hasn't been a change to the @Input values, example optional fields
    if (this.ngControl && this.ngControl.valueChanges) {
      this.valueChangesSubsc = this.ngControl.valueChanges.subscribe(() =>
        this.cdRef.markForCheck()
      );
    }
  }

  ngOnDestroy() {
    if (this.valueChangesSubsc) {
      this.valueChangesSubsc.unsubscribe();
    }
  }

  onChange(e: any) {} // required; ok to leave empty

  onTouched(e: any) {} // required; ok to leave empty

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  private get lang(): string {
    return this.router.url?.split("/")[1];
  }
}

// Prevents event bubbling and performance issues with many event listeners on the longer forms
window.document.addEventListener("keydown", (event: KeyboardEvent) => {
  event.stopPropagation();
});

window.document.addEventListener("keyup", (event: KeyboardEvent) => {
  event.stopPropagation();
});
