import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentInit,
  Component,
  HostBinding,
  Input,
  Optional,
  Self,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Inject,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import "moment/locale/ja";
import "moment/locale/fr";
import moment from "moment";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  selector: "lib-datepicker",
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: "en-US" },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
})
export class DatepickerComponent
  implements OnInit, ControlValueAccessor, AfterContentInit, OnDestroy
{
  @Input() type?: "text" | "email" | "password" = "text";
  @Input() identifier: string;
  @Input() label?: string;
  @Input() required?: boolean;
  @Input() placeholder?: string = "";
  @Input() hideRequired?: boolean;
  @Input() readOnly?: boolean;
  @Input() errors?: ValidationErrors;
  @Input() helpText?: string;

  opened = false;
  originalType?: "text" | "email" | "password";
  hidePasswordImg: string = "../../../../../assets/images/icons/eye-block.svg";
  peekButtonLabel: string = "";
  peekButtonLabels = {
    show: {
      en: "Show Password",
      fr: "Afficher le mot de passe",
    },
    hide: {
      en: "Hide Password",
      fr: "Masquer le mot de passe",
    },
  };

  date = moment();

  selDate: string;
  selDay: string;
  selMonth: string;
  selYear: string;
  testField = new FormControl("", [Validators.required]);

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = moment(event.value);
    this.selDate = this.date.format("DD");
    this.selDay = this.date.format("dddd");
    this.selMonth = this.date.format("MMMM");
    this.selYear = this.date.format("YYYY");
  }

  checkInput(event: any) {
    console.log("input: ", event.target.value);
  }

  // adds class to component conditionally based on width attr
  @HostBinding("class.input-sm") @Input() widthSm?: boolean;
  @HostBinding("class.input-md") @Input() widthMd?: boolean;

  public value: any;
  public disabled?: boolean = false;
  public _moment = moment;
  private valueChangesSubsc: Subscription;
  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    this.ngControl.valueAccessor = this;
  }

  public get requiredText(): string {
    return this.lang === "fr" ? "requis" : "required";
  }

  public get noDataText(): string {
    return this.lang === "fr" ? "Pas de données" : "No data";
  }

  ngOnInit() {
    this.originalType = this.type;
    this.peekButtonLabel =
      this.lang === "fr"
        ? this.peekButtonLabels.show.fr
        : this.peekButtonLabels.show.en;
    this._locale = this.lang === "fr" ? "fr" : "en-US";
    this._adapter.setLocale(this._locale);
  }

  ngAfterContentInit() {
    if (this.ngControl && this.ngControl.valueChanges) {
      this.valueChangesSubsc = this.ngControl.valueChanges.subscribe(
        (value) => {
          if (
            value &&
            value?.length === 10 &&
            value?.match("[0-9]{4}/[0-9]{2}/[0-9]{2}$")
          ) {
            this.writeValue(moment(value).utc(true));
          }
          console.log("value of DatePicker: ", value);
          this.cdRef.markForCheck();
        }
      );
    }
  }

  // do unsubscribe as this is used accorss the project and subscriptions are not cleared
  ngOnDestroy() {
    if (this.valueChangesSubsc) {
      this.valueChangesSubsc.unsubscribe();
    }
  }

  onChange(e: any) {} // required; ok to leave empty

  onTouched(e: any) {} // required; ok to leave empty

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  togglePasswordHide() {
    if (this.type === "password") {
      this.type = "text";
      this.hidePasswordImg = "../../../../../assets/images/icons/eye.svg";
      this.peekButtonLabel =
        this.lang === "fr"
          ? this.peekButtonLabels.hide.fr
          : this.peekButtonLabels.hide.en;
    } else if (this.type === "text") {
      this.type = "password";
      this.hidePasswordImg = "../../../../../assets/images/icons/eye-block.svg";
      this.peekButtonLabel =
        this.lang === "fr"
          ? this.peekButtonLabels.show.fr
          : this.peekButtonLabels.show.en;
    }
  }

  public get lang(): string {
    return this.router?.url?.split("/")[1];
  }
}

// Prevents event bubbling and performance issues with many event listeners on the longer forms
window.document.addEventListener("keydown", (event: KeyboardEvent) => {
  event.stopPropagation();
});

window.document.addEventListener("keyup", (event: KeyboardEvent) => {
  event.stopPropagation();
});
