import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CropperCoordinatesBias } from "../ngx-photo-editor/ngx-photo-editor.component";

@Component({
  selector: "lib-image-preview",
  template: `
    <canvas
      class="preview_image"
      [id]="canvasId"
      [width]="width"
      [height]="height"
      [attr.aria-label]="imageAlt"
    ></canvas>
    <div style="display:none;">
      <img
        [id]="sourceImgId"
        [src]="imageSrc"
        [alt]="imageAlt"
        (load)="drawCanvas()"
      />
    </div>
  `,
  styles: [
    `
      .preview_image {
        max-width: 210px;
        max-height: 270px;
      }
    `,
  ],
})
export class ImagePreviewComponent {
  @Input() coordinates: CropperCoordinatesBias;
  @Input() imageSrc: any;
  @Input() imageAlt: string;
  @Input() width: number = 210;
  @Input() height: number = 270;
  @Input() canvasId: string = "previewImg";
  @Input() sourceImgId: string = "source";
  @Output() outputUrl: EventEmitter<string> = new EventEmitter<string>(
    undefined
  );

  drawCanvas() {
    const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const image = document.getElementById(this.sourceImgId) as HTMLImageElement;
    image.crossOrigin = "anonymous";

    let left = 0;
    let top = 0;
    let width = image.width;
    let height = image.height;

    if (this.coordinates?.leftDiff) {
      left = left + image.width * this.coordinates.leftDiff;
    }
    if (this.coordinates?.topDiff) {
      top = top + image.height * this.coordinates.topDiff;
    }
    if (this.coordinates?.widthCompare) {
      width = width * this.coordinates.widthCompare;
    }
    if (this.coordinates?.heightCompare) {
      height = height * this.coordinates.heightCompare;
    }
    this.width = width;
    this.height = height;
    setTimeout(() => {
      ctx?.drawImage(image, left, top, width, height, 0, 0, width, height);
      this.outputUrl.emit(canvas.toDataURL("image/jpeg", 100));
    }, 200);
  }
}
