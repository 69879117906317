import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentInit,
  Component,
  HostBinding,
  Input,
  Optional,
  Self,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  ControlValueAccessor,
  NgControl,
  ValidationErrors,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "lib-form-input",
  templateUrl: "./form-input.component.html",
})
export class FormInputComponent
  implements OnInit, ControlValueAccessor, AfterContentInit, OnDestroy
{
  @Input() type?: "text" | "email" | "password" = "text";
  @Input() identifier: string;
  @Input() label?: string;
  @Input() required?: boolean;
  @Input() placeholder?: string = "";
  @Input() hideRequired?: boolean;
  @Input() ariaPlaceHolder?: string = "";
  @Input() autocomplete?: string = "on";
  @Input() editable?: boolean = true;
  @Input() errors?: ValidationErrors;
  @Input() helpText?: string;
  @Input() validateOn?: "blur" | "change" = "blur";
  @Input() eraseMarginBottom?: boolean = false;
  @Input() allowOverflow?: boolean = false;
  @Output() sendBlurEvent = new EventEmitter<Event>();
  @ViewChild("formInput") theInput: ElementRef;

  originalType?: "text" | "email" | "password";
  hidePasswordImg: string = "../../../../../assets/images/icons/eye-block.svg";
  peekButtonLabel: string = "";
  peekButtonLabels = {
    show: {
      en: "Show Password",
      fr: "Afficher le mot de passe",
    },
    hide: {
      en: "Hide Password",
      fr: "Masquer le mot de passe",
    },
  };

  // adds class to component conditionally based on width attr
  @HostBinding("class.input-sm") @Input() widthSm?: boolean;
  @HostBinding("class.input-md") @Input() widthMd?: boolean;

  public value: any;
  public disabled?: boolean = false;
  private valueChangesSubsc: Subscription;
  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.ngControl.valueAccessor = this;
  }

  public get requiredText(): string {
    return this.lang === "fr" ? "requis" : "required";
  }

  public get noDataText(): string {
    return this.lang === "fr" ? "Pas de données" : "No data";
  }

  public handleBlur(ev: Event): void {
    this.sendBlurEvent.emit(ev);
    this.onTouched(ev);
  }

  ngOnInit() {
    this.originalType = this.type;
    this.peekButtonLabel =
      this.lang === "fr"
        ? this.peekButtonLabels.show.fr
        : this.peekButtonLabels.show.en;
  }

  ngAfterContentInit() {
    // Run change detection if the value changes. This method works with the change detection strategy
    // if there hasn't been a change to the @Input values, example optional fields
    if (this.ngControl && this.ngControl.valueChanges) {
      this.valueChangesSubsc = this.ngControl.valueChanges.subscribe(() =>
        this.cdRef.markForCheck()
      );
    }
  }

  // do unsubscribe as this is used accorss the project and subscriptions are not cleared
  ngOnDestroy() {
    if (this.valueChangesSubsc) {
      this.valueChangesSubsc.unsubscribe();
    }
  }

  onChange(e: any) {} // required; ok to leave empty

  onTouched(e: any) {}
  // required; ok to leave empty

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  togglePasswordHide() {
    if (this.type === "password") {
      this.type = "text";
      this.hidePasswordImg = "../../../../../assets/images/icons/eye.svg";
      this.peekButtonLabel =
        this.lang === "fr"
          ? this.peekButtonLabels.hide.fr
          : this.peekButtonLabels.hide.en;
    } else if (this.type === "text") {
      this.type = "password";
      this.hidePasswordImg = "../../../../../assets/images/icons/eye-block.svg";
      this.peekButtonLabel =
        this.lang === "fr"
          ? this.peekButtonLabels.show.fr
          : this.peekButtonLabels.show.en;
    }
  }

  public get lang(): string {
    return this.router?.url?.split("/")[1];
  }

  public setFocusOnInput() {
    this.theInput.nativeElement ? this.theInput.nativeElement.focus() : "";
  }
}

// Prevents event bubbling and performance issues with many event listeners on the longer forms
window.document.addEventListener("keydown", (event: KeyboardEvent) => {
  event.stopPropagation();
});

window.document.addEventListener("keyup", (event: KeyboardEvent) => {
  event.stopPropagation();
});
