import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, documentTypes } from "lib";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { DocumentService } from "../../../core/services/document/document.service";
import { State } from "../../../store/app.reducer";
import { Document } from "../../../core/models/document.model";

@Component({
  selector: "prcw-e-signature-with-5669-declaration-page",
  templateUrl: "./e-signature-with-5669-declaration-page.component.html",
  styleUrls: ["./e-signature-with-5669-declaration-page.component.scss"],
})
export class ESignatureWith5669DeclarationPageComponent implements OnInit {
  public lang: string;
  public checkedDeclaration = false;

  // case docs and case id
  public caseId: string;
  public esigDocumentId?: number;
  public formData: any;

  public isLoading: boolean = true;

  public declarationFormId = documentTypes.declarationSignatureWith5669.id;

  public declarationForm = this.fb.group({
    eSignature: [null],
    canContact: [{ value: null, disabled: true }],
    canShareData: [{ value: null, disabled: true }],
  });

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    private translate: TranslateService,
    private alertService: AlertService,
    private documentService: DocumentService
  ) {}

  async ngOnInit() {
    this.lang = this.routeLocalizer.getCurrentRouteLang();

    await this.getStoredCase();
  }

  /* PRIVATE ---------------------------------------------------------------------------------- */

  private async getStoredCase(): Promise<void> {
    this.store.select("intake").subscribe((intakeData) => {
      if (intakeData && intakeData.selectedApplication) {
        const application = intakeData.selectedApplication;
        this.caseId = application?.id;

        const esigDocument = application.documents?.find(
          (doc: Document) => doc?.documentTypeId === this.declarationFormId
        );
        this.esigDocumentId = esigDocument?.id;

        this.getESigData();
      }
    });
  }

  private setFormValues(): void {
    this.declarationForm?.patchValue({
      eSignature: this.formData?.form?.eSignature,
      canContact: this.formData?.form?.canContact,
      canShareData: this.formData?.form?.canShareData,
    });
  }

  private async getESigData(): Promise<any> {
    if (this.caseId && this.esigDocumentId) {
      try {
        const response = await this.documentService.getFormByDocumentId(
          this.caseId,
          this.esigDocumentId
        );
        this.formData = response;
        this.isLoading = false;

        await this.setFormValues();
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }
}
