import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { PhotoModule } from "./photo-module/photo.module";
import { CaseConfirmationUploadComponent } from "./components/case-confirmation-upload/case-confirmation-upload";
import { CaseDocumentTableComponent } from "./components/case-document-table/case-document-table.component";
import { CaseInformationComponent } from "./components/case-information/case-information.component";
import { CaseListPageComponent } from "./pages/case-list-page/case-list-page.component";
import { CreateCasePageComponent } from "./pages/create-case-page/create-case-page.component";
import { CaseDetailsPageComponent } from "./pages/case-details-page/case-details-page.component";
import { CasesTableComponent } from "./components/cases-table/cases-table.component";
import { CasesModule } from "../core/cases-module/cases.module";

@NgModule({
  declarations: [
    CaseListPageComponent,
    CreateCasePageComponent,
    CaseDetailsPageComponent,
    CaseDocumentTableComponent,
    CaseConfirmationUploadComponent,
    CaseInformationComponent,
    CasesTableComponent,
  ],
  providers: [],
  exports: [],
  imports: [
    CasesModule,
    CommonModule,
    FormsModule,
    PhotoModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
  ],
})
export class ConfirmationModule {}
