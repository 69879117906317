import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "lib";
import { map } from "rxjs/operators";
import { RouteLocalizerService } from "../../routing/route-localizer.service";
import * as fromApp from "../../store/app.reducer";
import { AuthService } from "../auth-module/services/auth.service";
import * as AuthActions from "../auth-module/store/auth.actions";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

@Component({
  selector: "prcw-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public loggedIn = false;
  public toggleLang: string;
  private subs: Subscription[] = [];

  constructor(
    private routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private authService: AuthService,
    private store: Store<fromApp.State>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setToggleLang();
    this.subs.push(
      this.store
        .select("auth")
        .pipe(map((authState) => authState.user))
        .subscribe((user) => {
          const authenticated = !!user;
          this.loggedIn = authenticated;
        })
    );
    this.loadHtmlLang();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  get currentLang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  onChangedLang() {
    const nextLang = this.currentLang === "en" ? "fr" : "en";
    this.translate
      .use(nextLang)
      .pipe(first())
      .subscribe(() => {
        this.routeLocalizer.goToLocalizedCurrentRoute(nextLang);
      });
  }

  public logout(): void {
    this.authService
      .logout()
      .then(() => {
        this.store.dispatch(
          new AuthActions.Logout({
            successLogout: true,
            user: null,
          })
        );
        this.loggedIn = false;
        this.alertService.success(this.alertLogoutSuccess);
      })
      .catch((error: any) => {
        console.error("Error during sign out", error);
      });
  }

  private setToggleLang(): void {
    this.subs.push(
      this.translate.get("HEADER.TOGGLE_LANG").subscribe((res) => {
        this.toggleLang = res;
      })
    );
  }

  private get alertLogoutSuccess(): string {
    return this.translate.instant("LOGIN.ALERTS.SUCCESSFUL_LOGOUT");
  }

  private loadHtmlLang() {
    const head = document.getElementsByTagName("html")[0];
    head.lang = this.currentLang;
  }
}
