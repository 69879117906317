import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as CasesActions from "../../confirmation-module/store/cases.actions";
import { Reason } from "../../confirmation-module/models/document-rejection.model";
import { RouteLocalizerService } from "../../routing/route-localizer.service";

@Component({
  selector: "prcw-rejection-input",
  templateUrl: "./rejection-input.component.html",
  styleUrls: ["./rejection-input.component.scss"],
})
export class RejectionInputComponent implements OnInit, OnChanges {
  @Input() public documentType: "ADDRESS" | "PHOTO";
  @Input() public rejectionsArray: Reason[];
  @Input() public readOnly: boolean;
  @Input() public caseId: string | number;

  public currentLang: string;
  public copyRejectionsArray: Reason[];
  public idIdentifier: string;
  public isValidInput: boolean;

  @Output() public reasonToggled: EventEmitter<object> = new EventEmitter();
  @Output() public closeRejectionModal: EventEmitter<{}> = new EventEmitter();

  // Focus
  @ViewChild("focusModal") focusModal: ElementRef;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store
  ) {}

  ngOnInit() {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
  }

  ngOnChanges() {
    /*
        this.rejectionsArray initially comes undefined from the case-details page
        (when this component is part of a modal on that page), so we need to run
        the following lines when a value is provided */
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    if (this.rejectionsArray !== undefined) {
      this.copyRejectionsArray = JSON.parse(
        JSON.stringify(this.rejectionsArray)
      ); // deep clone
      this.checkIfReasonsProvided(this.copyRejectionsArray);
    }
  }

  public get selectedReasons() {
    return this.rejectionsArray?.filter(
      (reason) => Boolean(reason.selected) === true
    );
  }

  public updateRejectionReasons(
    $event: { target: HTMLInputElement },
    reasonId: number
  ) {
    const value = $event.target.checked;
    const index = this.copyRejectionsArray.findIndex((x) => x.id === reasonId);
    this.copyRejectionsArray[index].selected = value;
    this.checkIfReasonsProvided(this.copyRejectionsArray);
    this.reasonToggled.emit({});
  }

  private checkIfReasonsProvided(reasons: Reason[]) {
    // if at least 1 reason was selected
    let isValid = false;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < reasons.length; ++i) {
      if (Boolean(reasons[i].selected) === true) {
        isValid = true;
        break;
      }
    }
    this.isValidInput = isValid;
  }

  public cancelRejectDocument(documentType: string) {
    const checkboxes = document.getElementsByClassName(`clear-${documentType}`);
    for (let i = 0; i < checkboxes.length; i++) {
      const e = checkboxes[i] as HTMLInputElement;
      e.checked = this.rejectionsArray[i].selected;
    }
    this.closeRejectionModal.emit({ documentType, documentNewStatus: null }); // EMIT CLOSE MODAL
  }

  public rejectDocument(documentType: string) {
    const saveToStore = JSON.parse(JSON.stringify(this.copyRejectionsArray)); // deep clone
    // if there was a rejection reason
    if (this.isValidInput) {
      if (documentType === "ADDRESS") {
        // this.isRejectAddressModalVisible = false;  EMIT OUTPUT
        this.store.dispatch(
          new CasesActions.SetAddressStatus({
            decision: {
              approvedStatus: false,
              rejectionReasons: saveToStore,
              modified: true,
            },
            caseId: this.caseId,
          })
        );
      } else if (documentType === "PHOTO") {
        // this.isRejectPhotoModalVisible = false; EMIT OUTPUT
        this.store.dispatch(
          new CasesActions.SetPhotoStatus({
            decision: {
              approvedStatus: false,
              rejectionReasons: saveToStore,
              modified: true,
            },
            caseId: this.caseId,
          })
        );
      }
    }
    this.closeRejectionModal.emit({ documentType, documentNewStatus: false });
  }

  public onKeyPressCancel($event: KeyboardEvent) {
    if (!this.isValidInput && $event.code === "Tab") {
      this.focusModal.nativeElement.focus();
    }
  }

  public onKeyPressReject($event: KeyboardEvent) {
    if ($event.code === "Tab") {
      this.focusModal.nativeElement.focus();
    }
  }

  public trapFocus($event: KeyboardEvent) {
    if ($event.key === "Tab" && $event.shiftKey) {
      this.focusModal.nativeElement.focus();
    }
  }
}
