<!--
  The code in this file is for editor convenience purposes only.
  If any changes are made, this must be copied into the 'template' property in the controller file,
  to allow the component to be properly built when testing components that use it.
 -->
<div
  class="modal"
  role="dialog"
  [attr.aria-label]="title"
  [attr.aria-describedby]="bodyId"
>
  <h1
    class="modal__header emphasis"
    [ngClass]="{ 'modal-delete-header': deleteModal === true }"
  >
    <span *ngIf="deleteModal === true" class="modal-icon">
      <ircc-cl-lib-icon [config]="trashIcon"></ircc-cl-lib-icon>
    </span>
    {{ title }}
  </h1>
  <div [id]="bodyId" class="modal__body">
    <ng-content select="[modalBody]"></ng-content>
  </div>
  <div class="modal__actions">
    <ng-content select="[modalButtons]"></ng-content>
  </div>
</div>
