import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  Router,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  NavigationError,
  Event as RouterEvent,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { RouteLocalizerService } from "../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../auth-module/store/auth.actions";
import { map } from "rxjs/operators";
import { HeartBeatService } from "@pr-applicant/app/shared/services/heart-beat/heart-beat.service";

@Component({
  selector: "prcw-localized-app",
  templateUrl: "./localized-app.component.html",
  styleUrls: ["./localized-app.component.scss"],
})
export class LocalizedAppComponent implements OnInit, OnDestroy {
  public lang: string;
  public loading = false;
  public skipLinkPath: string;
  public isAuthenticated: boolean;
  private subs: Subscription[] = [];
  public bannerBoxHeading = "";
  public bannerBoxType = "problem";
  public bannerBoxBody = "";
  public applicationMode = "";

  constructor(
    private store: Store<fromApp.State>,
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService,
    private router: Router,
    private heartBeatService: HeartBeatService
  ) {}

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      setTimeout(() => (this.loading = true));
    }
    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      setTimeout(() => (this.loading = false));
      if (!this.router.url.endsWith("#main")) {
        this.skipLinkPath = `${this.router.url}#main`;
      }
    }
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.subs.push(
      this.heartBeatService.getApplicationMode().subscribe((data) => {
        this.applicationMode = data && data.mode;
        const currentLang = this.routeLocalizer.getCurrentRouteLang();
        if (this.applicationMode === "banner") {
          this.bannerBoxHeading =
            currentLang === "fr" ? data.titleFr : data.titleEn;
          this.bannerBoxBody =
            currentLang === "fr" ? data.messageFr : data.messageEn;
        } else if (this.applicationMode === "outage") {
          if (this.isAuthenticated)
            this.store.dispatch(
              new AuthActions.Logout({
                successLogout: true,
                user: null,
              })
            );
        }
      })
    );

    this.subs.push(
      this.router.events.subscribe((routerEvent: RouterEvent) => {
        this.navigationInterceptor(routerEvent);
      })
    );
    this.subs.push(
      this.store
        .select("auth")
        .pipe(map((authState) => authState.user))
        .subscribe((user) => {
          const username = user?.username;
          this.isAuthenticated = !!username;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
