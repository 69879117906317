import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import routePaths from "./route-paths";
import { FirstLoginGuard } from "../core/auth-module/services/firstLogin.guard";
import { AWSGuard } from "../core/auth-module/services/AWS.guard";
import { LangGuard } from "../../../../lib/src/lib/services/auth-service/lang.guard";
import { NavigationGuard } from "./guards/nav.guard";

/* Confirmation Pages */
import { CaseDetailsResolver } from "../confirmation-module/pages/case-details-page/case-details-page-resolver.service";
import { LocalizedAppComponent } from "../core/localized-app/localized-app.component";
import { LoginPageComponent } from "../core/auth-module/pages/login-page/login-page.component";
import { FirstResetPageComponent } from "../core/auth-module/pages/first-login-reset-page/first-reset-page.component";
import { CaseListPageComponent } from "../confirmation-module/pages/case-list-page/case-list-page.component";
import { CreateCasePageComponent } from "../confirmation-module/pages/create-case-page/create-case-page.component";
import { CaseDetailsPageComponent } from "../confirmation-module/pages/case-details-page/case-details-page.component";
import { ForgotPasswordPageComponent } from "../core/auth-module/pages/forgot-password-page/forgot-password-page.component";
import { ResetPasswordPageComponent } from "../core/auth-module/pages/reset-password-page/reset-password-page.component";
import { PhotoReviewPageComponent } from "../confirmation-module/photo-module/photo-review-page/photo-review-page.component";

/* Intake/Application Pages */
import { IntakeCaseListResolver } from "../intake-module/pages/case-list-page/case-list-page-resolver.service";
import { IntakeCaseDetailsResolver } from "../intake-module/pages/case-details-page/case-details-page-resolver.service";
import { IntakeCaseListPageComponent } from "../intake-module/pages/case-list-page/case-list-page.component";
import { CaseListDetailsPageComponent } from "../intake-module/pages/case-details-page/case-details-page.component";
import { WebformViewTablePageComponent } from "../core/webform-view-table-module/webform-view-table-page/webform-view-table-page.component";
import { IMM0008BarcodesPageComponent } from "../intake-module/pages/IMM0008-barcodes-page/IMM0008-barcodes-page.component";
import { ESignatureDeclarationPageComponent } from "../intake-module/pages/declaration/e-signature-declaration-page.component";
import { ESignatureWith5669DeclarationPageComponent } from "../intake-module/pages/declaration with 5669/e-signature-with-5669-declaration-page.component";

/* WEBFORM CONFIGS */
import { IMM0008Configs } from "../core/webform-view-table-module/webform-configs/imm0008.config";
import { IMM5406Configs } from "../core/webform-view-table-module/webform-configs/imm5406.config";
import { IMM5562Configs } from "../core/webform-view-table-module/webform-configs/imm5562.config";
import { IMM5669Configs } from "../core/webform-view-table-module/webform-configs/imm5669.config";
import { IMM0008AdditionalDependentsConfigs } from "../core/webform-view-table-module/webform-configs/imm0008-additional-dependants.config";
import { WebformViewimm0008PageComponent } from "../core/webform-view-table-module/webform-view-imm0008-page/webform-view-imm0008-page.component";
import { WebformViewImm5406PageComponent } from "../core/webform-view-table-module/webform-view-imm5406-page/webform-view-imm5406-page.component";
import { WebformViewImm5562PageComponent } from "../core/webform-view-table-module/webform-view-imm5562-page/webform-view-imm5562-page.component";
import { WebformViewImm5669PageComponent } from "../core/webform-view-table-module/webform-view-imm5669-page/webform-view-imm5669-page.component";
const localizedRoutes: Routes = Object.entries(routePaths).map(
  ([lang, paths]) => {
    return {
      path: lang,
      data: { lang },
      runGuardsAndResolvers: "always",
      component: LocalizedAppComponent,
      children: [
        { path: "", redirectTo: paths.APPLICATION_CASES, pathMatch: "full" },
        { path: paths.LOGIN, component: LoginPageComponent, data: { lang } },
        {
          path: paths.FIRST_RESET,
          component: FirstResetPageComponent,
          canActivate: [FirstLoginGuard],
          data: { lang },
        },
        {
          path: paths.FORGOT_PASSWORD,
          component: ForgotPasswordPageComponent,
          data: { lang },
        },
        {
          path: paths.RESET_PASSWORD,
          component: ResetPasswordPageComponent,
          data: { lang },
        },

        /* Intake/Application Pages */
        {
          path: paths.APPLICATION_CASES,
          canActivate: [AWSGuard],
          children: [
            {
              path: "",
              component: IntakeCaseListPageComponent,
              resolve: { cases: IntakeCaseListResolver },
              runGuardsAndResolvers: "always",
              data: { lang },
              canActivate: [AWSGuard],
            },
            {
              path: `:id`,
              resolve: { case: IntakeCaseDetailsResolver },
              canActivate: [AWSGuard],
              children: [
                {
                  path: "",
                  component: CaseListDetailsPageComponent,
                  data: { lang },
                  canDeactivate: [NavigationGuard],
                  canActivate: [AWSGuard],
                },
                {
                  path: paths.DECLARATION_PAGE,
                  component: ESignatureDeclarationPageComponent,
                  data: { lang },
                },
                {
                  path: paths.DECLARATION_PAGE_WITH_5669,
                  component: ESignatureWith5669DeclarationPageComponent,
                  data: { lang },
                },
                // IMM0008 WEBFORM
                {
                  path: paths.IMM0008_WEBFORM,
                  data: { lang },
                  canActivate: [AWSGuard],
                  children: [
                    {
                      path: "",
                      redirectTo: paths.WEBFORM_BARCODES,
                      pathMatch: "full",
                    },
                    {
                      path: paths.WEBFORM_IMM008_DATA_PAGE,
                      component: WebformViewimm0008PageComponent,
                      data: { lang, formConfigs: IMM0008Configs },
                    },
                    {
                      path: paths.IMM0008_ADDITIONAL_DEPENDANTS,
                      component: WebformViewTablePageComponent,
                      data: {
                        lang,
                        formConfigs: IMM0008AdditionalDependentsConfigs,
                      },
                    },
                    {
                      path: paths.WEBFORM_BARCODES,
                      component: IMM0008BarcodesPageComponent,
                      data: { lang },
                    },
                  ],
                },
                // IMM5406 WEBFORM
                {
                  path: paths.IMM5406_WEBFORM,
                  component: WebformViewImm5406PageComponent,
                  data: { lang, formConfigs: IMM5406Configs },
                },
                // IMM5562 WEBFORM
                {
                  path: paths.IMM5562_WEBFORM,
                  component: WebformViewImm5562PageComponent,
                  data: { lang, formConfigs: IMM5562Configs },
                },
                // IMM5669 WEBFORM
                {
                  path: paths.IMM5669_WEBFORM,
                  component: WebformViewImm5669PageComponent,
                  data: { lang, formConfigs: IMM5669Configs },
                },
              ],
            },
            { path: "**", redirectTo: "", data: { lang } },
          ],
        },

        /* Confirmation Pages */
        {
          path: paths.CONFIRMATION_CASES,
          runGuardsAndResolvers: "always",
          children: [
            {
              path: "",
              component: CaseListPageComponent,
              canActivate: [AWSGuard],
              data: { lang },
            },
            {
              path: paths.CREATE_CASE,
              component: CreateCasePageComponent,
              canActivate: [AWSGuard],
              data: { lang },
            },
            {
              path: `${paths.CASE_DETAILS}/:id`,
              canActivate: [AWSGuard],
              resolve: { case: CaseDetailsResolver },
              children: [
                {
                  path: "",
                  component: CaseDetailsPageComponent,
                  canActivate: [AWSGuard],
                  data: { lang },
                },
                {
                  path: paths.PHOTO_REVIEW,
                  component: PhotoReviewPageComponent,
                  resolve: { case: CaseDetailsResolver },
                  canActivate: [AWSGuard],
                },
                { path: "**", redirectTo: "", data: { lang } },
              ],
            },
            { path: "**", redirectTo: "", data: { lang } },
          ],
        },
        {
          path: paths.RENEWAL_TRAVEL_DOC_CASES,
          children: [
            {
              path: "",
              loadChildren: () =>
                import("../renewal-module/renewal.module").then(
                  (m) => m.RenewalModule
                ),
              canActivate: [AWSGuard],
            },
          ],
        },
        /* Leave this line at the bottom, it acts as a catch-all */
        { path: "**", redirectTo: paths.APPLICATION_CASES },
      ],
    };
  }
);

export const routes: Routes = [
  { path: "", pathMatch: "full", children: [], canActivate: [LangGuard] },
  ...localizedRoutes,
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
  providers: [FirstLoginGuard, AWSGuard],
})
export class AppRoutingModule {}
