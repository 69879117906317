<h1 class="emphasis">
  {{ displayName }}
  <lib-label-pill
    *ngIf="hasRepresentative"
    [type]="'blue'"
    [text]="'INTAKE.CASE_DETAILS_PAGE.REPRESENTATIVE_LABEL' | translate"
  ></lib-label-pill>
</h1>

<!-- ASSIGNMENT STATUS -->
<section>
  <prcw-assignment-status-section></prcw-assignment-status-section>
</section>

<!-- CLIENT INFORMATION -->
<section>
  <prcw-client-information-section
    #applicationFormsTable
    [hasRep]="withRep"
    (isEditingClientInfoForm)="disableSaveApplicationBtn($event)"
  ></prcw-client-information-section>
</section>

<section *ngIf="withRep">
  <prcw-rep-info-section></prcw-rep-info-section>
</section>

<section *ngIf="initiatedBySponsor">
  <prcw-primary-sponsor-section></prcw-primary-sponsor-section>
</section>

<section *ngIf="initiatedByReferralPartner">
  <prcw-referral-partner-section></prcw-referral-partner-section>
</section>

<!-- DOCS TABLE -->

<prcw-application-forms-table
  (downloadInProgress)="onStartOrFinishDownload($event)"
></prcw-application-forms-table>
<!-- IF REP: REP DECLARATION -->
<ng-container *ngIf="withRep">
  <div class="rep_title__row">
    <h3>{{ "INTAKE.REP_DECLARATION_TITLE" | translate }}</h3>
    <div class="rep_title_pill_fix" *ngIf="case?.caseStatusId === 7">
      <lib-status-pill
        [type]="'complete'"
        [text]="
          'INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.COMPLETED_LABEL'
            | translate
        "
      ></lib-status-pill>
    </div>
  </div>
  <div class="form__terms checkbox-control checkbox-disabled">
    <input
      value="true"
      id="repDeclaration"
      name="repDeclaration"
      [formControl]="applicationReviewForm.controls.repDeclaration"
      type="checkbox"
      aria-disabled="true"
      (click)="toggleRepDeclaration($event)"
    />
    <label
      [innerHTML]="'INTAKE.REP_DECLARATION' | translate"
      for="repDeclaration"
      class="disabled-rep-declaration-label"
    ></label>
  </div>
</ng-container>

<!-- NAVIGATION -->
<lib-layout-row justify="space-between" align="flex-end" mt="3" mb="3">
  <a
    [routerLink]="routeLocalizer.get(lang, 'APPLICATION_CASES', '../')"
    category="secondary"
  >
    {{ "INTAKE.PAGE_BACK" | translate }}
  </a>
  <lib-layout-row gap="one-and-half">
    <div *ngIf="!documentDownloadInProgress">
      <lib-form-select
        identifier="applicationStatus"
        [formControl]="applicationReviewForm.controls.applicationStatus"
        [label]="'INTAKE.APPLICATION_STATUS' | translate"
        [options]="formApplicationStatusOptions"
        required="true"
        hideRequired="true"
      >
        <span
          *ngIf="
            applicationReviewForm.controls.applicationStatus.touched &&
            applicationReviewForm.controls.applicationStatus.hasError(
              'required'
            )
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
    </div>
    <button
      category="primary"
      class="nav-btn"
      (click)="saveApplication()"
      *ngIf="!documentDownloadInProgress"
      [disabled]="!canSaveApplication"
    >
      {{ "INTAKE.CASE_DETAILS_PAGE.SAVE_APPLICATION" | translate }}
    </button>
  </lib-layout-row>
</lib-layout-row>
