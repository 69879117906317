import * as IntakeSearchActions from "./searchTerms.actions";

export interface SearchTermsState {
  searchTerm: string | null;
  lobFilterValue: string | null;
  statusFilterValue: number | null;
  SetLobFilterAndClassFilterValue: any;
  assignedFilterValue: boolean | null;
  classFilterValue: string | null;
  sortOrder: string;
  pageToReview: number;
  searchBy: number;
  assignedToMe: boolean | null;
  noApplicationNumber: boolean | null;
}

const initialSearchTermsState: SearchTermsState = {
  searchTerm: null,
  lobFilterValue: null,
  statusFilterValue: null,
  SetLobFilterAndClassFilterValue: null,
  classFilterValue: null,
  assignedFilterValue: null,
  sortOrder: "submittedAt ASC",
  pageToReview: 0,
  searchBy: 0,
  assignedToMe: false,
  noApplicationNumber: false,
};

export function searchTermsReducer(
  state: SearchTermsState = initialSearchTermsState,
  action: IntakeSearchActions.IntakeSearchActions
) {
  switch (action.type) {
    case IntakeSearchActions.SET_LOB_FILTER_VALUE:
      return {
        ...state,
        lobFilterValue: action.lobFilterValue,
        pageToReview: 0,
      };
    case IntakeSearchActions.SET_STATUS_FILTER_VALUE:
      return {
        ...state,
        statusFilterValue: action.statusFilterValue,
        pageToReview: 0,
      };
    case IntakeSearchActions.SET_LOB_FILTER_AND_CLASS_FILTER_VALUE:
      return {
        ...state,
        lobFilterValue: action.lobFilterValue,
        classFilterValue: action.classFilterValue,
        pageToReview: 0,
      };
    case IntakeSearchActions.SET_CLASS_FILTER_VALUE:
      return {
        ...state,
        classFilterValue: action.classFilterValue,
        pageToReview: 0,
      };
    case IntakeSearchActions.SET_SUB_CLASS_FILTER_VALUE:
      return {
        ...state,
        subClassFilterValue: action.subClassFilterValue,
        pageToReview: 0,
      };
    case IntakeSearchActions.SET_ASSIGNED_FILTER_VALUE:
      return {
        ...state,
        assignedFilterValue: action.assignedFilterValue,
        pageToReview: 0,
      };
    case IntakeSearchActions.SET_PAGE_TO_REVIEW:
      return {
        ...state,
        pageToReview: action.pageToReview,
      };
    case IntakeSearchActions.SET_SORT_VALUE:
      return {
        ...state,
        sortOrder: action.sortOrder,
        pageToReview: 0,
      };
    case IntakeSearchActions.SET_ASSIGNED_TO_ME:
      return {
        ...state,
        pageToReview: 0,
        assignedToMe: action.assignedToMe,
      };
    case IntakeSearchActions.SET_NO_APPLICATION_NUMBER:
      return {
        ...state,
        pageToReview: 0,
        noApplicationNumber: action.noApplicationNumber,
      };
    case IntakeSearchActions.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerms.searchValue,
        pageToReview: 0,
        searchBy: action.searchTerms.searchBy,
      };
    case IntakeSearchActions.RESET_ALL_FILTERS:
      return {
        ...initialSearchTermsState,
        sortOrder: action.sortOrder,
      };
    case IntakeSearchActions.RESET_ALL_FILTERS_AND_SORTING:
      return initialSearchTermsState;
    default:
      return state;
  }
}
