import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "../../../../core/cases-module/models/case.model";
import { Subscription } from "rxjs";
import { RoleIds } from "../../../../shared/constants/roles";

@Component({
  selector: "prcw-primary-sponsor-section",
  templateUrl: "./primary-sponsor.component.html",
  styleUrls: ["./primary-sponsor.component.scss"],
})
export class PrimarySponsorSectionComponent implements OnInit, OnDestroy {
  public lang: string;
  public case: Case;

  // Required Information to be Displayed - Can be required null so had to set type to any
  public name = "";
  public email = "";
  public submittedTime = "";

  private subs: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit() {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getCurrentCaseDate();
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public formatDate(field: string) {
    return field?.replace(/\s/, "T");
  }

  private getCurrentCaseDate() {
    this.subs.push(
      this.store.select("intake").subscribe((caseData) => {
        console.log(caseData);
        const currentCase = caseData.selectedApplication;
        if (currentCase) {
          this.submittedTime = currentCase.submittedAt;
          const primarySponsor = currentCase?.members?.find((member) => {
            return (
              member.emailAddress === currentCase.emailAddress &&
              member.roleId === RoleIds.PRIMARY_SPONSOR
            );
          });
          if (primarySponsor) {
            this.name = primarySponsor.givenName
              ? `${primarySponsor.givenName} ${primarySponsor.familyName}`
              : primarySponsor.familyName;
            this.email = primarySponsor.emailAddress;
          }
        }
      })
    );
  }
}
