import * as fromModel from "./barcodes.model";

import {
  DateConversionOption,
  PropertyType,
} from "../../core/models/data-retrieval.model";
import { LovService, ValidationService } from "lib";
import {
  barcode10Config,
  barcode3Config,
  barcode4Config,
  barcode5Config,
  barcode6Config,
  barcode7Config,
  barcode8Config,
  barcode9Config,
} from "./barcode-config";
import { format, isAfter, isBefore, isValid, max, min, sub } from "date-fns";

import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { trimObject } from "./barcodes.utils";

@Injectable({
  providedIn: "root",
})
export class BarcodesService {
  private crcId: string = Math.floor(1000 + Math.random() * 9000) + ""; // TODO: calculate CRC using IRCC's function

  constructor(
    private translate: TranslateService,
    private validationService: ValidationService,
    private lovService: LovService
  ) {}

  /* PUBLIC ------------------------------------------------------------------------ */

  /* 6. Generate barcodes */
  public generateBarcode1(formData: any, theCase: Case): string {
    const barcode = this.createBarcodeData1(formData, theCase);
    const formattedString = this.formatBarcodeString(barcode);
    return formattedString;
  }

  public generateBarcode2(formData: any): string {
    const barcode = this.createBarcodeData2(formData);
    const formattedString = this.formatBarcodeString(barcode);
    return formattedString;
  }

  /* 7. Combine barcodes in an array - this will be called at the component level */
  public generateIMM008Barcodes(formDataFromAPI: any, theCase: Case): any[] {
    // add this level of trimming empty spaces in your JSON values
    let formData = trimObject(formDataFromAPI);
    if (!formData.lists) formData.lists = this.lovService.lovs;

    this.preprocessFormData(formData);
    const barcode1 = this.generateBarcode1(formData, theCase);
    const barcode2 = this.generateBarcode2(formData);
    const barcodes = [
      { id: "bc1", text: barcode1 },
      { id: "bc2", text: barcode2 },
    ];
    // code to fix old data for placeOfLastEntry
    formData.form.dependantDetails.dependants.forEach(
      (dependant: { personalDetails: any }) => {
        if (dependant.personalDetails.placeOfLastEntry) {
          dependant.personalDetails.placeOfLastEntry =
            dependant.personalDetails.placeOfLastEntry.slice(0, 30);
        }
      }
    );
    // end of code to fix old invalid data
    this.processDependantLanguageDetail(
      formData.form.dependantDetails.dependants,
      formData.lists.languageDetails.officialLanguage
    );
    [3, 4, 5, 6, 7, 8, 9, 10].forEach((barcodeNumber) => {
      const barcodeText = this.generateBarcode3To10(
        formData,
        barcodeNumber,
        theCase
      );
      barcodes.push({ id: `bc${barcodeNumber}`, text: barcodeText });
    });

    return barcodes;
  }

  /* PRIVATE ------------------------------------------------------------------------ */

  /* 1. Get the form and LOB data - at the component level*/

  /* 2. Create barcode objects using the barcode interfaces */
  private createBarcodeData1(formData: any, theCase: Case): fromModel.Barcode1 {
    const {
      applicationDetails,
      personalDetails,
      passportDetails,
      nationalIdentityDetails,
      educationOccupationDetails,
      languageDetails,
    } = formData.form;

    if (!formData.lists) formData.lists = this.lovService.lovs;

    const firstSection: fromModel.FirstSection = {
      programLic: theCase.lob.programLic,
      categoryLic: theCase.lob.categoryLic,
      familyMembers: Math.min(applicationDetails.familyMembers, 6), // only encode max 6 applicants (PA + 5 deps)
      correspondence: applicationDetails.correspondence,
      interview: applicationDetails.interview,
      interpreterRequested: applicationDetails.interpreterRequested,
      province: applicationDetails.province,
      city: applicationDetails.city,
      receivedCSQ: applicationDetails.receivedCSQ,
      csqNumber: applicationDetails.csqNumber,
      dateAppliedForCSQ: this.formatDate(applicationDetails.dateAppliedForCSQ),
    };
    const personalDetailsSection: fromModel.PersonalDetails = {
      familyName: personalDetails.familyName.trim(),
      givenName: personalDetails.givenName,
      usedOtherName: personalDetails.usedOtherName,
      otherFamilyName: personalDetails.otherFamilyName,
      otherGivenName: personalDetails.otherGivenName,
      uci: personalDetails.uci,
      sex: personalDetails.sex,
      heightInCM: personalDetails.heightInCM,
      eyeColour: personalDetails.eyeColour,
      dobYear: this.splitDate(personalDetails.dob, "Y"),
      dobMonth: this.splitDate(personalDetails.dob, "M"),
      dobDay: this.splitDate(personalDetails.dob, "D"),
      cityOfBirth: personalDetails.cityOfBirth,
      countryOfBirth: personalDetails.countryOfBirth,
      citizenship1: personalDetails.citizenship1,
      citizenship2: personalDetails.citizenship2,
      CORCountry: personalDetails.currentCountry.country,
      CORImmigrationStatus: personalDetails.currentCountry.immigrationStatus,
      COROtherImmigrationStatus:
        personalDetails.currentCountry.otherImmigrationStatus,
      CORCountryStartDateOfImmigrationStatus: this.formatDate(
        personalDetails.currentCountry.startDateOfImmigrationStatus
      ),
      CORCountryEndDateOfImmigrationStatus: this.formatDate(
        personalDetails.currentCountry.endDateOfImmigrationStatus
      ),
      dateOfLastEntry: this.formatDate(personalDetails.dateOfLastEntry),
      placeOfLastEntry: personalDetails.placeOfLastEntry,
      hasPreviousCountries: personalDetails.hasPreviousCountries,
      PCOR1Country: personalDetails.previousCountries?.length
        ? personalDetails.previousCountries[0].country
        : null,
      PCOR1ImmigrationStatus: personalDetails.previousCountries?.length
        ? personalDetails.previousCountries[0].immigrationStatus
        : null,
      PCOR1OtherImmigrationStatus: personalDetails.previousCountries?.length
        ? personalDetails.previousCountries[0].otherImmigrationStatus
        : null,
      PCOR1StartDateOfImmigrationStatus: personalDetails.previousCountries
        ?.length
        ? this.formatDate(
            personalDetails.previousCountries[0].startDateOfImmigrationStatus
          )
        : null,
      PCOR1EndDateOfImmigrationStatus: personalDetails.previousCountries?.length
        ? this.formatDate(
            personalDetails.previousCountries[0].endDateOfImmigrationStatus
          )
        : null,
      PCOR2Country:
        personalDetails.previousCountries?.length > 1
          ? personalDetails.previousCountries[1].country
          : null,
      PCOR2ImmigrationStatus:
        personalDetails.previousCountries?.length > 1
          ? personalDetails.previousCountries[1].immigrationStatus
          : null,
      PCOR2OtherImmigrationStatus:
        personalDetails.previousCountries?.length > 1
          ? personalDetails.previousCountries[1].otherImmigrationStatus
          : null,
      PCOR2StartDateOfImmigrationStatus:
        personalDetails.previousCountries?.length > 1
          ? this.formatDate(
              personalDetails.previousCountries[1].startDateOfImmigrationStatus
            )
          : null,
      PCOR2EndDateOfImmigrationStatus:
        personalDetails.previousCountries?.length > 1
          ? this.formatDate(
              personalDetails.previousCountries[1].endDateOfImmigrationStatus
            )
          : null,
    };
    const passportDetailsSection: fromModel.PassportDetails = {
      validPassport: passportDetails.validPassport,
      passportNumber: passportDetails.passportNumber,
      countryOfIssue: passportDetails.countryOfIssue,
      issueDate: this.formatDate(passportDetails.issueDate),
      expiryDate: this.formatDate(passportDetails.expiryDate),
      usingTaiwanesePassportForTrip:
        passportDetails.usingTaiwanesePassportForTrip,
      usingIsraeliPassportForTrip: passportDetails.usingIsraeliPassportForTrip,
    };
    let natIdentityDetails: fromModel.NationalIdentityAPI = {
      ...nationalIdentityDetails,
    };
    if (
      nationalIdentityDetails &&
      nationalIdentityDetails.nationalIdentityDocument
    ) {
      natIdentityDetails = this.correctNationalIdentityDetails(
        nationalIdentityDetails
      );
    }
    const nationalIdentitySection: fromModel.NationalIdentityDetails = {
      nationalIdentityDocument: natIdentityDetails.nationalIdentityDocument,
      nationalIdentityNumber: natIdentityDetails.nationalIdentityNumber,
      nationalIdentityCountryOfIssue: natIdentityDetails.countryOfIssue,
      nationalIdentityIssueDate: natIdentityDetails.issueDate,
      nationalIdentityExpiryDate: natIdentityDetails.expiryDate,
    };
    const educationOccupationSection: fromModel.EducationOccupationDetails = {
      educationLevel: educationOccupationDetails.educationLevel,
      numberOfYear: educationOccupationDetails.numberOfYear,
      currentOccupation: educationOccupationDetails.currentOccupation,
      intendedOccupation: educationOccupationDetails.intendedOccupation,
    };
    const languageSection: fromModel.LanguageDetails = {
      nativeLanguage: languageDetails.nativeLanguage,
      preferredLanguage: languageDetails.preferredLanguage, // swapped
      language: this.getCommunicationLanguage(
        languageDetails.language,
        formData.lists.languageDetails.officialLanguage
      ), // swapped
      takenTest: languageDetails.takenTest,
    };

    const barcode1: fromModel.Barcode1 = {
      barcodeName: this.getBarcodeName(1),
      barcodeId: this.crcId + formData.caseId,
      ...firstSection,
      ...personalDetailsSection,
      ...passportDetailsSection,
      ...nationalIdentitySection,
      ...educationOccupationSection,
      ...languageSection,
    };
    return barcode1;
  }

  private createBarcodeData2(formData: any): fromModel.Barcode2 {
    const { personalDetails, contactDetails } = formData.form;

    const personalDetailsSection: fromModel.PersonalDetails2 = {
      maritalStatus: personalDetails.maritalStatus,
      dateOfMarriageOrCommonLaw: this.formatDate(
        personalDetails.dateOfMarriageOrCommonLaw
      ),
      familyNameOfSpouse: personalDetails.familyNameOfSpouse,
      givenNameOfSpouse: personalDetails.givenNameOfSpouse,
      previouslyMarriedOrCommonLaw:
        personalDetails.previouslyMarriedOrCommonLaw,
      previousSpouseFamilyName: personalDetails.previousSpouseFamilyName,
      previousSpouseGivenName: personalDetails.previousSpouseGivenName,
      typeOfRelationship: personalDetails.typeOfRelationship,
      startDateOfRelationship: this.formatDate(
        personalDetails.startDateOfRelationship
      ),
      endDateOfRelationship: this.formatDate(
        personalDetails.endDateOfRelationship
      ),
      previousSpouseDobDay: this.splitDate(
        personalDetails.previousSpouseDob,
        "D"
      ), // swapped
      previousSpouseDobMonth: this.splitDate(
        personalDetails.previousSpouseDob,
        "M"
      ), // swapped
      previousSpouseDobYear: this.splitDate(
        personalDetails.previousSpouseDob,
        "Y"
      ),
    };
    const contactDetailsSection: fromModel.ContactDetails = {
      postOfficeBox: contactDetails.mailingAddress.postOfficeBox,
      apartmentUnit: contactDetails.mailingAddress.apartmentUnit,
      streetNumber: contactDetails.mailingAddress.streetNumber,
      streetName: contactDetails.mailingAddress.streetName,
      city: contactDetails.mailingAddress.city,
      country: contactDetails.mailingAddress.country,
      province: contactDetails.mailingAddress.province,
      postalCode: this.formatPostalCode(
        contactDetails.mailingAddress.postalCode
      ),
      district: contactDetails.mailingAddress.district,
      // Double not converts 0/1 to T/F boolean value
      residentialSameAsMailingAddress:
        !!contactDetails.residentialSameAsMailingAddress,
      residentialApartmentUnit: contactDetails.residentialAddress.apartmentUnit,
      residentialStreetNumber: contactDetails.residentialAddress.streetNumber,
      residentialStreetName: contactDetails.residentialAddress.streetName,
      residentialCity: contactDetails.residentialAddress.city,
      residentialCountry: contactDetails.residentialAddress.country,
      residentialProvince: contactDetails.residentialAddress.province,
      residentialPostalCode: this.formatPostalCode(
        contactDetails.residentialAddress.postalCode
      ),
      residentialDistrict: contactDetails.residentialAddress.district,
      type: contactDetails.primaryTelephone.type,
      number: this.formatPhoneNumber(contactDetails.primaryTelephone),
      altType: contactDetails.alternateTelephone.type,
      altNumber: this.formatPhoneNumber(contactDetails.alternateTelephone),
      faxNumber: this.formatPhoneNumber(contactDetails.faxNumber),
      emailAddress: contactDetails.emailAddress,
    };
    // handle residential addres same like mailing address
    // for legacy data
    if (
      contactDetailsSection.residentialSameAsMailingAddress &&
      contactDetailsSection.postOfficeBox
    ) {
      console.warn("Residential address with postOfficeBox identified!");
      contactDetailsSection.residentialSameAsMailingAddress = false;
      contactDetailsSection.residentialApartmentUnit =
        contactDetailsSection.apartmentUnit;
      contactDetailsSection.residentialStreetName =
        contactDetailsSection.streetName;
      contactDetailsSection.residentialStreetNumber =
        contactDetailsSection.streetNumber;
      contactDetailsSection.residentialCity = contactDetailsSection.city;
      contactDetailsSection.residentialCountry = contactDetailsSection.country;
      contactDetailsSection.residentialProvince =
        contactDetailsSection.province;
      contactDetailsSection.residentialPostalCode =
        contactDetailsSection.postalCode;
      contactDetailsSection.residentialDistrict =
        contactDetailsSection.district;
    }

    const barcode: fromModel.Barcode2 = {
      barcodeName: this.getBarcodeName(2),
      barcodeId: this.crcId + formData.caseId,
      ...personalDetailsSection,
      ...contactDetailsSection,
    };
    return barcode;
  }
  // functions for barcodes 3+ will go here

  private processDependantLanguageDetail(
    dependantDetails: any[],
    languageList: { value: string; text: { en: string; fr: string } }[]
  ): void {
    dependantDetails.forEach((dep) => {
      dep.languageDetails.language = this.getCommunicationLanguage(
        dep.languageDetails.language,
        languageList
      );
    });
  }

  private getCommunicationLanguage(
    language: string,
    languageList: { value: string; text: { en: string; fr: string } }[]
  ): string {
    const listItem = languageList.find((lang) => lang.value === language);
    const currentLang = this.translate.currentLang as "en" | "fr";
    return listItem?.text[currentLang] || "";
  }

  private getBarcodeName(barcodeNumber: number): string {
    return `IMM0008_06-2018_${barcodeNumber}`;
  }

  private generateBarcode3To10(
    formData: any,
    barcodeNumber: number,
    theCase: Case
  ): string {
    const barcodeNumberToConfig: {
      [i: number]: fromModel.DependentInfoFlattenedItem[];
    } = {
      3: barcode3Config,
      4: barcode4Config,
      5: barcode5Config,
      6: barcode6Config,
      7: barcode7Config,
      8: barcode8Config,
      9: barcode9Config,
      10: barcode10Config,
    };
    const config = barcodeNumberToConfig[barcodeNumber];
    if (config) {
      const barcodeObject = this.createBarcode3To10(
        formData,
        barcodeNumber,
        config,
        theCase
      );
      return this.formatBarcodeString(barcodeObject);
    }
    return "";
  }

  private createBarcode3To10(
    formData: any,
    barcodeNumber: number,
    barcodeItems: fromModel.DependentInfoFlattenedItem[],
    theCase: Case
  ): fromModel.Barcode3To10 {
    const barcode: fromModel.Barcode3To10 = {
      barcodeName: this.getBarcodeName(barcodeNumber),
      barcodeId: this.crcId + formData.caseId,
    };
    const dependantDetails = formData.form.dependantDetails.dependants;
    barcodeItems.forEach((item: fromModel.DependentInfoFlattenedItem) => {
      const targetField = item.targetField;
      const depIndex = item.index;
      if (targetField && depIndex !== undefined) {
        const depInfo = dependantDetails[depIndex];
        if (depInfo) {
          if (item.type === PropertyType.Primitive) {
            barcode[targetField] = this.getPrimitiveBarcodeItem(
              depInfo,
              item.sourceField
            );
          } else if (item.type === PropertyType.Object && item.subfield) {
            barcode[targetField] = this.getObjectBarcodeItem(
              depInfo,
              item.sourceField,
              item.subfield
            );
          } else if (item.type === PropertyType.Array && item.subfield) {
            barcode[targetField] = this.getArrayBarcodeItem(
              depInfo[item.sourceField],
              item.sourceField,
              item.subfield
            );
          } else if (item.type === PropertyType.Date) {
            barcode[targetField] = this.getDateBarcodeItem(
              depInfo[item.sourceField],
              item.sourceField,
              item.dateConversionOption
            );
          }
        } else {
          barcode[targetField] = "";
        }
      }
    });

    if (barcodeNumber === 10) {
      const submittedAt = this.validationService.convertFromStringToDate(
        theCase.submittedAt.split(" ")[0]
      );
      const startDates: Date[] = [];
      const endDates: Date[] = [];
      if (formData.form.nationalIdentityDetails.nationalIdentityDocument) {
        const nationalIdentityIssueDate =
          this.validationService.convertFromStringToDate(
            formData.form?.nationalIdentityDetails?.issueDate
          );
        const nationalIdentityExpiryDate =
          this.validationService.convertFromStringToDate(
            formData.form?.nationalIdentityDetails?.expiryDate
          );
        isValid(nationalIdentityIssueDate)
          ? startDates.push(nationalIdentityIssueDate)
          : startDates.push(
              this.validationService.convertFromStringToDate("1901-01-01")
            );
        isValid(nationalIdentityExpiryDate)
          ? endDates.push(nationalIdentityExpiryDate)
          : endDates.push(
              this.validationService.convertFromStringToDate("2100-01-01")
            );
      }

      const currentCountryStartDate =
        this.validationService.convertFromStringToDate(
          formData.form?.personalDetails?.currentCountry
            ?.startDateOfImmigrationStatus
        );
      const currentCountryEndDate =
        this.validationService.convertFromStringToDate(
          formData.form?.personalDetails?.currentCountry
            ?.endDateOfImmigrationStatus
        );
      isValid(currentCountryStartDate)
        ? startDates.push(currentCountryStartDate)
        : "";
      isValid(currentCountryEndDate)
        ? endDates.push(currentCountryEndDate)
        : "";

      if (formData.form?.passportDetails.validPassport) {
        const passPortIssueDate =
          this.validationService.convertFromStringToDate(
            formData.form?.passportDetails?.issueDate.split(" ")[0]
          );
        const passportExpDate = this.validationService.convertFromStringToDate(
          formData.form?.passportDetails?.expiryDate?.split(" ")[0]
        );
        isValid(passPortIssueDate) ? startDates.push(passPortIssueDate) : "";
        isValid(passportExpDate) ? endDates.push(passportExpDate) : "";
      }
      const minDate = max(startDates);
      const maxDate = min(endDates);

      //lets discuss what we do with exceptions... logging to console the exception
      if (
        isValid(submittedAt) &&
        isValid(minDate) &&
        isValid(maxDate) &&
        startDates.length > 0
      ) {
        if (isAfter(submittedAt, minDate) && isBefore(submittedAt, maxDate)) {
          barcode.dateLastValidated = format(submittedAt, "yyyy-MM-dd");
        } else {
          // Validation date = MIN(3 expiries) - 1 day
          const lastValidatedDate = sub(maxDate, { days: 1 });
          barcode.dateLastValidated = format(lastValidatedDate, "yyyy-MM-dd");
        }
      } else {
        console.log("No date ranges....using submittedAt date!!!");
        barcode.dateLastValidated = format(submittedAt, "yyyy-MM-dd");
      }
      barcode.readerInfo =
        "IMM0008_PR_Web_Portal_V1.0_" + this.getCurrentTimestamp();
    }

    return barcode;
  }

  private getPrimitiveBarcodeItem(obj: any, propertyName: string): any {
    return this.getFormattedValue(
      obj === undefined || obj === null ? obj : obj[propertyName]
    );
  }

  private getObjectBarcodeItem(
    obj: any,
    propertyName: string,
    subfield: fromModel.DependentInfoFlattenedItem
  ): any {
    const val = obj[propertyName];
    if (subfield.type === PropertyType.Primitive) {
      return this.getPrimitiveBarcodeItem(val, subfield.sourceField);
    } else if (subfield.type === PropertyType.Object && subfield.subfield) {
      return this.getObjectBarcodeItem(
        val,
        subfield.sourceField,
        subfield.subfield
      );
    } else if (subfield.type === PropertyType.Date) {
      return this.getDateBarcodeItem(
        val,
        subfield.sourceField,
        subfield.dateConversionOption
      );
    } else if (subfield.type === PropertyType.Array && subfield.subfield) {
      return this.getArrayBarcodeItem(
        val,
        subfield.sourceField,
        subfield.subfield
      );
    }
  }

  private getDateBarcodeItem(
    obj: any,
    propertyName: string,
    dateTarget?: DateConversionOption
  ): string {
    if (obj === undefined || obj === null) {
      return this.getPrimitiveBarcodeItem(obj, propertyName);
    }
    const val = obj[propertyName];
    if (!dateTarget) {
      return this.formatDate(val);
    }
    return this.splitDate(val, dateTarget.toString());
  }

  private getArrayBarcodeItem(
    obj: any,
    propertyName: string,
    subfield: fromModel.DependentInfoFlattenedItem
  ): any {
    const index = subfield.index;
    const val = obj[propertyName];
    if (Array.isArray(val) && index !== undefined) {
      if (subfield.type === PropertyType.Primitive) {
        return this.getPrimitiveBarcodeItem(val[index], subfield.sourceField);
      } else if (subfield.type === PropertyType.Object && subfield.subfield) {
        return this.getObjectBarcodeItem(
          val[index],
          subfield.sourceField,
          subfield.subfield
        );
      } else if (subfield.type === PropertyType.Date) {
        return this.getDateBarcodeItem(
          val[index],
          subfield.sourceField,
          subfield.dateConversionOption
        );
      }
    }
  }

  /* 3-5. Order and format barcode data then return a delimited string*/
  private formatBarcodeString(
    barcode: fromModel.Barcode1 | fromModel.Barcode2 | fromModel.Barcode3To10
  ): string {
    const barcodeArray = this.getOrderedArray(barcode);
    const formattedBarcodeArray = this.getFormattedArray(barcodeArray);
    const delimitedString = this.getDelimitedString(formattedBarcodeArray);
    return delimitedString;
  }

  /* Returns an ordered array of the barcode object */
  private getOrderedArray(
    barcode: fromModel.Barcode1 | fromModel.Barcode2 | fromModel.Barcode3To10
  ): any[] {
    // Get ordered values from ordered key array
    const orderedKeys = Object.getOwnPropertyNames(barcode);
    // @ts-ignore // TODO: resolve type issue below
    const orderedValues = orderedKeys.map((key) => barcode[key]);
    return orderedValues;
  }

  /* Returns an array of formatted values - true/false -> Y/N and undefined/null -> "" */
  private getFormattedArray(barcodeArray: any[]): any[] {
    return barcodeArray.map((data) => this.getFormattedValue(data));
  }

  private getFormattedValue(val: any): any {
    // If the type is undefined or null return an empty string
    if (val === undefined || val === null) {
      return "";
    }
    // If the type is boolean return Y/N
    else if (typeof val === "boolean") {
      return val ? "Y" : "N";
    }
    // Otherwise return the data as is
    else {
      return val;
    }
  }

  /* Returns a string of form values separated by a pipe */
  private getDelimitedString(barcodeArray: any[]): string {
    return `${barcodeArray.join("|")}|`;
  }

  /* Returns a portion of the date (year, month, or day) based on the format Y, M, or D withOUT a leading 0 */
  private splitDate(date: string, format: string): string {
    if (date) {
      const dateArray = date.split("/");
      switch (format) {
        case "Y":
          return dateArray[0];
        case "M":
          return dateArray[1].replace(/^0+/, "");
        case "D":
          return dateArray[2].replace(/^0+/, "");
        default:
          return "";
      }
    }
    return "";
  }

  /* Returns the date separated by a dash */
  private formatDate(date: string): string {
    return date ? date.split("/").join("-") : "";
  }

  /* Replaces dashes with empty strings */
  private removeDashes(text: string): string {
    return text.replace(/-/g, "");
  }

  /* Returns true if the postal code follows the US format */
  private isUSPostalCode(postalCode: string): boolean {
    const regex = new RegExp("^[0-9]{5}(-)[0-9]{4}$");
    return regex.test(postalCode);
  }

  /* Returns true if the country code has a leading 1*/
  private hasLeadingOne(countryCode: string): boolean {
    const regex = new RegExp("^1");
    return regex.test(countryCode);
  }

  /* Removes dashes if the postal code is a US code*/
  private removeLeadingOne(countryCode: string): string {
    return countryCode.substring(1);
  }

  /* Removes dashes if the postal code is a US code*/
  private formatPostalCode(postalCode: string): string {
    return this.isUSPostalCode(postalCode)
      ? this.removeDashes(postalCode)
      : postalCode;
  }

  private getCurrentTimestamp(): string {
    const currentDate = new Date();
    const dateString =
      currentDate.getUTCFullYear() +
      "-" +
      (currentDate.getUTCMonth() + 1) +
      "-" +
      currentDate.getUTCDate() +
      "_" +
      currentDate.getUTCHours() +
      ":" +
      currentDate.getUTCMinutes() +
      currentDate.getUTCSeconds();
    return dateString;
  }

  /* Takes in the phone number object and returns the formatted phone number */
  private formatPhoneNumber(numberObject: any): string {
    if (numberObject.canadaOrUSA) {
      const phoneNumber =
        numberObject.number === null
          ? ""
          : this.removeDashes(numberObject.number).replace(/ /g, "");

      return numberObject.extension === "" || numberObject.extension === null
        ? phoneNumber
        : phoneNumber + numberObject.extension;
    } else {
      const countryCode = this.hasLeadingOne(numberObject.countryCode)
        ? this.removeLeadingOne(numberObject.countryCode)
        : numberObject.countryCode;
      const phoneNumber =
        numberObject.number === null
          ? ""
          : "+" +
            countryCode +
            this.removeDashes(numberObject.number).replace(/ /g, "");

      return numberObject.extension === "" || numberObject.extension === null
        ? phoneNumber
        : phoneNumber + "x" + numberObject.extension;
    }
  }

  // Pre-process form data from the back-end.
  // This function can be removed once all validation issues with the front-end forms are fixed
  private preprocessFormData(formData: any): void {
    const pa = formData.form;
    this.preprocessPersonalDetails(pa.personalDetails);
    const deps = formData.form.dependantDetails.dependants;
    (deps as any[]).forEach((dep) => this.preprocessDependent(dep));
  }

  private preprocessPersonalDetails(personalDetails: any): void {
    // nickname. IPI 1172
    if (
      personalDetails.familyName === personalDetails.otherFamilyName &&
      personalDetails.givenName === personalDetails.otherGivenName
    ) {
      personalDetails.otherFamilyName = null;
      personalDetails.otherGivenName = null;
    }

    // TEMORARY BUG FIX - IPI-1537
    // Temporary fix adds a space to nickname fields. Waiting on open issue
    // (https://github.com/pkoretic/pdf417-generator/issues/13) to be resolved to update the temporary fix
    if (personalDetails.otherFamilyName !== null) {
      personalDetails.otherFamilyName = `${personalDetails.otherFamilyName}`;
    }

    if (personalDetails.otherGivenName !== null) {
      personalDetails.otherGivenName = `${personalDetails.otherGivenName}`;
    }

    const preprocessOtherImmigrationStatus = (countryOfResidence: any) => {
      if (countryOfResidence.immigrationStatus !== "06") {
        // immigration status in country of residence is not "Other"
        countryOfResidence.otherImmigrationStatus = null;
      }
    };

    // status in country of residence - Other. IPI 1317
    preprocessOtherImmigrationStatus(personalDetails.currentCountry);

    // From & To dates for the current country of residence. IPI 1457
    if (
      ["01", "02", "07", "08", "09"].includes(
        personalDetails.currentCountry.immigrationStatus
      )
    ) {
      personalDetails.currentCountry.startDateOfImmigrationStatus = null;
      personalDetails.currentCountry.endDateOfImmigrationStatus = null;
    }

    // status in previous countries of residence - Other. IPI 1318
    if (personalDetails.previousCountries) {
      (personalDetails.previousCountries as any[]).forEach((previousCor) => {
        preprocessOtherImmigrationStatus(previousCor);
      });
    }

    // Spouse info when not married or in common-law. IPI 1319
    if (!["01", "03"].includes(personalDetails.maritalStatus)) {
      personalDetails.familyNameOfSpouse = null;
      personalDetails.givenNameOfSpouse = null;
      personalDetails.dateOfMarriageOrCommonLaw = null;
    }
  }

  private preprocessDependent(dep: any): void {
    // dependent type. IPI 1178
    if (dep.personalDetails.accompanyingPA === false) {
      dep.personalDetails.dependantType = null;
    }

    // relationship to PA - Other. IPI 1316
    if (dep.personalDetails.relationshipToPA !== "85") {
      // relationship to PA is not "Other"
      dep.personalDetails.otherRelationshipToPA = null;
    }
    // Parent / Adoptive Parent - IPI-1461
    if (["05", "37"].indexOf(dep.personalDetails.relationshiptoPA) > -1) {
      dep.personalDetails.otherRelationshipToPA =
        dep.personalDetails.relationshiptoPA;
      dep.personalDetails.relationshiptoPA = "85";
    }

    // new: to correct correctNationalIdentityDetails(dates) for dependants
    if (
      dep.nationalIdentityDetails &&
      dep.nationalIdentityDetails.nationalIdentityDocument
    ) {
      dep.nationalIdentityDetails = this.correctNationalIdentityDetails(
        dep.nationalIdentityDetails
      );
    }
    this.preprocessPersonalDetails(dep.personalDetails);
  }

  // this corrects for now issueDate and expiryDate
  private correctNationalIdentityDetails(
    natIdentInfo: fromModel.NationalIdentityAPI
  ): fromModel.NationalIdentityAPI {
    const result = { ...natIdentInfo };
    if (natIdentInfo.nationalIdentityDocument) {
      let nationalIdentityIssueDateStr = this.formatDate(
        natIdentInfo?.issueDate
      );
      let nationalIdentityIssueDate =
        this.validationService.convertFromStringToDate(natIdentInfo?.issueDate);
      if (!isValid(nationalIdentityIssueDate)) {
        nationalIdentityIssueDateStr = "1901-01-01";
      } else {
        nationalIdentityIssueDateStr = format(
          nationalIdentityIssueDate,
          "yyyy-MM-dd"
        );
      }
      let nationalIdentityExpiryDateStr = this.formatDate(
        natIdentInfo.expiryDate
      );
      let nationalIdentityExpiryDate =
        this.validationService.convertFromStringToDate(
          natIdentInfo?.expiryDate
        );
      if (!isValid(nationalIdentityExpiryDate)) {
        nationalIdentityExpiryDateStr = "2100-01-01";
      } else {
        nationalIdentityExpiryDateStr = format(
          nationalIdentityExpiryDate,
          "yyyy-MM-dd"
        );
      }
      result.issueDate = nationalIdentityIssueDateStr;
      result.expiryDate = nationalIdentityExpiryDateStr;
    }
    return result;
  }
}
