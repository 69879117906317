import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "lib-file-upload",
  template: `
    <button
      type="button"
      [class]="buttonClasses"
      (click)="fileInput.value = null; fileInput.click()"
      [disabled]="disabled"
    >
      <ng-content></ng-content>
    </button>
    <input
      #fileInput
      type="file"
      [attr.accept]="fileTypes"
      (change)="select($event)"
      tabindex="-1"
      [id]="inputId + inputSuffix"
    />
  `,
  styles: [
    `
      input[type="file"] {
        position: absolute;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        overflow: hidden;
        white-space: nowrap;
        display: none;
      }
      .btn {
        margin: 0;
      }
      .fixed-width {
        transition: none;
        width: 7em;
      }
      @media screen and (max-width: 480px) {
        .fixed-width {
          width: 100%;
        }
      }
    `,
  ],
})
export class FileUploadComponent {
  @Input() public id = "";
  // optional Inputs:
  @Input() public fileTypes = "";
  @Input() public disabled = false;
  @Input() public buttonStyle:
    | "primary"
    | "primary-alt"
    | "secondary"
    | "fixed-width" = "primary";
  @Input() public inputSuffix = "-input";
  @Input() getImgSrc: boolean = false;

  public selectedFile: File;

  @Output() selectFile: EventEmitter<File> = new EventEmitter();

  constructor() {}

  public get buttonClasses(): string {
    return this.buttonStyle === "fixed-width"
      ? `btn btn-primary-alt fixed-width`
      : `btn btn-${this.buttonStyle}`;
  }

  public select(e: { target?: HTMLInputElement }): void {
    this.selectedFile = (e.target?.files as FileList)[0];
    this.selectFile.emit(this.selectedFile);
  }

  // Method to clear white space and lower case string for Cypress automation
  public get inputId(): string {
    this.id = this.id.replace(/\s/g, "").toLowerCase();
    return this.id;
  }
}
