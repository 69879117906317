<h1 class="title emphasis">
  {{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}
</h1>
<h2 class="emphasis">
  {{ "INTAKE.IMM0008.BARCODES.TITLE" | translate }}
</h2>

<p [innerHTML]="'INTAKE.IMM0008.BARCODES.INSTRUCTIONS' | translate"></p>

<div class="spinner">
  <lib-spinner [showSpinner]="isLoading" [fullPage]="false"></lib-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <prcw-barcodes-table
    [barcodesArray]="barcodesArray"
    [applicationId]="applicationId"
  >
  </prcw-barcodes-table>
</ng-container>

<lib-layout-row gap="one-and-half">
  <a [routerLink]="['../../']" category="secondary">
    {{ "INTAKE.PAGE_BACK" | translate }}
  </a>
  <a
    [routerLink]="routeLocalizer.get(lang, 'WEBFORM_IMM008_DATA_PAGE', '../')"
    category="primary"
  >
    {{ "INTAKE.PAGE_NEXT" | translate }}
  </a>
</lib-layout-row>
