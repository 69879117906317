import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DownloadService } from "../../shared/services/download.service";
import { SharedModule } from "../../shared/shared.module";
import { CasesService } from "./cases.service";
import { CasesSearchComponent } from "./components/cases-search/cases-search.component";
import { ConfirmationCasesSearchComponent } from "./components/confirmation-cases-search/confirmation-cases-search.component";
import { LobFilterComponent } from "./components/lob-filter/lob-filter.component";
import { ClassFilterComponent } from "./components/class-filter/class-filter.component";
import { StatusFilterComponent } from "./components/status-filter/status-filter.component";
import { AssignmentFilterComponent } from "./components/assignment-filter/assignment-filter.component";

@NgModule({
  declarations: [
    CasesSearchComponent,
    ConfirmationCasesSearchComponent,
    LobFilterComponent,
    ClassFilterComponent,
    StatusFilterComponent,
    AssignmentFilterComponent,
  ],
  providers: [CasesService, DownloadService],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule],
  exports: [
    CasesSearchComponent,
    ConfirmationCasesSearchComponent,
    LobFilterComponent,
    ClassFilterComponent,
    StatusFilterComponent,
    AssignmentFilterComponent,
  ],
})
export class CasesModule {}
