<lib-form-select
  *ngIf="!isHidden"
  identifier="classSearch"
  [formControl]="classFilter"
  [label]="copyKey | translate"
  [options]="subcategory"
  class="class-search"
  (change)="classFiltered()"
>
</lib-form-select>
