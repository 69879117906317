/*
RouteLocalizerService depends on route paths following the rules below:
1. unique keys
2. unique values
3. no nesting
4. matching structure (keys) in each language
*/
import en from "./en.json";
import fr from "./fr.json";

export default { en, fr };
