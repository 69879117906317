import { Action } from "@ngrx/store";
import { Decision } from "../models/document-rejection.model";
import { Case, NewCase } from "../../core/cases-module/models/case.model";
export const GET_ALL_CASES = "GET_ALL_CASES";
export const CREATE_CASE = "CREATE_CASE";
export const SET_CURRENT_CASE = "SET_CURRENT_CASE";
export const SET_CURRENT_CASE_STATUSES = "SET_CURRENT_CASE_STATUSES";
export const UPDATE_CASE_DECISIONS = "UPDATE_CASE_DECISIONS";
export const RESET_CASE_INFO = "RESET_CASE_INFO";
export const SET_ADDRESS_STATUS = "SET_ADDRESS_STATUS";
export const SET_PHOTO_STATUS = "SET_PHOTO_STATUS";
export const SHAKE_CASE = "SHAKE_CASE";
export const START_LOAD_CURRENT_CASE = "START_LOAD_CURRENT_CASE";
export const SET_CURRENT_CASE_ERROR = "SET_CURRENT_CASE_ERROR";
export const START_DELETE_CURRENT_CASE = "START_DELETE_CURRENT_CASE";
export const DELETE_CURRENT_CASE = "DELETE_CURRENT_CASE";

export class GetAllCases implements Action {
  readonly type = GET_ALL_CASES;
  constructor(
    public payload: {
      cases: Case[];
    }
  ) {}
}

export class CreateCase implements Action {
  readonly type = CREATE_CASE;
  constructor(
    public payload: {
      newCase: NewCase;
    }
  ) {}
}

export class SetCurrentCase implements Action {
  readonly type = SET_CURRENT_CASE;
  constructor(public payload: Case) {}
}

export class ResetCaseInfo implements Action {
  readonly type = RESET_CASE_INFO;
  constructor() {}
}

export class SetAddressStatus implements Action {
  readonly type = SET_ADDRESS_STATUS;
  constructor(
    public payload: {
      decision: Decision;
      caseId: number | string;
    }
  ) {}
}

export class SetPhotoStatus implements Action {
  readonly type = SET_PHOTO_STATUS;
  constructor(
    public payload: {
      decision: Decision;
      caseId: number | string;
    }
  ) {}
}
export class ShakeCase implements Action {
  readonly type = SHAKE_CASE;
  constructor() {}
}
export class StartLoadCurrentCase implements Action {
  readonly type = START_LOAD_CURRENT_CASE;
  constructor(
    public payload: {
      caseId: string | undefined;
    }
  ) {}
}
export class SetCurrentCaseError implements Action {
  readonly type = SET_CURRENT_CASE_ERROR;
  constructor(
    public payload: {
      caseId: number | string | undefined;
      apiLoadingError?: string;
    }
  ) {}
}
export class StartDeleteCurrentCase implements Action {
  readonly type = START_DELETE_CURRENT_CASE;
  constructor(
    public payload: {
      case: Case;
    }
  ) {}
}
export class DeleteCurrentCase implements Action {
  readonly type = DELETE_CURRENT_CASE;
  constructor(
    public payload: {
      case: Case;
    }
  ) {}
}

export type CaseActions =
  | GetAllCases
  | SetCurrentCase
  | CreateCase
  | SetAddressStatus
  | SetPhotoStatus
  | ResetCaseInfo
  | ShakeCase
  | StartLoadCurrentCase
  | SetCurrentCaseError
  | StartDeleteCurrentCase
  | DeleteCurrentCase;
