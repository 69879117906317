<table class="table cases-table">
  <caption class="visually-hidden">
    {{
      caption
    }}
  </caption>
  <thead class="cases-table__head">
    <tr>
      <ng-content select="[tableHeadings]"></ng-content>
    </tr>
  </thead>
  <tbody *ngIf="!isLoadingCases" class="cases-table__body">
    <tr
      class="cases-table__body__row"
      role="button"
      tabindex="0"
      *ngFor="let case of cases"
      (keyup.enter)="navigateToCase(case?.id)"
    >
      <ng-container
        *ngIf="
          user &&
            (user.userPool.toUpperCase() === 'CASEWORKERS' ||
              user.userPool.toUpperCase() === 'TEAMLEADS');
          else clientInfo
        "
      >
        <td
          class="cases-table__body__row__item cases-table__body__row__digits"
          (click)="navigateToCase(case?.id)"
        >
          <p>
            <strong>{{ "CONFIRMATION.UCI" | translate }}</strong>
            {{ case?.uci }}
          </p>
          <p>
            <strong>
              {{ "CONFIRMATION.APPLICATION_NUMBER" | translate }}</strong
            >
            {{ case.applicationNumber }}
          </p>
        </td>
        <td
          class="cases-table__body__row__item"
          (click)="navigateToCase(case?.id)"
        >
          {{ case.surname + " " + case.givenName }}
        </td>
      </ng-container>
      <ng-template #clientInfo>
        <td class="cases-table__body__row__item cases-table__body__row__digits">
          {{ case?.uci }}
        </td>
        <td class="cases-table__body__row__item cases-table__body__row__digits">
          {{ case.applicationNumber }}
        </td>
        <td class="cases-table__body__row__item">
          {{ case.surname }}
        </td>
        <td class="cases-table__body__row__item">
          {{ case.givenName }}
        </td>
      </ng-template>

      <td
        class="cases-table__body__row__item cases-table__body__row__digits"
        (click)="navigateToCase(case?.id)"
      >
        {{ case.createdAt | date : "yyyy/MM/dd" }}
      </td>

      <ng-container
        *ngIf="
          user &&
          (user.userPool.toUpperCase() === 'CASEWORKERS' ||
            user.userPool.toUpperCase() === 'TEAMLEADS')
        "
      >
        <td
          class="cases-table__body__row__item"
          (click)="navigateToCase(case?.id)"
        >
          {{ getOfficeLabel(case.offices) }}
        </td>
        <td class="cases-table__body__row__item decaration-mark">
          <img
            *ngIf="case.addressDeclarationTimestamp"
            [alt]="'CASELIST.TABLE_HEADINGS.DECLARATION' | translate"
            src="../../../../assets/images/icons/square-check.svg"
          />
        </td>
        <td class="cases-table__body__row__item">
          <div>
            <div
              class="autocomplete-container"
              *ngIf="
                !case.isUpdating &&
                case.isCaseAssignable &&
                case.status.id !== '8' &&
                case.status.id !== '10'
              "
            >
              <div>
                <label for="'assignBulkRow' + case.id">
                  <span class="visually-hidden">{{
                    "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.DROPDOWN_PLACEHOLDER"
                      | translate
                  }}</span>
                </label>
                <lib-form-autocomplete
                  class="no-border"
                  [identifier]="'assignBulkRow' + case.id"
                  [options]="
                    case.cognitoCaseWorkerId === null &&
                    user?.userPool?.toUpperCase() === 'CASEWORKERS'
                      ? singleCaseworkerOption
                      : assignToList?.slice()
                  "
                  [placeholder]="'INTAKE.CASE_LIST_PAGE.UNASSIGNED'"
                  [formControl]="assignToFormGroup?.controls[case.id]"
                  [label]="
                    'INTAKE.CASE_LIST_PAGE.CASES_TABLE.ASSIGN_TO_HEADING'
                      | translate
                  "
                  [showLabel]="false"
                  (optionSelected)="assignUser($event, case)"
                >
                </lib-form-autocomplete>
                <img
                  class="cases-error"
                  *ngIf="case.assignError"
                  src="../../../../assets/images/icons/circle-empty-alert.svg"
                  [alt]="
                    'INTAKE.CASELIST.ASSIGN_ERROR.' + case.assignError
                      | translate
                  "
                />
              </div>
              <div *ngIf="case.assignError">
                <span class="error-message" aria-live="polite">{{
                  "INTAKE.CASELIST.ASSIGN_ERROR." + case.assignError | translate
                }}</span>
              </div>
            </div>
            <div
              *ngIf="!case.isCaseAssignable"
              class="cases-table__body__assigned"
              (click)="navigateToCase(case?.id)"
            >
              {{ case.cognitoCaseWorkerId }}
            </div>
            <div *ngIf="case.isUpdating">
              <lib-spinner
                class="spinner spinner-container"
                [showSpinner]="case.isUpdating"
                [fullPage]="false"
              ></lib-spinner>
            </div>
          </div>
        </td>
      </ng-container>
      <td
        class="cases-table__body__row__item"
        (click)="navigateToCase(case?.id)"
      >
        <lib-status-pill
          *ngIf="!applicant"
          [text]="
            currentLang === 'fr' ? case.status.statusFr : case.status.statusEn
          "
          [type]="checkApplicantStatus(case.status.id)"
          [wordBreak]="true"
        ></lib-status-pill>
        <lib-status-pill
          *ngIf="applicant"
          [text]="getPillStatusLabel(case.status.id)"
          [type]="checkApplicantStatus(case.status.id)"
          [wordBreak]="true"
        ></lib-status-pill>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="isLoadingCases" class="empty-table">
    <tr>
      <td colspan="100%">
        <div class="spinner">
          <lib-spinner
            [showSpinner]="isLoadingCases"
            [id]="'LOAD_CASES'"
            [fullPage]="false"
          >
          </lib-spinner>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<div *ngIf="!cases.length">
  <p class="no-results-text">{{ noResults }}</p>
</div>
