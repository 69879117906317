/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ca-central-1",
  aws_cognito_identity_pool_id:
    "ca-central-1:722b5c0d-66d9-4583-aa29-eae2ac298c78",
  aws_cognito_region: "ca-central-1",
  aws_user_pools_id: "ca-central-1_BNq0hygLw",
  aws_user_pools_web_client_id: "25dk89t98sqd2m54mju67h8t79",
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: "irccprsonapi",
      endpoint: "https://api.prson-srpel-stg.apps.cic.gc.ca",
      heartbeatEndpoint: "https://prson-srpel-stg.apps.cic.gc.ca/app-mode.json",
      region: "ca-central-1",
    },
  ],
  aws_user_files_s3_bucket: "irccprson212847-stg",
  aws_user_files_s3_bucket_region: "ca-central-1",
  storage: sessionStorage,
};

export default awsmobile;
