import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { CasesService } from "../../../core/cases-module/cases.service";
import { Case } from "../../../core/cases-module/models/case.model";
import { Observable, forkJoin, from } from "rxjs";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";
@Injectable({
  providedIn: "root",
})
export class IntakeCaseListResolver implements Resolve<Case[]> {
  constructor(
    private casesService: CasesService,
    private lobsService: LobService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Observable<never> {
    return forkJoin([
      this.casesService.getDesignatedEntities(),
      from(this.lobsService.fetchAllLobs()),
    ]);
  }
}
