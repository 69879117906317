import { Component, OnInit } from "@angular/core";
import { Barcode2D } from "../../barcodes/barcodes.model";
import { Router } from "@angular/router";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { BarcodesService } from "../../barcodes/barcodes.service";
import * as fromApp from "../../../../app/store/app.reducer";
import { DocumentService } from "../../../core/services/document/document.service";
import { Store } from "@ngrx/store";
import { documentTypes } from "lib";
import { first } from "rxjs/operators";
import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";
import { intakeSelectedApplication } from "../../store/intake-state";

@Component({
  selector: "prcw-imm0008-barcodes-page",
  templateUrl: "./IMM0008-barcodes-page.component.html",
  styleUrls: ["./IMM0008-barcodes-page.component.scss"],
})
export class IMM0008BarcodesPageComponent implements OnInit {
  lang: string;

  public barcodesArray: Barcode2D[] = [];
  public applicationId: string;
  public isLoading: boolean = true;
  private case: Case;
  private imm0008DocId: any;
  private imm0008DocumentTypeId = documentTypes.imm0008.id;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    public router: Router,
    private barcodesService: BarcodesService,
    private documentService: DocumentService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit() {
    this.applicationId = "0001";
    this.getStoredCase();
    this.generateBarcodes();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  private getStoredCase() {
    this.store
      .select(intakeSelectedApplication)
      .pipe(first())
      .subscribe((data: any) => {
        const selectedApplication = data;
        if (selectedApplication.id !== "") {
          this.case = { ...selectedApplication };
          this.imm0008DocId = selectedApplication?.documents.filter(
            (doc: any) => doc.documentTypeId === this.imm0008DocumentTypeId
          )[0].id;
        }
      });
  }

  /* Fetch, create, order and format barcode data, then create a delimited string*/
  public async generateBarcodes() {
    const formData = await this.documentService.getFormByDocumentId(
      this.case.id,
      this.imm0008DocId
    );
    const barcodes = this.barcodesService.generateIMM008Barcodes(
      formData,
      this.case
    );
    this.barcodesArray = barcodes;
    this.isLoading = false;
  }
}
