import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import RoutePathsEn from "../../../lib/route-paths/en.json";
import RoutePathsFr from "../../../lib/route-paths/fr.json";
import { FormAutoCompleteOption } from "../../models/form-autocomplete.model";
import { DisplayedCase, Offices } from "../../models/case.model";
import { LovService } from "projects/lib/src/public-api";

@Component({
  selector: "lib-cases-table",
  templateUrl: "./cases-table-lib.component.html",
  styleUrls: ["./cases-table-lib.component.scss"],
})
export class CasesTableLibComponent {
  @Input() cases: any[] = [];
  @Input() currentLang: string;
  @Input() public caption: string;
  @Input() public noResults: string;

  // different case pill statuse labels for applicant vs caseworker
  @Input() public applicant: boolean;
  @Input() public review: string;
  @Input() public intiated: string;
  @Input() public revision: string;
  @Input() public closed: string;
  @Input() public assignToList?: FormAutoCompleteOption[];
  @Input() public assignToFormGroup?: FormGroup;
  @Input() public user: any;
  @Input() public singleCaseworkerOption?: FormAutoCompleteOption;
  @Output() public emitAssignedUser = new EventEmitter<any>();
  @Input() public isLoadingCases = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private lovService: LovService
  ) {}

  public checkApplicantStatus(caseStatusId: number): string {
    switch (caseStatusId) {
      case 2:
        return "review";
      case 3:
        return "revision";
      case 4:
        return "accepted";
      default:
        return "initiated";
    }
  }

  public getPillStatusLabel(caseStatus: number): string {
    switch (caseStatus) {
      case 2:
        return this.review;
      case 3:
        return this.revision;
      case 4:
        return this.closed;
      default:
        return this.intiated;
    }
  }
  public getOfficeLabel(offices: Offices): string {
    if (offices) {
      return this.currentLang === "fr" ? offices.officeFr : offices.officeEn;
    }
    return this.currentLang === "fr" ? "Ne s’applique pas" : "Not Applicable";
  }

  public navigateToCase(caseId: string): void {
    const routeName = `${
      this.currentLang === "fr"
        ? RoutePathsFr.CONFIRMATION_CASE_DETAILS
        : RoutePathsEn.CONFIRMATION_CASE_DETAILS
    }`;
    this.router.navigate([`${routeName}/${caseId}`], {
      relativeTo: this.route,
    });
  }

  public assignUser(assignTo: any, caseToAssign: DisplayedCase) {
    this.emitAssignedUser.emit({ assignTo, caseToAssign });
  }
}
