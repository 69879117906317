import { APP_INITIALIZER, NgModule } from "@angular/core";
/* NgRx & state */
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";
import {
  AxiosConfigFactory,
  Cloud9TunnelInterceptorService,
} from "./shared/interceptors/cloud9-tunnel.interceptor";
import { BrowserModule, Title } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { LibModule, NgIdleStorage } from "lib";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import {
  renewalReducers,
  renewalStateKey,
} from "@pr-caseworker/app/renewal-module/store/renewal-state";

/* components */
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./routing/app-routing.module";
import { AuthEffects } from "./core/auth-module/store/auth.effects";
import { AuthModule } from "./core/auth-module/auth.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CaseEffects } from "./confirmation-module/store/cases.effects";
import { CasesModule } from "./core/cases-module/cases.module";
import { ConfirmationModule } from "./confirmation-module/confirmation.module";
import { EffectsModule } from "@ngrx/effects";
import { HeaderComponent } from "./core/header/header.component";
import { IntakeCasesModule } from "./intake-module/intake.module";
import { LangGuard } from "../../../lib/src/lib/services/auth-service/lang.guard";
import { LocalStorage } from "@ng-idle/core";
import { LocalizedAppComponent } from "./core/localized-app/localized-app.component";
import { LoggerModule } from "ngx-logger";
import { NavBarComponent } from "./core/navbar/navbar.component";
import { NavigationGuard } from "./routing/guards/nav.guard";
/* Ng idle */
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { NgIdleLogoutComponent } from "./shared/ng-idle/ng-idle-logout.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PhotoModule } from "./confirmation-module/photo-module/photo.module";
import { RenewalEffects } from "@pr-caseworker/app/renewal-module/store/effects/renewal.effects";
import { RouteLocalizerService } from "./routing/route-localizer.service";
/* feature modules */
import { SharedModule } from "./shared/shared.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { localStorageSync } from "ngrx-store-localstorage";
import { reducers } from "./store/app.reducer";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function sessionStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["auth"],
    rehydrate: true,
    removeOnUndefined: true,
    storage: sessionStorage,
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [sessionStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LocalizedAppComponent,
    NgIdleLogoutComponent,
    NavBarComponent,
  ],
  imports: [
    AuthModule,
    AppRoutingModule,
    BrowserModule,
    EffectsModule.forRoot([AuthEffects, CaseEffects]),
    EffectsModule.forFeature([RenewalEffects]),
    CasesModule,
    IntakeCasesModule,
    ConfirmationModule,
    HttpClientModule,
    LibModule,
    FormsModule,
    PhotoModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forFeature(renewalStateKey, renewalReducers),
    SharedModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
      isolate: false,
    }),
    NgIdleKeepaliveModule.forRoot(),
    NgbModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot(environment.configLog),
  ],
  providers: [
    LangGuard,
    RouteLocalizerService,
    Title,
    NavigationGuard,
    { provide: LocalStorage, useClass: NgIdleStorage },
    {
      provide: APP_INITIALIZER,
      useFactory: AxiosConfigFactory,
      deps: [Cloud9TunnelInterceptorService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
