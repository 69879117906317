import { Action } from "@ngrx/store";

export const SET_LOB_FILTER_VALUE = "SET_LOB_FILTER_VALUE";
export const SET_STATUS_FILTER_VALUE = "SET_STATUS_FILTER_VALUE";
export const SET_SORT_VALUE = "SET_SORT_VALUE";
export const SET_CLASS_FILTER_VALUE = "SET_CLASS_FILTER_VALUE";
export const SET_SUB_CLASS_FILTER_VALUE = "SET_SUB_CLASS_FILTER_VALUE";
export const SET_PAGE_TO_REVIEW = "SET_PAGE_TO_REVIEW";
export const SET_ASSIGNED_TO_ME = "SET_ASSIGNED_TO_ME";
export const SET_NO_APPLICATION_NUMBER = "SET_NO_APPLICATION_NUMBER";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
export const RESET_ALL_FILTERS_AND_SORTING = "RESET_ALL_FILTERS_AND_SORTING";
export const SET_ASSIGNED_FILTER_VALUE = "SET_ASSIGNED_FILTER_VALUE";
export const SET_LOB_FILTER_AND_CLASS_FILTER_VALUE =
  "SET_LOB_FILTER_AND_CLASS_FILTER_VALUE";

export class SetLobFilterValue implements Action {
  readonly type = SET_LOB_FILTER_VALUE;
  constructor(public lobFilterValue: string | null) {}
}

export class SetStatusFilterValue implements Action {
  readonly type = SET_STATUS_FILTER_VALUE;
  constructor(public statusFilterValue: number | null) {}
}

export class SetLobFilterAndClassFilterValue implements Action {
  readonly type = SET_LOB_FILTER_AND_CLASS_FILTER_VALUE;
  constructor(public lobFilterValue: any, public classFilterValue: any) {}
}

export class SetClassFilterValue implements Action {
  readonly type = SET_CLASS_FILTER_VALUE;
  constructor(public classFilterValue: boolean | null) {}
}
export class SetSubClassFilterValue implements Action {
  readonly type = SET_SUB_CLASS_FILTER_VALUE;
  constructor(public subClassFilterValue: boolean | null) {}
}
export class SetAssignedFilterValue implements Action {
  readonly type = SET_ASSIGNED_FILTER_VALUE;
  constructor(public assignedFilterValue: boolean | null) {}
}

export class SetPageToReview implements Action {
  readonly type = SET_PAGE_TO_REVIEW;
  constructor(public pageToReview: number) {}
}

export class SetSortValue implements Action {
  readonly type = SET_SORT_VALUE;
  constructor(public sortOrder: string) {}
}

export class SetAssignedToMe implements Action {
  readonly type = SET_ASSIGNED_TO_ME;
  constructor(public assignedToMe: boolean) {}
}

export class SetNoApplicationNumber implements Action {
  readonly type = SET_NO_APPLICATION_NUMBER;
  constructor(public noApplicationNumber: boolean) {}
}

export class SetSearchField implements Action {
  readonly type = SET_SEARCH_TERM;
  constructor(public searchTerms: any) {}
}

export class ResetAllFilters implements Action {
  readonly type = RESET_ALL_FILTERS;
  constructor(public sortOrder: any) {}
}

export class ResetAllFiltersAndSorting implements Action {
  readonly type = RESET_ALL_FILTERS_AND_SORTING;
  constructor() {}
}

export type IntakeSearchActions =
  | SetLobFilterValue
  | SetStatusFilterValue
  | SetLobFilterAndClassFilterValue
  | SetClassFilterValue
  | SetSubClassFilterValue
  | SetAssignedFilterValue
  | SetPageToReview
  | SetSortValue
  | SetAssignedToMe
  | SetNoApplicationNumber
  | SetSearchField
  | ResetAllFilters
  | ResetAllFiltersAndSorting;
