export const casePhases = {
  intake: {
    id: 1,
  },
  confirmation: {
    id: 2,
  },
  renewals: {
    id: 3,
  },
};

export const caseStatuses = {
  confirmation: {
    initiated: {
      id: 1,
      name: "initiated",
    },
    review: {
      id: 2,
      name: "review",
    },
    revision: {
      id: 3,
      name: "revision",
    },
    accepted: {
      id: 4,
      name: "accepted",
    },
    rejected: {
      id: 5,
      name: "rejected",
    },
  },
  // note: id's 11,12,13,14 are internal status'
  intake: {
    initiated: {
      id: 6,
      name: "initiated",
    },
    submitted: {
      id: 7,
      name: "submitted",
    },
    incomplete: {
      id: 8,
      name: "incomplete",
    },
    withdrawn: {
      id: 9,
      name: "withdrawn",
    },
    complete: {
      id: 10,
      name: "complete",
    },
    rpaUploadFailed: {
      id: 11,
      name: "rpa_upload_failed",
    },
    readyForUpload: {
      id: 12,
      name: "ready_for_support_doc_upload",
    },
    docUploadFailed: {
      id: 13,
      name: "supporting_doc_upload_failed",
    },
    readyForCheck: {
      id: 14,
      name: "ready_for_check",
    },
    expired: {
      id: 21,
      name: "expired",
    },
  },
  renewals: {
    initiated: {
      id: 15,
      name: "initiated",
    },
    submitted: {
      id: 16,
      name: "submitted",
    },
    incomplete: {
      id: 17,
      name: "incomplete",
    },
    // <--- internal status' START --->
    ReadyForUpload: {
      id: 18,
      name: "Ready for Upload",
    },
    RPAFailed: {
      id: 19,
      name: "RPA Failed",
    },
    // <--- internal status' END --->
    complete: {
      id: 20,
      name: "complete",
    },
  },
};

export const enableViewButton = [
  caseStatuses.intake.initiated.id,
  caseStatuses.intake.incomplete.id,
  caseStatuses.renewals.initiated.id,
  caseStatuses.renewals.incomplete.id,
  caseStatuses.confirmation.accepted.id,
  caseStatuses.confirmation.initiated.id,
  caseStatuses.confirmation.rejected.id,
  caseStatuses.confirmation.review.id,
  caseStatuses.confirmation.revision.id,
];

export const disableViewButton = [
  caseStatuses.intake.submitted.id,
  caseStatuses.intake.complete.id,
  // internal status'
  caseStatuses.intake.rpaUploadFailed.id,
  caseStatuses.intake.readyForUpload.id,
  caseStatuses.intake.docUploadFailed.id,
  caseStatuses.intake.readyForCheck.id,
  caseStatuses.renewals.submitted.id,
  caseStatuses.renewals.ReadyForUpload.id,
  caseStatuses.renewals.RPAFailed.id,
  caseStatuses.renewals.complete.id,
];

export const enableDeleteButton = [caseStatuses.intake.initiated.id];

export const showDeleteButton = [
  caseStatuses.intake.initiated.id,
  caseStatuses.intake.incomplete.id,
  caseStatuses.intake.submitted.id,
  caseStatuses.intake.complete.id,
  // internal status'
  caseStatuses.intake.rpaUploadFailed.id,
  caseStatuses.intake.readyForUpload.id,
  caseStatuses.intake.docUploadFailed.id,
  caseStatuses.intake.readyForCheck.id,
];

export const showExpiredText = [caseStatuses.intake.expired.id];
