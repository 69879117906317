import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LibModule } from "lib";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "./services/auth.service";
import { ValidationService } from "lib";

import { RouterModule } from "@angular/router";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { ResetComponent } from "./components/reset/reset.component";
import { LoginComponent } from "./components/login/login.component";
import { FirstResetPageComponent } from "./pages/first-login-reset-page/first-reset-page.component";
import { ResetPasswordPageComponent } from "./pages/reset-password-page/reset-password-page.component";
import { ForgotPasswordPageComponent } from "./pages/forgot-password-page/forgot-password-page.component";
import { MultiFactorCodeComponent } from "./components/multi-factor-code/multi-factor-code.component";
import { SharedModule } from "../../shared/shared.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoginPageComponent,
    LoginComponent,
    ResetComponent,
    FirstResetPageComponent,
    ResetPasswordPageComponent,
    ForgotPasswordPageComponent,
    MultiFactorCodeComponent,
  ],
  providers: [AuthService, ValidationService],
  imports: [
    CommonModule,
    LibModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
    SharedModule,
  ],
})
export class AuthModule {}
