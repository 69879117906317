<ng-container *ngIf="alerts.length > 0">
  <div
    *ngFor="let alert of alerts; index as i"
    id="{{ 'alert__container-' + i }}"
    class="alert__card"
    [class]="cssClass('alert__card--', alert.type)"
    role="alertdialog"
    aria-live="assertive"
    [attr.aria-label]="alertTypeName(alert.type)"
    [attr.aria-describedby]="'alert__message-' + i"
    tabindex="0"
  >
    <div class="alert__info">
      <div class="alert__heading">
        <div
          class="alert__icon"
          [class]="cssClass('alert__icon--', alert.type)"
        >
          <lib-icon-chooser icon="{{ alert.type }}"></lib-icon-chooser>
        </div>
        <h5 class="alert__title emphasis">{{ alertTypeName(alert.type) }}</h5>
      </div>
      <button
        class="alert__btn"
        category="plain"
        size="small"
        (click)="closeAlert(alert)"
        id="{{ 'close-container-' + i }}"
        [attr.aria-label]="closeText"
      >
        <lib-icon-chooser icon="close"></lib-icon-chooser>
      </button>
    </div>
    <div class="alert__body">
      <div class="alert__border-container">
        <div
          class="alert__border"
          [class]="cssClass('alert__border--', alert.type)"
        ></div>
      </div>
      <p id="{{ 'alert__message-' + i }}" class="alert__text">
        {{ alert.message }}
      </p>
    </div>
  </div>
</ng-container>
