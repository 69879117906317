import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { State } from "@pr-applicant/app/store/app.reducer";

export const prcPrtdRnewalKey = "prcPrtdRenewal";

export interface prcPrtdApplicationsState {
  list: Array<any>;
  isLoaded: boolean;
  isLoading: boolean;
  hasError: boolean;
}
export interface SelectedPermanentResidentCardState {
  prcData: Case | null;
  isLoaded: boolean;
  isLoading: boolean;
  hasError?: boolean;
  error?: string;
}

export interface SelectedPermanentResidentTravelDocumentState {
  prtdData: Case | null;
  isLoaded: boolean;
  isLoading: boolean;
  hasError?: boolean;
  error?: string;
}

export interface PrcPrtdState {
  prcPrtdApplications: prcPrtdApplicationsState;
  selectedPRC: SelectedPermanentResidentCardState;
  selectedPRTD: SelectedPermanentResidentTravelDocumentState;
}

export interface AppPrcPrtdState extends State {
  prcPrtdRnewalKey: PrcPrtdState;
}

/// selectors
export const prcPrtdRenewalStateSelector =
  createFeatureSelector<PrcPrtdState>("prcPrtdRenewal");

export const renewalApplicationsSelector = createSelector(
  prcPrtdRenewalStateSelector,
  (state: PrcPrtdState) => state.prcPrtdApplications
);

export const selectedPRCState = createSelector(
  prcPrtdRenewalStateSelector,
  (state: PrcPrtdState) => state?.selectedPRC
);

export const selectedPRCard = createSelector(
  selectedPRCState,
  (state: SelectedPermanentResidentCardState) => state?.prcData
);

export const getSelectedPRTDState = createSelector(
  prcPrtdRenewalStateSelector,
  (state: PrcPrtdState) => state?.selectedPRTD
);

export const selectedPRtravelDocument = createSelector(
  getSelectedPRTDState,
  (state: SelectedPermanentResidentTravelDocumentState) => state?.prtdData
);
