import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "safeTrim" })
export class SafeTrimPipe implements PipeTransform {
  transform(value: any): any {
    if (value === null || value === undefined) return "";
    if (typeof value === "string") return value.trim();

    return value;
  }
}
