import { WebformFlattened } from "../webform-view-table.model";
import { imm0008PAConfigs } from "./imm0008-pa-configs";
import { getImm0008DependantConfigs } from "./imm0008-dep-configs";
import { DOC_LIMITS } from "lib";

// This config file is a function that can accept the number of dependants available
// and generate the proper number of dependants configs
export function IMM0008Configs(depNumber: number = -1): WebformFlattened {
  // Start initially with PA table
  const configTables = [imm0008PAConfigs];

  // Based on the number of dependants passed to the function,
  // add the dependant table config to the array up to a max
  // of 5 dependants
  for (let index = 0; index < depNumber; index++) {
    configTables.push(getImm0008DependantConfigs(index));
    if (index === DOC_LIMITS.IMM0008.VIEW_DEPENDANTS_BARCODE_LIMIT - 1) {
      break;
    }
  }

  return {
    metaData: {
      form: "imm0008",
      documentTypeId: 15,
      pageTitle: "INTAKE.IMM0008.PAGES.MAIN_HEADING",
      // Set to true if the form has multiple pages (ex: immxxxx/barcodes and immxxxx/form-data) to set the proper navigation buttons
      multiPageForm: true,
      prevPageKey: "WEBFORM_BARCODES",
      nextPageKey: depNumber > 5 ? "IMM0008_ADDITIONAL_DEPENDANTS" : undefined,
    },
    tables: configTables,
  };
}
