import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AlertService } from "../../services/alert/alert.service";
import { Alert } from "./alert.model";

@Component({
  selector: "lib-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  public alerts: Alert[] = [];
  public alertSub: Subscription;
  public alertFocus: number;

  constructor(private alertService: AlertService, private router: Router) {}

  ngOnInit(): void {
    this.alertSub = this.alertService.onAlert().subscribe((alert: Alert) => {
      if (!alert) {
        this.alerts = []; // clear alerts when an empty alert is received
      } else {
        // Set a small timeout before the alert appears to allow the screenreader
        // to pick it up when switching pages
        setTimeout(() => {
          this.alerts.push(alert); // add alert to array
          // Set alert focus on last item (Alert)
          this.setAlertFocus(this.alerts.length - 1);
        }, 250);
      }
    });
  }

  /* PUBLIC -------------------------------------------- */

  public get closeText(): string {
    return this.currentLang === "fr" ? "Fermer" : "Close";
  }

  public alertTypeName(alertType: string): string {
    // TODO: if/when RouteLocalizer gets extracted from the apps and moved into lib, use it here
    const name: any = {
      danger: {
        en: "error",
        fr: "erreur",
      },
      success: {
        en: "success",
        fr: "succès",
      },
      warning: {
        en: "warning",
        fr: "attention",
      },
      info: {
        en: "information",
        fr: "information",
      },
    };
    return name[alertType][this.currentLang];
  }

  public closeAlert(alert: Alert): void {
    if (!this.alerts.includes(alert)) {
      return;
    }
    this.alerts = this.alerts.filter((x) => x !== alert);
  }

  public cssClass(prefix: string, alertType: string): string {
    return `${prefix}${alertType}`;
  }

  public setAlertFocus(alertIndex: number) {
    this.alertFocus = alertIndex;
    setTimeout(() => {
      document.getElementById(`alert-container-${alertIndex}`)?.focus();
    }, 550);
  }

  /* PRIVATE -------------------------------------------- */

  private get currentLang(): string {
    return this.router.url.split("/")[1];
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(e: KeyboardEvent): void {
    const firstItem = document.getElementById(
      `alert-container-${this.alertFocus}`
    );
    const closeButton = document.getElementById(
      `close-container-${this.alertFocus}`
    );

    /* trap focus in alert */
    const tabOnly = (e.key === "Tab" || e.code === "9") && !e.shiftKey;
    const shiftTab = (e.key === "Tab" || e.code === "9") && e.shiftKey;
    if (shiftTab && e.target === firstItem) {
      e.preventDefault();
      closeButton?.focus();
    }
    if (tabOnly && e.target === closeButton) {
      e.preventDefault();
      firstItem?.focus();
    }

    // the first to pop up should be the first to close
    if (e.key === "Escape" && this.alerts[this.alertFocus] && firstItem) {
      this.closeAlert(this.alerts[this.alertFocus]);
    }
  }
}
