export enum PropertyType {
  Primitive,
  Date,
  Object,
  Array, // array of objects. Note: array of primitives is not supported
}

export enum DateConversionOption {
  Year = "Y",
  Month = "M",
  Day = "D",
}

export interface DataRetrievalItem {
  index?: number; // index in the array
  type: PropertyType;
  sourceField: string;
  subfield?: DataRetrievalItem; // for Object and Array
  dateConversionOption?: DateConversionOption; // for Date
}
