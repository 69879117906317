import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../core/auth-module/store/auth.actions";
import { AuthService } from "../../core/auth-module/services/auth.service";
import { ModalService } from "lib";
import { RouteLocalizerService } from "../../routing/route-localizer.service";
import { combineLatest, Subscription } from "rxjs";

@Component({
  selector: "prcw-ng-idle-logout",
  templateUrl: "./ng-idle-logout.component.html",
  styleUrls: ["./ng-idle-logout.component.scss"],
})
export class NgIdleLogoutComponent implements OnInit, OnDestroy {
  public lang: string;

  // NG IDLE
  public idleCountdown: string;
  public timedOut = false;
  private lastPing?: Date | null = null;
  public idleCountdownSeconds = 0;
  public idleCountdownMinutes = 0;
  private subs: Subscription[] = [];

  // Modals
  public sessionWithTimeLabel: string;
  public timeoutWarningModalId = "timeoutWarningModal";
  public sessionExpiredModalId = "sessionExpiredModal";

  constructor(
    private translate: TranslateService,
    private routeLocalizer: RouteLocalizerService,
    private idle: Idle,
    private store: Store<fromApp.State>,
    private keepalive: Keepalive,
    private authService: AuthService,
    private modalService: ModalService
  ) {
    // Idle after 30 minutes of inactivity
    idle.setIdle(60 * 30);

    // Warning to be displayed for 1 minute 30 seconds
    idle.setTimeout(90);

    // Sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // End idle
    this.subs.push(
      idle.onIdleEnd.subscribe(() => {
        this.reset();
      })
    );

    // On start of idle - show the warning modal
    this.subs.push(
      idle.onIdleStart.subscribe(() => {
        this.modalService.open(this.timeoutWarningModalId);
        this.idle.clearInterrupts();
      })
    );

    // Display the number of seconds left in the warning modal

    this.subs.push(
      idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleCountdownSeconds = countdown % 60;
        this.idleCountdownMinutes = Math.floor(countdown / 60);
        this.sessionWithTimeLabel = this.translate.instant(
          "IDLE.MODAL_BODY_TIMER",
          {
            minutes: this.idleCountdownMinutes.toString(),
            seconds: this.idleCountdownSeconds.toString(),
          }
        );
      })
    );

    // On official time out - log the user out
    this.subs.push(
      idle.onTimeout.subscribe(() => {
        this.timedOut = true;
        this.modalService.close(this.timeoutWarningModalId);
        this.modalService.open(this.sessionExpiredModalId);
        this.authService.logout().then(() => {
          this.store.dispatch(
            new AuthActions.Logout({
              successLogout: true,
              user: null,
            })
          );
        });
      })
    );

    // Ng-idle - sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    //  Only when authenticated we are leveraging ng-idle
    this.subs.push(
      this.store.select("auth").subscribe((auth) => {
        if (auth.user) {
          idle.watch();
          this.timedOut = false;
        } else {
          idle.stop();
        }
      })
    );
  }

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // continues the same session, does not log the user out
  public continueSession(): void {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.modalService.close(this.timeoutWarningModalId);
    this.reset();
  }

  // logs the user out and sends them to the login page
  public logout(): void {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.modalService.close(this.timeoutWarningModalId);
    this.authService.logout().then(() => {
      this.store.dispatch(
        new AuthActions.Logout({
          successLogout: true,
          user: null,
        })
      );
      this.navigateToLogin();
    });
  }

  // navigates the the login page
  public navigateToLogin(): void {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.modalService.close(this.sessionExpiredModalId);
  }

  public handleEscapedModal(): void {
    if (!this.timedOut) {
      this.continueSession();
    } else {
      this.logout();
    }
  }

  // resets the time
  private reset(): void {
    this.idle.watch();
    this.timedOut = false;
  }
}
