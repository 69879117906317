<form class="search-form form-control" [formGroup]="filterForm">
  <div class="upper">
    <lib-form-select
      identifier="caseStatusId"
      [formControl]="filterForm.controls.caseStatusId"
      [label]="
        'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CONFIRMATION_STATUS_SEARCH'
          | translate
      "
      [options]="statuses"
      class="status-search"
    ></lib-form-select>

    <lib-form-select
      identifier="officeId"
      [formControl]="filterForm.controls.officeId"
      [label]="'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.OFFICE_SEARCH' | translate"
      [options]="offices"
      class="office-search"
    ></lib-form-select>

    <lib-form-select
      identifier="isAssigned"
      [formControl]="filterForm.controls.isAssigned"
      [label]="
        'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CONFIRMATION_ASSIGNED_SEARCH'
          | translate
      "
      [options]="assignementStatuses"
      class="status-search"
    ></lib-form-select>

    <lib-form-autocomplete
      identifier="cognitoCaseWorkerId"
      class="search-form__input"
      [label]="
        'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CASEWORKER_EMAIL' | translate
      "
      [options]="caseworkerOptions"
      [formControl]="filterForm.controls.cognitoCaseWorkerId"
      [placeholder]="
        'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CASEWORKER_EMAIL' | translate
      "
      [showChevron]="false"
      [takeOnly]="20"
      [preventAutoselect]="true"
    >
    </lib-form-autocomplete>

    <lib-form-input
      identifier="searchTerm"
      [formControl]="searchForm.controls.searchTerm"
      [label]="
        'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CONFIRMATION_SEARCH' | translate
      "
      [placeholder]="
        'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.EMAIL_SEARCH' | translate
      "
      class="search-form__input"
    ></lib-form-input>
    <button
      type="submit"
      class="btn btn-primary-alt search-submit"
      (click)="submitSearchForm()"
    >
      {{ "CASELIST.TABLE_HEADINGS.SEARCH" | translate }}
    </button>
    <button
      (click)="clearFilters()"
      category="plain"
      class="clear-all"
      id="clearAll"
    >
      {{ "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CLEAR_ALL" | translate }}
      <span class="visually-hidden">{{
        "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CLEAR_ALL_CONTEXT" | translate
      }}</span>
    </button>
  </div>
  <div class="lower">
    <lib-form-checkbox
      identifier="assignedToMe"
      [description]="
        'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.ASSIGNED_TO_ME' | translate
      "
      [formControl]="filterForm.controls.assignedToMe"
      [isRequired]="false"
    ></lib-form-checkbox>
  </div>
</form>
