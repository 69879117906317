import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  constructor() {}

  mapFilters = (
    urlParams: URLSearchParams,
    filters: object,
    prependKey?: string
  ) =>
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null) {
        const actualKey = prependKey ? `${prependKey}[${key}]` : key;
        if (typeof value === "object") {
          //  That's right! Recursion.
          this.mapFilters(urlParams, value, actualKey);
        } else {
          urlParams.append(`${actualKey}`, `${value}`);
        }
      }
    });
}
