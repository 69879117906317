import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  AfterContentInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { FormAutoCompleteOption } from "../../models/form-autocomplete.model";
import { ValidationService } from "../../services/validation-service/validation.service";
import { Subscription } from "rxjs";

@Component({
  selector: "lib-form-autocomplete",
  templateUrl: "./form-autocomplete.component.html",
})
export class FormAutocompleteComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() identifier: string;
  @Input() label = "";
  @Input() placeholder = "";
  @Input() options: FormAutoCompleteOption[];
  @Input() required = false;
  @Input() hideRequired = false;
  @Input() showChevron = true;
  @Input() showSearch = false;
  @Input() showLabel = true;
  @Input() takeOnly = 2000;
  @Input() helpText?: string;
  @Input() readOnly: boolean = false;
  @Input() sortFilteredOptions?: (
    selected: string | null,
    options: FormAutoCompleteOption[]
  ) => FormAutoCompleteOption[];
  @Input() public preventAutoselect = false;
  @Output() optionSelected = new EventEmitter<FormAutoCompleteOption | null>();

  // adds class to component conditionally based on width attr
  @HostBinding("class.select-sm") @Input() widthSm?: boolean;
  @HostBinding("class.select-md") @Input() widthMd?: boolean;

  @ViewChild(MatAutocomplete, { static: true }) auto: MatAutocomplete;

  id: string;
  value: FormAutoCompleteOption | null = null;
  hasNoMatch = false;
  disabled?: boolean;
  filteredOptions: FormAutoCompleteOption[];
  private activatedOption: any;
  private originalValue: any;
  private originalText: string;
  private needUpdateOptions = true;
  private subs: Subscription[] = [];
  private ctrlSubsc: Subscription;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private router: Router,
    private translate: TranslateService,
    public validationService: ValidationService
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.id = this.identifier.replace(/\s/g, "_");
  }

  public get showEditableInput() {
    return !this.readOnly;
  }

  buildControlSubscription(): void {
    if (this.ctrlSubsc) {
      return;
    }
    if (this.ngControl.control) {
      this.filteredOptions = this.options.slice(0, this.takeOnly);
      this.ctrlSubsc = this.ngControl.control.valueChanges.subscribe(
        (filterBy: any) => {
          if (!this.needUpdateOptions) {
            this.needUpdateOptions = true;
            return;
          }
          if (typeof filterBy === "string") {
            if (!filterBy) {
              this.filteredOptions = this.options.slice(0, this.takeOnly);
            } else {
              this.filteredOptions = this.getFilteredOptions(filterBy);
            }
          } else if (typeof filterBy === "object") {
            if (filterBy?.text) {
              this.filteredOptions = this.getFilteredOptions(filterBy?.text);
            } else if (filterBy && "value" in filterBy) {
              const filteredList = this.options
                ? this.options.filter((op) => op.value === filterBy.value)
                : [];
              if (filteredList.length > 0) {
                this.ngControl.control?.setValue(filteredList[0]);
              }
              if (filterBy.value === null) {
                this.filteredOptions = this.options.slice(0, this.takeOnly);
              } else {
                this.filteredOptions = filteredList;
              }
            }
          } else {
            this.filteredOptions = this.options.slice(0, this.takeOnly);
          }
          if (this.filteredOptions.length > 0) {
            if (this.sortFilteredOptions) {
              this.filteredOptions = this.sortFilteredOptions(
                this.value ? this.value.value : null,
                this.filteredOptions.slice()
              );
            }
            if (!this.preventAutoselect) {
              this.onOptionActivated({
                option: { value: this.filteredOptions[0] },
              });
              this.hasNoMatch = false;
            }
          } else {
            this.onOptionActivated({ option: { value: null } });
            this.hasNoMatch = true;
          }
        }
      );
      this.subs.push(this.ctrlSubsc);
    }
  }
  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public get lang(): "en" | "fr" {
    return this.router.url?.split("/")[1] as "en" | "fr";
  }

  public get requiredText(): string {
    return this.lang === "fr" ? "requis" : "required";
  }

  onChange(e: any) {}

  onTouched(e: any) {}

  onOptionSelected(event: any) {
    const updated = this.originalValue !== event.option.value.value;
    this.value = event.option.value;
    this.onOptionActivated(event);
    if (updated) {
      this.optionSelected.emit(this.value);
      this.originalValue = this.value?.value;
    }
  }

  onOptionActivated(event: any) {
    if (event.option === null) {
      this.activatedOption = this.ngControl.control?.value;
    } else {
      this.activatedOption = event.option?.value;
    }
  }

  onBlur() {
    if (!this.activatedOption) {
      this.activatedOption = { text: "", value: null };
    }
    if (this.filteredOptions.length === 0) {
      this.needUpdateOptions = false;
      // revert to the original value if there is no match
      this.activatedOption = {
        text: this.originalText,
        value: this.originalValue,
      };
      this.ngControl.control?.setValue(this.activatedOption);
    }
    if (this.originalValue !== this.activatedOption.value) {
      this.needUpdateOptions = false;
      this.ngControl.control?.setValue(this.activatedOption);
      this.optionSelected.emit(this.value);
      this.originalValue = this.value?.value;
    }
    this.hasNoMatch = false;
  }

  onFocus(event: any) {
    this.buildControlSubscription();
    this.originalValue = this.value?.value;
    this.originalText = this.value?.text || "";
    this.activatedOption = { value: this.value?.value };
    if (this.sortFilteredOptions) {
      this.filteredOptions = this.sortFilteredOptions(
        this.value ? this.value.value : null,
        this.options.slice()
      );
    } else {
      this.filteredOptions = this.options.slice();
    }
    if (event?.target?.select) {
      event.target.select();
    }
  }

  getDisplayedLabel(op: FormAutoCompleteOption) {
    if (op?.text) {
      return this.translate.instant(op.text);
    }
    return "";
  }

  registerOnChange(fn: any): void {
    // required
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // required
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    // required
    this.value = value;
  }

  private getFilteredOptions(filterBy: string): FormAutoCompleteOption[] {
    const filterByLowerCase = filterBy.toLowerCase();
    const exactMatch = this.options?.findIndex((op) => {
      if (op.text) {
        return this.getDisplayedLabel(op).toLowerCase() === filterByLowerCase;
      }
      return false;
    });
    if (typeof exactMatch === "number" && exactMatch >= 0) {
      const exactMatchCW = filterByLowerCase;
      return [
        {
          text: exactMatchCW,
          value: exactMatchCW,
        },
      ];
    }
    const filtered =
      this.options?.filter((op) => {
        if (op.text) {
          return this.getDisplayedLabel(op)
            .toLowerCase()
            .includes(filterByLowerCase);
        }
        return false;
      }) || [];
    return filtered;
  }

  public trackElemnt(index: number, element: any): any {
    return element?.value ? element.value : index;
  }
}
