<div class="wrapper">
  <div class="form">
    <form
      [formGroup]="resetPasswordForm"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <fieldset>
        <h2 class="emphasis">{{ "RESET.FORM_TITLE" | translate }}</h2>
        <lib-form-input
          *ngIf="!isFirstLogin"
          identifier="verificationCode"
          [formControl]="resetPasswordForm.controls.verificationCode"
          [label]="'RESET.VERIFICATION_INPUT_LABEL' | translate"
          required="true"
        >
          <span
            *ngIf="
              resetPasswordForm.controls.verificationCode.touched &&
              resetPasswordForm.controls.verificationCode.errors
            "
          >
            {{ "RESET.VERIFICATION_CODE_ERROR" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          identifier="password"
          type="password"
          [formControl]="resetPasswordForm.controls.password"
          [label]="'RESET.PASSWORD_INPUT_LABEL' | translate"
          required="true"
        >
          <span
            *ngIf="
              resetPasswordForm.controls.password.touched &&
              resetPasswordForm.controls.password.errors?.required
            "
          >
            {{ "RESET.REQUIRED_PASSWORD" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          identifier="passwordConfirm"
          type="password"
          [formControl]="resetPasswordForm.controls.passwordConfirm"
          [label]="'RESET.CONFIRM_PASSWORD_INPUT_LABEL' | translate"
          required="true"
          [errors]="verifyPasswordErrors"
        >
          <span
            *ngIf="
              resetPasswordForm.controls.passwordConfirm.touched &&
              resetPasswordForm.controls.passwordConfirm.errors?.required
            "
          >
            {{ "RESET.REQUIRED_CONFIRM_PASSWORD" | translate }}
          </span>
          <span
            *ngIf="
              resetPasswordForm.controls.passwordConfirm.touched &&
              resetPasswordForm.controls.passwordConfirm.errors?.matches
            "
          >
            {{ "RESET.MATCHING" | translate }}
          </span>
        </lib-form-input>

        <div>
          <div class="form-validations">
            <ul class="form-validations-list" aria-labelledby="titleMain">
              <li
                class="form-validations-rule"
                aria-live="polite"
                aria-labelledby="titleMinChars"
              >
                <span class="form-validations-blank">
                  <img
                    [ngStyle]="{
                      opacity: resetPasswordForm.controls.password.errors
                        ?.minChars
                        ? '0'
                        : '1'
                    }"
                    class="form-validations-check"
                    src="assets/images/icons/circle-check.svg"
                    alt=""
                    aria-hidden="true"
                  />
                </span>
                <p class="visually-hidden">
                  {{
                    (resetPasswordForm.controls.password.errors?.minChars
                      ? "RESET.RULE_UNMET"
                      : "RESET.RULE_MET"
                    ) | translate
                  }}
                </p>
                <p id="titleMinChars">{{ "RESET.MIN_CHARS" | translate }}</p>
              </li>

              <li
                class="form-validations-rule"
                aria-live="polite"
                aria-labelledby="titleSymbols"
              >
                <span class="form-validations-blank">
                  <img
                    [ngStyle]="{
                      opacity: resetPasswordForm.controls.password.errors
                        ?.symbol
                        ? '0'
                        : '1'
                    }"
                    class="form-validations-check"
                    src="assets/images/icons/circle-check.svg"
                    alt=""
                    aria-hidden="true"
                  />
                </span>
                <p class="visually-hidden">
                  {{
                    (resetPasswordForm.controls.password.errors?.symbol
                      ? "RESET.RULE_UNMET"
                      : "RESET.RULE_MET"
                    ) | translate
                  }}
                </p>
                <p>
                  {{ "RESET.SYMBOLS" | translate }} <br /><span
                    aria-hidden="true"
                    >{{ "RESET.CHARACTERS" | translate }}</span
                  >
                </p>
                <p id="titleSymbols" class="visually-hidden">
                  {{ "RESET.CHARACTERS_ARIA" | translate }}
                </p>
              </li>

              <li
                class="form-validations-rule"
                aria-live="polite"
                aria-labelledby="titleUppercase"
              >
                <span class="form-validations-blank">
                  <img
                    [ngStyle]="{
                      opacity: resetPasswordForm.controls.password.errors
                        ?.uppercase
                        ? '0'
                        : '1'
                    }"
                    class="form-validations-check"
                    src="assets/images/icons/circle-check.svg"
                    alt=""
                    aria-hidden="true"
                  />
                </span>
                <p class="visually-hidden">
                  {{
                    (resetPasswordForm.controls.password.errors?.uppercase
                      ? "RESET.RULE_UNMET"
                      : "RESET.RULE_MET"
                    ) | translate
                  }}
                </p>
                <p id="titleUppercase">{{ "RESET.UPPERCASE" | translate }}</p>
              </li>

              <li
                class="form-validations-rule"
                aria-live="polite"
                aria-labelledby="titleLowercase"
              >
                <span class="form-validations-blank">
                  <img
                    [ngStyle]="{
                      opacity: resetPasswordForm.controls.password.errors
                        ?.lowercase
                        ? '0'
                        : '1'
                    }"
                    class="form-validations-check"
                    src="assets/images/icons/circle-check.svg"
                    alt=""
                    aria-hidden="true"
                  />
                </span>
                <p class="visually-hidden">
                  {{
                    (resetPasswordForm.controls.password.errors?.lowercase
                      ? "RESET.RULE_UNMET"
                      : "RESET.RULE_MET"
                    ) | translate
                  }}
                </p>
                <p id="titleLowercase">{{ "RESET.LOWERCASE" | translate }}</p>
              </li>

              <li
                class="form-validations-rule"
                aria-live="polite"
                aria-labelledby="titleNumbers"
              >
                <span class="form-validations-blank">
                  <img
                    [ngStyle]="{
                      opacity: resetPasswordForm.controls.password.errors
                        ?.numbers
                        ? '0'
                        : '1'
                    }"
                    class="form-validations-check"
                    src="assets/images/icons/circle-check.svg"
                    alt=""
                    aria-hidden="true"
                  />
                </span>
                <p class="visually-hidden">
                  {{
                    (resetPasswordForm.controls.password.errors?.numbers
                      ? "RESET.RULE_UNMET"
                      : "RESET.RULE_MET"
                    ) | translate
                  }}
                </p>
                <p id="titleNumbers">{{ "RESET.NUMBERS" | translate }}</p>
              </li>

              <li
                class="form-validations-rule"
                aria-live="polite"
                aria-labelledby="titleMatch"
              >
                <span class="form-validations-blank">
                  <img
                    [ngStyle]="{
                      opacity: resetPasswordForm.errors?.matches ? '0' : '1'
                    }"
                    class="form-validations-check"
                    src="assets/images/icons/circle-check.svg"
                    alt=""
                    aria-hidden="true"
                  />
                </span>
                <p class="visually-hidden">
                  {{
                    (resetPasswordForm.errors?.matches
                      ? "RESET.RULE_UNMET"
                      : "RESET.RULE_MET"
                    ) | translate
                  }}
                </p>
                <p id="titleMatch">{{ "RESET.MATCHING" | translate }}</p>
              </li>
            </ul>
          </div>
        </div>
      </fieldset>
      <button
        class="form-submit btn btn-primary"
        type="submit"
        attr.aria-disabled="{{ !resetPasswordForm.valid }}"
      >
        {{ "RESET.SUBMIT" | translate }}
      </button>
    </form>
  </div>
</div>
