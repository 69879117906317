import { Component, Input } from "@angular/core";

export type BannerBoxType = "problem";

@Component({
  selector: "lib-banner-box",
  template: `
    <div class="banner-box" class="banner-box--{{ type }}">
      <img
        class="banner-box__icon"
        src="/assets/lib-assets/icons/{{ icons[type] }}.svg"
        aria-hidden="true"
        alt=""
      />
      <div class="banner-box__text">
        <p *ngIf="title" class="banner-box__title">{{ title }}</p>
        <ng-content><!-- banner-box content gets projected here --></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .banner-box {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
        padding: 1rem;
      }
      .banner-box--problem {
        background: #f1e7e6;
        color: #a70609;
      }
      .banner-box__icon {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 16px;
      }
      .banner-box__text {
        font-size: 0.8rem;
      }
      .banner-box__title {
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
      }
    `,
  ],
})
export class BannerBoxComponent {
  @Input() title?: string;
  @Input() type: BannerBoxType;

  public icons: any = {
    problem: "triangle-danger",
  };

  constructor() {}
}
