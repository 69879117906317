import { WebformFlattened } from "../webform-view-table.model";
import { PropertyType } from "../../models/data-retrieval.model";

// This config file is a function that can accept the number of dependants available
// and generate the proper number of dependants configs - if they exists
export function IMM5562Configs(): WebformFlattened {
  return {
    metaData: {
      form: "imm5562",
      documentTypeId: 37,
      pageTitle: "INTAKE.IMM5562.PAGES.PAGE_HEADING",
      // Set to false if the form has only one page (ex: immxxxx/) to set the proper back navigation buttons
      multiPageForm: false,
      hasNextPageButton: false,
    },
    // Having only PA table
    tables: [
      {
        heading: "INTAKE.IMM5562.TABLE_TITLES.PRIMARY_APPLICANT",
        fields: [
          // Section A: Principal applicant (yourself)
          // full name - Family name
          {
            type: PropertyType.Object,
            sourceField: "principalApplicant",
            sourceLabels: {
              formName: "INTAKE.IMM5562.FORMS.SECTION_A.FORM_HEADING",
              fieldsetLegend:
                "INTAKE.IMM5562.FORMS.FIELDS.TRIP.FULL_NAME_LEGEND",
              fieldLabel: "INTAKE.IMM5562.FORMS.FIELDS.TRIP.FAMILY_NAME_LABEL",
            },
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "familyName",
            },
          },
          // full name - Given name
          {
            type: PropertyType.Object,
            sourceField: "principalApplicant",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5562.FORMS.FIELDS.TRIP.GIVEN_NAME_LABEL",
            },
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "givenName",
            },
          },
          // Did you travel checkbox
          {
            type: PropertyType.Object,
            sourceField: "principalApplicant",
            sourceLabels: {
              fieldsetLegend:
                "INTAKE.IMM5562.FORMS.FIELDS.DID_NOT_TRAVEL_CHECKBOX_LABEL",
            },
            complexSourceList: ["yesOrNoCheckboxList"],
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "haveNotTravelled",
            },
          },
          // Trips Add another array
          {
            type: PropertyType.Object,
            sourceField: "principalApplicant",
            complexSubfield: {
              type: PropertyType.Array,
              sourceField: "trips",
              complexSubfield: [
                {
                  // from
                  type: PropertyType.Primitive,
                  sourceField: "from",
                  sourceLabels: {
                    fieldsetLegend: "INTAKE.IMM5562.FORMS.FIELDS.TRIP.LEGEND",
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_FROM_LABEL",
                  },
                },
                {
                  // to
                  type: PropertyType.Primitive,
                  sourceField: "to",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_TO_LABEL",
                  },
                },
                {
                  // destination
                  type: PropertyType.Primitive,
                  sourceField: "destination",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DESTINATION_LABEL",
                  },
                },
                {
                  // length
                  type: PropertyType.Primitive,
                  sourceField: "length",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.TRIP_LENGTH_LABEL",
                  },
                },
                {
                  // purposeOfTravel
                  type: PropertyType.Primitive,
                  sourceField: "purposeOfTravel",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.PURPOSE_LABEL",
                  },
                },
              ],
            },
          },

          // Section B: Your spouse or common-law partner
          // Did you travel checkbox
          {
            type: PropertyType.Object,
            sourceField: "partnerApplicant",
            sourceLabels: {
              formName: "INTAKE.IMM5562.FORMS.SECTION_B.FORM_HEADING",
              fieldsetLegend:
                "INTAKE.IMM5562.FORMS.FIELDS.DID_NOT_TRAVEL_CHECKBOX_LABEL",
            },
            complexSourceList: ["yesOrNoCheckboxList"],
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "haveNotTravelled",
            },
          },
          // Trips Add another array
          {
            type: PropertyType.Object,
            sourceField: "partnerApplicant",
            complexSubfield: {
              type: PropertyType.Array,
              sourceField: "trips",
              complexSubfield: [
                {
                  // from
                  type: PropertyType.Primitive,
                  sourceField: "from",
                  sourceLabels: {
                    fieldsetLegend: "INTAKE.IMM5562.FORMS.FIELDS.TRIP.LEGEND",
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_FROM_LABEL",
                  },
                },
                {
                  // to
                  type: PropertyType.Primitive,
                  sourceField: "to",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_TO_LABEL",
                  },
                },
                {
                  // destination
                  type: PropertyType.Primitive,
                  sourceField: "destination",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DESTINATION_LABEL",
                  },
                },
                {
                  // length
                  type: PropertyType.Primitive,
                  sourceField: "length",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.TRIP_LENGTH_LABEL",
                  },
                },
                {
                  // purposeOfTravel
                  type: PropertyType.Primitive,
                  sourceField: "purposeOfTravel",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.PURPOSE_LABEL",
                  },
                },
              ],
            },
          },
          // Section C: Your dependant child 18 years or older
          // Did you travel checkbox
          {
            type: PropertyType.Object,
            sourceField: "dependantApplicant",
            sourceLabels: {
              formName: "INTAKE.IMM5562.FORMS.SECTION_C.FORM_HEADING",
              fieldsetLegend:
                "INTAKE.IMM5562.FORMS.FIELDS.DID_NOT_TRAVEL_CHECKBOX_LABEL",
            },
            complexSourceList: ["yesOrNoCheckboxList"],
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "haveNotTravelled",
            },
          },
          // Trips Add another array
          {
            type: PropertyType.Object,
            sourceField: "dependantApplicant",
            complexSubfield: {
              type: PropertyType.Array,
              sourceField: "trips",
              complexSubfield: [
                {
                  // givenName
                  type: PropertyType.Primitive,
                  sourceField: "givenName",
                  sourceLabels: {
                    fieldsetLegend: "INTAKE.IMM5562.FORMS.FIELDS.TRIP.LEGEND",
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.GIVEN_NAME_LABEL",
                  },
                },
                {
                  // from
                  type: PropertyType.Primitive,
                  sourceField: "from",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_FROM_LABEL",
                  },
                },
                {
                  // to
                  type: PropertyType.Primitive,
                  sourceField: "to",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_TO_LABEL",
                  },
                },
                {
                  // destination
                  type: PropertyType.Primitive,
                  sourceField: "destination",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.DESTINATION_LABEL",
                  },
                },
                {
                  // length
                  type: PropertyType.Primitive,
                  sourceField: "length",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.TRIP_LENGTH_LABEL",
                  },
                },
                {
                  // purposeOfTravel
                  type: PropertyType.Primitive,
                  sourceField: "purposeOfTravel",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM5562.FORMS.FIELDS.TRIP.PURPOSE_LABEL",
                  },
                },
              ],
            },
          },
        ],
      },
    ],
  };
}
