<lib-modal
  [id]="timeoutWarningModalId"
  [title]="'IDLE.MODAL_TITLE' | translate"
  (escape)="handleEscapedModal()"
  class="modal-body"
>
  <ng-container modalBody>
    <p>{{ "IDLE.MODAL_BODY_PENDING" | translate }}</p>
    <div role="timer" class="modal-timer">{{ sessionWithTimeLabel }}</div>
    <p>{{ "IDLE.MODAL_BODY_PENDING_ACTION" | translate }}</p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" (click)="continueSession()">
      {{ "IDLE.MODAL_BUTTON_CONTINUE" | translate }}
    </button>
    <button category="secondary" (click)="logout()">
      {{ "IDLE.MODAL_BUTTON_END" | translate }}
    </button>
  </ng-container>
</lib-modal>

<lib-modal
  [id]="sessionExpiredModalId"
  [title]="'IDLE.MODAL_TITLE' | translate"
  (escape)="handleEscapedModal()"
>
  <ng-container modalBody>
    <p>{{ "IDLE.MODAL_BODY_EXPIRED" | translate }}</p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" (click)="navigateToLogin()">
      {{ "IDLE.MODAL_BUTTON_SIGNIN" | translate }}
    </button>
  </ng-container>
</lib-modal>
