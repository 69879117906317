import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "lib-change-browser-page",
  template: `
    <main class="change-browser">
      <img
        src="../../../assets/images/governmentOfCanadaLogoEn.svg"
        class="browser__logo"
        alt="Government of Canada, Gouvernement du Canada"
      />
      <div class="browser__wrapper">
        <article class="browser__wrapper__content">
          <h1 class="browser__wrapper__content__heading emphasis">
            Browser not supported.
          </h1>
          <p class="browser__wrapper__content__p">
            The browser you are using cannot run all of our content. Please use
            Chrome, Firefox or Safari.
          </p>
          <p class="browser__wrapper__content__p">
            For more information, please go to our
            <a
              aria-label="Go to FAQ page"
              target="_blank"
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/pr-confirmation-portal.html"
              >FAQ page</a
            >.
          </p>
        </article>
        <article class="browser__wrapper__content">
          <h1 class="browser__wrapper__content__heading emphasis">
            Navigateur non pris en charge.
          </h1>
          <p class="browser__wrapper__content__p">
            Le navigateur que vous utilisez ne peut pas exécuter tout notre
            contenu. Veuillez utiliser Chrome, Firefox ou Safari.
          </p>
          <p class="browser__wrapper__content__p">
            Pour de plus amples renseignements, veuillez visiter nos
            <a
              aria-label="Questions fréquemment posées"
              target="_blank"
              href="https://www.canada.ca/fr/immigration-refugies-citoyennete/services/immigrer-canada/portail-confirmation-rp.html"
              >questions fréquemment posées</a
            >
            .
          </p>
        </article>
      </div>
      <div class="browser__footer">
        <img
          src="../../../assets/images/Canada-logo.svg"
          class="browser__footer__img"
          role="presentation"
          alt=""
        />
      </div>
    </main>
  `,
  styles: [
    `
      .change-browser {
        max-width: 800px;
        margin: 100px auto;
        .browser__logo {
          width: 271px;
          padding: 0 32px;
        }
        .browser__wrapper {
          padding: 32px;
        }
        .browser__wrapper__content__heading {
          font-family: "Lato", "Verdana", "Geneva", sans-serif;
          font-weight: bold;
          font-size: 1.2rem;
          line-height: 1.1;
        }
        .browser__wrapper__content__p {
          font-family: "Noto Sans", "Tahoma", "Arial", "Helvetica", sans-serif;
          font-weight: normal;
          font-size: 0.8rem;
          line-height: 1.5;
        }
        .browser__footer {
          display: flex;
          justify-content: flex-end;
          height: 95px;
          background-color: #e1e4e7;
          width: 100%;
        }
        .browser__footer__img {
          width: 125px;
          height: 100%;
          margin-right: 36px;
        }
        @media (min-width: 576px) {
          transform: translateY(45%);
          margin: 0 auto;
          .browser__wrapper {
            display: flex;
            padding: 0px 15px 50px;
          }
          .browser__wrapper__content {
            width: 50%;
            padding: 15px;
          }
        }
      }
    `,
  ],
})
export class ChangeBrowserPageComponent implements OnInit {
  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle(
      `Browser not supported || Navigateur non pris en charge.`
    );
  }
}
