<h1 class="webform-table-view-page__heading emphasis">
  {{ pageTitleKey | translate }}
</h1>

<div class="spinner">
  <lib-spinner [showSpinner]="isLoading" [fullPage]="false"></lib-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <!-- PA Table -->
  <ng-container *ngIf="formTables?.length === 0">
    <p class="no-results-text">{{ "INTAKE.TABLE_NO_DATA" | translate }}</p>
  </ng-container>
  <ng-container *ngIf="formTables?.length > 0">
    <div
      *ngFor="let table of formTables"
      class="webform-table-view-page__table-wrapper"
    >
      <prcw-webform-view-table
        [heading]="table.heading"
        [columnHeadings]="columnHeadings"
        [rows]="table.tableRows"
      ></prcw-webform-view-table>
    </div>
  </ng-container>

  <ng-container *ngIf="formMetadata?.documentTypeId === imm5669DocType">
    <prcw-webform-view-imm5669-disclosure-declaration
      [applicantFullName]="fullName"
    ></prcw-webform-view-imm5669-disclosure-declaration>

    <prcw-webform-view-imm5669-solemn-declaration></prcw-webform-view-imm5669-solemn-declaration>
  </ng-container>

  <ng-container *ngIf="formMetadata?.documentTypeId === imm5444DocType">
    <prcw-webform-view-imm5444-sub-tables-view
      [formData]="formData"
      [lang]="lang"
    ></prcw-webform-view-imm5444-sub-tables-view>
  </ng-container>
</ng-container>

<prcw-webform-nav-buttons></prcw-webform-nav-buttons>

<!-- <div class="buttons-container"> -->
<!-- BACK PAGE -->
<!-- For the form pages that have multiple pages , ex: IMM0008 Has multiple pages-->
<!-- <ng-container *ngIf="!formMetadata?.onlyRightSideButton">
    <a
      *ngIf="formMetadata?.multiPageForm"
      [routerLink]="routeLocalizer.get(lang, formMetadata?.prevPageKey, '../')"
      class="btn btn-secondary"
    >
      {{ "INTAKE.PAGE_BACK" | translate }}
    </a> -->

<!-- For the form pages that have only one page with back button -->
<!-- <a
      *ngIf="!formMetadata?.multiPageForm"
      [routerLink]="['../']"
      class="btn btn-secondary"
    >
      {{ "INTAKE.PAGE_BACK" | translate }}
    </a>
  </ng-container> -->
<!-- Save the space -->
<!-- <div *ngIf="formMetadata?.onlyRightSideButton"></div> -->
<!-- NEXT/RETURN BUTTON -->
<!-- <ng-container
    *ngIf="
      formMetadata?.hasNextPageButton !== false &&
      !formMetadata?.onlyRightSideButton
    "
  > -->
<!-- For the form pages with a next button, ex: IMM0008 Form Data Page  -->
<!-- <a
      *ngIf="formMetadata?.nextPageKey"
      [routerLink]="routeLocalizer.get(lang, formMetadata?.nextPageKey, '../')"
      class="btn btn-primary"
    >
      {{ "INTAKE.PAGE_NEXT" | translate }}
    </a> -->
<!-- For the form pages with return to home screen buttons, ex: IMM0008 Additional Page  -->
<!-- <a
      *ngIf="!formMetadata?.nextPageKey"
      [routerLink]="['../../']"
      class="btn btn-primary"
    >
      {{ "INTAKE.PAGE_RETURN" | translate }}
    </a>
  </ng-container>
  <ng-container *ngIf="formMetadata?.onlyRightSideButton">
    <a
      *ngIf="!formMetadata?.nextPageKey"
      [routerLink]="['../']"
      class="btn btn-primary"
    >
      {{ "INTAKE.PAGE_RETURN_RENEWAL" | translate }}
    </a>
  </ng-container>
</div> -->
