import { Component, Input } from "@angular/core";

export type PillLabelType = "blue";

@Component({
  selector: "lib-label-pill",
  template: `
    <span class="label-pill label-pill--{{ type }}">{{ text }}</span>
  `,
  styles: [
    `
      .label-pill {
        border-radius: 1.2em;
        display: inline-block;
        min-width: 12em;
        padding: 0.7em;
        margin: 0.5em;
        text-align: center;
        font-family: "Lato", "Verdana", "Geneva", sans-serif;
        font-size: 0.7rem;
        font-weight: bold;
        top: -7px;
        position: relative;
      }
      .label-pill--blue {
        background: #a1e5e1;
        color: #000000;
      }
    `,
  ],
})
export class LabelPillComponent {
  @Input() type: PillLabelType;
  @Input() text: string;

  constructor() {}
}
