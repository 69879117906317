import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { CasesService } from "../../../core/cases-module/cases.service";
import { Store } from "@ngrx/store";
import * as CaseActions from "../../store/cases.actions";
import { Case } from "../../../core/cases-module/models/case.model";
import * as fromApp from "../../../store/app.reducer";
import { filter, map, take } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CaseDetailsResolver implements Resolve<Case[]> {
  case: any;

  constructor(
    private route: ActivatedRoute,
    private caseService: CasesService,
    private store: Store<fromApp.State>
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const caseId = route.params.id;
    this.store.dispatch(new CaseActions.StartLoadCurrentCase({ caseId }));
    return this.store.select("confirmation").pipe(
      filter(
        (data) => data.selectedCase?.id?.toString() === caseId?.toString()
      ),
      map((data) => data.selectedCase),
      take(1)
    );
  }
}
