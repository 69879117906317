import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modals: any[] = [];
  public navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.navigateAwaySelection$.next(false);
  }

  /* add modal to array of active modals */
  public add(modal: any): void {
    this.modals.push(modal);
  }

  /* remove modal with specified id from array of modals */
  public remove(id: string): void {
    this.modals = this.modals.filter((m) => m.id !== id);
  }

  /* open modal with specified id */
  public open(id: string): void {
    const modal = this.modals.find((m) => m.id === id);
    modal.open();
  }

  /* close modal with specified id */
  public close(id: string): void {
    const modal = this.modals.find((m) => m.id === id);
    modal.close();
  }
}
