import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";

@Component({
  selector: "prcw-intake-case-list-page",
  templateUrl: "./case-list-page.component.html",
  styleUrls: ["./case-list-page.component.scss"],
})
export class IntakeCaseListPageComponent implements OnInit {
  lang: string;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.routeLocalizer.setTranslatedTitle("INTAKE.CASE_LIST_PAGE.PAGE_TITLE");
  }
}
