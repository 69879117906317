import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "prcw-webform-view-imm5669-disclosure-declaration",
  templateUrl: "./webform-view-imm5669-disclosure-declaration.component.html",
  styleUrls: ["./webform-view-imm5669-disclosure-declaration.component.scss"],
})
export class WebformViewImm5669DisclosureDeclarationComponent
  implements OnInit
{
  @Input() applicantFullName: string;

  constructor() {}

  ngOnInit(): void {
    if (this.applicantFullName) {
      this.applicantFullName = this.applicantFullName.trim();
    }
  }
}
