import * as CaseActions from "./cases.actions";
import { Case, NewCase } from "../../core/cases-module/models/case.model";
import { Decision } from "../models/document-rejection.model";

export interface State {
  allCases: Case[] | null;
  selectedCase: Case | null;
}

const initialState = {
  allCases: null,
  selectedCase: null,
};

export function caseReducer(
  state: State = initialState,
  action: CaseActions.CaseActions
) {
  switch (action.type) {
    case CaseActions.GET_ALL_CASES:
      const updatedCases = action.payload.cases;
      return {
        ...state,
        allCases: updatedCases,
      };

    case CaseActions.SET_CURRENT_CASE:
      return {
        ...state,
        selectedCase: action.payload,
      };

    case CaseActions.CREATE_CASE:
      const updatedCase = action.payload.newCase as Case;
      return {
        ...state,
        selectedCase: { ...state.selectedCase, ...updatedCase },
      };

    case CaseActions.SET_ADDRESS_STATUS:
      const updatedAddressStatus = action.payload.decision;
      return {
        ...state,
        selectedCase: Object.assign({}, state.selectedCase, {
          address: updatedAddressStatus,
        }),
      };
    case CaseActions.SET_PHOTO_STATUS:
      const updatedPhotoStatus = action.payload.decision;
      return {
        ...state,
        selectedCase: Object.assign({}, state.selectedCase, {
          photo: updatedPhotoStatus,
        }),
      };
    case CaseActions.SHAKE_CASE:
      return {
        ...state,
        selectedCase: Object.assign({}, state.selectedCase),
      };
    case CaseActions.RESET_CASE_INFO:
      return {
        ...initialState,
      };
    case CaseActions.SET_CURRENT_CASE_ERROR:
      const caseId = action.payload.caseId;
      return {
        ...state,
        selectedCase: Object.assign({}, state.selectedCase, {
          id: caseId,
          apiLoadingError: action.payload.apiLoadingError,
        }),
      };
    default:
      return state;
  }
}
