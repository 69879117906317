<div class="barcode" tabindex="0">
  <canvas class="barcode__canvas-hidden" attr.id="{{ barcodeId }}"></canvas>
  <img
    class="barcode__canvas"
    [src]="dataURL"
    alt="{{ 'INTAKE.IMM0008.BARCODES.ALT_TEXT' | translate }} {{
      barcodeAltText
    }}"
  />
</div>
