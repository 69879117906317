import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Document } from "../../../../core/models/document.model";
import { CasesService } from "../../../../core/cases-module/cases.service";
import { DownloadService } from "../../../../shared/services/download.service";
import { UntypedFormBuilder, FormGroup, FormArray } from "@angular/forms";
import {
  documentTypes,
  FormSelectOption,
  webformPdfTypes,
  AlertService,
} from "lib";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { DocumentService } from "@pr-caseworker/app/core/services/document/document.service";
import {
  allDocumentGCMStatuses,
  allDocumentStatuses,
} from "./document-details-models";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "prcw-application-forms-table",
  templateUrl: "./application-forms-table.component.html",
  styleUrls: ["./application-forms-table.component.scss"],
})
export class ApplicationFormsTableComponent implements OnInit, OnDestroy {
  @Output() downloadInProgress = new EventEmitter<boolean>();

  public lang: string;
  public applicationDocumentLength: number;
  public sponsorDocumentLength: number;
  public formattedFormRows: any[] = [];
  public applicationFormattedRows: any[] = [];
  public sponsorFormattedRows: any[] = [];
  public docsForAPI: Document[];
  public isBulkDownloadAvailable: boolean = false;
  public isLoading = false;
  public clientName: string;
  //  max length is 190, but leaving buffer for added characters on OS & extension
  public docNameMaxLength = 180;

  public statusOptions: FormSelectOption[] = allDocumentStatuses;
  public gcmsStatusOptions: FormSelectOption[] = allDocumentGCMStatuses;

  public caseListForm: FormGroup = this.fb.group({
    documentStatuses: this.fb.array([]),
  });

  public sponsorCaseListForm: FormGroup = this.fb.group({
    documentStatuses: this.fb.array([]),
  });

  private case: any;
  private caseDocuments: Document[];
  private clientNameAvailable: Promise<any>;
  private subscriptions: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private caseService: CasesService,
    private downloadService: DownloadService,
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    private documentService: DocumentService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.subscriptions.push(
      this.getStoredCase().subscribe(() => {
        this.cloneCaseDocuments();
        this.clientNameAvailable = this.getClientName();
        this.checkBulkDownloadAvailability();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subsc) => subsc.unsubscribe());
  }

  /* PUBLIC METHODS ------------------------------------------------------------ */

  public checkBulkDownloadAvailability() {
    this.prepareFormRows().then((data) => {
      let isPDFAvailable;
      this.formattedFormRows.forEach((doc) => {
        if (doc.pdfSuccessful || doc.downloadDoc) {
          isPDFAvailable = true;
        } else {
          isPDFAvailable = false;
        }
      });
      isPDFAvailable
        ? (this.isBulkDownloadAvailable = true)
        : (this.isBulkDownloadAvailable = false);
    });
  }

  public get documentStatusesAreSet(): boolean {
    let flag = true;
    for (const control of this.statusesFormArray.controls) {
      if (control.value.status == null) {
        flag = false;
        break;
      }
      if (control.value.gcmsUploadStatus == null) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  public get sponsorStatuesFromArray(): FormArray {
    return this.sponsorCaseListForm.controls.documentStatuses as FormArray;
  }

  public get statusesFormArray(): FormArray {
    return this.caseListForm.controls.documentStatuses as FormArray;
  }

  private getStoredCase() {
    return this.store.select("intake").pipe(
      tap((caseData) => {
        this.case = caseData.selectedApplication;
        this.caseDocuments = this.case.documents;
      })
    );
  }

  public async downloadFile(document: any) {
    const downloadName = document.downloadDoc.split("/").pop(); // the last string is the filename (incase its in a folder)
    try {
      await this.downloadService.downloadDocument(
        document.caseId,
        document.id,
        downloadName
      );
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  public updateDocsForAPI(
    document: Document,
    value: string,
    statusType: string
  ): void {
    const foundDoc = this.docsForAPI.find((d) => d.id === document.id);
    if (foundDoc && statusType === "docStatus") {
      if (value === "1: true") {
        foundDoc.documentApproved = true;
      } else if (value === "2: false") {
        foundDoc.documentApproved = false;
      } else {
        foundDoc.documentApproved = null;
      }
    } else if (foundDoc && statusType === "gcmsStatus") {
      if (value === "1: false") {
        foundDoc.gcmsUploadStatus = false;
      } else if (value === "2: true") {
        foundDoc.gcmsUploadStatus = true;
      } else {
        foundDoc.gcmsUploadStatus = null;
      }
    }
  }

  /* PRIVATE METHODS ------------------------------------------------------------ */

  // grab IMM8 and take res.form.personalInfo.familyName + giveName
  private getClientName(): Promise<void> {
    const imm8DocId = this.caseDocuments.filter(
      (doc: any) => doc.documentTypeId === documentTypes.imm0008.id
    )[0].id;
    return this.documentService
      .getFormByDocumentId(this.case.id, imm8DocId)
      .then((res) => {
        this.clientName =
          res.form.personalDetails.familyName +
          "_" +
          res.form.personalDetails.givenName;
      })
      .catch(() => {
        this.clientName = "";
      });
  }

  /* Create an array of documents that can be updated to send to the API (because this.formattedFormRows cannot) */
  private cloneCaseDocuments(): void {
    this.docsForAPI = this.caseDocuments
      ? JSON.parse(JSON.stringify(this.caseDocuments))
      : [];
  }

  /**
   * TODO: Find a way to refactor so that we don't have to maintain separate arrays of cases?
   * Transforming this.caseDocuments => this.formattedFormRows => this.docsForAPI shouldn't be necesary
   */
  private async prepareFormRows(): Promise<void> {
    const appFormsList = await this.caseService.getAppForms();
    const appDocsList = await this.caseService.getAppDocs();

    this.formattedFormRows = this.caseDocuments.map((submitted) => {
      const form = appFormsList.find(
        ({ id }: any) => id === submitted.documentTypeId
      );
      const document = appDocsList.find(
        ({ id }: any) => id === submitted.documentTypeId
      );
      // if the documentName does not include ca-central-1 it is not in s3, therefore it is a web form
      const isWebForm = !submitted.documentName.startsWith("ca-central-1");
      const isForm = form?.form;
      const name =
        this.lang === "fr"
          ? document?.documentTypeFr
          : document?.documentTypeEn;
      const formattedName =
        this.lang === "fr" ? form?.form.nameFr : form?.form.nameEn;
      let gcmsUploadStatusMessage;
      return {
        id: submitted.id,
        name,
        formattedName,
        details:
          this.lang === "fr" ? form?.form.detailsFr : form?.form.detailsEn,
        uploadedRawDocument: submitted.documentName.split("/").pop(), // the last string is the filename (incase its in a folder)
        downloadDoc: submitted.documentName,
        isWebForm,
        isForm,
        link: isWebForm ? name?.replace(/\s+/g, "-").toLowerCase() : null,
        documentApproved: submitted.documentApproved,
        pdfLink: submitted.pdfLink,
        pdfSuccessful: submitted.pdfSuccessful,
        caseId: submitted.caseId,
        documentTypeId: submitted.documentTypeId,
        helpText:
          formattedName === "IMM 0008"
            ? "INTAKE.INTAKE_CASE_DETAILS.IMM8_BARCODE_NOT_INCLUDED"
            : "",
        gcmsUploadStatus: submitted.gcmsUploadStatus,
        gcmsResponse: submitted.gcmsResponse,
        gcmsUploadStatusMessage: gcmsUploadStatusMessage,
        caseMemberId: submitted.caseMemberId,
      };
    });

    this.applicationFormattedRows = this.formattedFormRows.filter(
      (document: any) => document.caseMemberId === null
    );
    this.applicationDocumentLength = this.applicationFormattedRows.length;

    this.sponsorFormattedRows = this.formattedFormRows.filter(
      (document: any) => document.caseMemberId !== null
    );
    this.sponsorDocumentLength = this.sponsorFormattedRows.length;

    this.sortFormRows();
    this.createDocumentStatusControls();
  }

  // add document status to dynamic FormArray
  private createDocumentStatusControls(): void {
    this.statusesFormArray.clear();
    this.applicationFormattedRows.forEach((doc) => {
      this.statusesFormArray.push(
        this.fb.group({
          status: doc.documentApproved,
          gcmsUploadStatus: doc.gcmsUploadStatus,
        })
      );
    });

    this.sponsorStatuesFromArray.clear();
    this.sponsorFormattedRows.forEach((doc) => {
      this.sponsorStatuesFromArray.push(
        this.fb.group({
          status: doc.documentApproved,
          gcmsUploadStatus: doc.gcmsUploadStatus,
        })
      );
    });
  }

  private sortFormRows(): void {
    this.applicationFormattedRows.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA === "IMM0008" && nameB === "IMM0008DEP") {
        return -1;
      }
      if (nameA === "IMM0008DEP" && nameB === "IMM0008") {
        return 1;
      }
      if (nameA === "IMM0008" || nameA === "IMM0008DEP") {
        return -1;
      }
      if (nameB === "IMM0008" || nameB === "IMM0008DEP") {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  async downloadAllDocuments() {
    await this.clientNameAvailable;
    this.downloadService.setClientName(this.clientName);
    if (this.formattedFormRows) {
      this.downloadInProgress.emit(true);
      this.isLoading = true;
      const downloads = this.formattedFormRows
        .filter((doc) => {
          if (!doc) {
            return false;
          }
          if (
            webformPdfTypes.includes(doc.documentTypeId) &&
            !doc.pdfSuccessful
          ) {
            return false;
          }
          if (!doc.downloadDoc) {
            return false;
          }
          return true;
        })
        .map((ddoc) => {
          if (ddoc.pdfSuccessful) {
            let docName = `${this.clientName}_${ddoc.name}_${ddoc.uploadedRawDocument}`;
            //  if doc name is too long, shorten it
            if (docName.length > this.docNameMaxLength) {
              docName = docName.substring(0, this.docNameMaxLength);
            }
            const downloadName = `${docName}.pdf`;
            return this.downloadService.documentDownload(
              ddoc.pdfLink,
              downloadName,
              true
            );
          }
          const downloadName = this.getDocumentDownloadName(ddoc);
          return this.downloadService.documentDownload(
            ddoc.downloadDoc,
            downloadName,
            true
          );
        });

      this.downloadService.setBulkDownloadCounter(downloads.length);
      Promise.all(downloads).finally(() => {
        this.downloadInProgress.emit(false);
        this.isLoading = false;
        this.downloadService.setBulkDownloadCounter(0);
      });
    }
  }

  getDocumentDownloadName(ddoc: any): string {
    const nameArray = ddoc.uploadedRawDocument.split(".");
    const extension = nameArray.pop();
    const fileName = nameArray.join(".");
    //  eg. if there are duplicates, finder will add '(1)'
    let res = `${this.clientName}_${ddoc.name}_${fileName}`;

    //  if doc name is too long, shorten it
    if (res.length > this.docNameMaxLength) {
      res = res.substring(0, this.docNameMaxLength);
    }

    switch (ddoc.documentTypeId) {
      // Travel Document
      case 5:
        return `${res}_1.${extension}`;
      // Confirmation of Nomination
      case 6:
        return `${res}_2.${extension}`;
      // Proof of Language Proficiency
      case 7:
        return `${res}_3.${extension}`;
      // Identity and Civil Status Documents
      case 8:
        return `${res}_4.${extension}`;
      // Children's Information
      case 9:
        return `${res}_5.${extension}`;
      // Police Certificates
      case 10:
        return `${res}_6.${extension}`;
      // Photos
      case 11:
        return `${res}_7.${extension}`;
      // Letter of Explanation
      case 12:
        return `${res}_8.${extension}`;
      // Transaltion and Affidavit
      case 13:
        return `${res}_9.${extension}`;
      // Document Checklist
      case 51:
        return `${res}_10.${extension}`;
      // Other
      case 1000:
        return `${res}_11.${extension}`;
      default:
        return `${res}.${extension}`;
    }
  }
}
