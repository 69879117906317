import * as RenewalSearchActions from "./search-terms.actions";

import { Action, createReducer, on } from "@ngrx/store";

export interface SearchTermsState {
  pageToReview: number;
  searchTerm: string | null;
  searchBy: number;
  sortOrder: string;
  lobFilterValue: string | null;
  statusFilterValue: number | null;
  assignedFilterValue: boolean | null;
  assignedToMe: boolean | null;
  urgentApplications: boolean | null;
  urgencyGranted: boolean | null;
}

const initialSearchTermsState: SearchTermsState = {
  pageToReview: 0,
  searchTerm: "",
  searchBy: 0,
  sortOrder: "submittedAt ASC",
  lobFilterValue: null,
  statusFilterValue: null,
  assignedFilterValue: null,
  assignedToMe: false,
  urgentApplications: false,
  urgencyGranted: false,
};

const searchTermsReducer = createReducer(
  initialSearchTermsState,
  on(RenewalSearchActions.setSearchFieldAction, (state, { searchTerms }) => {
    return {
      ...state,
      searchTerm: searchTerms.searchValue,
      searchBy: searchTerms.searchBy,
      pageToReview: 0,
    };
  }),
  on(RenewalSearchActions.setPageReviewAction, (state, { pageToReview }) => {
    return {
      ...state,
      pageToReview,
    };
  }),
  on(RenewalSearchActions.setSortValueAction, (state, { sortOrder }) => {
    return {
      ...state,
      sortOrder,
    };
  }),
  on(
    RenewalSearchActions.setLobFilterValueAction,
    (state, { lobFilterValue }) => {
      return {
        ...state,
        lobFilterValue,
        pageToReview: 0,
      };
    }
  ),
  on(
    RenewalSearchActions.setStatusFilterValue,
    (state, { statusFilterValue }) => {
      return {
        ...state,
        statusFilterValue,
        pageToReview: 0,
      };
    }
  ),
  on(
    RenewalSearchActions.setAssignedFilterValue,
    (state, { assignedFilterValue }) => {
      return {
        ...state,
        assignedFilterValue,
        pageToReview: 0,
      };
    }
  ),
  on(RenewalSearchActions.setAssignedToMe, (state, { assignedToMe }) => {
    return {
      ...state,
      assignedToMe,
      pageToReview: 0,
    };
  }),
  on(RenewalSearchActions.resetAllFilters, (state, { sortOrder }) => {
    return {
      ...initialSearchTermsState,
      sortOrder,
    };
  }),
  on(
    RenewalSearchActions.setUrgentApplications,
    (state, { urgentApplications }) => {
      return {
        ...state,
        urgentApplications,
        pageToReview: 0,
      };
    }
  ),
  on(RenewalSearchActions.setUrgencyGranted, (state, { urgencyGranted }) => {
    return {
      ...state,
      urgencyGranted,
      pageToReview: 0,
    };
  })
);

export function reducer(
  state: SearchTermsState | undefined,
  action: Action
): SearchTermsState {
  return searchTermsReducer(state, action);
}

export interface SearchFieldValues {
  searchValue: string | null;
  searchBy: number;
}
