import { PropertyType } from "../../models/data-retrieval.model";
import { ConfigTableData } from "../webform-view-table.model";

// Dynamically building out dependants configs by using the index for the dependant
// based on the dependants Array coming from the API
export function getImm0008DependantConfigs(index: number = 0): ConfigTableData {
  return {
    heading: "INTAKE.IMM0008.TABLE_TITLES.DEPENDANT",
    index,
    fields: [
      /* ----------- Personal Details ----------- */
      // Relationship to principal applicant - Relationship type
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "relationshipToPA",
        sourceLabels: {
          formName: "INTAKE.IMM0008.PAGES.PERSONAL_DETAILS.SUBHEADING",
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.RELATIONSHIP_TYPE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "relationshipToPA",
            },
          },
        },
      },
      // Relationship to principal applicant - if other
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.OTHER_RELATIONSHIP_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "otherRelationshipToPA",
            },
          },
        },
      },
      // Relationship to principal applicant - Dependant type
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "dependantType",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.DEPENDANT_TYPE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "dependantType",
            },
          },
        },
      },
      // Will you accopany PA
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.DEPENDANT.ACCOMPANYING_PA.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "accompanyingPA",
            },
          },
        },
      },
      // Reasons for not accompany PA
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.DEPENDANT.REASON_NOT_ACCOMPANYING.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.DEPENDANT.REASON_NOT_ACCOMPANYING.REASON_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "reasonNotAccompanying",
            },
          },
        },
      },
      // Full name - Family name
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.SURNAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "familyName",
            },
          },
        },
      },
      // Full name - Given name(s)
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.GIVEN_NAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "givenName",
            },
          },
        },
      },
      // Have you ever used any other name
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.NICKNAME.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "usedOtherName",
            },
          },
        },
      },
      // Other name - Family name(s)
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.SURNAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "otherFamilyName",
            },
          },
        },
      },
      // Other name - Given name(s)
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.GIVEN_NAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "otherGivenName",
            },
          },
        },
      },
      // UCI
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.UCI.LEGEND",
          fieldLabel: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.UCI.UCI_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: { type: PropertyType.Primitive, sourceField: "uci" },
          },
        },
      },
      // Physical characteristics - Sex
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "gender",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.SEX_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: { type: PropertyType.Primitive, sourceField: "sex" },
          },
        },
      },
      // Physical characteristics - Eye color
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "eyeColor",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.EYE_COLOUR_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "eyeColour",
            },
          },
        },
      },
      // Physical characteristics - Height
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.HEIGHT_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "heightInCM",
            },
          },
        },
      },
      // Birth information - DOB
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.DOB_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: { type: PropertyType.Primitive, sourceField: "dob" },
          },
        },
      },
      // Birth information - Place of birth
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.CITY_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "cityOfBirth",
            },
          },
        },
      },
      // Birth information - Country of birth
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "countryOfBirth",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.COUNTRY_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "countryOfBirth",
            },
          },
        },
      },
      // Citizenship(s) - Country 1
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "countryOfCitizenship",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.COUNTRY_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "citizenship1",
            },
          },
        },
      },
      // Citizenship(s) - Country 2
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "countryOfCitizenship",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.COUNTRY_2_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "citizenship2",
            },
          },
        },
      },
      // Current country of residence - Country
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "country",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.COUNTRY_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Object,
              sourceField: "currentCountry",
              subfield: {
                type: PropertyType.Primitive,
                sourceField: "country",
              },
            },
          },
        },
      },
      // Current country of residence - Status
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "immigrationStatus",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.IMMIGRATION_STATUS_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Object,
              sourceField: "currentCountry",
              subfield: {
                type: PropertyType.Primitive,
                sourceField: "immigrationStatus",
              },
            },
          },
        },
      },
      // Current country of residence - Start date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.START_DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Object,
              sourceField: "currentCountry",
              subfield: {
                type: PropertyType.Primitive,
                sourceField: "startDateOfImmigrationStatus",
              },
            },
          },
        },
      },
      // Current country of residence - End date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.END_DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Object,
              sourceField: "currentCountry",
              subfield: {
                type: PropertyType.Primitive,
                sourceField: "endDateOfImmigrationStatus",
              },
            },
          },
        },
      },
      // Current country of residence - Other status
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.OTHER_DETAILS_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Object,
              sourceField: "currentCountry",
              subfield: {
                type: PropertyType.Primitive,
                sourceField: "otherImmigrationStatus",
              },
            },
          },
        },
      },
      // Date of your last entry to Canada
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "dateOfLastEntry",
            },
          },
        },
      },
      // Place of your last entry to Canada
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.PLACE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "placeOfLastEntry",
            },
          },
        },
      },
      // Previous countries of residence
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PREV_COUNTRIES.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "hasPreviousCountries",
            },
          },
        },
      },
      // Provide pervious countries
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.LEGEND",
        },
      },
      // Provide pervious countries
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        complexSubfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          complexSubfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            complexSubfield: {
              type: PropertyType.Array,
              sourceField: "previousCountries",
              complexSubfield: [
                {
                  // Country
                  type: PropertyType.Primitive,
                  sourceField: "country",
                  complexSourceList: ["personalDetails", "country"],
                  sourceLabels: {
                    fieldsetLegend:
                      "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.COUNTRY_LEGEND",
                    fieldLabel:
                      "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.COUNTRY_LABEL",
                  },
                },
                {
                  // Immigration Status
                  type: PropertyType.Primitive,
                  sourceField: "immigrationStatus",
                  complexSourceList: ["personalDetails", "immigrationStatus"],
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.IMMIGRATION_STATUS_LABEL",
                  },
                },
                {
                  // Start Date
                  type: PropertyType.Primitive,
                  sourceField: "startDateOfImmigrationStatus",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.START_DATE_LABEL",
                  },
                },
                {
                  // End Date
                  type: PropertyType.Primitive,
                  sourceField: "endDateOfImmigrationStatus",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.END_DATE_LABEL",
                  },
                },
                {
                  // Other status
                  type: PropertyType.Primitive,
                  sourceField: "otherImmigrationStatus",
                  sourceLabels: {
                    fieldLabel:
                      "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.OTHER_DETAILS_LABEL",
                  },
                },
              ],
            },
          },
        },
      },
      // Current marital status
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "maritalStatus",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_STATUS.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_STATUS.MARITAL_STATUS_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "maritalStatus",
            },
          },
        },
      },
      // Provide the date on which you were married
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_DATE.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_DATE.DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "dateOfMarriageOrCommonLaw",
            },
          },
        },
      },
      // Provide name of current partner - Family name
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend: "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.SURNAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "familyNameOfSpouse",
            },
          },
        },
      },
      // Provide name of current partner - Given name
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.GIVEN_NAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "givenNameOfSpouse",
            },
          },
        },
      },
      // Have you previously been married
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PREV_RELATIONSHIP.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "previouslyMarriedOrCommonLaw",
            },
          },
        },
      },
      // Provide Previous relationship - Family name
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.SURNAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "previousSpouseFamilyName",
            },
          },
        },
      },
      // Provide Previous relationship - Given name
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.GIVEN_NAME_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "previousSpouseGivenName",
            },
          },
        },
      },
      // Provide Previous relationship - Date of birth
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.DOB_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "previousSpouseDob",
            },
          },
        },
      },
      // Provide Previous relationship - Type of relationship
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "maritalStatusHistory",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.RELATIONSHIP_TYPE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "typeOfRelationship",
            },
          },
        },
      },
      // Provide Previous relationship - Start date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.START_DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "startDateOfRelationship",
            },
          },
        },
      },
      // Provide Previous relationship - End date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.END_DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "personalDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "endDateOfRelationship",
            },
          },
        },
      },
      /* ----------- Education/occupation detail ----------- */
      // Education information - level of education
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "educationLevel",
        sourceLabels: {
          formName: "INTAKE.IMM0008.PAGES.EDUCATION_OCCUPATION.HEADING",
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.HIGHEST_LEVEL_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "educationOccupationDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "educationLevel",
            },
          },
        },
      },
      // Education information - years of education
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.NUMBERS_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "educationOccupationDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "numberOfYear",
            },
          },
        },
      },
      // Occupation information - Current occupation
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.CURRENT_OCCUPATION_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "educationOccupationDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "currentOccupation",
            },
          },
        },
      },
      // Occupation information - Intended occupation
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.INTENDED_OCCUPATION_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "educationOccupationDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "intendedOccupation",
            },
          },
        },
      },
      /* ----------- Language detail ----------- */
      // Native language/mother tongue
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "contactLanguage",
        sourceLabels: {
          formName: "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.HEADING",
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.NATIVE_LANGUAGE.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.NATIVE_LANGUAGE.NATIVE_LANGUAGE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "languageDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "nativeLanguage",
            },
          },
        },
      },
      // communication Language
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "officialLanguage",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL.LANGUAGE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "languageDetails",
            subfield: { type: PropertyType.Primitive, sourceField: "language" },
          },
        },
      },
      // In which language are you most at ease
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "preferenceLanguage",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL_PREFERENCE.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL_PREFERENCE.LANGUAGE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "languageDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "preferredLanguage",
            },
          },
        },
      },
      // Have you taken test
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.LANGUAGE_TEST.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "languageDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "takenTest",
            },
          },
        },
      },
      /* ----------- Passport Details ----------- */
      // Do you have passport?
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          formName: "INTAKE.IMM0008.PAGES.PASSPORT_DETAILS.SUBHEADING",
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.VALID_PASSPORT.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "passportDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "validPassport",
            },
          },
        },
      },
      // Passport/travel document number
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.DOCUMENT_NUMBER_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "passportDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "passportNumber",
            },
          },
        },
      },
      // Passport/travel document - Country of issue
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "countryOfIssue",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.COUNTRY_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "passportDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "countryOfIssue",
            },
          },
        },
      },
      // Passport/travel document - Issue date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.ISSUE_DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "passportDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "issueDate",
            },
          },
        },
      },
      // Passport/travel document - Expiry date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.EXPIRY_DATE_LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "passportDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "expiryDate",
            },
          },
        },
      },
      // Passport/travel document - Using Taiwanese Passport
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.TAIWAN_PASSPORT.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "passportDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "usingTaiwanesePassportForTrip",
            },
          },
        },
      },
      // Passport/travel document - Using Israeli Passport
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.ISRAEL_PASSPORT.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "passportDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "usingIsraeliPassportForTrip",
            },
          },
        },
      },
      /* ----------- National Identity Details ----------- */
      // Do you have National Identity document?
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "yesOrNoList",
        sourceLabels: {
          formName:
            "INTAKE.IMM0008.PAGES.NATIONAL_IDENTITY_DOCUMENT.SUB_HEADING",
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_CHECK.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "nationalIdentityDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "nationalIdentityDocument",
            },
          },
        },
      },
      // National identity document - Number
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldsetLegend:
            "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_NUMBER.LEGEND",
          fieldLabel:
            "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_NUMBER.LABEL",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "nationalIdentityDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "nationalIdentityNumber",
            },
          },
        },
      },
      // National identity document - Country of Issue
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceList: "countryOfIssue",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.COUNTRY_ISSUE.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "nationalIdentityDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "countryOfIssue",
            },
          },
        },
      },
      // National identity document - Issue date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.ISSUE_DATE.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "nationalIdentityDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "issueDate",
            },
          },
        },
      },
      // National identity document - Expiry date
      {
        type: PropertyType.Object,
        sourceField: "dependantDetails",
        sourceLabels: {
          fieldLabel:
            "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.EXPIRY_DATE.LEGEND",
        },
        subfield: {
          type: PropertyType.Array,
          sourceField: "dependants",
          subfield: {
            index,
            type: PropertyType.Object,
            sourceField: "nationalIdentityDetails",
            subfield: {
              type: PropertyType.Primitive,
              sourceField: "expiryDate",
            },
          },
        },
      },
    ],
  };
}
