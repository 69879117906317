import { FormSelectOption } from "lib";

export const allDocumentStatuses: FormSelectOption[] = [
  {
    value: true,
    text: {
      en: "Complete",
      fr: "Complète",
    },
  },
  {
    value: false,
    text: {
      en: "Incomplete",
      fr: "Incomplète",
    },
  },
];
export const allDocumentGCMStatuses = [
  {
    value: false,
    text: {
      en: "Document Upload Failed",
      fr: "Échec du téléchargement du document",
    },
  },
  {
    value: true,
    text: {
      en: "Uploaded to GCMS",
      fr: "Téléchargé sur SMGC",
    },
  },
];
