import { Injectable } from "@angular/core";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { Storage, Auth, API, StorageClass } from "aws-amplify";
import { environment } from "../../../../environments/environment";
import awsconfig from "../../../../../../../config/aws-exports";

@Injectable({ providedIn: "root" })
export class AWSService {
  auth: AuthClass = Auth;
  api = API;
  storage: StorageClass = Storage;
  apiName = environment.API_NAME;

  constructor() {
    this.auth.configure(awsconfig);
    this.api.configure(awsconfig);
    this.storage.configure(awsconfig);
    this.storage.configure({ level: "private" });
  }

  public async getToken(): Promise<any> {
    try {
      return `Bearer ${(await this.auth.currentSession())
        .getIdToken()
        .getJwtToken()}`;
    } catch (error) {
      return error;
    }
  }

  public async getIdentityId(): Promise<any> {
    try {
      const credentials = await this.auth.currentCredentials();
      return credentials.identityId;
    } catch (error) {
      return error;
    }
  }

  public async getIdentityIdAndToken(): Promise<IdentityIdAndTokenResponse> {
    try {
      return {
        identityId: await this.getIdentityId(),
        token: (await this.auth.currentSession()).getIdToken().getJwtToken(),
      };
    } catch (error) {
      return error;
    }
  }
}

export interface IdentityIdAndTokenResponse {
  identityId: string;
  token: string;
}
