<div>
  <button
    category="primary"
    type="button"
    (click)="downloadAllDocuments()"
    [disabled]="!isBulkDownloadAvailable"
  >
    {{ "INTAKE.INTAKE_CASE_DETAILS.DOWNLOAD_ALL_DOCUMENTS" | translate }}
  </button>
</div>

<div class="header-row">
  <h2>
    {{
      "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_DOCUMENT_TABLE_HEADING"
        | translate
    }}
  </h2>
  <div></div>
</div>

<form [formGroup]="caseListForm">
  <div class="wrapper">
    <lib-spinner
      [showSpinner]="isLoading"
      [fullPage]="false"
      [useOverlay]="true"
    ></lib-spinner>
    <table class="table">
      <thead>
        <tr>
          <th>
            {{
              "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_DOCUMENT_NAME_HEADING"
                | translate
            }}
          </th>
          <th>
            {{
              "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_DETAILS_HEADING"
                | translate
            }}
          </th>
          <th>
            {{
              "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_ATTACHMENTS_HEADING"
                | translate
            }}
          </th>
          <th>
            {{ "INTAKE.CASE_DETAILS_PAGE.COMPLETNESS_CHECK_LABEL" | translate }}
          </th>
          <th>
            {{ "INTAKE.CASE_DETAILS_PAGE.GCMS_DOC_UPLOAD_LABEL" | translate }}
          </th>
          <th>
            {{
              "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_ACTION_HEADING"
                | translate
            }}
          </th>
        </tr>
      </thead>

      <tbody *ngIf="applicationDocumentLength">
        <tr
          formArrayName="documentStatuses"
          *ngFor="let doc of applicationFormattedRows; let i = index"
        >
          <td>
            <ng-container *ngIf="doc.isForm">
              <span>{{ doc.formattedName }}</span
              ><br />
            </ng-container>
            <ng-container *ngIf="!doc.isForm">
              <span>{{ doc.name }}</span
              ><br />
            </ng-container>
            <div *ngIf="!!doc.helpText" class="doc-name-help-text">
              {{ doc.helpText | translate }}
            </div>
          </td>
          <td>
            {{ doc.details }}
          </td>

          <!-- Attachment Column -->
          <td class="attachments">
            <ng-container *ngIf="doc.isWebForm">
              {{ "INTAKE.CASE_DETAILS_PAGE.WEBFORM_LABEL" | translate }}
            </ng-container>
            <ng-container *ngIf="!doc.isWebForm">
              {{ doc.uploadedRawDocument }}
            </ng-container>
          </td>

          <!-- Status Column -->
          <td [formGroupName]="i">
            <lib-form-select
              class="complete-select"
              [identifier]="doc.name + ' ' + i"
              formControlName="status"
              [options]="statusOptions"
              [newEmptyOption]="true"
              (change)="updateDocsForAPI(doc, $event.target.value, 'docStatus')"
              [ariaLabel]="
                'INTAKE.CASE_DETAILS_PAGE.DOCUMENT_STATUS'
                  | translate : { docName: doc.name }
              "
              [noLabel]="true"
            ></lib-form-select>
          </td>
          <td [formGroupName]="i">
            <lib-form-select
              class="complete-select"
              [identifier]="doc.name + ' ' + i"
              formControlName="gcmsUploadStatus"
              [options]="gcmsStatusOptions"
              [newEmptyOption]="true"
              [noLabel]="true"
              (change)="
                updateDocsForAPI(doc, $event.target.value, 'gcmsStatus')
              "
              [ariaLabel]="
                'INTAKE.CASE_DETAILS_PAGE.GCMS_DOCUMENT_STATUS'
                  | translate : { docName: doc.name }
              "
              [isDocUploadFailed]="doc.gcmsUploadStatus"
            ></lib-form-select>
          </td>

          <!-- Action Column -->
          <td class="actions">
            <ng-container *ngIf="doc.isWebForm">
              <a [routerLink]="doc.link" category="secondary">
                <!-- NEED TO ADD VIEW ABILITY OF READ ONLY FORM -->
                {{ "INTAKE.CASE_DETAILS_PAGE.VIEW" | translate }}
                <span class="visually-hidden">{{ doc.formattedName }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="!doc.isWebForm">
              <button
                category="secondary"
                type="button"
                (click)="downloadFile(doc)"
              >
                {{ "INTAKE.CASE_DETAILS_PAGE.DOWNLOAD" | translate }}
                <span class="visually-hidden">{{
                  doc.uploadedRawDocument
                }}</span>
              </button>
            </ng-container>
            <p
              *ngIf="
                !doc.uploadedRawDocument ||
                !doc.uploadedRawDocument[0] ||
                !doc.link
              "
              class="visually-hidden"
            >
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
        </tr>
      </tbody>

      <tbody *ngIf="!applicationDocumentLength" class="empty-table">
        <tr>
          <td colspan="5">
            <p class="no-results-text">
              {{ "INTAKE.TABLE_NO_DATA" | translate }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>

<ng-container *ngIf="sponsorDocumentLength">
  <div class="header-row">
    <h2>
      {{
        "INTAKE.INTAKE_CASE_DETAILS.SPONSOR_DOCUMENT_TABLE_HEADING" | translate
      }}
    </h2>
  </div>

  <form [formGroup]="sponsorCaseListForm">
    <div class="wrapper">
      <lib-spinner
        [showSpinner]="isLoading"
        [fullPage]="false"
        [useOverlay]="true"
      ></lib-spinner>
      <table class="table">
        <thead>
          <tr>
            <th>
              {{
                "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_DOCUMENT_NAME_HEADING"
                  | translate
              }}
            </th>
            <th>
              {{
                "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_DETAILS_HEADING"
                  | translate
              }}
            </th>
            <th>
              {{
                "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_ATTACHMENTS_HEADING"
                  | translate
              }}
            </th>
            <th>
              {{
                "INTAKE.CASE_DETAILS_PAGE.COMPLETNESS_CHECK_LABEL" | translate
              }}
            </th>
            <th>
              {{ "INTAKE.CASE_DETAILS_PAGE.GCMS_DOC_UPLOAD_LABEL" | translate }}
            </th>
            <th>
              {{
                "INTAKE.INTAKE_CASE_DETAILS.APPLICATION_ACTION_HEADING"
                  | translate
              }}
            </th>
          </tr>
        </thead>

        <tbody *ngIf="sponsorDocumentLength">
          <tr
            formArrayName="documentStatuses"
            *ngFor="let doc of sponsorFormattedRows; let i = index"
          >
            <td>
              <ng-container *ngIf="doc.isForm">
                <span>{{ doc.formattedName }}</span
                ><br />
              </ng-container>
              <ng-container *ngIf="!doc.isForm">
                <span>{{ doc.name }}</span
                ><br />
              </ng-container>
              <div *ngIf="!!doc.helpText" class="doc-name-help-text">
                {{ doc.helpText | translate }}
              </div>
            </td>
            <td>
              {{ doc.details }}
            </td>

            <!-- Attachment Column -->
            <td class="attachments">
              <ng-container *ngIf="doc.isWebForm">
                {{ "INTAKE.CASE_DETAILS_PAGE.WEBFORM_LABEL" | translate }}
              </ng-container>
              <ng-container *ngIf="!doc.isWebForm">
                {{ doc.uploadedRawDocument }}
              </ng-container>
            </td>

            <!-- Status Column -->
            <td [formGroupName]="i">
              <lib-form-select
                class="complete-select"
                [identifier]="doc.name + ' ' + i"
                formControlName="status"
                [options]="statusOptions"
                [newEmptyOption]="true"
                (change)="
                  updateDocsForAPI(doc, $event.target.value, 'docStatus')
                "
                [ariaLabel]="
                  'INTAKE.CASE_DETAILS_PAGE.DOCUMENT_STATUS'
                    | translate : { docName: doc.name }
                "
                [noLabel]="true"
              ></lib-form-select>
            </td>
            <td [formGroupName]="i">
              <lib-form-select
                class="complete-select"
                [identifier]="doc.name + ' ' + i"
                formControlName="gcmsUploadStatus"
                [options]="gcmsStatusOptions"
                [newEmptyOption]="true"
                [noLabel]="true"
                (change)="
                  updateDocsForAPI(doc, $event.target.value, 'gcmsStatus')
                "
                [ariaLabel]="
                  'INTAKE.CASE_DETAILS_PAGE.GCMS_DOCUMENT_STATUS'
                    | translate : { docName: doc.name }
                "
                [isDocUploadFailed]="doc.gcmsUploadStatus"
              ></lib-form-select>
            </td>

            <!-- Action Column -->
            <td class="actions">
              <ng-container *ngIf="doc.isWebForm">
                <a [routerLink]="doc.link" category="secondary">
                  <!-- NEED TO ADD VIEW ABILITY OF READ ONLY FORM -->
                  {{ "INTAKE.CASE_DETAILS_PAGE.VIEW" | translate }}
                  <span class="visually-hidden">{{ doc.formattedName }}</span>
                </a>
              </ng-container>
              <ng-container *ngIf="!doc.isWebForm">
                <button
                  category="secondary"
                  type="button"
                  (click)="downloadFile(doc)"
                >
                  {{ "INTAKE.CASE_DETAILS_PAGE.DOWNLOAD" | translate }}
                  <span class="visually-hidden">{{
                    doc.uploadedRawDocument
                  }}</span>
                </button>
              </ng-container>
              <p
                *ngIf="
                  !doc.uploadedRawDocument ||
                  !doc.uploadedRawDocument[0] ||
                  !doc.link
                "
                class="visually-hidden"
              >
                {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</ng-container>
