import {
  Component,
  OnInit,
  SecurityContext,
  ViewEncapsulation,
} from "@angular/core";
import { AlertService, documentTypes } from "lib";
import { ConfigMetadata } from "../webform-view-table.model";
import { State } from "../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { Document } from "../../models/document.model";
import { DocumentService } from "../../services/document/document.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { WebformViewTableService } from "../webform-view-table.service";
import { first } from "rxjs";

@Component({
  selector: "prcw-webform-view-imm5562-page",
  templateUrl: "./webform-view-imm5562-page.component.html",
  styleUrls: ["./webform-view-imm5562-page.component.scss"],
})
export class WebformViewImm5562PageComponent implements OnInit {
  public lang: string;
  public isLoading: boolean = true;
  public imm5562DocTypeId: number = documentTypes.imm5562.id;
  protected caseId: string; // this is the case ID
  protected formDocumentId?: number; // this variable is the IMM008 documentId for the selected Case
  public formData: any; // form data as they come from the API
  public formMetadata: ConfigMetadata; // Form meta data from formConfigsFunc

  constructor(
    private intakeStore: Store<State>,
    private documentService: DocumentService,
    protected translate: TranslateService,
    protected formTableService: WebformViewTableService,
    private alertService: AlertService,
    public routeLocalizer: RouteLocalizerService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getStoredCase();
    this.getImm5562Data();
  }

  ngAfterContentInit() {}

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  protected getStoredCase(): void {
    this.intakeStore
      .select("intake")
      .pipe(first())
      .subscribe((intakeData) => {
        if (intakeData && intakeData.selectedApplication) {
          const application = intakeData.selectedApplication;
          this.caseId = application?.id;
          const formDocument = application.documents?.find(
            (doc: Document) => doc?.documentTypeId === this.imm5562DocTypeId
          );
          this.formDocumentId = formDocument?.id;
        }
      });
  }

  public async getImm5562Data() {
    if (this.caseId && this.formDocumentId) {
      try {
        const data = await this.documentService.getWebformDataForCaseworkerView(
          this.caseId,
          this.formDocumentId,
          this.lang
        );

        //regex to get body from raw HTML returned
        this.formData = this.sanitizer.sanitize(
          SecurityContext.HTML,
          data.message.match(/<body[^>]*>[\s\S]*<\/body>/gi)
        );
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    }
    this.isLoading = false;
  }
}
