/*
NavigationGuard  implements the CanDeactivate feature of angular which happens when the route changes
Preventing route navigation away from component if the condition is met (i.e the form hasn't been saved yet)

More details:
https://medium.com/@tobias.ljungstrom/how-to-use-a-custom-dialogue-with-the-candeactivate-route-guard-in-angular-385616470b6a
*/
import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../../core/auth-module/services/auth.service";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavigationGuard implements CanDeactivate<CanComponentDeactivate> {
  public isAuthenticated: any;

  constructor(private authService: AuthService) {
    // Checks if you arent authenticated this will allow the navigation to continue to the login page
    this.isAuthenticated = this.authService.checkSession();
  }

  canDeactivate(component: CanComponentDeactivate) {
    return this.isAuthenticated
      ? component.canDeactivate
        ? component.canDeactivate()
        : false
      : true;
  }
}
