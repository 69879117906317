import { createAction, props } from "@ngrx/store";

import {
  Case,
  ReturnReason,
  UpdatedPhoto,
} from "@pr-caseworker/app/core/cases-module/models/case.model";
import {
  ApplicationDocument,
  ApplicationFormDocs,
  Document,
} from "@pr-caseworker/app/core/models/document.model";
import { RenewalReturnReasonsById } from "@pr-caseworker/app/renewal-module/models/renewal-photo.model";

export enum SelectedApplicationActionTypes {
  SetSelectedApplication = "[RenewalState] Set Selected Application",
  SetSelectedApplicationById = "[RenewalState] Set Selected Application By Id",
  SetCurrentCaseError = "[RenewalState] Set Current Case Error",
  UpdateDocs = "[RenewalState] Update Docs",
  GetAppDocTypes = "[RenewalState] Get Application Doc Types",
  SetAppDocTypes = "[RenewalState] Set Application Doc Types",
  GetUrgentAppDocTypes = "[RenewalState] Get Urgent Application Doc Types",
  SetUrgentAppDocTypes = "[RenewalState] Set Urgent Application Doc Types",
  GetAppFormTypes = "[RenewalState] Get Application Form Types",
  SetAppFormTypes = "[RenewalState] Set Application Form Types",
  SetSelectedReturnReasons = "[RenewalState] Set Application Return Reasons",
  GetSelectedReturnReasons = "[RenewalState] Get Application Return Reasons",
  UpdatePhoto = "[RenewalState] Update Photo",
  UpdatePhotoDownload = "[RenewalState] Update Photo Download Link",
  SetPhotoFrontReturnReasons = "[RenewalState] Set Photo Front Return Reasons",
  SetPhotoBackReturnReasons = "[RenewalState] Set Photo Back Return Reasons",
  ToggleClientInfoEditing = "[RenewalState] Toggle Client Info Editing",
  SetCaseUpdated = "[RenewalState] Set Case Updated",
  ClearSelectedCaseState = "[RenewalState] Clear Selected Case State",
  UpdateBulkDownloadIsLoading = "[RenewalState] Update Bulk Download is Loading",
}

export const setSelectedApplication = createAction(
  SelectedApplicationActionTypes.SetSelectedApplication,
  props<{ payload: Case }>()
);

export const setSelectedApplicationById = createAction(
  SelectedApplicationActionTypes.SetSelectedApplicationById,
  props<{ payload: string }>()
);

export const setCurrentCaseError = createAction(
  SelectedApplicationActionTypes.SetCurrentCaseError,
  props<{ payload: CasesApiError }>()
);

export const updateDocs = createAction(
  SelectedApplicationActionTypes.UpdateDocs,
  props<{ payload: Document[] }>()
);

export const getAppDocsList = createAction(
  SelectedApplicationActionTypes.GetAppDocTypes,
  props<{ payload: number }>()
);

export const setAppDocsList = createAction(
  SelectedApplicationActionTypes.SetAppDocTypes,
  props<{ payload: ApplicationDocument[] }>()
);

export const getUrgentAppDocsList = createAction(
  SelectedApplicationActionTypes.GetUrgentAppDocTypes
);

export const setUrgentAppDocsList = createAction(
  SelectedApplicationActionTypes.SetUrgentAppDocTypes,
  props<{ payload: ApplicationDocument[] }>()
);

export const getAppFormsList = createAction(
  SelectedApplicationActionTypes.GetAppFormTypes
);

export const setAppFormsList = createAction(
  SelectedApplicationActionTypes.SetAppFormTypes,
  props<{ payload: ApplicationFormDocs[] }>()
);

export const setSelectedReturnReasons = createAction(
  SelectedApplicationActionTypes.SetSelectedReturnReasons,
  props<{ payload: ReturnReason[] }>()
);

export const getSelectedReturnReasons = createAction(
  SelectedApplicationActionTypes.GetSelectedReturnReasons
);

export const updatePhoto = createAction(
  SelectedApplicationActionTypes.UpdatePhoto,
  props<{ payload: UpdatedPhoto }>()
);

export const updatePhotoDownload = createAction(
  SelectedApplicationActionTypes.UpdatePhotoDownload,
  props<{ payload: string }>()
);

export const setPhotoFrontReturnReasons = createAction(
  SelectedApplicationActionTypes.SetPhotoFrontReturnReasons,
  props<{ payload: RenewalReturnReasonsById }>()
);

export const setPhotoBackReturnReasons = createAction(
  SelectedApplicationActionTypes.SetPhotoBackReturnReasons,
  props<{ payload: RenewalReturnReasonsById }>()
);

export const toggleClientInfoEditing = createAction(
  SelectedApplicationActionTypes.ToggleClientInfoEditing,
  props<{ payload: boolean }>()
);

export const clearSelectedCaseState = createAction(
  SelectedApplicationActionTypes.ClearSelectedCaseState
);

export const updateBulkDownloadIsLoading = createAction(
  SelectedApplicationActionTypes.UpdateBulkDownloadIsLoading,
  props<{ payload: boolean }>()
);

export interface CasesApiError {
  caseId: number | string;
  apiLoadingError?: string;
}
