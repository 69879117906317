<table class="submissionTable" *ngIf="!!submittedTime">
  <tr>
    <th class="submission-title">
      {{ "INTAKE.INTAKE_CASE_DETAILS.TIMESTAMP_TITLE" | translate }}
    </th>
    <td *ngIf="!submittedTime">
      {{ "INTAKE.INTAKE_CASE_DETAILS.NO_TIMESTAMP_TITLE" | translate }}
    </td>
    <td *ngIf="submittedTime">
      {{ formattedSubmissionTime | date : "yyyy/MM/dd HH:mm" }} (UTC)
    </td>
  </tr>
</table>
