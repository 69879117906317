import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConfirmationTableFilterService {
  public defaultFilters: ConfirmationTableSelections = {
    filters: {
      isAssigned: null,
      caseStatusId: null,
      officeId: null,
      assignedToMe: false,
      cognitoCaseWorkerId: null,
    },
    search: {
      searchTerm: null,
    },
    sortField: null,
    sortDirection: null,
  };
  public selections = new BehaviorSubject<ConfirmationTableSelections>(
    this.defaultFilters
  );
  constructor() {}
}

export type ConfirmationTableSelections = {
  filters: ConfirmationFilters;
  search: ConfirmationSearch;
  sortField: string | null;
  sortDirection: "ASC" | "DESC" | null;
};

export type ConfirmationFilters = {
  isAssigned: boolean | null;
  caseStatusId: string | null | undefined;
  officeId: string | null;
  assignedToMe: boolean | null | undefined;
  cognitoCaseWorkerId: string | null | undefined;
};

export type ConfirmationSearch = {
  searchTerm: string | null | undefined;
};
