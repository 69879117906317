import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, ModalService, ValidationService } from "lib";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { CasesService } from "../../../core/cases-module/cases.service";

@Component({
  selector: "prcw-case-information",
  templateUrl: "./case-information.component.html",
  styleUrls: ["./case-information.component.scss"],
})
export class CaseInformationComponent implements OnInit, OnDestroy {
  public case: any;
  public editInformation = false;
  public resendEnabled = false;
  public loading = false;
  public caseForm = this.fb.group({
    uci: [null, Validators.required],
    applicationNumber: [null, Validators.required],
    createdAt: [{ value: "", disabled: true }],
    surname: [null, Validators.required],
    givenName: [null],
    dob: [
      null,
      [
        Validators.required,
        this.validationService.validatorDateWithWildcards,
        this.validationService.validatorDatePast,
      ],
    ],
    emailAddress: [{ value: "", disabled: true }],
    clientLanded: [{ value: false, disabled: true }, Validators.required],
    isPrincipalApplicant: [
      { value: false, disabled: true },
      Validators.required,
    ],
  });

  @Output() public editInformationAlert: EventEmitter<boolean> =
    new EventEmitter();

  //  Reset Pass for Client
  public alertResendSuccess: string;

  private valueChanged: boolean = false;
  private formChangeSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private validationService: ValidationService,
    private casesService: CasesService,
    private authService: AuthService,
    private alertService: AlertService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const self = this;
    this.activatedRoute.data.subscribe((data) => {
      this.case = data.case;
      self.enableResendPasswordButton();
      this.setFormValues();
    });

    const displayName =
      this.case.givenName !== null
        ? `${this.case.surname}, ${this.case.givenName}`
        : `${this.case.surname}`;

    this.translate
      .get("CASE_DETAILS.RESET.SUCCESS", {
        name: displayName,
      })
      .subscribe((res) => {
        this.alertResendSuccess = res;
      });
  }

  ngOnDestroy(): void {
    if (!!this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  public setFormValues() {
    this.caseForm.controls.uci.setValue(this.case.uci);
    this.caseForm.controls.applicationNumber.setValue(
      this.case.applicationNumber
    );
    this.caseForm.controls.createdAt.setValue(
      this.formatDate(this.case.createdAt)
    );
    this.caseForm.controls.surname.setValue(this.case.surname);
    this.caseForm.controls.givenName.setValue(this.case.givenName);
    this.caseForm.controls.dob.setValue(this.formatDOB);
    this.caseForm.controls.emailAddress.setValue(this.case.emailAddress);
    this.caseForm.controls.clientLanded.setValue(this.case.clientLanded);
    this.caseForm.controls.isPrincipalApplicant.setValue(
      this.case.isPrincipalApplicant
    );
  }

  get formatDOB(): string {
    const dob = this.case?.dob;
    const [year, month, day] = dob.split("-");
    let formattedDOB = "";

    [month, day].forEach((str, index, arr) => {
      const newStr = str.replace("00", "**");
      arr[index] = newStr;
      formattedDOB = year + "/" + arr.join("/");
    });

    return formattedDOB;
  }

  get formatDOBDb(): string {
    const dob = this.caseForm.controls.dob.value;
    return dob.split("*").join("0").split("/").join("-");
  }

  public formatDate(field: string) {
    return field?.substring(0, 10).replace(/-/g, "/");
  }

  get formatGivenName(): string {
    const givenName = this.caseForm.controls.givenName.value;
    return givenName === "" ? null : givenName;
  }

  public async editCase(): Promise<void> {
    const updatedCase = {
      ...this.caseForm.value,
      givenName: this.formatGivenName,
      dob: this.formatDOBDb,
    };
    try {
      this.loading = true;
      await this.casesService.editClientCase(this.case.id, updatedCase);
      await this.updateCase();
      this.loading = false;
      this.editInformationAlert.emit(false);
    } catch (error) {
      this.loading = false;
      this.editInformationAlert.emit(true);
    }
  }

  public async updateCase(): Promise<any> {
    const updatedCase = await this.casesService.getClientCasebyCaseId(
      this.case.id
    );
    this.case = updatedCase;
  }

  public enableSubmitButton(): boolean {
    return this.caseForm.valid && this.valueChanged;
  }

  public enableResendPasswordButton(): void {
    this.authService
      .checkForcePasswordChangeStatus(this.case.id)
      .then((value) => {
        this.resendEnabled = value;
      });
  }

  public handleSaveButton(): void {
    this.editInformation = false;
    this.editCase();
    this.valueChanged = false;
    this.formChangeSubscription.unsubscribe();
    this.caseForm.controls.clientLanded.disable();
    this.caseForm.controls.isPrincipalApplicant.disable();
  }

  public handleToggleButton(): void {
    this.setFormValues();
    this.editInformation = !this.editInformation;
    if (!this.editInformation) {
      this.formChangeSubscription.unsubscribe();
      this.valueChanged = false;
      this.caseForm.controls.clientLanded.disable();
      this.caseForm.controls.isPrincipalApplicant.disable();
    } else {
      this.caseForm.controls.clientLanded.enable();
      this.caseForm.controls.isPrincipalApplicant.enable();
      this.formChangeSubscription = this.caseForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => (this.valueChanged = true));
    }
  }

  public openResendPasswordModal(): void {
    this.modalService.open("resendPasswordModal");
  }

  public closeResendPasswordModal(): void {
    this.modalService.close("resendPasswordModal");
  }

  public async resendPassword(): Promise<any> {
    this.closeResendPasswordModal();
    try {
      await this.authService.resendClientPassword(this.case.id);
      this.alertService.success(this.alertResendSuccess);
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.TECHNICAL");
  }
}
