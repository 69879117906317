import { Component, OnInit } from "@angular/core";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";

@Component({
  selector: "prcw-case-list-page",
  templateUrl: "./case-list-page.component.html",
  styleUrls: ["./case-list-page.component.scss"],
})
export class CaseListPageComponent implements OnInit {
  public currentLang: string;

  constructor(public routeLocalizer: RouteLocalizerService) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("CASELIST.PAGE_TITLE");
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
  }
}
