<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml">
  <head prefix="og: http://ogp.me/ns#">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta charset="utf-8" />
    <title>Service Unavailable - Canada.ca</title>
    <meta content="width=device-width,initial-scale=1" name="viewport" />

    <link
      rel="stylesheet"
      href="https://www.canada.ca/etc/designs/canada/wet-boew/css/wet-boew.min.css"
    />
    <link
      rel="stylesheet"
      href="https://www.canada.ca/etc/designs/canada/wet-boew/css/theme.min.css"
    />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
      integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
      crossorigin="anonymous"
    />

    <link
      href="https://www.canada.ca/etc/designs/canada/wet-boew/assets/favicon.ico"
      rel="icon"
      type="image/x-icon"
    />

    <noscript>
      <link
        rel="stylesheet"
        href="https://www.canada.ca/etc/designs/canada/wet-boew/css/noscript.min.css"
      />
    </noscript>
  </head>

  <body>
    <header>
      <div id="wb-bnr" class="container">
        <div class="row">
          <div class="brand col-xs-8 col-sm-9 col-md-6">
            <a href="https://www.canada.ca/content/canada.html" target="_blank">
              <img
                src="https://www.canada.ca/etc/designs/canada/wet-boew/assets/sig-blk-en.svg"
                alt="Government of Canada / Gouvernement du Canada"
              />
            </a>
          </div>
        </div>
      </div>
    </header>

    <main property="mainContentOfPage" resource="#wb-main" class="container">
      <h1 id="wb-cont" class="wb-inv">Service Unavailable</h1>
      <div class="row mrgn-tp-lg mrgn-bttm-lg">
        <div class="col-md-12">
          <div class="row">
            <section>
              <div class="col-md-6">
                <div class="mwstext section">
                  <div class="row">
                    <div
                      class="col-xs-3 col-sm-2 col-md-2 text-center mrgn-tp-md"
                    >
                      <span
                        class="glyphicon glyphicon-warning-sign glyphicon-error"
                      ></span>
                    </div>
                    <div class="col-xs-9 col-sm-10 col-md-10">
                      <h2 class="emphasis mrgn-tp-md">
                        This service is currently not available
                      </h2>
                      <p class="pagetag"><b>Error 503</b></p>
                    </div>
                  </div>
                  <p>
                    The web server that provides this service is temporarily
                    down for maintenance. We suggest that you try again in 90
                    minutes or later in the day.
                  </p>
                  <p>We apologize for the inconvenience.</p>
                </div>
              </div>
            </section>
            <section lang="fr">
              <div class="col-md-6">
                <div class="mwstext section">
                  <div class="row">
                    <div
                      class="col-xs-3 col-sm-2 col-md-2 text-center mrgn-tp-md"
                    >
                      <span
                        class="glyphicon glyphicon-warning-sign glyphicon-error"
                      ></span>
                    </div>
                    <div class="col-xs-9 col-sm-10 col-md-10">
                      <h2 class="emphasis mrgn-tp-md">
                        Le service est actuellement indisponible
                      </h2>
                      <p class="pagetag"><b>Erreur 503</b></p>
                    </div>
                  </div>
                  <div class="row mrgn-bttm-lg">
                    <div class="col-md-12">
                      <p>
                        Le serveur Web auquel vous tentez d’accéder est
                        temporairement hors service à des fins d’entretien. Nous
                        vous suggérons réessayez dans 90 minutes ou plus tard
                        dans la journée.
                      </p>
                      <p>Nous nous excusons de cet inconvénient.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
    <footer role="contentinfo" id="wb-info">
      <div class="brand">
        <div class="container">
          <div class="row">
            <div class="col-xs-6 visible-sm visible-xs tofpg">
              <a href="#wb-cont"
                >Top of page / <span lang="fr">Haut de la page</span>
                <span class="glyphicon glyphicon-chevron-up"></span
              ></a>
            </div>
            <div class="col-xs-6 col-md-12 text-right">
              <img
                src="https://www.canada.ca/etc/designs/canada/wet-boew/assets/wmms-blk.svg"
                alt="Symbol of the Government of Canada / Symbole du gouvernement du Canada"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </body>
</html>
