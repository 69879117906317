<nav class="navbar jds-escape">
  <div class="navbar__container">
    <ul class="navbar__list">
      <li [class.active]="isLinkActive('INTAKE')" class="navbar__item">
        <a
          [class.active]="isLinkActive('INTAKE')"
          [routerLink]="routeLocalizer.get(lang, 'APPLICATION_CASES')"
          class="btn-unstyled navbar__item-link"
        >
          {{ "NAVBAR.APPLICATION" | translate }}
        </a>
      </li>
      <li class="navbar__item" [class.active]="isLinkActive('CONFIRMATION')">
        <a
          [class.active]="isLinkActive('CONFIRMATION')"
          [routerLink]="routeLocalizer.get(lang, 'CONFIRMATION_CASES')"
          class="navbar__item-link"
        >
          {{ "NAVBAR.CONFIRMATION" | translate }}
        </a>
      </li>
      <li class="navbar__item" [class.active]="isLinkActive('RENEWAL')">
        <a
          [class.active]="isLinkActive('RENEWAL')"
          [routerLink]="routeLocalizer.get(lang, 'RENEWAL_TRAVEL_DOC_CASES')"
          class="navbar__item-link"
        >
          {{ "NAVBAR.RENEWAL" | translate }}
        </a>
      </li>
    </ul>
  </div>
</nav>
