import { Injectable } from "@angular/core";
import { combinedLists } from "./combinedLists";
@Injectable({
  providedIn: "root",
})
export class LovService {
  lovs: lovsListModel = {
    preferenceLanguage: combinedLists.PreferenceLanguageList,
    contactLanguage: combinedLists.ContactLanguageList,
    provinceAbbrev: combinedLists.ProvinceAbbrevList,
    city: combinedLists.CityList,
    gender: combinedLists.GenderMelList,
    eyeColor: combinedLists.EyeColorList,
    countryOfBirth: combinedLists.CountryOfBirthList,
    countryOfCitizenship: combinedLists.CountryOfCitizenshipList,
    countryOfLastPermanentResident:
      combinedLists.CountryOfLastPermanentResidentList,
    country: combinedLists.CountryList,
    foreignCountry5444: combinedLists.json5444ForeignCountryList,
    immigrationStatus: combinedLists.ImmigrationStatusList,
    maritalStatus: combinedLists.MaritalStatusList,
    maritalStatusHistory: combinedLists.MaritalStatusHistoryList,
    stateAbbrev: combinedLists.StateAbbrevList,
    phoneType: combinedLists.PhoneTypeList,
    countryOfIssue: combinedLists.CountryTravelDocumentList,
    educationLevel: combinedLists.EducationLevelList,
    OfficeList: combinedLists.OfficeList,
    officialLanguage: combinedLists.OfficialLanguageList,
    relationshipToPA: combinedLists.RelationshipToPAList,
    dependantType: combinedLists.DependantTypeList,
    principalMaritalStatus: combinedLists.json5406PrincipalMaritalStatusList,
    partnerMaritalStatus: combinedLists.json5406PartnerMaritalStatusList,
    otherMaritalStatus: combinedLists.json5406OtherMaritalStatusList,
    gender5444: combinedLists.json5444GenderCitList,
    eyeColor5444: combinedLists.json5444EyeColorList,
    lossType5444: combinedLists.json5444LossTypeList,
    reasonsForAbsence5444: combinedLists.json5444ReasonsForAbsenceList,
    relationship5444: combinedLists.json5444RelationshipList,
    statusInCanada5444: combinedLists.json5444StatusInCanadaList,
    province: combinedLists.ProvinceList,
    maritalStatus5444: combinedLists.json5444MaritalStatusList,
    applicantSituationPRTD5444:
      combinedLists.json5444ApplicantSituationPRTDList,
    applicantSituationPRC5444: combinedLists.json5444ApplicantSituationPRCList,
    guardian5444: combinedLists.json5444GuardianList,
    trueFalse: combinedLists.TrueFalseList,
    referralPartnerList: combinedLists.ReferralPartnerList,
    referralPartnerCaseworkerList: combinedLists.ReferralPartnerCaseworkerList,
    designatedEntityList: combinedLists.DesignatedEntityList,
  };
}
export interface lovModel {
  value: string;
  text: {
    fr: string;
    en: string;
  };
}
interface cityModel {
  [provinceCode: string]: lovModel[];
}
interface lovsListModel {
  preferenceLanguage: lovModel[];
  contactLanguage: lovModel[];
  provinceAbbrev: lovModel[];
  city: cityModel;
  gender: lovModel[];
  eyeColor: lovModel[];
  countryOfBirth: lovModel[];
  countryOfCitizenship: lovModel[];
  countryOfLastPermanentResident: lovModel[];
  country: lovModel[];
  foreignCountry5444: lovModel[];
  immigrationStatus: lovModel[];
  maritalStatus: lovModel[];
  maritalStatusHistory: lovModel[];
  stateAbbrev: lovModel[];
  phoneType: lovModel[];
  countryOfIssue: lovModel[];
  educationLevel: lovModel[];
  OfficeList: lovModel[];
  officialLanguage: lovModel[];
  relationshipToPA: lovModel[];
  dependantType: lovModel[];
  principalMaritalStatus: lovModel[];
  partnerMaritalStatus: lovModel[];
  otherMaritalStatus: lovModel[];
  gender5444: lovModel[];
  eyeColor5444: lovModel[];
  lossType5444: lovModel[];
  reasonsForAbsence5444: lovModel[];
  relationship5444: lovModel[];
  statusInCanada5444: lovModel[];
  province: lovModel[];
  maritalStatus5444: lovModel[];
  applicantSituationPRTD5444: lovModel[];
  applicantSituationPRC5444: lovModel[];
  guardian5444: lovModel[];
  trueFalse: lovModel[];
  referralPartnerList: lovModel[];
  referralPartnerCaseworkerList: lovModel[];
  designatedEntityList: lovModel[];
}
