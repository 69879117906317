<p>{{ 'CASE_DETAILS.CASE_CONFIRMATION.BODY' | translate}}</p>

<lib-file-upload
  *ngIf="!ecoprExistsModal"
  #uploadFile
  fileTypes="application/pdf"
  [disabled]="!allowUploadECopr"
  id="confirmationeCoPRUpload"
  (selectFile)="selectCoprFile($event)"
  >{{ 'CASE_DETAILS.CASE_CONFIRMATION.UPLOAD_DOCUMENT' | translate
  }}</lib-file-upload
>
<button
  *ngIf="ecoprExistsModal"
  #focusUploadButton
  (click)="openModal('confirmReplaceCopr')"
  category="primary"
  [disabled]="!allowUploadECopr"
>
  {{ 'CASE_DETAILS.CASE_CONFIRMATION.UPLOAD_DOCUMENT' | translate }}
</button>
<p *ngIf="fileError" class="upload-error">{{ fileError }}</p>

<table class="cases-table">
  <tbody>
    <tr>
      <!-- File has not been uploaded -->
      <td *ngIf="previewName === ''">
        {{ 'CASE_DETAILS.CASE_CONFIRMATION.NO_DOCUMENT' | translate}}
      </td>
      <!-- File exists -->
      <td class="cases-table__copr" *ngIf="previewName !== ''">
        <p>{{ previewName }}</p>
        <button
          *ngIf="ecoprExistsModal"
          category="secondary"
          (click)="downloadCopr()"
        >
          {{ 'CASE_DETAILS.CASE_CONFIRMATION.VIEW_DOCUMENT' | translate}}
        </button>
      </td>
      <td class="cases-table__delete" *ngIf="previewName !== ''">
        <button
          category="plain"
          color="critical"
          (click)="openModal('confirmDeleteCopr')"
          [disabled]="case.caseStatusId === 4"
        >
          <lib-icon-chooser icon="delete"></lib-icon-chooser>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<!-- MODALS -->
<lib-modal
  id="confirmDeleteCopr"
  [title]="'CASE_DETAILS.MODAL.COPR.HEADING' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p>{{ 'CASE_DETAILS.MODAL.COPR.BODY' | translate }}</p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" color="critical" (click)="removeFile()">
      {{ 'CASE_DETAILS.MODAL.COPR.CONFIRM' | translate }}
    </button>
    <button category="secondary" (click)="closeModal('confirmDeleteCopr')">
      {{ 'CASE_DETAILS.MODAL.COPR.DECLINE' | translate }}
    </button>
  </ng-container>
</lib-modal>

<lib-modal
  id="confirmReplaceCopr"
  [title]="'CASE_DETAILS.MODAL.COPR.HEADING' | translate"
>
  <ng-container modalBody>
    <p>{{ 'CASE_DETAILS.MODAL.COPR.UPLOAD' | translate }}</p>
    <p *ngIf="fileError" class="upload-error">{{ fileError }}</p>
  </ng-container>
  <ng-container modalButtons>
    <lib-file-upload
      #uploadFile
      fileTypes="application/pdf"
      buttonStyle="primary-alt"
      (selectFile)="selectCoprFileFromModal($event)"
      id="replaceConfirmationeCoPR"
      >{{ 'CASE_DETAILS.CASE_CONFIRMATION.UPLOAD_DOCUMENT' | translate
      }}</lib-file-upload
    >
    <button category="secondary" (click)="closeModal('confirmReplaceCopr')">
      {{ 'CASE_DETAILS.MODAL.COPR.DECLINE' | translate }}
    </button>
  </ng-container>
</lib-modal>
