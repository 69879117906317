import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  AlertService,
  ModalService,
  ValidationService,
  FormSelectOption,
} from "lib";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { CasesService } from "../../../core/cases-module/cases.service";
import { LovService } from "projects/lib/src/public-api";
@Component({
  selector: "prcw-create-case-page",
  templateUrl: "./create-case-page.component.html",
  styleUrls: ["./create-case-page.component.scss"],
})
export class CreateCasePageComponent implements OnInit {
  private regexName: RegExp =
    /^[a-zA-Za-zéàèùâêîôûëïöüæçœÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ\r\s][a-zA-Z-'a-zéàèùâêîôûëïöüæçœîôÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ\r\s]*$/;

  public caseForm = this.fb.group({
    clientLanded: [false, Validators.required],
    uci: [
      null,
      [
        Validators.required,
        Validators.maxLength(10),
        this.validationService.validatorOnlyNumbers,
      ],
    ],
    isPrincipalApplicant: [false, Validators.required],
    applicationNumber: [null, [Validators.required, Validators.maxLength(10)]],
    surname: [
      null,
      {
        validators: [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(this.regexName),
        ],
      },
    ],
    givenName: [
      null,
      {
        validators: [
          Validators.maxLength(100),
          Validators.pattern(this.regexName),
        ],
      },
    ],
    dob: [
      null,
      [
        Validators.required,
        this.validationService.validatorDateWithWildcards,
        this.validationService.validatorDatePast,
      ],
    ],
    office: [
      null,
      {
        Validators: [Validators.required],
      },
    ],
    emailAddress: [null, [Validators.required, Validators.email]],
  });

  public createCaseError = false;
  public max10 = { max: "10" };
  public max100 = { max: "100" };
  public currentLang: string;
  public showClientDetailsForm = false; //  used to show/hide form, toggle buttons
  public accountExistedModalId = "checkEmailModal";
  public isCaseSubmitted = false;
  public officeOptions: FormSelectOption[];

  constructor(
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private validationService: ValidationService,
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService,
    private casesService: CasesService,
    private modalService: ModalService,
    private lovService: LovService
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("CREATE_CASE.PAGE_TITLE");
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.officeOptions = this.lovService.lovs.OfficeList;
  }

  get formattedDOB(): string {
    const givenDOB = this.caseForm.controls.dob.value;
    return givenDOB.split("*").join("0");
  }

  get formattedEmail(): string {
    const email = this.caseForm.value.emailAddress;
    return email.toLowerCase().trim();
  }

  async verifyEmail(): Promise<any> {
    const email = this.caseForm.value.emailAddress;
    const emailTrim = email?.toLowerCase().trim();
    const emailExists = await this.casesService.doesEmailExist(emailTrim);

    return emailExists;
  }

  get cleanName(): string {
    const givenName = this.caseForm.value.givenName;
    return givenName ? givenName.trim() : null;
  }

  get cleanSurname(): string {
    const surname = this.caseForm.value.surname;
    return surname.trim();
  }

  get cleanApplicationNumber(): string {
    const applicationNumber = this.caseForm.value.applicationNumber;
    return applicationNumber.trim();
  }

  get cleanUci(): string {
    const uci = this.caseForm.value.uci;
    return uci.trim();
  }

  get isPrincipalApplicant(): boolean {
    const isPrincipalApplicant = this.caseForm.value.isPrincipalApplicant;
    return isPrincipalApplicant;
  }

  get hasAssignedOffice(): string {
    const office = this.caseForm.value.office;
    return office;
  }

  private setTabbingStart(formId: string): void {
    if (formId !== null && document.getElementById(formId)) {
      const formContainer = document.getElementById(formId);
      const allFormHeadings =
        formContainer &&
        Array.from<HTMLElement>(
          formContainer.querySelectorAll("h1, h2, h3, h4, h4, h6, p")
        );

      if (allFormHeadings !== null && allFormHeadings) {
        const firstFormHeading = allFormHeadings[0];
        firstFormHeading.setAttribute("tabindex", "0");
        firstFormHeading.focus();
        firstFormHeading.style.outline = "0";
      }
    }
  }

  openAccountExistedModal() {
    this.modalService.open(this.accountExistedModalId);
  }

  closeAccountExistedModal() {
    // set isLoading to false;
    this.modalService.close(this.accountExistedModalId);
  }

  navigateToNextStep() {
    const emailField = this.caseForm.controls.emailAddress;
    const formId = "caseDetailsForm";
    this.showClientDetailsForm = !this.showClientDetailsForm;
    this.showClientDetailsForm ? emailField.disable() : emailField.enable();
    //  sets focus on start of form, after rest of form is rendered with *ngif, hence timeout
    setTimeout(() => {
      this.setTabbingStart(formId);
      this.modalService.close(this.accountExistedModalId);
    }, 0);
  }

  async handleToggleForm(isVerify: boolean): Promise<any> {
    if (isVerify) {
      try {
        await this.verifyEmail().then((emailExists) => {
          if (emailExists) {
            //  show modal
            // this.alertService.info(this.infoEmailExists);
            this.openAccountExistedModal();
          } else {
            const emailField = this.caseForm.controls.emailAddress;
            const formId = "caseDetailsForm";
            this.showClientDetailsForm = !this.showClientDetailsForm;
            this.showClientDetailsForm
              ? emailField.disable()
              : emailField.enable();
            //  sets focus on start of form, after rest of form is rendered with *ngif, hence timeout
            setTimeout(() => this.setTabbingStart(formId), 0);
          }
        });
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    } else {
      const emailField = this.caseForm.controls.emailAddress;
      const formId = "caseDetailsForm";
      this.showClientDetailsForm = !this.showClientDetailsForm;
      this.showClientDetailsForm ? emailField.disable() : emailField.enable();
      //  sets focus on start of form, after rest of form is rendered with *ngif, hence timeout
      setTimeout(() => this.setTabbingStart(formId), 0);
    }
  }

  public createCase(): void {
    this.isCaseSubmitted = true;
    //  .disabled form controls are exempt from validation, so want to reenable it before we build case object
    this.caseForm.controls.emailAddress.enable();
    const newCase = {
      ...this.caseForm.value,
      surname: this.cleanSurname,
      givenName: this.cleanName,
      dob: this.formattedDOB,
      emailAddress: this.formattedEmail,
      applicationNumber: this.cleanApplicationNumber,
      uci: this.cleanUci,
      isPrincipalApplicant: this.isPrincipalApplicant,
      officeId: +this.hasAssignedOffice,
    };

    this.casesService
      .createCase(newCase)
      .then(() => {
        this.createCaseError = false;
        this.routeLocalizer.goTo(this.currentLang, "CONFIRMATION_CASES");
        this.alertService.success(this.getAlertSuccess());
      })
      .catch((err: any) => {
        this.isCaseSubmitted = false;
        this.createCaseError = true;
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("CREATE_CASE.ERROR_ALERT.TECHNICAL_ERROR");
  }

  private get infoEmailExists(): string {
    return this.translate.instant("CREATE_CASE.INFO_ALERT.EMAIL");
  }

  private getAlertSuccess(): string {
    const firstName = this.caseForm.value.givenName
      ? `${this.caseForm.value.givenName} `
      : "";
    const displayValue = `${firstName}${this.caseForm.value.surname}, ${this.caseForm.value.uci}`;
    return this.translate.instant("CREATE_CASE.CONFIRM_ALERT.LABEL", {
      user: displayValue,
    });
  }
}
