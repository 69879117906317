import * as AuthActions from "./auth.actions";

// an user can have usersList attachhed to him
// ex. TeamLeads can have caseworkers etc
export class User {
  constructor(
    public username: string | undefined,
    public session: string | undefined,
    public isFirstLogin: boolean | undefined,
    public expiryDate: Date | undefined,
    public token: string | undefined,
    public userPool: string | undefined,
    public usersList?: Partial<User>[]
  ) {}
}

export interface State {
  user: User | null;
}

const initialState = {
  user: null,
};

export function authReducer(
  state: State = initialState,
  action: AuthActions.AuthActions
) {
  switch (action.type) {
    case AuthActions.LOGIN:
      const dateExpiry = new Date();
      dateExpiry.setHours(dateExpiry.getHours() + 12);
      const user = new User(
        action.payload.username.toLowerCase(),
        action.payload.session,
        action.payload.isFirstLogin,
        dateExpiry,
        action.payload.token,
        action.payload.userPool
      );
      return {
        ...state,
        user,
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        user: null,
      };
    case AuthActions.FIRST_LOGIN_COMPLETE:
      return {
        ...state,
        user: null,
      };
    case AuthActions.LOAD_USERS_LIST:
      const newUser = Object.assign({}, state.user);
      newUser.usersList = action.payload.usersList;
      return {
        ...state,
        user: newUser,
      };
    default:
      return state;
  }
}
