import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { LibModule } from "lib";

import { AppRoutingModule } from "../routing/app-routing.module";
import { BarcodeComponent } from "./barcodes/barcode-component/barcode.component";
import { BarcodesTableComponent } from "./barcodes/barcodes-table/barcodes-table.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RouterModule } from "@angular/router";
import { CasesModule } from "../core/cases-module/cases.module";
import { WebformViewTableModule } from "../core/webform-view-table-module/webform-view-table.module";

/* Intake/Application pages */
import { IntakeCaseListPageComponent } from "./pages/case-list-page/case-list-page.component";
import { CaseListDetailsPageComponent } from "./pages/case-details-page/case-details-page.component";
import { ESignatureDeclarationPageComponent } from "./pages/declaration/e-signature-declaration-page.component";
import { ESignatureWith5669DeclarationPageComponent } from "./pages/declaration with 5669/e-signature-with-5669-declaration-page.component";
import { IMM0008BarcodesPageComponent } from "./pages/IMM0008-barcodes-page/IMM0008-barcodes-page.component";

/* Intake/Application components */
import { ApplicationFormsTableComponent } from "./pages/case-details-page/application-forms-table/application-forms-table.component";
import { AssignmentStatusSectionComponent } from "./pages/case-details-page/assignment-status-section/assignment-status-section.component";
import { ClientInformationSectionComponent } from "./pages/case-details-page/client-information-section/client-information-section.component";
import { CaseListTableComponent } from "./components/case-list-table/case-list-table.component";
import { RepInfoSectionComponent } from "./pages/case-details-page/rep-info/rep-info.component";
import { PrimarySponsorSectionComponent } from "./pages/case-details-page/primary-sponsor/primary-sponsor.component";
import { ReferralPartnerSectionComponent } from "./pages/case-details-page/referral-partner/referral-partner.component";
import { SubmissionTimeComponent } from "./pages/case-details-page/submission-time/submission-time.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    ESignatureDeclarationPageComponent,
    ESignatureWith5669DeclarationPageComponent,
    IntakeCaseListPageComponent,
    IMM0008BarcodesPageComponent,
    BarcodeComponent,
    BarcodesTableComponent,
    CaseListTableComponent,
    CaseListDetailsPageComponent,
    AssignmentStatusSectionComponent,
    ClientInformationSectionComponent,
    RepInfoSectionComponent,
    PrimarySponsorSectionComponent,
    ReferralPartnerSectionComponent,
    SubmissionTimeComponent,
    ApplicationFormsTableComponent,
  ],
  imports: [
    CasesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    LibModule,
    AppRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
    WebformViewTableModule,
  ],
})
export class IntakeCasesModule {}
