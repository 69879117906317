<header class="case-header">
  <div class="case-status-title">
    <h1 class="emphasis">
      {{ "CASE_DETAILS.HEADINGS.CASE" | translate }} {{ case?.uci }} -
      {{ case?.givenName }} {{ case?.surname }}
    </h1>
    <lib-status-pill
      [text]="caseStatusText"
      [type]="caseStatusColour"
      class="case-status-item"
    >
    </lib-status-pill>
  </div>
  <div class="case-status-item" *ngIf="allowDeleteCase">
    <button
      id="btnDeleteCase"
      type="submit"
      (click)="handleDeleteCase()"
      category="primary"
      color="critical"
      [disabled]="!allowDeleteCase || isDeletingCase"
    >
      {{ "CASE_DETAILS.BUTTONS.DELETE_CASE" | translate }}
    </button>
  </div>
</header>
<div class="spinner" *ngIf="isDeletingCase">
  <lib-spinner
    [showSpinner]="isDeletingCase"
    [useOverlay]="true"
    [id]="'DELETE_CASE_SPINNER'"
    [fullPage]="true"
  >
  </lib-spinner>
</div>
<section>
  <h2 class="emphasis">
    {{ "CASE_DETAILS.HEADINGS.CLIENT_INFO" | translate }}
  </h2>
  <prcw-case-information
    (editInformationAlert)="handleEditInformation($event)"
  ></prcw-case-information>
</section>

<section class="case-documents">
  <h2
    *ngIf="this.case.addressDeclarationTimestamp !== null"
    class="case-documents__header"
  >
    {{ "CASE_DETAILS.HEADINGS.DOCUMENTS_INFO" | translate }}
  </h2>
  <prcw-case-document-table
    (displayRejectionModal)="handleDisplayRejectModal($event)"
    (updateDocumentStatus)="handleDocumentStatus($event)"
    (newCroppedImageInfo)="handleCroppedImageInfo($event)"
    [documents]="documentStatuses"
    [photoLink]="photoLocation"
    [photoName]="photoName"
    [case]="case"
  >
  </prcw-case-document-table>
</section>
<div class="spinner" *ngIf="isNavigatingAway">
  <lib-spinner
    [showSpinner]="isNavigatingAway"
    [fullPage]="false"
  ></lib-spinner>
</div>
<!-- If client has landed already we do not display this section -->
<section *ngIf="!case.clientLanded" class="case-confirmation">
  <h2 class="emphasis">
    {{ "CASE_DETAILS.HEADINGS.CASE_CONFIRMATION" | translate }}
  </h2>
  <prcw-case-confirmation-upload
    (ecoprExists)="handleEcoprExists($event)"
    (ecoprFileSelected)="handleEcoprFileSelected($event)"
    (removeEcoprError)="handleRemoveEcoprError($event)"
    [caseStatusId]="case.caseStatusId"
  >
  </prcw-case-confirmation-upload>
</section>
<lib-layout-row mt="2" gap="one-and-half">
  <a
    [routerLink]="
      routeLocalizer.get(currentLang, 'CONFIRMATION_CASES', '../../../../')
    "
    category="secondary"
  >
    {{ "CREATE_CASE.BACK_LINK" | translate }}
  </a>
  <button
    id="btnUpdateCase"
    type="submit"
    (click)="handleUpdateCase()"
    class="btn btn-primary"
    [disabled]="!(enableSubmitBtn || isModified) || isNavigatingAway"
  >
    {{ "CASE_DETAILS.BUTTONS.UPDATE" | translate }}
  </button>
</lib-layout-row>

<!-- MODALS -->
<div
  *ngIf="
    this.documentStatuses !== undefined &&
    this.documentStatuses[0] !== undefined &&
    this.documentStatuses[0]?.rejectionReasonsArray
  "
>
  <lib-modal
    [id]="addressRejectionModalId"
    [title]="'CASE_DETAILS.MODAL.REJECTION_ADDRESS_TITLE' | translate"
    (escape)="handleEscapedModal(addressRejectionModalId)"
  >
    <ng-container modalBody>
      <p>{{ "CASE_DETAILS.MODAL.REJECTION_ADDRESS_BODY" | translate }}</p>
      <prcw-rejection-input
        #addressRejection
        [caseId]="this.caseId"
        (closeRejectionModal)="handleRejectModalUpdate($event)"
        [documentType]="'ADDRESS'"
        [rejectionsArray]="addressRejectionReasons"
      >
      </prcw-rejection-input>
    </ng-container>
    <ng-container modalButtons>
      <button
        category="primary"
        [disabled]="!addressRejection.isValidInput"
        (click)="addressRejection.rejectDocument('ADDRESS')"
        (keydown)="addressRejection.onKeyPressReject($event)"
      >
        {{ "CASE_DETAILS.MODAL.REJECTION_ADDRESS_REJECT" | translate }}
      </button>
      <button
        category="secondary"
        (click)="addressRejection.cancelRejectDocument('ADDRESS')"
        (keydown)="addressRejection.onKeyPressCancel($event)"
      >
        {{ "CASE_DETAILS.MODAL.REJECTION_ADDRESS_CANCEL" | translate }}
      </button>
    </ng-container>
  </lib-modal>
</div>

<div
  *ngIf="
    this.documentStatuses !== undefined &&
    this.documentStatuses[1] !== undefined &&
    this.documentStatuses[1]?.rejectionReasonsArray
  "
>
  <lib-modal
    [id]="photoRejectionModalId"
    [title]="'CASE_DETAILS.MODAL.REJECTION_PHOTO_TITLE' | translate"
    (escape)="handleEscapedModal(photoRejectionModalId)"
  >
    <ng-container modalBody>
      <p>{{ "CASE_DETAILS.MODAL.REJECTION_PHOTO_BODY" | translate }}</p>
      <prcw-rejection-input
        #photoRejection
        [caseId]="this.caseId"
        (closeRejectionModal)="handleRejectModalUpdate($event)"
        [documentType]="'PHOTO'"
        [rejectionsArray]="photoRejectionReasons"
      >
      </prcw-rejection-input>
    </ng-container>
    <ng-container modalButtons>
      <button
        category="primary"
        [disabled]="!photoRejection.isValidInput"
        (click)="photoRejection.rejectDocument('PHOTO')"
        (keydown)="photoRejection.onKeyPressReject($event)"
      >
        {{ "CASE_DETAILS.MODAL.REJECTION_PHOTO_REJECT" | translate }}
      </button>
      <button
        category="secondary"
        (click)="photoRejection.cancelRejectDocument('PHOTO')"
        (keydown)="photoRejection.onKeyPressCancel($event)"
      >
        {{ "CASE_DETAILS.MODAL.REJECTION_PHOTO_CANCEL" | translate }}
      </button>
    </ng-container>
  </lib-modal>
</div>
<div>
  <lib-modal
    [id]="deleteCaseModalId"
    [title]="'CASE_DETAILS.MODAL.DELETE_CASE_TITLE' | translate"
    [deleteModal]="true"
  >
    <ng-container modalBody>
      <p class="subheading">
        {{ deleteCaseModalSubheading }}
      </p>
      <p>
        {{ "CASE_DETAILS.MODAL.DELETE_CASE.DESCRIPTION" | translate }}
      </p>
    </ng-container>
    <ng-container modalButtons>
      <button
        category="primary"
        color="critical"
        class="delete-case-button-modal"
        (click)="acceptDeleteCaseFromModal()"
      >
        {{ "CASE_DETAILS.BUTTONS.DELETE_CASE" | translate }}
      </button>
      <button category="secondary" (click)="cancelDeleteCaseModal()">
        {{ "CASE_DETAILS.BUTTONS.CANCEL" | translate }}
      </button>
    </ng-container>
  </lib-modal>
</div>
