import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "../../../../core/cases-module/models/case.model";
import { Subscription } from "rxjs";
import { allCaseStatuses } from "../case-details-models";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "prcw-assignment-status-section",
  templateUrl: "./assignment-status-section.component.html",
  styleUrls: ["./assignment-status-section.component.scss"],
})
export class AssignmentStatusSectionComponent implements OnInit, OnDestroy {
  public case: Case;
  public currentLang: string = "en";
  private subscList: Subscription[] = [];
  public caseStatusDescription: string | undefined = "";
  public assignmentGroup: FormGroup;

  constructor(
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    public routeLocalizer: RouteLocalizerService,
    public fb: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.getStoredCase();
    this.assignmentGroup = this.fb.group({
      caseworkerId: [this.caseworkerId],
      caseStatusDescription: [this.caseStatusDescription],
    });
  }

  public get caseworkerId(): string {
    return this.case?.cognitoCaseWorkerId
      ? this.case?.cognitoCaseWorkerId
      : this.translate.instant(
          "INTAKE.INTAKE_CASE_DETAILS.NO_ASSIGNMENT_LABEL"
        );
  }

  private getStoredCase(): void {
    this.subscList.push(
      this.store.select("intake").subscribe((currentCaseData: any) => {
        if (currentCaseData) {
          this.case = currentCaseData.selectedApplication;
          const caseStatusInfo = allCaseStatuses.find(
            (option) => option.statusId === this.case.caseStatusId
          );
          this.caseStatusDescription =
            this.currentLang === "fr"
              ? caseStatusInfo?.text?.fr
              : caseStatusInfo?.text?.en;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscList.forEach((s) => s.unsubscribe());
  }
}
