import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "lib-icon-chooser",
  templateUrl: "./icon-chooser.component.html",
  styleUrls: ["./icon-chooser.component.scss"],
})
export class IconChooserComponent implements OnInit {
  @Input() icon: string;

  constructor() {}

  ngOnInit(): void {}

  chooseIcon(icon: string): string {
    const icons: any = {
      danger: "fa-light fa-circle-xmark",
      success: "fa-light fa-circle-check",
      warning: "fa-light fa-circle-exclamation",
      info: "fa-light fa-circle-info",
      delete: "fa-light fa-trash-can",
      asterisk: "fa-light fa-asterisk",
      close: "fa-light fa-times",
      search: "fa-light fa-magnifying-glass",
      add: "fa-light fa-circle-plus",
      check: "fa-regular fa-circle-check",
      question: "fa-regular fa-circle-question",
      exclamation: "fa-regular fa-triangle-exclamation",
      infoRegular: "fa-regular fa-circle-info",
    };
    return `${icons[icon]}`;
  }
}
