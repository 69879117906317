import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InterFormsService {
  public spinnerEmitter: Subject<any> = new Subject();

  constructor() {}

  public startSpinner(actionName?: string, spinnerId?: string) {
    const spinner = {
      actionType: "START",
      actionName,
      spinnerId,
    };
    this.spinnerEmitter.next(spinner);
  }

  public stopSpinner(actionName?: string) {
    const spinner = {
      actionType: "STOP",
      actionName,
    };
    this.spinnerEmitter.next(spinner);
  }
}
