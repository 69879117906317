<div class="spinner" *ngIf="loading">
  <lib-spinner [showSpinner]="loading" [fullPage]="false"></lib-spinner>
</div>

<form class="client-information" *ngIf="!loading">
  <lib-layout-row gap="one-and-half">
    <lib-layout-col flex="1"
      ><lib-form-input
        identifier="uci"
        [formControl]="caseForm.controls.uci"
        [label]="'CREATE_CASE.FORM_LABELS.UCI' | translate"
        required="true"
        [editable]="editInformation ? true : false"
      >
        <span
          *ngIf="
            caseForm.get('uci')?.touched &&
            caseForm.get('uci')?.hasError('required')
          "
        >
          {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-input></lib-layout-col
    >
    <lib-layout-col flex="1"
      ><lib-form-input
        identifier="applicationNumber"
        [formControl]="caseForm.controls.applicationNumber"
        [label]="'CREATE_CASE.FORM_LABELS.APPLICATION_NUM' | translate"
        required="true"
        [editable]="editInformation ? true : false"
      >
        <span
          *ngIf="
            caseForm.get('applicationNumber')?.touched &&
            caseForm.get('applicationNumber')?.hasError('required')
          "
        >
          {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-input></lib-layout-col
    >
    <lib-layout-col flex="1">
      <lib-form-input
        identifier="createdAt"
        [formControl]="caseForm.controls.createdAt"
        [label]="'CASE_DETAILS.LABELS.CREATED_ON' | translate"
        required="true"
        [editable]="editInformation ? true : false"
      >
      </lib-form-input>
    </lib-layout-col>
  </lib-layout-row>
  <lib-layout-row gap="one-and-half">
    <lib-layout-col flex="1">
      <lib-form-input
        identifier="surname"
        [formControl]="caseForm.controls.surname"
        [label]="'CREATE_CASE.FORM_LABELS.SURNAME' | translate"
        required="true"
        [editable]="editInformation ? true : false"
      >
        <span
          *ngIf="
            caseForm.get('surname')?.touched &&
            caseForm.get('surname')?.hasError('required')
          "
        >
          {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-input></lib-layout-col
    >
    <lib-layout-col flex="1">
      <lib-form-input
        identifier="givenName"
        [formControl]="caseForm.controls.givenName"
        [label]="'CREATE_CASE.FORM_LABELS.GIVEN_NAME' | translate"
        [editable]="editInformation ? true : false"
      ></lib-form-input
    ></lib-layout-col>
    <lib-layout-col flex="1">
      <lib-form-input
        identifier="dob"
        [formControl]="caseForm.controls.dob"
        [label]="'CREATE_CASE.FORM_LABELS.DOB' | translate"
        maxlength="10"
        required="true"
        [editable]="editInformation ? true : false"
      >
        <span
          *ngIf="
            caseForm.get('dob')?.touched &&
            caseForm.get('dob')?.hasError('required')
          "
        >
          {{ "CREATE_CASE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            caseForm.get('dob')?.touched &&
            caseForm.get('dob')?.hasError('wildcardDate')
          "
        >
          {{ "CREATE_CASE.FORM_ERRORS.DATE" | translate }}
        </span>
        <span
          *ngIf="
            caseForm.get('dob')?.touched &&
            caseForm.get('dob')?.hasError('pastDate')
          "
        >
          {{ "CREATE_CASE.FORM_ERRORS.DOB_PAST" | translate }}
        </span>
      </lib-form-input></lib-layout-col
    >
  </lib-layout-row>
  <!-- email -->
  <lib-layout-row>
    <lib-form-input
      identifier="emailAddress"
      [formControl]="caseForm.controls.emailAddress"
      [label]="'CREATE_CASE.FORM_LABELS.EMAIL' | translate"
      required="true"
      [editable]="editInformation ? true : false"
    >
    </lib-form-input>
  </lib-layout-row>
  <!-- Principal applicant and Previous PR status (not editing)-->
  <div class="data-item" *ngIf="!editInformation">
    <span class="data-label data-label-checkbox">
      <label
        [innerHTML]="'CREATE_CASE.FORM_LABELS.CLIENT_IS_PA_LABEL' | translate"
      ></label>
    </span>
    <div class="checkbox-control checkbox-disabled data-item-row">
      <input
        id="isPrincipalApplicant"
        value="false"
        [formControl]="caseForm.controls.isPrincipalApplicant"
        type="checkbox"
      />
      <label
        [innerHTML]="
          'CREATE_CASE.FORM_LABELS.CLIENT_IS_PA_CHECKBOX' | translate
        "
        for="isPrincipalApplicant"
      >
      </label>
    </div>

    <span class="data-label data-label-checkbox">
      <label [innerHTML]="'CREATE_CASE.FORM_LANDING' | translate"></label>
    </span>
    <div class="checkbox-control checkbox-disabled">
      <input
        id="clientLanded"
        value="false"
        [formControl]="caseForm.controls.clientLanded"
        type="checkbox"
      />
      <label
        [innerHTML]="'CREATE_CASE.FORM_LABELS.LANDED' | translate"
        for="clientLanded"
      >
      </label>
    </div>
  </div>
  <!-- Principal applicant and Previous PR status (editing)-->
  <div class="data-item-edit__checkbox" *ngIf="editInformation">
    <span class="data-label data-label-checkbox-edit">
      <label
        [innerHTML]="'CREATE_CASE.FORM_LABELS.CLIENT_IS_PA_LABEL' | translate"
      ></label>
    </span>
    <div class="checkbox-control data-item-row-edit">
      <input
        value="true"
        id="isPrincipalApplicant"
        name="isPrincipalApplicant"
        [formControl]="caseForm.controls.isPrincipalApplicant"
        type="checkbox"
        (change)="caseForm.controls.isPrincipalApplicant.markAsTouched()"
        tabindex="0"
      />
      <label
        [innerHTML]="
          'CREATE_CASE.FORM_LABELS.CLIENT_IS_PA_CHECKBOX' | translate
        "
        for="isPrincipalApplicant"
      >
      </label>
    </div>

    <span class="data-label data-label-checkbox-edit">
      <label [innerHTML]="'CREATE_CASE.FORM_LANDING' | translate"></label>
    </span>
    <div class="checkbox-control data-item-row-edit">
      <input
        value="true"
        id="clientLanded"
        name="clientLanded"
        [formControl]="caseForm.controls.clientLanded"
        type="checkbox"
        (change)="caseForm.controls.clientLanded.markAsTouched()"
        tabindex="0"
      />
      <label
        [innerHTML]="'CREATE_CASE.FORM_LABELS.LANDED' | translate"
        for="clientLanded"
      >
      </label>
    </div>
  </div>
</form>
<lib-layout-row *ngIf="!loading" gap="one-and-half">
  <button
    *ngIf="!editInformation"
    type="submit"
    (click)="handleToggleButton()"
    category="secondary"
    class="btn-max"
  >
    {{ "CASE_DETAILS.CASE_INFORMATION.EDIT_BUTTON" | translate }}
  </button>
  <button
    *ngIf="editInformation"
    type="submit"
    (click)="handleSaveButton()"
    category="primary"
    [disabled]="!enableSubmitButton()"
    class="btn-max"
  >
    {{ "CASE_DETAILS.CASE_INFORMATION.SAVE_BUTTON" | translate }}
  </button>
  <button
    *ngIf="editInformation"
    type="submit"
    (click)="handleToggleButton()"
    category="secondary"
    class="btn-max"
  >
    {{ "CASE_DETAILS.CASE_INFORMATION.CANCEL_BUTTON" | translate }}
  </button>
  <div>
    <button
      type="button"
      (click)="openResendPasswordModal()"
      category="secondary"
      [disabled]="!resendEnabled"
      aria-describedby="resendTemporaryPasswordHelpText"
    >
      {{ "CASE_DETAILS.RESET.BUTTON" | translate }}
    </button>
    <p
      *ngIf="resendEnabled"
      class="help-text"
      id="resendTemporaryPasswordHelpText"
    >
      {{ "CASE_DETAILS.RESET.ENABLED_HELP_TEXT" | translate }}
    </p>
    <p
      *ngIf="!resendEnabled"
      class="help-text"
      id="resendTemporaryPasswordHelpText"
    >
      {{ "CASE_DETAILS.RESET.DISABLED_HELP_TEXT" | translate }}
    </p>
  </div>
</lib-layout-row>
<!-- MODALS -->
<lib-modal
  id="resendPasswordModal"
  [title]="'CASE_DETAILS.RESET.MODAL.HEADER' | translate"
>
  <ng-container modalBody>
    <p>{{ "CASE_DETAILS.RESET.MODAL.BODY" | translate }}</p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" (click)="resendPassword()">
      {{ "CASE_DETAILS.RESET.MODAL.CONFIRM" | translate }}
    </button>
    <button category="secondary" (click)="closeResendPasswordModal()">
      {{ "CASE_DETAILS.RESET.MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
