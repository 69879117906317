import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { UserGroup } from "lib";
import * as fromApp from "../../../store/app.reducer";
import { Auth } from "aws-amplify";
import routePaths from "../../../routing/route-paths";

@Injectable({ providedIn: "root" })
export class AWSGuard implements CanActivate {
  public currentLang: string;
  public isAuthenticated: boolean | undefined;

  constructor(private router: Router, private store: Store<fromApp.State>) {
    this.store.select(fromApp.selectRouteData).subscribe((data) => {
      this.currentLang = data?.lang;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): any {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const groups = user.signInUserSession.idToken.payload[
            "cognito:groups"
          ] as string[] | undefined;
          if (
            groups?.includes(UserGroup.CW) ||
            groups?.includes(UserGroup.TL)
          ) {
            resolve(true);
          } else {
            resolve(
              this.router.parseUrl(
                `/${this.currentLang}/${
                  this.currentLang === "fr"
                    ? routePaths.fr.LOGIN
                    : routePaths.en.LOGIN
                }`
              )
            );
          }
        })
        .catch(() => {
          resolve(
            this.router.parseUrl(
              `/${this.currentLang}/${
                this.currentLang === "fr"
                  ? routePaths.fr.LOGIN
                  : routePaths.en.LOGIN
              }`
            )
          );
        });
    });
  }
}
