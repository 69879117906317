import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "lib";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "prcw-multi-factor-code",
  templateUrl: "./multi-factor-code.component.html",
  styleUrls: ["./multi-factor-code.component.scss"],
})
export class MultiFactorCodeComponent implements OnInit {
  @Input() public cellphone: string;
  public multiFactorAuthForm: FormGroup;
  public currentLang: string;
  public verificationTextLabel: string;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.translate
      .get("MFA.VERIFICATION_TEXT", {
        cellphone: this.cellphone,
      })
      .subscribe((res) => {
        this.verificationTextLabel = res;
      });

    this.multiFactorAuthForm = new FormGroup({
      verificationCode: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^\d+$/),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    });
  }

  public async onSubmit() {
    if (this.multiFactorAuthForm.status === "INVALID") {
      return;
    } else {
      const verificationCode = this.multiFactorAuthForm.value.verificationCode;
      try {
        await this.authService.confirmMFASignIn(verificationCode);
      } catch (error) {
        switch (error.code) {
          case "CodeMismatchException":
            this.alertService.danger(this.alertIncorrectCode);
            break;
          default:
            this.alertService.danger(this.alertTechnicalError);
            break;
        }
      }
    }
  }

  public async resendVerificationCode() {
    try {
      await this.authService.resendVerificationCodeForMFA();
      this.alertService.success(this.alertResendSuccess);
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  private get alertIncorrectCode(): string {
    return this.translate.instant("MFA.ALERTS.INCORRECT_CODE");
  }

  private get alertResendSuccess(): string {
    return this.translate.instant("MFA.ALERTS.RESEND_SUCCESS");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("MFA.ALERTS.TECHNICAL_ERROR");
  }
}
