import { WebformFlattened } from "../webform-view-table.model";
import { PropertyType } from "../../models/data-retrieval.model";

const getConfigForParent = (parent: "father" | "mother") => {
  const fieldsetLegend =
    parent === "father"
      ? "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.FATHER"
      : "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.MOTHER";
  return [
    // Section A - father / mother - family name
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldsetLegend,
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.FAMILY_NAME",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: parent,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "familyName",
        },
      },
    },
    // Section A - father / mother - given name
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.GIVEN_NAME",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: parent,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "givenName",
        },
      },
    },
    // Section A - father / mother - DOB
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DOB",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: parent,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "dob",
        },
      },
    },
    // Section A - father / mother - date of death
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DOD",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: parent,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "deceasedDate",
        },
      },
    },
    // Section A - father / mother - City of birth
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.CITY_OF_BIRTH",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: parent,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "cityOfBirth",
        },
      },
    },
    // Section A - father / mother - Country of birth
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.COUNTRY_OF_BIRTH",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: parent,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "countryOfBirth",
        },
      },
    },
  ];
};

export const getConfigForSectionAtoC = () => {
  return [
    // Section A - Full name - family name
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        formName: "INTAKE.IMM5669.FORMS.SECTION_A.FORM_HEADING",
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.FAMILY_GIVEN_NAME",
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.FAMILY_NAME",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "familyName",
      },
    },
    // Section A - Full name - given name
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.GIVEN_NAME",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "givenName",
      },
    },
    // Section A - Full name in native language
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.FULL_NAME",
        fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_A.LABEL.FULL_NAME",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "nativeFullName",
      },
    },
    // Section A - DOB
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.BIRTH",
        fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DOB",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "dob",
      },
    },
    ...getConfigForParent("father"),
    ...getConfigForParent("mother"),
    // Section B - 1. convicted crime in Canada
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        formName: "INTAKE.IMM5669.FORMS.SECTION_B.FORM_HEADING",
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_CONVICTED_IN_CANADA",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isConvictedInCanada",
      },
    },
    // Section B - 2. convicted crime in other countries
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_CONVICTED_OUTSIDE_CANADA",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isConvictedOutsideCanada",
      },
    },
    // Section B - 3. made refugee claims
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_CLAIMED_REFUGEE_PROTECTION",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isClaimedRefugeeProtection",
      },
    },
    // Section B - 4. been refused refugee status
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_REFUSED_REFUGEE_OR_VISA",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isRefusedRefugeeOrVisa",
      },
    },
    // Section B - 5. been refused admission to / ordered to leave canada
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_ORDERED_TO_LEAVE_COUNTRY",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isOrderedToLeaveCountry",
      },
    },
    // Section B - 6. involved in genocide or war crime
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_WAR_CRIMINAL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isWarCriminal",
      },
    },
    // Section B - 7. used armed struggle to reach political objectives
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_COMMITTED_ACT_OF_VIOLENCE",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isCommittedActOfViolence",
      },
    },
    // Section B - 8. associated with a group that uses armed struggle to reach political objectives
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_ASSOCIATED_WITH_VIOLENT_GROUP",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isAssociatedWithViolentGroup",
      },
    },
    // Section B - 9. member of criminal org
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_MEMBER_OF_CRIMINAL_ORG",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isMemberOfCriminalOrg",
      },
    },
    // Section B - 10. been detained or jailed
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_DETAINED_OR_JAILED",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isDetainedOrJailed",
      },
    },
    // Section B - 11. has desease or mental disorder
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      complexSourceList: ["yesOrNoList"],
      sourceLabels: {
        fieldsetLegend:
          "INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.IS_PHYSICAL_OR_MENTAL_DISORDER",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "isPhysicalOrMentalDisorder",
      },
    },
    // Section B - Details for 'yes' questions
    {
      type: PropertyType.Object,
      sourceField: "sectionB",
      sourceLabels: {
        fieldsetLegend: "INTAKE.IMM5669.FORMS.FIELDS.ADDITIONAL_DETAILS",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "additionalDetails",
      },
    },
    // Section C - Number of years of elementary school
    {
      type: PropertyType.Object,
      sourceField: "sectionC",
      sourceLabels: {
        formName: "INTAKE.IMM5669.FORMS.SECTION_C.FORM_HEADING",
        fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_C.SUMMARY_LEGEND",
        fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_C.ELEMENTARY",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "elementarySchoolYears",
      },
    },
    // Section C - Number of years of secondary school
    {
      type: PropertyType.Object,
      sourceField: "sectionC",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_C.SECONDARY",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "secondarySchoolYears",
      },
    },
    // Section C - Number of years of university
    {
      type: PropertyType.Object,
      sourceField: "sectionC",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_C.UNIVERSITY",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "universityAndCollegeYears",
      },
    },
    // Section C - Number of years of trade school or other schools
    {
      type: PropertyType.Object,
      sourceField: "sectionC",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_C.TRADE_SCHOOL",
      },
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "otherSchoolYears",
      },
    },
  ];
};

export const getConfigForSectionCEducation = () => {
  return [
    {
      type: PropertyType.Object,
      sourceField: "sectionC",
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: "educationHistory",
        complexSubfield: [
          {
            // From
            type: PropertyType.Primitive,
            sourceField: "from",
            sourceLabels: {
              fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_C.EDUCATION",
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM",
            },
          },
          {
            // To
            type: PropertyType.Primitive,
            sourceField: "to",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_TO",
            },
          },
          {
            // Name of institution
            type: PropertyType.Primitive,
            sourceField: "nameOfInstitution",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_C.NAME_OF_INSTITUTION",
            },
          },
          {
            // City and country
            type: PropertyType.Primitive,
            sourceField: "cityAndCountry",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_E.CITY_AND_COUNTRY",
            },
          },
          {
            // Type of certificate issued
            type: PropertyType.Primitive,
            sourceField: "typeOfDiploma",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_C.TYPE_OF_CERTIFICATE",
            },
          },
          {
            // Field of study
            type: PropertyType.Primitive,
            sourceField: "fieldOfStudy",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_C.FIELD_OF_STUDY",
            },
          },
        ],
      },
    },
  ];
};
export const numberOfRowsPerEducation = 6;

// Personal history
export const getConfigForSectionD = () => {
  return [
    {
      type: PropertyType.Object,
      sourceField: "sectionD",
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: "personalHistory",
        complexSubfield: [
          {
            // From
            type: PropertyType.Primitive,
            sourceField: "from",
            sourceLabels: {
              fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_D.INSTANCE_LABEL",
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM",
            },
          },
          {
            // To
            type: PropertyType.Primitive,
            sourceField: "to",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_TO",
            },
          },
          {
            // Activity
            type: PropertyType.Primitive,
            sourceField: "activity",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.ACTIVITY_LABEL",
            },
          },
          {
            // City and country
            type: PropertyType.Primitive,
            sourceField: "cityAndCountry",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.ACTIVITY_LOCATION_LABEL",
            },
          },
          {
            // Status in country
            type: PropertyType.Primitive,
            sourceField: "status",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.STATUS_LABEL",
            },
          },
          {
            // Name of company or school
            type: PropertyType.Primitive,
            sourceField: "nameOfEmployerOrSchool",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.INSTITUTION_NAME_LABEL",
            },
          },
        ],
      },
    },
  ];
};
export const numberOfRowsPerPersonalHistory = 6;

// Membership
export const getConfigForSectionE = () => {
  return [
    {
      type: PropertyType.Object,
      sourceField: "sectionE",
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: "memberships",
        complexSubfield: [
          {
            // From
            type: PropertyType.Primitive,
            sourceField: "from",
            sourceLabels: {
              fieldsetLegend:
                "INTAKE.IMM5669.FORMS.SECTION_E.CURRENT_MEMBERSHIP",
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM",
            },
          },
          {
            // To
            type: PropertyType.Primitive,
            sourceField: "to",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_TO",
            },
          },
          {
            // Name of organization
            type: PropertyType.Primitive,
            sourceField: "nameOfOrganization",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_E.ORGANIZATION_NAME",
            },
          },
          {
            // Type of organization
            type: PropertyType.Primitive,
            sourceField: "typeOfOrganization",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_E.ORGANIZATION_TYPE",
            },
          },
          {
            // Activities in organization
            type: PropertyType.Primitive,
            sourceField: "activities",
            sourceLabels: {
              fieldLabel:
                "INTAKE.IMM5669.FORMS.SECTION_E.ORGANIZATION_ACTIVITIES",
            },
          },
          {
            // City and country
            type: PropertyType.Primitive,
            sourceField: "cityAndCountry",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_E.CITY_AND_COUNTRY",
            },
          },
        ],
      },
    },
  ];
};
export const numberOfRowsPerMembership = 6;

// Government position
export const getConfigForSectionF = () => {
  return [
    {
      type: PropertyType.Object,
      sourceField: "sectionF",
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: "governmentPositions",
        complexSubfield: [
          {
            // From
            type: PropertyType.Primitive,
            sourceField: "from",
            sourceLabels: {
              fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_F.INSTANCE_LABEL",
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM",
            },
          },
          {
            // To
            type: PropertyType.Primitive,
            sourceField: "to",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_TO",
            },
          },
          {
            // Country and level of jurisdiction
            type: PropertyType.Primitive,
            sourceField: "cityAndCountry",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_F.JURISDICTION_LABEL",
            },
          },
          {
            // Department / branch
            type: PropertyType.Primitive,
            sourceField: "department",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_F.DEPARTMENT_LABEL",
            },
          },
          {
            // Activities and/or positions held
            type: PropertyType.Primitive,
            sourceField: "activities",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_F.POSITION_HELD_LABEL",
            },
          },
        ],
      },
    },
  ];
};
export const numberOfRowsPerGovPosition = 5;

// Military service
export const getConfigForSectionG = () => {
  return [
    {
      type: PropertyType.Object,
      sourceField: "sectionG",
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: "militaryPositions",
        complexSubfield: [
          {
            // Name of country
            type: PropertyType.Primitive,
            sourceField: "country",
            sourceLabels: {
              fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_G.INSTANCE_LABEL",
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_G.LOCATION_LABEL",
            },
          },
          {
            // Branch of service
            type: PropertyType.Primitive,
            sourceField: "branchOfService",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_G.DUTY_DETAILS_LABEL",
            },
          },
          {
            // From
            type: PropertyType.Primitive,
            sourceField: "from",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM",
            },
          },
          {
            // To
            type: PropertyType.Primitive,
            sourceField: "to",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_TO",
            },
          },
          {
            // Rank
            type: PropertyType.Primitive,
            sourceField: "rank",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_G.RANK_LABEL",
            },
          },
          {
            // Reason for end of service
            type: PropertyType.Primitive,
            sourceField: "reasonsEndService",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_G.END_OF_SERVICE_LABEL",
            },
          },
          {
            // Dates and places of combat
            type: PropertyType.Primitive,
            sourceField: "combatDetails",
            sourceLabels: {
              fieldLabel:
                "INTAKE.IMM5669.FORMS.SECTION_G.DATE_LOCATION_COMBAT_LABEL",
            },
          },
        ],
      },
    },
  ];
};
export const numberOfRowsPerMilitaryService = 7;

// Addresses
export const getConfigForSectionH = () => {
  return [
    {
      type: PropertyType.Object,
      sourceField: "sectionH",
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: "addresses",
        complexSubfield: [
          {
            // From
            type: PropertyType.Primitive,
            sourceField: "from",
            sourceLabels: {
              fieldsetLegend: "INTAKE.IMM5669.FORMS.SECTION_H.ADDRESS",
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM",
            },
          },
          {
            // To
            type: PropertyType.Primitive,
            sourceField: "to",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.FIELDS.DATE_TO",
            },
          },
          {
            // Street and number
            type: PropertyType.Primitive,
            sourceField: "street",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_H.STREET",
            },
          },
          {
            // City or town
            type: PropertyType.Primitive,
            sourceField: "city",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_H.CITY",
            },
          },
          {
            // Province, state, or district
            type: PropertyType.Primitive,
            sourceField: "provinceOrState",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_H.PROVINCE",
            },
          },
          {
            // Country
            type: PropertyType.Primitive,
            sourceField: "country",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_H.COUNTRY",
            },
          },
          {
            // Postal code
            type: PropertyType.Primitive,
            sourceField: "postalCode",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5669.FORMS.SECTION_H.POSTAL_CODE",
            },
          },
        ],
      },
    },
  ];
};
export const numberOfRowsPerAddress = 7;

export const IMM5669Configs = (): WebformFlattened => {
  return {
    metaData: {
      form: "imm5669",
      documentTypeId: 41,
      pageTitle: "INTAKE.IMM5669.PAGES.PAGE_TITLE",
      // Set to false if the form has only one page (ex: immxxxx/) to set the proper back navigation buttons
      multiPageForm: false,
      hasNextPageButton: false,
    },
    tables: [],
  };
};
