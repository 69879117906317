import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, FormInputComponent } from "lib";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "../../../../core/cases-module/models/case.model";
import { CasesService } from "../../../../core/cases-module/cases.service";
import { UntypedFormBuilder, FormControl, FormGroup } from "@angular/forms";
import { intakeSelectedApplication } from "@pr-caseworker/app/intake-module/store/intake-state";
import { allCaseStatuses, CaseStatusSelect } from "../case-details-models";
import { BreakpointObserver } from "@angular/cdk/layout";
import * as _ from "lodash";
import * as CasesActions from "../../../../confirmation-module//store/cases.actions";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";

@Component({
  selector: "prcw-client-information-section",
  templateUrl: "./client-information-section.component.html",
  styleUrls: ["./client-information-section.component.scss"],
})
export class ClientInformationSectionComponent implements OnInit {
  @Input() hasRep = false;
  @Output() isEditingClientInfoForm: EventEmitter<any> = new EventEmitter();
  @ViewChild("uci") uciField: FormInputComponent;

  public lang: string;
  public case: Case;

  // Required Information to be Displayed - Can be required null so had to set type to any
  public caseProgramName: string | null;
  public caseCategoryName: string | null;
  public applicationName: string | null;

  public clientInfoForm: FormGroup;
  public isEditing: boolean = false;
  public disableEdit: boolean = false;
  public loading: boolean = false;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private fb: UntypedFormBuilder,
    private casesService: CasesService,
    private alertService: AlertService,
    private translate: TranslateService,
    private lobService: LobService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    if (!this.lobService.lobs) await this.lobService.updateLobs(); // needed for proper categoryText to show up
    this.store.select(intakeSelectedApplication).subscribe((caseData: Case) => {
      this.case = _.cloneDeep(caseData);
      this.formatCaseData();
      const disableEditingCaseStatues = [8, 12, 13, 14];
      let disablingEditCaseStatusExists = disableEditingCaseStatues.filter(
        (caseStatus) => caseStatus === this.case.caseStatusId
      );
      if (disablingEditCaseStatusExists.length > 0) {
        this.disableEdit = true;
      }
    });

    this.clientInfoForm = this.fb.group({
      uci: [this.case.uci],
      applicationNumber: [this.case.applicationNumber],
      applicationName: [this.case.applicationName],
      caseProgramName: [this.caseProgramName],
      caseCategoryName: [this.caseCategoryName],
    });
  }

  public formatDate(field: string) {
    return field?.replace(/\s/, "T");
  }

  public toggleEditing(): void {
    this.setFormValues();
    this.isEditing = !this.isEditing;
    this.isEditingClientInfoForm.emit(this.isEditing);

    setTimeout(() => {
      this.uciField.setFocusOnInput();
    }, 1);
  }

  public onSubmit(): void {
    this.loading = true;

    this.casesService
      .updateIntakeCase(this.case.id, this.updatedCase)
      .then(() => {
        this.alertService.success(this.alertSuccessClientInfoUpdated);
        if (this.updatedCase) {
          this.case.uci = this.updatedCase.uci;
          this.case.applicationNumber = this.updatedCase.applicationNumber;
        }
        this.store.dispatch(new CasesActions.SetCurrentCase(this.case));

        this.loading = false;
        this.toggleEditing();
      })
      .catch(() => {
        this.alertService.danger(this.alertTechnicalError);
        this.loading = false;
      });
  }

  public get canSaveForm(): boolean {
    //  check if form changed
    //  can also add logic here to check for any errors
    return !(
      this.clientInfoForm.controls.uci.value === this.case.uci &&
      this.clientInfoForm.controls.applicationNumber.value ===
        this.case.applicationNumber
    );
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  private get alertSuccessClientInfoUpdated(): string {
    return this.translate.instant("INTAKE.ALERTS.DOCUMENTS_SAVED");
  }

  private get updatedCase(): any {
    if (this.clientInfoForm.controls.uci.value === "") {
      this.clientInfoForm.controls.uci.setValue(null);
    }
    if (this.clientInfoForm.controls.applicationNumber.value === "") {
      this.clientInfoForm.controls.applicationNumber.setValue(null);
    }

    return {
      uci: this.clientInfoForm.controls.uci.value,
      applicationNumber: this.clientInfoForm.controls.applicationNumber.value,
    };
  }

  private setFormValues(): void {
    this.clientInfoForm.controls.applicationNumber.setValue(
      this.case.applicationNumber
    );
    this.clientInfoForm.controls.uci.setValue(this.case.uci);
  }

  private formatCaseData() {
    this.caseProgramName = this.lobService.getTranslatedProperty(
      this.case.lob,
      "program"
    );
    this.caseCategoryName = this.lobService.getCategoryDisplayText(
      this.case.lob
    );
  }
}
