import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { ConfigMetadata } from "../webform-view-table.model";

@Component({
  selector: "prcw-webform-nav-buttons",
  templateUrl: "./webform-nav-buttons.component.html",
  styleUrls: ["./webform-nav-buttons.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WebformNavButtonsComponent implements OnInit {
  @Input() showReturn?: boolean = false;

  constructor() {}
  ngOnInit(): void {}
}
