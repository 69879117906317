import { FormSelectOption } from "lib";

export interface CaseStatusSelect extends FormSelectOption {
  statusId?: string | number;
}

export const allCaseStatuses: CaseStatusSelect[] = [
  {
    value: "SUBMITTED",
    text: {
      en: "1. Ready for review",
      fr: "1. Prêt pour le traitement",
    },
    statusId: 7,
  },
  {
    value: "INCOMPLETE",
    text: {
      en: "6. Returned",
      fr: "6. Retournée",
    },
    statusId: 8,
  },
  {
    value: "WITHDRAWN",
    text: {
      en: "Withdrawn",
      fr: "Withdrawn",
    },
    statusId: 9,
  },
  {
    value: "COMPLETE",
    text: {
      en: "7. Complete",
      fr: "7. Complète",
    },
    statusId: 10,
  },
  {
    value: "RPA-UPLOAD-FAILED",
    text: {
      en: "2. RPA Upload Failed",
      fr: "2. Échec du téléchargement ARP",
    },
    statusId: 11,
  },
  {
    value: "READY-FOR-SUPPORT-DOC-UPLOAD",
    text: {
      en: "3. Ready for Supporting Doc Upload",
      fr: "3. Prêt pour le téléchargement des piéces justificatives",
    },
    statusId: 12,
  },
  {
    value: "SUPPORTING-DOC-UPLOAD-FAIL",
    text: {
      en: "4. Supporting Doc Upload Fail",
      fr: "4. Échec du téléchargement des piéces justificatives",
    },
    statusId: 13,
  },
  {
    value: "READY-FOR-CHECK",
    text: {
      en: "5. Ready for Check",
      fr: "5. Prêt pour le vérification",
    },
    statusId: 14,
  },
  {
    value: "EXPIRED",
    text: {
      en: "21. Expired",
      fr: "21. Expiré",
    },
    statusId: 21,
  },
];

// keep tehse here until we finish development
// these are as per backend contract
/*
    // 7 ready for review
    
    [
    
    8, // returned (need to check documents documentApproved)
    
    10, // completed (need to check documents documentApproved)
    
    11, // RPA upload fail (have to have applicationNumber and uci)
    
    12, // Ready for supporting doc upload (have to have applicationNumber and uci)
    
    ],
    
    // 8 returned
    
    [
    
    7, // ready for review
    
    10, // complete (need to check documents documentApproved)
    
    ],
    
    // 10 completed : this one may not needed if we don't allow CW to modify it after case completed
    
    [
    
    8, // returned (need to check documents documentApproved)
    
    ],
    
    // 11 RPA upload failed
    
    [
    
    8, // returned (need to check documents documentApproved)
    
    10, // completed (need to check documents documentApproved)
    
    12, // Ready for supporting doc upload (have to have applicationNumber and uci)
    
    ],
    
    // 12 Ready for supporting doc upload (BE handle this one)
    
    [
    
    13, // Supporting doc upload failed (need to check if any GCMSDocstatus failed)
    
    14, // Ready for check (need to check all GCMSDocstatus true)
    
    ],
    
    // 13 Supporting doc upload failed
    
    [
    
    8, // returned (need to check documents documentApproved)
    
    10, // completed (need to check documents documentApproved)
    
    ],
    
    // 14 Ready for check
    
    [
    
    8, // returned (need to check documents documentApproved)
    
    10, // completed (need to check documents documentApproved)
    */
