import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as SelectedAppActions from "@pr-caseworker/app/renewal-module/store/selected-application/selected-application.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { CasesService } from "@pr-caseworker/app/core/cases-module/cases.service";
import { from, of } from "rxjs";
import { RouteLocalizerService } from "@pr-caseworker/app/routing/route-localizer.service";
import { AlertService } from "../../../../../../lib/src/lib/services/alert/alert.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class RenewalEffects {
  constructor(
    private actions$: Actions,
    private caseService: CasesService,
    private routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  GetSelectedClientCaseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SelectedAppActions.SelectedApplicationActionTypes
          .SetSelectedApplicationById
      ),
      switchMap((action: any) => {
        const caseId = action?.payload;
        return from(this.caseService.getClientCasebyCaseId(caseId)).pipe(
          catchError((err) => of(null)),
          map((data) => {
            if (data) {
              return SelectedAppActions.setSelectedApplication({
                payload: data,
              });
            } else {
              this.routeLocalizer.goTo(
                this.routeLocalizer.getCurrentRouteLang(),
                "RENEWAL_TRAVEL_DOC_CASES"
              );
              this.alertService.danger(this.alertTechnicalError);
              return SelectedAppActions.setCurrentCaseError({
                payload: {
                  caseId,
                  apiLoadingError: "Error when attept to load case from API",
                },
              });
            }
          })
        );
      })
    );
  });

  GetApplicationDocTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SelectedAppActions.SelectedApplicationActionTypes.GetAppDocTypes),
      switchMap((action: any) => {
        return from(this.caseService.getAppDocs(3, false, action.payload)).pipe(
          catchError((err) => of(null)),
          map((data) => {
            return SelectedAppActions.setAppDocsList({ payload: data });
          })
        );
      })
    );
  });

  GetUrgentApplicationDocTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SelectedAppActions.SelectedApplicationActionTypes.GetUrgentAppDocTypes
      ),
      switchMap((action: any) => {
        return from(this.caseService.getAppDocs(3, true)).pipe(
          catchError((err) => of(null)),
          map((data) => {
            return SelectedAppActions.setUrgentAppDocsList({ payload: data });
          })
        );
      })
    );
  });

  GetApplicationFormTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SelectedAppActions.SelectedApplicationActionTypes.GetAppFormTypes),
      switchMap((action: any) => {
        return from(this.caseService.getAppForms(3)).pipe(
          catchError((err) => of(null)),
          map((data) => {
            return SelectedAppActions.setAppFormsList({ payload: data });
          })
        );
      })
    );
  });

  private get alertTechnicalError(): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.TECHNICAL_ERROR");
  }
}
