export enum RoleIds {
  PRIMARY_SPONSOR = 1,
  SPONSOR_MEMBER = 2,
  REFUGEE = 3,
  SPONSOR_REP = 4,
  PAID_REFUGEE_REP = 5,
  UNPAID_REFUGEE_REP = 6,
  REFERAL_PARTNER = 11,
  OYW_REPRESENTATIVE = 12,
  OYW_CLIENT = 13,
}
