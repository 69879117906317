import * as IntakeSearchActions from "@pr-caseworker/app/intake-module/store/searchTerms/searchTerms.actions";
import * as fromApp from "@pr-caseworker/app/store/app.reducer";

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormAutoCompleteOption, FormSelectOption } from "lib";

import { CasesService } from "../../cases.service";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { intakeSearchTerms } from "@pr-caseworker/app/intake-module/store/intake-state";
import { map } from "rxjs/operators";

@Component({
  selector: "prcw-cases-search",
  templateUrl: "./cases-search.component.html",
  styleUrls: ["./cases-search.component.scss"],
})
export class CasesSearchComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  searchByClientField: FormControl;
  searchByCwField: FormControl;
  searchByAppNumberField: FormControl;
  searchBy: FormControl;

  searchByCaseWorkerEmail: boolean;
  searchByApplicationNumber: boolean;
  searchByClientEmail: boolean;

  searchByOptions: FormSelectOption[] = [
    {
      value: 0,
      text: {
        en: "Client email or Rep. ID#",
        fr: "Recherche (par courriel du client ou par numéro de membre du représentant)",
      },
    },
    {
      value: 1,
      text: {
        en: "Caseworker email",
        fr: "Courriel de l'agent",
      },
    },
    {
      value: 2,
      text: {
        en: "Application number",
        fr: "Numéro de la demande",
      },
    },
  ];
  cwEmails: FormAutoCompleteOption[] = [];

  @Input() copyKey: string;
  @Input() searchByValue: number;
  @Input() value: string;
  @Input() ariaLabelKey: string;
  @Output() searchedTerm = new EventEmitter<string>();

  private unassigned = "UNASSIGNED";

  constructor(
    private caseService: CasesService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit() {
    this.searchByClientField = new FormControl("");
    this.searchByCwField = new FormControl({ value: null, disabled: false });
    this.searchByAppNumberField = new FormControl("");
    this.searchByClientEmail = this.searchByValue === 0 ? true : false;
    this.searchByCaseWorkerEmail = this.searchByValue === 1 ? true : false;
    this.searchByApplicationNumber = this.searchByValue === 2 ? true : false;
    this.searchBy = new FormControl(null);
    this.subs.push(
      this.store
        .select("auth")
        .pipe(map((authState) => authState?.user))
        .subscribe((user) => {
          if (user?.usersList) {
            const cws = user.usersList.map((item: any) => ({
              value: item.username,
              text: item.username,
            }));
            cws.unshift({
              value: this.unassigned,
              text: "INTAKE.CASE_LIST_PAGE.UNASSIGNED",
            });
            this.cwEmails = cws;
          }
        })
    );
    this.subs.push(
      this.store
        .select(intakeSearchTerms)
        .pipe()
        .subscribe((searchTerms) => {
          this.searchByValue = searchTerms?.searchBy;
          this.searchBy.setValue(this.searchByValue);
          // this.searchBy.updateValueAndValidity();

          if (this.searchByValue === 0) {
            this.searchByClientEmail = true;
            this.searchByCaseWorkerEmail = false;
            this.searchByApplicationNumber = false;
            this.searchByClientField.setValue(searchTerms?.searchTerm);
            this.searchByCwField.setValue({
              text: null,
              value: null,
            });
            this.searchByClientField.updateValueAndValidity();
          } else if (this.searchByValue === 1) {
            this.searchByClientEmail = false;
            this.searchByCaseWorkerEmail = true;
            this.searchByApplicationNumber = false;
            this.searchByCwField.setValue({
              text: searchTerms?.searchTerm,
              value: searchTerms?.searchTerm,
            });
            this.searchByCwField.updateValueAndValidity();
          } else {
            this.searchByValue === 2;
            this.searchByClientEmail = false;
            this.searchByCaseWorkerEmail = false;
            this.searchByApplicationNumber = true;
            this.searchByAppNumberField.setValue(searchTerms?.searchTerm);
            this.searchByAppNumberField.updateValueAndValidity();
          }
        })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  onSubmit() {
    let formatSearchValue;
    if (this.searchBy.value === 0) {
      this.searchByClientField.value === null
        ? (formatSearchValue = "")
        : (formatSearchValue = this.searchByClientField.value?.trim());
      this.store.dispatch(
        new IntakeSearchActions.SetSearchField({
          searchValue: formatSearchValue,
          searchBy: 0,
        })
      );
    } else if (this.searchBy.value === 1) {
      formatSearchValue =
        this.searchByCwField.value.value || this.searchByCwField.value.text;
      this.store.dispatch(
        new IntakeSearchActions.SetSearchField({
          searchValue: formatSearchValue,
          searchBy: 1,
        })
      );
    } else {
      formatSearchValue = this.searchByAppNumberField.value?.trim();
      this.store.dispatch(
        new IntakeSearchActions.SetSearchField({
          searchValue: formatSearchValue,
          searchBy: 2,
        })
      );
    }
  }

  searchByUpdated() {
    if (this.searchBy.value === 0) {
      this.searchByClientEmail = true;
      this.searchByCaseWorkerEmail = false;
      this.searchByApplicationNumber = false;

      const cwFieldValue =
        this.searchByCwField.value.value &&
        this.searchByCwField.value.value === this.unassigned
          ? null
          : this.searchByCwField.value.value;
      this.searchByClientField.setValue(cwFieldValue);
      const appNumberField = this.searchByAppNumberField.value;
      if (appNumberField) {
        this.searchByClientField.setValue(appNumberField);
      }
    } else if (this.searchBy.value === 1) {
      this.searchByClientEmail = false;
      this.searchByCaseWorkerEmail = true;
      this.searchByApplicationNumber = false;

      const searchByCWTarget = this.searchByCwField.value;
      if (searchByCWTarget) {
        this.searchByCwField.setValue(searchByCWTarget);
      }

      const searchByClientValue = this.searchByClientField.value;
      if (searchByClientValue) {
        this.searchByCwField.setValue({
          value: null,
          text:
            searchByClientValue === this.unassigned
              ? null
              : searchByClientValue,
        });
      }
      const appNumberFieldValue = this.searchByAppNumberField.value;
      if (appNumberFieldValue) {
        this.searchByCwField.setValue(appNumberFieldValue);
      }
    } else if (this.searchBy.value === 2) {
      this.searchByClientEmail = false;
      this.searchByApplicationNumber = true;
      this.searchByCaseWorkerEmail = false;
      const cwFieldValue =
        this.searchByCwField.value.value &&
        this.searchByCwField.value.value === this.unassigned
          ? null
          : this.searchByCwField.value.value;
      this.searchByAppNumberField.setValue(cwFieldValue);
      const searchByClientEmailValue = this.searchByClientField.value;
      if (searchByClientEmailValue) {
        this.searchByAppNumberField.setValue(searchByClientEmailValue);
      }
    }
  }
}
