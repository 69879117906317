import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormSelectOption } from "lib";

@Component({
  selector: "prcw-assignment-filter",
  templateUrl: "./assignment-filter.component.html",
  styleUrls: ["./assignment-filter.component.scss"],
})
export class AssignmentFilterComponent implements OnInit, OnChanges {
  @Input() copyKey: string;
  @Input() value: boolean | null;
  @Output() selectedAssigmentStatus$: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public assignmentFilter: FormControl;
  public assignementStatuses: FormSelectOption[] = [
    {
      value: true,
      text: {
        en: "Assigned",
        fr: "Assigné",
      },
    },
    {
      value: false,
      text: {
        en: "Unassigned",
        fr: "Non assigné",
      },
    },
  ];
  constructor() {}

  ngOnInit(): void {
    this.assignmentFilter = new FormControl(null);
    if (!!this.value) {
      this.assignmentFilter.setValue(this.value);
      this.assignmentFilter.updateValueAndValidity();
    }
  }

  ngOnChanges(): void {
    if (this.assignmentFilter) {
      this.assignmentFilter.setValue(this.value);
      this.assignmentFilter.updateValueAndValidity();
    }
  }
  assignmentStatusFiltered() {
    this.selectedAssigmentStatus$.emit(this.assignmentFilter.value);
  }
}
