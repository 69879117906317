<div class="form-autocomplete" aria-live="assertive">
  <label
    class="form-autocomplete__label"
    [for]="id"
    [ngClass]="{ 'visually-hidden': !showLabel || !label }"
  >
    <div class="form-autocomplete__label-info">
      <div
        *ngIf="required && !hideRequired"
        class="form-autocomplete__required-icon"
      >
        <lib-icon-chooser icon="asterisk"></lib-icon-chooser>
      </div>
      <div class="form-autocomplete__shift">
        {{ label }}
        <!-- unneeded for now -->
        <!-- <span
          *ngIf="required && !hideRequired"
          class="form-autocomplete__required"
        >
          ({{ requiredText }})
        </span> -->
      </div>
    </div>
    <div
      *ngIf="helpText !== ''"
      class="form-autocomplete__help-text"
      [id]="identifier + '-help-text'"
      [innerHTML]="helpText"
    ></div>
  </label>

  <ng-container *ngIf="showEditableInput">
    <div class="form-autocomplete__field-container">
      <input
        autocomplete="off"
        [matAutocomplete]="auto"
        matInput
        type="text"
        [id]="id"
        [name]="id"
        [formControl]="ngControl.control"
        [placeholder]="placeholder | translate"
        (focus)="onFocus($event)"
        (blur)="onBlur()"
        class="form-autocomplete__field"
      />
      <!-- <div class="form-autocomplete__chevron-container" *ngIf="showChevron">
      <div class="form-autocomplete__chevron">
        <i
          class="fa-thin fa-chevron-down form-autocomplete__chevron-icon"
          [class.form-autocomplete__chevron-icon--disabled]="isReadOnly"
        ></i>
      </div>
    </div> -->
      <div *ngIf="showSearch" class="form-autocomplete__search">
        <lib-icon-chooser icon="search"></lib-icon-chooser>
      </div>
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="getDisplayedLabel.bind(this)"
        (optionSelected)="onOptionSelected($event)"
        (optionActivated)="onOptionActivated($event)"
      >
        <mat-option
          *ngFor="let option of filteredOptions; trackBy: trackElemnt"
          [value]="option"
        >
          {{ option.text | translate }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </ng-container>

  <ng-container *ngIf="!showEditableInput">
    <div class="form-autocomplete__field-container">
      <input
        type="text"
        class="form-autocomplete__field"
        [value]="value | safeTrim"
        [class.form-autocomplete__field--disabled]="true"
        [disabled]="true"
        [attr.aria-disabled]="true"
        [placeholder]="placeholder | translate"
      />
      <div *ngIf="showSearch" class="form-autocomplete__search">
        <lib-icon-chooser icon="search"></lib-icon-chooser>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="hasNoMatch && showEditableInput"
    class="form-autocomplete__errors"
  >
    <span>{{ "PRDI_COMPONENTS.MESSAGES.NO_RESULTS" | translate }}</span>
  </div>

  <div
    *ngIf="ngControl.errors && ngControl.touched && showEditableInput"
    class="form-autocomplete__errors"
  >
    <ng-content>
      <!-- error spans -->
    </ng-content>
  </div>
</div>
