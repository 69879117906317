import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";

import * as AllApplicationsActions from "./allApplications.actions";

export interface AllApplicationsState {
  allApplications?: Case[];
}

const initialAllApplication: AllApplicationsState = {
  allApplications: [],
};

export function allApplicationsReducer(
  state: AllApplicationsState = initialAllApplication,
  action: AllApplicationsActions.AllApplicationsActions
) {
  switch (action.type) {
    case AllApplicationsActions.GET_APPLICATIONS:
      return {
        allApplications: action.payload,
      };
    default:
      return state;
  }
}
