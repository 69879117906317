<h1 class="page__title emphasis">
  {{ "INTAKE.DECLARATION.ESIG.HEADING" | translate }}
</h1>
<h2 class="emphasis subheading">
  {{ "INTAKE.DECLARATION.ESIG.SUBHEADING" | translate }}
</h2>

<lib-spinner [showSpinner]="isLoading" [fullPage]="false"></lib-spinner>

<div *ngIf="!isLoading">
  <p>{{ "INTAKE.DECLARATION.ESIG.PAGE_DESCRIPTION" | translate }}</p>

  <form [formGroup]="declarationForm" autocomplete="off">
    <fieldset>
      <lib-form-radio
        [legend]="'INTAKE.DECLARATION.ESIG.FORM.CAN_CONTACT.LEGEND' | translate"
        firstId="canContact-yes"
        secondId="canContact-no"
        [firstValue]="true"
        [secondValue]="false"
        [firstLabel]="
          'INTAKE.DECLARATION.ESIG.FORM.FIELDS.RADIO_YES' | translate
        "
        [secondLabel]="
          'INTAKE.DECLARATION.ESIG.FORM.FIELDS.RADIO_NO' | translate
        "
        nameVal="canContact-consent"
        [formControl]="declarationForm.controls.canContact"
      ></lib-form-radio>
    </fieldset>

    <h3 class="form-heading">
      {{ "INTAKE.DECLARATION.ESIG.FORM.RELEASE_INFO_TITLE" | translate }}
    </h3>
    <fieldset>
      <lib-form-radio
        [legend]="'INTAKE.DECLARATION.ESIG.FORM.CAN_SHARE.LEGEND' | translate"
        firstId="canShareData-yes"
        secondId="canShareData-no"
        [firstValue]="true"
        [secondValue]="false"
        [firstLabel]="
          'INTAKE.DECLARATION.ESIG.FORM.FIELDS.RADIO_YES' | translate
        "
        [secondLabel]="
          'INTAKE.DECLARATION.ESIG.FORM.FIELDS.RADIO_NO' | translate
        "
        nameVal="canShareData-consent"
        [formControl]="declarationForm.controls.canShareData"
      ></lib-form-radio>
    </fieldset>

    <div
      [innerHTML]="'INTAKE.DECLARATION.ESIG.FORM.LEGAL_TEXT' | translate"
    ></div>

    <h3 class="form-heading">
      {{ "INTAKE.DECLARATION.ESIG.FORM.SIGN_TITLE" | translate }}
    </h3>
    <ul>
      <li
        [innerHTML]="item"
        *ngFor="
          let item of 'INTAKE.DECLARATION.ESIG.FORM.SIGN_TERMS.LIST' | translate
        "
      ></li>
    </ul>

    <fieldset>
      <legend>
        {{ "INTAKE.DECLARATION.ESIG.FORM.SIGNATURE.LEGEND" | translate }}
      </legend>
      <lib-form-input
        identifier="eSignature"
        required="true"
        [label]="'INTAKE.DECLARATION.ESIG.FORM.SIGNATURE.LABEL' | translate"
        [formControl]="declarationForm.controls.eSignature"
        [ngClass]="'highlight'"
        [editable]="false"
      >
      </lib-form-input>
    </fieldset>

    <div
      [innerHTML]="
        'INTAKE.DECLARATION.ESIG.FORM.PERSONAL_INFO_LEGAL' | translate
      "
    ></div>

    <div class="buttons-container">
      <a [routerLink]="['../']" category="secondary">
        {{ "INTAKE.DECLARATION.ESIG.FORM.BUTTONS.BACK" | translate }}
      </a>
    </div>
  </form>
</div>
