import { Component, Input } from "@angular/core";
import { WebformViewTable } from "../webform-view-table.model";

@Component({
  selector: "prcw-webform-view-table",
  templateUrl: "./webform-view-table.component.html",
  styleUrls: ["./webform-view-table.component.scss"],
})
export class WebformViewTableComponent {
  @Input() heading: string;
  @Input() columnHeadings: WebformViewTable;
  @Input() rows: WebformViewTable[];

  constructor() {}
}
