import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";

@Component({
  selector: "prcw-list-buttons",
  templateUrl: "./list-buttons.component.html",
  styleUrls: ["./list-buttons.component.scss"],
})
export class ListButtonsComponent implements OnChanges {
  @Input() listNumbers: number;
  @Input() pageToReview: number;

  public numbers: number[];
  public pageInView: number = 1;
  public lastSet: number;
  public pageSets: any[] = [];
  public pageSetInView: number = 0;

  @Output() checkedPageNumber = new EventEmitter<number>();

  constructor() {}

  ngOnChanges(): void {
    if (!!this.listNumbers && !Number.isNaN(this.listNumbers)) {
      this.numbers = Array(this.listNumbers)
        .fill(this.listNumbers)
        .map((_, i) => i + 1);
    }

    // Accomodates for filter/search functionality
    if (this.pageSets.length !== 0) {
      this.pageSets = [];
    }

    if (this.pageToReview >= 0) {
      this.pageInView = this.pageToReview + 1;
    }

    // Creating an array of arrays, each subarray is a pageset (i.e. [1-10], or [11-20])
    while (this.numbers?.length) {
      this.pageSets.push(this.numbers?.splice(0, 10));
    }
    this.lastSet = this.pageSets.length - 1;
  }

  changePage(pageNumber: number) {
    this.checkedPageNumber.emit(pageNumber - 1);
    this.pageInView = pageNumber;
  }

  goToPrevious() {
    this.pageSetInView -= 1;
  }

  goToNext() {
    this.pageSetInView += 1;
  }
}
