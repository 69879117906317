import { Injectable } from "@angular/core";
import { environment } from "@pr-applicant/environments/environment";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { from, switchMap } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { RelatedDocumentType } from "@pr-applicant/app/core/models/lob.model";
import { FormControl } from "@angular/forms";

export enum RoleIds {
  PRIMARY_SPONSOR = 1,
  SPONSOR_MEMBER = 2,
  REFUGEE = 3,
  SPONSOR_REP = 4,
  PAID_REFUGEE_REP = 5,
  UNPAID_REFUGEE_REP = 6,
  PRIMARY_CONTACT = 10,
}

export interface IPsrDetails {
  isPsr: boolean;
  isG5: boolean;
  has5members: boolean;
  hasMemberDocsComplete: boolean;
  hasCurrentMemberDocsComplete: boolean;
}

export interface IGroupMember {
  familyName: string;
  givenName?: string;
  emailAddress: string;
  roleId?: number;
  caseMemberId: number;
  canResendPassword?: boolean;
}

export interface INewMember {
  familyName: string;
  givenName?: string;
  emailAddress: string;
}

export interface INewMemberForm {
  familyName: FormControl<string>;
  givenName: FormControl<string>;
  emailAddress: FormControl<string>;
  emailConfirm: FormControl<string>;
  isPrimaryContact: FormControl<boolean>;
}
export interface IPrincipalApplicant extends IGroupMember {}

export interface IPrincipalApplicantsRep extends IGroupMember {
  paidRep?: boolean;
  roleId?: number;
}

export interface IMemberStatus {
  memberId: number;
  status: boolean;
}

@Injectable({
  providedIn: "root",
})
export class PsrService {
  private apiVersion = environment.API_VERSION;
  private apiName = environment.API_NAME;
  private memberStatuses: IMemberStatus[] = [];

  constructor(
    private awsService: AWSService,
    private translate: TranslateService
  ) {}

  addMember(member: any, caseId: number, roleId: RoleIds) {
    let path = "";
    if (roleId === RoleIds.PRIMARY_SPONSOR) {
      path = `/${this.apiVersion}/cases/${caseId}/case-initiator`;
    } else {
      path = `/${this.apiVersion}/cases/${caseId}/role/${roleId}/member`;
    }
    return from(this.awsService.getToken()).pipe(
      switchMap((token) => {
        const httpOptions = {
          headers: {
            Authorization: token,
          },
          body: {
            emailAddress: member.emailAddress.toLowerCase(),
            givenName: member.givenName,
            familyName: member.familyName,
          },
        };
        return from(this.awsService.api.post(this.apiName, path, httpOptions));
      })
    );
  }

  deleteGroupMember(caseId: number, memberId: number) {
    const path = `/${this.apiVersion}/cases/${caseId}/member/${memberId}`;
    return from(this.awsService.getToken()).pipe(
      switchMap((token) => {
        const httpOptions = {
          headers: {
            Authorization: token,
          },
        };
        return from(this.awsService.api.del(this.apiName, path, httpOptions));
      })
    );
  }

  getRoleNameById(id: number): string {
    switch (id) {
      case 1:
        return this.translate.instant("PSR.ROLES.PRIMARY_SPONSOR");
      case 2:
        return this.translate.instant("PSR.ROLES.SPONSOR_MEMBER");
      case 3:
        return this.translate.instant("PSR.ROLES.REFUGEE");
      case 4:
        return this.translate.instant("PSR.ROLES.SPONSOR_REP");
      case 5:
        return this.translate.instant("PSR.ROLES.PAID_REFUGEE_REP");
      case 6:
        return this.translate.instant("PSR.ROLES.UNPAID_REFUGEE_REP");
      default:
        return this.translate.instant("PSR.ROLES.MISSING_ROLE");
    }
  }

  isPSR(lobId: number): boolean {
    return lobId !== null && [45, 46, 47, 48].includes(lobId);
  }

  isG5(lobId: number): boolean {
    return lobId !== null && lobId === 46;
  }

  has5members(members: IGroupMember[]): boolean {
    const g5members = [];
    members.forEach((m) => {
      if (
        m.roleId === RoleIds.SPONSOR_MEMBER ||
        m.roleId === RoleIds.PRIMARY_SPONSOR ||
        m.roleId === RoleIds.PRIMARY_CONTACT
      ) {
        g5members.push(m);
      }
    });
    return g5members.length >= 5;
  }

  hasMemberDocsComplete(): boolean {
    return this.memberStatuses.every(
      (memberStatus) => memberStatus.status === true
    );
  }

  hasCurrentMemberDocsComplete(
    email: string,
    members: IGroupMember[]
  ): boolean {
    const memberId = members.find(
      (m) =>
        m.emailAddress === email &&
        (m.roleId === RoleIds.SPONSOR_MEMBER ||
          m.roleId === RoleIds.PRIMARY_SPONSOR ||
          m.roleId === RoleIds.PRIMARY_CONTACT)
    )?.caseMemberId;
    return this.memberStatuses.some(
      (m) => m.memberId === memberId && m.status === true
    );
  }

  setMemberStatuses(
    members: IGroupMember[],
    documents: RelatedDocumentType[],
    memberDocuments: any[]
  ) {
    this.memberStatuses = [];
    members.forEach((member) => {
      if (
        member.roleId === RoleIds.SPONSOR_MEMBER ||
        member.roleId === RoleIds.PRIMARY_SPONSOR ||
        member.roleId === RoleIds.PRIMARY_CONTACT
      ) {
        const docs = documents
          .filter(
            (doc: RelatedDocumentType) => doc.isMemberDocument && doc.isRequired
          )
          .map((doc) => {
            const found = memberDocuments.find(
              (md) =>
                md.caseMemberId === member.caseMemberId &&
                md.documentTypeId === doc.id &&
                md.documentApproved !== false
            );
            return found ? true : false;
          });
        this.memberStatuses.push({
          memberId: member.caseMemberId,
          status: docs.every((doc) => doc === true),
        });
      }
    });
  }
}
