import {
  ConfigTableData,
  TableRowDataItem,
  WebformViewTable,
} from "./webform-view-table.model";

import { DataRetrievalService } from "../services/data-retrieval.service";
import { Injectable } from "@angular/core";
import { LovService } from "lib";
import { PropertyType } from "../models/data-retrieval.model";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class WebformViewTableService {
  caseId: string;

  constructor(
    private dataRetrievalService: DataRetrievalService,
    private translate: TranslateService,
    private lovService: LovService
  ) {}

  // This method returns the list of rows
  public buildFormTableRows(
    configFile: ConfigTableData,
    formData: any
  ): WebformViewTable[] | null {
    const fieldsConfigs = configFile?.fields;
    const lists = this.getModifiedLists(formData?.lists);
    if (fieldsConfigs) {
      const rows = fieldsConfigs?.map((config) => {
        return this.getRows(config, formData.form, lists);
      });
      return this.getFlattenedArray(rows);
    }
    return [];
  }

  // Returns one table row or a collection of table rows
  private getRows(
    rowConfig: TableRowDataItem,
    form: any,
    lists: any
  ): WebformViewTable | WebformViewTable[] {
    if (!this.isConfigForCollection(rowConfig)) {
      const data = this.getRowData(rowConfig, form, lists);
      const { formName, fieldsetLegend, fieldLabel, value } =
        rowConfig.sourceLabels || {};
      return {
        formName: formName ? this.translate.instant(formName) : "",
        fieldsetLegend: fieldsetLegend
          ? this.translate.instant(fieldsetLegend)
          : "",
        fieldLabel: fieldLabel ? this.translate.instant(fieldLabel) : "",
        data: value ? this.translate.instant(value) : data,
      };
    }

    if (rowConfig.complexSubfield) {
      const nextLevelData = form[rowConfig.sourceField];
      return this.getRowsFromComplexSubfield(
        rowConfig.complexSubfield,
        nextLevelData,
        lists
      );
    }
    return [];
  }

  private getRowsFromComplexSubfield(
    complexSubfield: TableRowDataItem | TableRowDataItem[],
    data: any,
    lists: any
  ): WebformViewTable | WebformViewTable[] {
    if (Array.isArray(complexSubfield)) {
      const fromComplexSubfieldArray = (
        subfieldData: any,
        complexSubfieldArray: TableRowDataItem[]
      ) => {
        const rows = complexSubfieldArray.map((subfieldConfig) => {
          return this.getRows(subfieldConfig, subfieldData, lists);
        });
        return this.getFlattenedArray(rows);
      };

      if (Array.isArray(data)) {
        const rowsArray = data.map((dataElement) => {
          return fromComplexSubfieldArray(dataElement, complexSubfield);
        });
        return this.getFlattenedArray(rowsArray);
      }
      return fromComplexSubfieldArray(data, complexSubfield);
    } else if (complexSubfield.complexSubfield) {
      if (Array.isArray(data) && complexSubfield.index !== undefined) {
        data = data[complexSubfield.index];
      }
      return this.getRowsFromComplexSubfield(
        complexSubfield.complexSubfield,
        data[complexSubfield.sourceField],
        lists
      );
    }
    return this.getRows(complexSubfield, data, lists);
  }

  private getRowData(
    rowConfig: TableRowDataItem,
    formData: any,
    lists: any
  ): string {
    const data = this.getData(rowConfig, formData);
    const list = this.getListForRow(rowConfig, lists);
    if (list) {
      return this.getTransformedData(data, list);
    }
    return data;
  }

  private getListForRow(rowConfig: TableRowDataItem, lists: any): any {
    if (lists) {
      if (rowConfig.sourceList && lists[rowConfig.sourceField]) {
        return lists[rowConfig.sourceField][rowConfig.sourceList];
      } else if (rowConfig.complexSourceList) {
        const duplicatedList = [...rowConfig.complexSourceList];
        const name = rowConfig.complexSourceList.shift();
        if (name && lists[name]) {
          const ret = this.getListForRow(rowConfig, lists[name]);
          rowConfig.complexSourceList = duplicatedList;
          return ret;
        }
        return lists;
      }
    }
    return undefined;
  }

  private isConfigForCollection(rowConfig: TableRowDataItem): boolean {
    const type = rowConfig.type;
    return (
      (type === PropertyType.Object || type === PropertyType.Array) &&
      !!rowConfig.complexSubfield
    );
  }

  private getFlattenedArray(arr: any[]): any[] {
    const ret = [];
    for (const element of arr) {
      if (Array.isArray(element)) {
        ret.push(...this.getFlattenedArray(element));
      } else {
        ret.push(element);
      }
    }
    return ret;
  }

  private getData(rowConfig: TableRowDataItem, form: any): string {
    let data = "";
    if (rowConfig.type === PropertyType.Primitive) {
      data = this.dataRetrievalService.getPrimitiveBarcodeItem(
        form,
        rowConfig.sourceField
      );
    } else if (rowConfig.type === PropertyType.Object && rowConfig.subfield) {
      data = this.dataRetrievalService.getObjectBarcodeItem(
        form,
        rowConfig.sourceField,
        rowConfig.subfield
      );
    } else if (rowConfig.type === PropertyType.Array && rowConfig.subfield) {
      data = this.dataRetrievalService.getArrayBarcodeItem(
        form[rowConfig.sourceField],
        rowConfig.sourceField,
        rowConfig.subfield
      );
    } else if (rowConfig.type === PropertyType.Date) {
      data = this.dataRetrievalService.getDateBarcodeItem(
        form[rowConfig.sourceField],
        rowConfig.sourceField,
        rowConfig.dateConversionOption
      );
    }
    return data;
  }

  private getTransformedData(
    data: string,
    transformObject: DataTransformInfo[] | object
  ): string {
    const transformInfo = this.getDataTransformInfo(data, transformObject);
    if (transformInfo) {
      const lang = this.translate.currentLang as "en" | "fr";
      return transformInfo.text[lang];
    }
    return data;
  }

  private getDataTransformInfo(
    data: string,
    transformObject: DataTransformInfo[] | object
  ): DataTransformInfo | undefined {
    let transformInfo: DataTransformInfo | undefined;
    if (Array.isArray(transformObject)) {
      transformInfo = transformObject.find((item) => item.value === data);
    } else {
      for (const subtransformObject of Object.values(transformObject)) {
        transformInfo = this.getDataTransformInfo(data, subtransformObject);
        if (transformInfo) {
          return transformInfo;
        }
      }
    }
    return transformInfo;
  }

  // This method will add translated lists for the YES/NO radio buttons
  // and the Canada/US vs other for contact information form
  private getModifiedLists(lists: any): any {
    // Use for Yes/No radio options
    const yesOrNoList = [
      { value: "true", text: { fr: "Oui", en: "Yes" } },
      { value: "1", text: { fr: "Oui", en: "Yes" } },
      { value: "false", text: { fr: "Non", en: "No" } },
      { value: "0", text: { fr: "Non", en: "No" } },
    ];

    // Use for Yes/No checkbox options
    const yesOrNoCheckboxList = [
      { value: "true", text: { fr: "Oui", en: "Yes" } },
      { value: "false", text: { fr: "Non", en: "No" } },
      { value: null, text: { fr: "Non", en: "No" } },
      { value: "", text: { fr: "Non", en: "No" } },
    ];

    if (lists) {
      const listsKeys = Object.keys(lists);
      listsKeys.map((key) => {
        // Add Yes/No radio options
        lists[key].yesOrNoList = [...yesOrNoList];
        // Add Canada/US or Other radio options
        lists[key].canadaUSAOrOtherList = [
          { value: "true", text: { fr: "Canada/États-Unis", en: "Canada/US" } },
          { value: "1", text: { fr: "Canada/États-Unis", en: "Canada/US" } },
          { value: "false", text: { fr: "Autre", en: "Other" } },
          { value: "0", text: { fr: "Autre", en: "Other" } },
        ];
        // Use for Yes/No checkbox options
        lists[key].yesOrNoCheckboxList = [...yesOrNoCheckboxList];
        // If the form includes state & province Abbrev, join the two lists together for each to be choosen
        if (lists[key].provinceAbbrev && lists[key].stateAbbrev) {
          lists[key].provinceAbbrev = [
            ...lists[key].provinceAbbrev,
            ...lists[key].stateAbbrev,
          ];
          lists[key].stateAbbrev = lists[key].provinceAbbrev;
        }
      });
      return lists;
    }
    return {
      yesOrNoList,
      yesOrNoCheckboxList,
    };
  }
}

interface DataTransformInfo {
  value: string;
  text: {
    fr: string;
    en: string;
  };
}
