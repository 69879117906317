import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import routePaths from "./route-paths";

@Injectable({
  providedIn: "root",
})
export class RouteLocalizerService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private title: Title
  ) {}

  /* set the document title; to be used on all "page" components
  pageTitle: use a constant from the language files
  params: optional ngx-translate params (empty if none provided)
  -------------------------------------------------------------- */
  public setTranslatedTitle(pageTitle: string, params: any = {}): void {
    this.translate.get(pageTitle, params).subscribe((title) => {
      this.translate.get("SITE_NAME").subscribe((site) => {
        this.title.setTitle(`${title} | ${site}`);
      });
    });
  }

  /* access routePaths object
  --------------------------- */
  public getRoutes(): any {
    return routePaths;
  }

  /* get the lang segment from the current route
  ---------------------------------------------- */
  public getCurrentRouteLang(): string {
    return this.router.url.split("/")[1];
  }

  /* get a localized path name by key; use in [routerLink].
  key: routePaths object key or null
  relativePath (optional): 'relative' prefix string as e.g.: '/', './', or '../'
  -------------------------------------------------------- */
  public get(lang: string, key: string | null, relativePath?: string): any {
    const rel = relativePath ? relativePath : "";
    return key === null
      ? `${rel}`
      : // @ts-ignore (typing issue with routePaths[lang]?)
        `${rel}${routePaths[lang][key]}`;
  }

  /* navigate to any localized route, using a path key
  ---------------------------------------------------- */
  public goTo(lang: string, key: string): void {
    lang === "en"
      ? // @ts-ignore (typing issue with routePaths[lang] ?)
        this.router.navigateByUrl(`/${lang}/${routePaths.en[key]}`)
      : // @ts-ignore (typing issue with routePaths[lang] ?)
        this.router.navigateByUrl(`/${lang}/${routePaths.fr[key]}`);
  }

  /* navigate to equivalent of current route; for the language toggle
  ------------------------------------------------------------------- */
  public goToLocalizedCurrentRoute(lang: string): void {
    let path = this.router.url;
    const queryIndex = path.indexOf("?");
    if (queryIndex > 0) {
      path = path.slice(0, queryIndex);
    }
    const currentUrlSegments = path.split("/"); // creates array of current url segments as: ["", "en", "case", "42"]
    const localUrlSegments = currentUrlSegments.slice(2); // ["case", "42"]
    let queryParams = {};
    this.route.queryParams
      .pipe(first())
      .subscribe((params) => (queryParams = params));
    this.goToLocalizedRoute(localUrlSegments, queryParams, lang);
  }

  /* navigate to any localized route, using given url segments
  ------------------------------------------------------------ */
  public goToLocalizedRoute(url: any, queryParams: object, lang: string): void {
    const newSegments = url.map((seg: string) =>
      this.getTranslatedSegment(decodeURIComponent(seg), lang)
    );
    this.router.navigate([lang, ...newSegments], { queryParams });
  }

  private getTranslatedSegment(seg: string, lang: string): any {
    const currentRouteLang = this.router.url.split("/")[1]; // "en"
    const key = this.getKeyFor(currentRouteLang, seg);

    // @ts-ignore (typing issue with routePaths[lang] ?)
    return key ? routePaths[lang][key] : seg;
  }

  private getKeyFor(lang: string, term: string): any {
    // @ts-ignore (typing issue with routePaths[lang] ?)
    const filteredRoutes = Object.entries(routePaths[lang]).filter(
      ([k, v]) => v === term
    );
    return filteredRoutes[0] ? filteredRoutes[0][0] : null;
  }
}
