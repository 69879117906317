import { Injectable } from "@angular/core";
import awsconfig from "../../../../../../../config/aws-exports";
import AWS from "aws-sdk/global";
import s3 from "aws-sdk/clients/s3";
import { AWSService } from "@pr-caseworker/app/core/auth-module/services/AWS.service";
import { ManagedUpload } from "aws-sdk/lib/s3/managed_upload";
import SendData = ManagedUpload.SendData;
import { from, Observable, Observer, of } from "rxjs";
import { PutObjectRequest } from "aws-sdk/clients/s3";
import { isEmpty as lIsEmpty } from "lodash";
import { switchMap } from "rxjs/operators";
import crypto from "crypto";
import { environment } from "@pr-caseworker/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AwsSdkService {
  s3Bucket: s3;
  identityId: string;
  awsToken: string;

  constructor(private awsConfigService: AWSService) {}

  configureAwsSdk() {
    this.awsConfigService.getIdentityIdAndToken().then((response) => {
      AWS.config.update({
        region: awsconfig.aws_user_files_s3_bucket_region,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityId: response.identityId,
          Logins: {
            [`cognito-idp.ca-central-1.amazonaws.com/${awsconfig.aws_user_pools_id}`]:
              response.token,
          },
        }),
      });

      this.s3Bucket = new s3({
        params: {
          Bucket: awsconfig.aws_user_files_s3_bucket,
        },
      });

      this.identityId = response.identityId;
      this.awsToken = response.token;
    });
  }

  uploadFileToS3(
    fileName: string,
    fileSelected: any,
    fileType: any,
    hash: string,
    metaData?: any,
    identityId?: string
  ): Observable<S3UploadResponse | boolean> {
    const identityIdToBeUsed = identityId || this.identityId;
    // only file greater than 0 bytes can be uploaded
    if (fileSelected?.size > 0) {
      return Observable.create(
        (observer: Observer<boolean | S3UploadResponse>) => {
          // call back function to be passed to upload method of aws sdk
          const uploadFileCallBack = (error: Error, data: SendData) => {
            if (error) {
              observer.next(false);
            }
            if (data) {
              const transformedData: S3UploadResponse = {
                ...data,
                identityId: identityIdToBeUsed,
                awsToken: this.awsToken,
                uploadedFileName: data.Key.split(`${identityIdToBeUsed}/`)[1],
              };
              observer.next(transformedData);
            }
            observer.complete();
          };

          const params: PutObjectRequest = {
            Body: fileSelected,
            Bucket: awsconfig.aws_user_files_s3_bucket,
            ChecksumSHA256: hash,
            ContentType: fileType,
            Key: `private/${identityIdToBeUsed}/${fileName}`,
          };

          if (!lIsEmpty(metaData)) {
            params.Metadata = metaData;
          }
          this.s3Bucket.upload(params, (error: Error, data: SendData) =>
            uploadFileCallBack(error, data)
          );
        }
      );
    }
    if (!environment.production) {
      console.error("file size is 0", fileSelected);
    }
    return of(false);
  }

  createFileChecksum(file: any): Observable<string> {
    return of("true").pipe(
      switchMap(() => from(new Response(file).arrayBuffer())),
      switchMap((fileBuffer) => {
        return of(
          crypto
            .createHash("sha256")
            .update(new Uint8Array(fileBuffer))
            .digest("base64")
        );
      })
    );
  }
}

export interface S3UploadResponse extends SendData {
  identityId: string;
  awsToken: string;
  uploadedFileName: string;
}
