import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { AuthService } from "../../services/auth.service";
import routePaths from "../../../../routing/route-paths";

@Component({
  selector: "prcw-forgot-password-page",
  templateUrl: "./forgot-password-page.component.html",
  styleUrls: ["./forgot-password-page.component.scss"],
})
export class ForgotPasswordPageComponent implements OnInit {
  public currentLang: string;
  public emailForm: FormGroup;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("RESET.EMAIL.PAGE_TITLE");
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    this.emailForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  onSubmit(): void {
    if (this.emailForm.status === "INVALID") {
      return;
    } else {
      const email = this.emailForm.value.email;
      this.authService
        .sendEmailToResetPassword(email)
        .then(() => {
          // TODO: use RouteLocalizer
          const url = `/${this.currentLang}/${
            this.currentLang === "fr"
              ? routePaths.fr.RESET_PASSWORD
              : routePaths.en.RESET_PASSWORD
          }`;
          this.router.navigate([url], { queryParams: { email } });
        })
        .catch(() => {
          this.alertService.danger(this.alertTechnicalError);
        });
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("LOGIN.ALERTS.TECHNICAL_ERROR");
  }
}
