import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as AuthActions from "../store/auth.actions";
import { delay, map, retry, switchMap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { AWSService } from "./AWS.service";
import { from, of } from "rxjs";
@Injectable()
export class AuthService {
  authenticated: boolean;
  expiryTime: Date | undefined;
  cognitoUser: any;
  private passwordForMFA: string;

  constructor(
    private http: HttpClient,
    private store: Store<fromApp.State>,
    private awsService: AWSService
  ) {
    this.store
      .select("auth")
      .pipe(map((authState) => authState.user))
      .subscribe((user) => {
        this.authenticated = !!user;
        this.expiryTime = user?.expiryDate;
      });
  }

  async login(username: string, password: string) {
    try {
      const user = await this.awsService.auth.signIn(
        username.toLowerCase(),
        password
      );
      this.cognitoUser = user;
      this.passwordForMFA = password;
      return user;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailToResetPassword(username: string) {
    try {
      const result = await this.awsService.auth.forgotPassword(
        username.toLowerCase()
      );
      return result;
    } catch (error) {
      // if email does not exist on the system we don't want to let the user know
      if (
        error.code !== "NotAuthorizedException" &&
        error.code !== "UserNotFoundException"
      ) {
        throw error;
      }
    }
  }

  async forgotPasswordReset(
    username: string,
    code: string,
    newPassword: string
  ) {
    try {
      const result = await this.awsService.auth.forgotPasswordSubmit(
        username.toLowerCase(),
        code,
        newPassword
      );
      return result;
    } catch (error) {
      throw error;
    }
  }

  // Gets called when clicking resend code in the MFA (SMS) page
  async resendVerificationCodeForMFA() {
    try {
      // Login again only to send the MFA SMS code
      await this.login(this.cognitoUser.username, this.passwordForMFA);

      // Clear for security reasons
      this.passwordForMFA = "";
    } catch (error) {
      // route back to login
    }
  }

  async confirmMFASignIn(verificationCode: string) {
    const mfaType = "SMS_MFA";
    try {
      const loggedUser = await this.awsService.auth.confirmSignIn(
        this.cognitoUser, // Return object from this.awsService.auth.signIn()
        verificationCode, // Confirmation code
        mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
      this.cognitoUser = loggedUser;
      this.store.dispatch(
        new AuthActions.Login({
          username:
            this.cognitoUser.signInUserSession.idToken.payload.email.toLowerCase(),
          session: this.cognitoUser.Session,
          isFirstLogin: false,
          token: this.cognitoUser.signInUserSession.accessToken.jwtToken,
          userPool:
            this.cognitoUser.signInUserSession.accessToken.payload[
              "cognito:groups"
            ][0],
        })
      );
      return loggedUser;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      const result = await this.awsService.auth.signOut();
      this.cognitoUser = null;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async checkSession() {
    if (this.authenticated) {
      const dateNow = new Date();
      if (this.expiryTime) {
        if (dateNow > this.expiryTime) {
          this.awsService.auth
            .signOut()
            .then((response) => {
              this.store.dispatch(
                new AuthActions.Logout({
                  successLogout: false,
                  user: null,
                })
              );
            })
            .catch((error) => {
              console.error("error signing you out", error);
            });
        }
      }
    } else {
      this.awsService.auth
        .signOut()
        .then((response) => {
          this.store.dispatch(
            new AuthActions.Logout({
              successLogout: false,
              user: null,
            })
          );
        })
        .catch((error) => {
          console.error("error signing you out", error);
        });
    }
  }

  async changeClientPassword(session: string, password: string, email: string) {
    const apiName = environment.API_NAME;
    const path = "/user";

    const myInit = {
      body: {
        email: email.toLowerCase(),
        session,
        password,
      },
    };
    try {
      const { data } = await this.awsService.api.put(apiName, path, myInit);
      return data;
    } catch (error) {
      throw error.response.data.error;
    }
  }

  public checkAutofillOnLogin(): boolean {
    /* CHROME AUTO FILL FIX */
    let username;
    let password;
    let isAutofill = false;

    if (!(navigator.userAgent.toLowerCase().indexOf("firefox") > -1)) {
      // eslint-disable-next-line @typescript-eslint/quotes
      username = document.querySelector("input[type='email']:-webkit-autofill");
      // eslint-disable-next-line @typescript-eslint/quotes
      password = document.querySelector(
        "input[type='password']:-webkit-autofill"
      );
      if (username && password) {
        isAutofill = true;
      }
    }
    return isAutofill;
  }

  async resendClientPassword(caseId: string) {
    const apiName = environment.API_NAME;
    const path = `/v2/cases/${caseId}/resend-password`;
    const myInit = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, myInit);
      return response;
    } catch (error) {
      throw error.response.data.error;
    }
  }

  async checkForcePasswordChangeStatus(caseId: string) {
    // checks if the user is has ever logged into the PR portal
    const apiName = environment.API_NAME;
    const path = `/v2/cases/${caseId}/users`;
    const myInit = {
      body: {},
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(apiName, path, myInit);
      if (response.data.userStatus === "FORCE_CHANGE_PASSWORD") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw error.response.data.error;
    }
  }
  async getUsersList(username: string) {
    const apiName = environment.API_NAME;
    const path = `/v2/caseworkers`;
    let counter = -1;
    const myInit = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      body: {},
      response: true,
    };
    of("signal")
      .pipe(
        switchMap((x) => {
          counter = counter + 1;
          return of(counter).pipe(
            delay(counter * 2000),
            switchMap((c) =>
              from(this.awsService.api.get(apiName, path, myInit))
            )
          );
        }),
        retry(2)
      )
      .subscribe(
        (response) => {
          this.store.dispatch(
            new AuthActions.LoadUsersList({ usersList: response.data })
          );
        },
        (err) => {
          console.error(
            "Unable to load users list after %s attempts",
            counter + 1
          );
        }
      );
  }
}
