import * as fromSearchTerms from "./search-terms/search-terms.reducer";
import * as fromRoot from "@pr-caseworker/app/store/app.reducer";
import * as fromSelectedApp from "./selected-application/selected-application.reducer";
import { ActionReducerMap } from "@ngrx/store";

export interface RenewalState {
  searchTerms: fromSearchTerms.SearchTermsState;
  selectedApplication: fromSelectedApp.SelectedApplicationState;
}

export interface State extends fromRoot.State {
  renewal: RenewalState;
}

export const renewalReducers: ActionReducerMap<RenewalState> = {
  searchTerms: fromSearchTerms.reducer,
  selectedApplication: fromSelectedApp.reducer,
};

// export const renewalReducer = combineReducers({
//   searchTerms: fromSearchTerms.reducer
// })

export const renewalStateKey = "renewal";
