import { createAction, props } from "@ngrx/store";
import { SearchFieldValues } from "@pr-caseworker/app/renewal-module/store/search-terms/search-terms.reducer";

export enum SearchTermsActionTypes {
  SetPageReview = "[SearchTermState] Set Page Review",
  SetSearchField = "[SearchTermState] Set Search Field",
  SetSortValue = "[SearchTermState] Set Sort Value",
  SetLobFilterValue = "[SearchTermState] Set Lob Filter Value",
  SetStatusFilterValue = "[SearchTermState] Set Status Filter Value",
  SetAssignedFilterValue = "[SearchTermState] Set Assigned Filter Value",
  SetAssignedToMe = "[SearchTermState] Set Assigned To Me",
  ResetAllFilters = "[SearchTermState] Reset All Filters",
  SetUrgentApplications = "[SearchTermState] Set Urgent Applications",
  SetUrgencyGranted = "[SearchTermState] Set Urgency Granted",
}

export const setPageReviewAction = createAction(
  SearchTermsActionTypes.SetPageReview,
  props<{ pageToReview: number }>()
);

export const setSearchFieldAction = createAction(
  SearchTermsActionTypes.SetSearchField,
  props<{ searchTerms: SearchFieldValues }>()
);

export const setSortValueAction = createAction(
  SearchTermsActionTypes.SetSortValue,
  props<{ sortOrder: string }>()
);

export const setLobFilterValueAction = createAction(
  SearchTermsActionTypes.SetLobFilterValue,
  props<{ lobFilterValue: string }>()
);

export const setStatusFilterValue = createAction(
  SearchTermsActionTypes.SetStatusFilterValue,
  props<{ statusFilterValue: number }>()
);

export const setAssignedFilterValue = createAction(
  SearchTermsActionTypes.SetAssignedFilterValue,
  props<{ assignedFilterValue: boolean }>()
);

export const setAssignedToMe = createAction(
  SearchTermsActionTypes.SetAssignedToMe,
  props<{ assignedToMe: boolean }>()
);

export const resetAllFilters = createAction(
  SearchTermsActionTypes.ResetAllFilters,
  props<{ sortOrder: any }>()
);

export const setUrgentApplications = createAction(
  SearchTermsActionTypes.SetUrgentApplications,
  props<{ urgentApplications: boolean }>()
);

export const setUrgencyGranted = createAction(
  SearchTermsActionTypes.SetUrgencyGranted,
  props<{ urgencyGranted: boolean }>()
);
