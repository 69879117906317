import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import Cropper from "cropperjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "../../services/alert/alert.service";
import { ModalService } from "../../services/modal/modal.service";
import { TranslateService } from "@ngx-translate/core";
import ViewMode = Cropper.ViewMode;
import { Subscription } from "rxjs";

export interface CropperCoordinatesBias {
  leftDiff: number;
  topDiff: number;
  widthCompare: number;
  heightCompare: number;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngx-photo-editor",
  templateUrl: "./ngx-photo-editor.component.html",
  styleUrls: ["./ngx-photo-editor.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NgxPhotoEditorComponent implements OnChanges, OnDestroy {
  @ViewChild("ngxPhotoEditorContent", { static: false }) content: any;
  @HostListener("document:keydown.escape", ["$event"]) onEscapePressed(
    event: KeyboardEvent
  ) {
    this.close(true);
  }

  public cropper: Cropper;
  public outputImage: string;
  prevZoom = 0;

  @Input() modalTitle: string;
  @Input() aspectRatio = 1;
  @Input() autoCropArea = 1;
  @Input() autoCrop = true;
  @Input() mask = true;
  @Input() guides = true;
  @Input() centerIndicator = true;
  @Input() viewMode: ViewMode = 2;
  @Input() modalSize: size;
  @Input() modalCentered = false;
  @Input() scalable = true;
  // @Input() zoomable = true;
  @Input() cropBoxMovable = true;
  @Input() cropBoxResizable = true;
  @Input() darkTheme = true;
  @Input() roundCropper = false;
  @Input() canvasHeight = 400;
  @Input() edit: boolean;
  @Input() resizeToWidth: number;
  @Input() resizeToHeight: number;
  @Input() imageSmoothingEnabled = true;
  @Input() imageSmoothingQuality: ImageSmoothingQuality = "high";
  @Input() preSetCropperCoordinatesBias: CropperCoordinatesBias;
  url: string;
  lastUpdate = Date.now();
  disableApply = false;
  public photoEditorModal = "photoEditorModal";
  public applyCopy = "INTAKE.PHOTOS.CROP.APPLY | translate";
  public cancelCopy = "INTAKE.PHOTOS.CROP.CANCEL | translate";
  format = "jpeg";
  quality = 100;

  subscriptions: Subscription[] = [];

  isFormatDefined = false;

  @Output() modalCloseCancel = new EventEmitter<boolean>();
  @Output() imageCropped = new EventEmitter<CroppedEvent>();
  @Output() cropperCoordinatesBias = new EventEmitter<CropperCoordinatesBias>();

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private libModalService: ModalService,
    private translate: TranslateService
  ) {
    this.subscriptions.push(
      router.events.subscribe((val) => {
        this.close(false);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  @Input() set imageQuality(value: number) {
    if (value > 0 && value <= 100) {
      this.quality = value;
    }
  }

  @Input() set imageFormat(type: imageFormat) {
    if (/^(gif|jpe?g|tiff|png|webp|bmp)$/i.test(type)) {
      this.format = type;
      this.isFormatDefined = true;
    }
  }

  @Input() set imageUrl(url: string) {
    if (url) {
      this.url = url;
      if (this.lastUpdate !== Date.now()) {
        this.open();
        this.lastUpdate = Date.now();
      }
    }
  }

  @Input() set imageBase64(base64: string) {
    if (base64 && /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/.test(base64)) {
      this.imageUrl = base64;
      if (!this.isFormatDefined) {
        this.format = base64
          .split(",")[0]
          .split(";")[0]
          .split(":")[1]
          .split("/")[1];
      }
    }
  }

  @Input() set imageChanedEvent(event: any) {
    if (event) {
      const file = event.target.files[0];
      if (file && /\.(gif|jpe?g|tiff|png|webp|bmp)$/i.test(file.name)) {
        if (!this.isFormatDefined) {
          this.format = event.target.files[0].type.split("/")[1];
        }
        const reader = new FileReader();
        reader.onload = (ev: any) => {
          this.imageUrl = ev.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  @Input() set imageFile(file: File) {
    if (file && /\.(gif|jpe?g|tiff|png|webp|bmp)$/i.test(file.name)) {
      if (!this.isFormatDefined) {
        this.format = file.type.split("/")[1];
      }
      const reader = new FileReader();
      reader.onload = (ev: any) => {
        this.imageUrl = ev.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  ngOnChanges() {
    if (!!this.edit) {
      this.open();
    }
  }

  onImageLoad(image: any) {
    image.addEventListener("ready", () => {
      if (this.roundCropper) {
        (
          document.getElementsByClassName("cropper-view-box")[0] as HTMLElement
        ).style.borderRadius = "50%";
        (
          document.getElementsByClassName("cropper-face")[0] as HTMLElement
        ).style.borderRadius = "50%";
      }
    });

    this.cropper = new Cropper(image, {
      aspectRatio: this.aspectRatio,
      autoCropArea: this.autoCropArea,
      autoCrop: this.autoCrop,
      modal: this.mask, // black mask
      guides: this.guides, // grid
      center: this.centerIndicator, // center indicator
      viewMode: this.viewMode,
      scalable: this.scalable,
      zoomable: false,
      movable: false,
      minCropBoxWidth: 50,
      minCropBoxHeight: 50,
      cropBoxMovable: this.cropBoxMovable,
      cropBoxResizable: this.cropBoxResizable,
      ready: () => {
        // preset cropper when it's ready
        // preset initial crop box size and position
        const pic = this.cropper.getCanvasData();
        if (!!this.preSetCropperCoordinatesBias) {
          const left =
            pic.left + pic.width * this.preSetCropperCoordinatesBias.leftDiff;
          const top =
            pic.top + pic.height * this.preSetCropperCoordinatesBias.topDiff;
          const width =
            pic.width * this.preSetCropperCoordinatesBias.widthCompare;
          const height =
            pic.height * this.preSetCropperCoordinatesBias.heightCompare;

          this.cropper.setCropBoxData({
            left,
            top,
            width,
            height,
          });
        }
        // preset the maxmum image size
        const baseImg = document.getElementsByClassName("cropper-canvas")[0]
          ?.children[0] as HTMLImageElement;
        const cropImg = document.getElementsByClassName("cropper-view-box")[0]
          ?.children[0] as HTMLImageElement;
        baseImg.style.maxHeight = this.canvasHeight + "px";
        baseImg.style.maxWidth =
          (this.canvasHeight / pic.height) * pic.width + "px";
        cropImg.style.maxHeight = this.canvasHeight + "px";
        cropImg.style.maxWidth =
          (this.canvasHeight / pic.height) * pic.width + "px";

        // calculate crop area when window resizing
        window.onresize = () => {
          this.updateCanvasSize();
          const container = this.cropper.getContainerData();
          const canvas = this.cropper.getCanvasData();
          const height = canvas.height;
          const width = canvas.width;
          const top = Math.abs((container.height - height) / 2);
          const left = Math.abs((container.width - width) / 2);
          this.cropper.setCanvasData({
            top,
            left,
          });
        };
      },
    });
  }

  updateCanvasSize() {
    const koef = 0.9; // how smaller than window viewport image should be
    const container = this.cropper.getContainerData();
    const allowedSize = Math.min(container.width, container.height) * koef;
    const image = this.cropper.getImageData();
    const imgW = Math.round(image.width);
    const imgH = Math.round(image.height);
    const originalW = Math.round(image.naturalWidth);
    const originalH = Math.round(image.naturalHeight);
    let newH = 1;
    let newW = 1;
    let scale = 1;
    if (allowedSize < imgH || imgW > allowedSize) {
      if (imgW > imgH) {
        scale = allowedSize / imgW;
        if (imgH * scale > allowedSize) {
          scale = allowedSize / imgH;
        }
      } else {
        scale = allowedSize / imgH;
        if (imgW * scale > allowedSize) {
          scale = allowedSize / imgW;
        }
      }
    }
    newW = Math.round(scale * imgW);
    newH = Math.round(scale * imgH);
    // this part is needed if you want to keep size of small images
    if (newW >= originalW && newH >= originalH) {
      newW = originalW;
      newH = originalH;
    }
    // end
    this.cropper.setCanvasData({
      width: newW,
      height: newH,
    });
  }

  public get currentLang(): string {
    return this.router.url.split("/")[1];
  }

  public getApplyButtonCopy(): string {
    const applyButtonKey: any = {
      apply: {
        en: "Apply",
        fr: "Appliquer les changements",
      },
    };
    return applyButtonKey.apply[this.currentLang];
  }

  public getCancelButtonCopy(): string {
    const cancelButtonKey: any = {
      cancel: {
        en: "Cancel",
        fr: "Annuler",
      },
    };
    return cancelButtonKey.cancel[this.currentLang];
  }

  public getModalTitle(): string {
    const modalTitleKey: any = {
      title: {
        en: "Photo Editor",
        fr: "Éditeur d’images",
      },
    };
    return modalTitleKey.title[this.currentLang];
  }

  public getHelpText(): string {
    const helpText: any = {
      text: {
        en: "Use the blue rectangle crop box to center and fit your head between the two circular green lines and ensure your chin touches the bottom of the circle. If you are unable to use your cursor to drag and crop your photo to meet the photo requirements, a caseworker can do so for you once your photo is submitted.",
        fr: `Utilisez le rectangle bleu de recadrage pour centrer et placer votre tête entre les deux lignes vertes circulaires; assurez-vous que votre menton touche le bas du cercle. Si vous n'êtes pas en mesure d'utiliser votre curseur pour recadrer votre photo afin de répondre aux exigences des photos, un agent de traitement des cas pourra le faire pour vous une fois que votre photo est soumise.`,
      },
    };
    return helpText.text[this.currentLang];
  }

  rotateRight() {
    this.cropper.rotate(45);
  }

  rotateLeft() {
    this.cropper.rotate(-45);
  }

  crop() {
    this.cropper.setDragMode("crop");
  }

  move() {
    this.cropper.setDragMode("move");
  }

  export() {
    let cropedImage;
    const cropBoxData = this.cropper.getCropBoxData();
    const originalImgData = this.cropper.getCanvasData();

    // calculate croppper difference bias
    const cropperDifferencesBias: CropperCoordinatesBias = {
      leftDiff:
        (cropBoxData.left - originalImgData.left) / originalImgData.width,
      topDiff: (cropBoxData.top - originalImgData.top) / originalImgData.height,
      widthCompare: cropBoxData.width / originalImgData.width,
      heightCompare: cropBoxData.height / originalImgData.height,
    };

    this.cropperCoordinatesBias.emit(cropperDifferencesBias);

    if (this.resizeToWidth && this.resizeToHeight) {
      cropedImage = this.cropper.getCroppedCanvas({
        width: this.resizeToWidth,
        imageSmoothingEnabled: this.imageSmoothingEnabled,
        imageSmoothingQuality: this.imageSmoothingQuality,
      });
    } else if (this.resizeToHeight) {
      cropedImage = this.cropper.getCroppedCanvas({
        height: this.resizeToHeight,
        imageSmoothingEnabled: this.imageSmoothingEnabled,
        imageSmoothingQuality: this.imageSmoothingQuality,
      });
    } else if (this.resizeToWidth) {
      cropedImage = this.cropper.getCroppedCanvas({
        width: this.resizeToWidth,
        imageSmoothingEnabled: this.imageSmoothingEnabled,
        imageSmoothingQuality: this.imageSmoothingQuality,
      });
    } else {
      cropedImage = this.cropper.getCroppedCanvas({
        imageSmoothingEnabled: this.imageSmoothingEnabled,
        imageSmoothingQuality: this.imageSmoothingQuality,
      });
    }
    this.outputImage = cropedImage.toDataURL(
      "image/" + this.format,
      this.quality
    );
    this.imageCropped.emit({
      base64: this.outputImage,
    });
  }

  open() {
    this.modalService.open(this.content, {
      size: this.modalSize,
      centered: this.modalCentered,
      backdrop: "static",
      windowClass: "custom-class",
    });
  }

  close(isButton: boolean) {
    this.modalService.dismissAll();
    if (isButton) {
      this.modalCloseCancel.emit(true);
    }
  }
}

export interface CroppedEvent {
  base64?: string;
  file?: File;
}

export type imageFormat = "gif" | "jpeg" | "tiff" | "png" | "webp" | "bmp";

export type size = "sm" | "lg" | "xl" | string;
