import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, ModalService } from "lib";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { RejectionInputComponent } from "../../../shared/rejection-input/rejection-input.component";
import { State } from "../../../store/app.reducer";
import { CasesService } from "../../../core/cases-module/cases.service";
import * as CasesActions from "../../store/cases.actions";
import {
  DocumentForReview,
  Reason,
} from "../../models/document-rejection.model";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { map } from "rxjs/operators";
import { StartDeleteCurrentCase } from "../../store/cases.actions";
/* eslint-disable eqeqeq */
// disabled because sometimes the DB sends back 0 or 1 instead of true or false for case document statuses
@Component({
  selector: "prcw-case-details-page",
  templateUrl: "./case-details-page.component.html",
  styleUrls: ["./case-details-page.component.scss"],
})
export class CaseDetailsPageComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  public currentLang: string;
  // Case Information
  public caseId: any;
  public case: any;
  public caseStatus: string;
  public caseStatusText: string;
  public caseStatusColour: string;
  // Documents Table
  public addressStatus: boolean | null;
  public photoName: string;
  public photoLocation: any;
  public photoId: number;
  public photoStatus: boolean | null;
  public addressRejectionModalId = "addressRejectionModal";
  public photoRejectionModalId = "photoRejectionModal";
  public allowDeleteCase = false;

  // Reasons used by modals
  photoRejectionReasons: Reason[];
  addressRejectionReasons: Reason[];
  // From modal
  tempModalRejectionReasons: Reason[] | null;
  tempIsValidInput: boolean | null;

  // from case confirmation
  ecoprFileSelected: any = null;
  ecoprFileExists = false;

  // Case worker decisions stored in an array
  public documentStatuses: DocumentForReview[];

  // Buttons
  public isModified = false;
  public enableSubmitBtn = false;
  public editCase = false;
  public isNavigatingAway = false;
  public isDeletingCase = false;

  @ViewChild("photoRejection" /* #name or Type*/, { static: false })
  photoRejection: RejectionInputComponent;
  @ViewChild("addressRejection" /* #name or Type*/, { static: false })
  addressRejection: RejectionInputComponent;

  // check to see if we are coming from photo review page
  public isNavigatingFromPhotoReview = false;
  public deleteCaseModalId = "deleteCaseModalId";
  public allowToDeleteCases: boolean | null = false;

  private clientDetailsExtracted = false;
  public croppedImageInfo: any = null;
  private subscriptions: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    private caseService: CasesService,
    private modalService: ModalService,
    private store: Store<State>,
    private translate: TranslateService,
    private cdref: ChangeDetectorRef
  ) {
    this.caseId = this.activatedRoute.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    const self = this;
    this.subscriptions.push(
      this.store
        .select("confirmation")
        .pipe(map((data) => data.selectedCase))
        .subscribe((data) => {
          self.case = _.cloneDeep(data);
          if (self.case?.apiLoadingError === "DELETE_ERROR") {
            this.isDeletingCase = false;
          }
          if (this.case?.uci) {
            this.routeLocalizer.setTranslatedTitle("CASE_DETAILS.PAGE_TITLE", {
              uci: this.case.uci,
            });
          }
          if (!this.clientDetailsExtracted) {
            this.getClientDetails();
          }
          if (this.currentLang === "fr") {
            this.caseStatusText = this.case?.status?.statusFr;
          } else {
            this.caseStatusText = this.case?.status?.statusEn;
          }
          this.caseStatusColour = this.caseService.checkApplicantStatus(
            this.case?.caseStatusId
          );
          self.addressStatus = self.case?.address?.approvedStatus;
          this.addressRejectionReasons = _.cloneDeep(
            this.case?.address?.rejectionReasons
          );
          if (this.case?.address?.modified) {
            this.isModified = true;
          }
          this.photoRejectionReasons = _.cloneDeep(
            this.case?.photo?.rejectionReasons
          );
          this.photoStatus = this.case?.photo?.approvedStatus;
          if (this.case?.photo?.modified) {
            this.isModified = true;
          }
          this.documentStatuses = [
            {
              type: "ADDRESS",
              status: this.addressStatus,
              rejectionReasonsArray: this.addressRejectionReasons
                ? [...this.addressRejectionReasons]
                : [],
            },
            {
              id: this.photoId,
              type: "PHOTO",
              status: this.photoStatus,
              rejectionReasonsArray: this.photoRejectionReasons
                ? [...this.photoRejectionReasons]
                : [],
            },
          ];
          this.handleNavigatingPhotoReviewPage();
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(map((authState) => authState?.user))
        .subscribe((user) => {
          user
            ? (this.allowDeleteCase =
                user.userPool?.toLocaleUpperCase() === "TEAMLEADS")
            : (this.allowDeleteCase = false);
        })
    );
  }

  ngAfterContentChecked() {
    if (this.isNavigatingFromPhotoReview) {
      this.cdref.detectChanges();
      this.handleNavigatingPhotoReviewPage();
    }
  }

  get casePhoto(): any {
    const photosOnly = this.case?.documents?.filter(
      (doc: any) => doc.documentTypeId === 1
    );
    if (photosOnly && photosOnly.length >= 1) {
      return photosOnly[0];
    }
  }

  get caseStatusLabel() {
    return this.caseService.getStatusLabel(this.case.caseStatusId);
  }

  private get clientConfirmation(): boolean {
    return Boolean(this.case.clientLanded) ? true : this.ecoprFileExists;
  }

  // Emitted From Case Document Table Component when clicking reject
  // triggered when Rejectd status is selected in dropdowns
  handleDisplayRejectModal(documentType: string) {
    switch (documentType) {
      case "ADDRESS":
        this.modalService?.open(this.addressRejectionModalId);
        break;
      case "PHOTO":
        this.modalService?.open(this.photoRejectionModalId);
        break;
      default:
        return;
    }
  }

  // Emitted from the Modal when clicking cancel or reject
  handleRejectModalUpdate($event: any) {
    const docType = $event.documentType;
    const newStatus = $event.documentNewStatus;
    this.enableSubmitBtn = newStatus === false ? true : false;
    switch (docType) {
      case "PHOTO":
        this.modalService.close(this.photoRejectionModalId);
        break;
      case "ADDRESS":
        this.modalService.close(this.addressRejectionModalId);
        break;
      default:
        return;
    }
    if (!_.isNull(newStatus) || !newStatus) {
      ///  reset data in store
      this.store.dispatch(new CasesActions.ShakeCase());
    }
  }

  public handleEscapedModal(id: string): void {
    if (id === this.photoRejectionModalId) {
      this.photoRejection.cancelRejectDocument("PHOTO");
    } else if (id === this.addressRejectionModalId) {
      this.addressRejection.cancelRejectDocument("ADDRESS");
    }
  }

  private getClientDetails(): void {
    this.clientDetailsExtracted = true;
    // checking if we are coming from photo review page and the right case photo
    if (
      window.history.state.from === "photoReview" &&
      window.history.state.caseId == this.caseId
    ) {
      this.isNavigatingFromPhotoReview = true; // this means check the store instead of DB
    }
    if (this.casePhoto) {
      this.getPhotoFromStorage();
    }
    if (this.case?.documents[0] !== undefined) {
      this.setDocumentsForCaseTable();
    }
  }

  private async getPhotoFromStorage(): Promise<any> {
    this.photoId = this.casePhoto.id;
    try {
      const photoName = `${this.case.uci}-photo.jpeg`;
      const { photoLocation } = await this.caseService.getPhoto(this.case);
      const imageExtension = this.casePhoto?.documentName
        .split("/")
        [this.casePhoto?.documentName.split("/").length - 1].split(".")[1];
      this.photoName = photoName.split(".")[0].concat("." + imageExtension);
      this.photoLocation = photoLocation;
      return null;
    } catch (error) {
      throw error;
    }
  }

  private async setDocumentsForCaseTable() {
    if (!this.isNavigatingFromPhotoReview) {
      this.addressRejectionReasons =
        await this.caseService.getAddressRejectionReasonsByCaseId(this.caseId);
      this.addressStatus = this.case.addressApproved;
      this.storeAddressStatus(false);
      this.photoRejectionReasons =
        await this.caseService.getDocumentRejectionReasonsByDocumentId(
          this.caseId,
          this.photoId
        );
      if (this.casePhoto) {
        this.photoStatus = this.casePhoto?.documentApproved;
        this.storePhotoStatus(false);
      }
    }
  }

  private storeAddressStatus(
    modified: boolean,
    isApproved = this.addressStatus,
    reasons = this.addressRejectionReasons
  ): void {
    if (isApproved) {
      reasons.forEach((rjReason) => (rjReason.selected = false));
    }
    const addressDocs = this.documentStatuses?.find(
      (d) => d.type === "ADDRESS"
    );
    if (addressDocs) {
      addressDocs.rejectionReasonsArray = reasons;
    }
    this.store.dispatch(
      new CasesActions.SetAddressStatus({
        decision: {
          approvedStatus: isApproved,
          rejectionReasons: reasons,
          modified,
        },
        caseId: this.caseId,
      })
    );
  }

  private storePhotoStatus(
    modified: boolean,
    isApproved = this.photoStatus,
    reasons = this.photoRejectionReasons
  ): void {
    if (isApproved) {
      reasons.forEach((rjReason) => (rjReason.selected = false));
    }
    const photoDocs = this.documentStatuses?.find((d) => d.type === "PHOTO");
    if (photoDocs) {
      photoDocs.rejectionReasonsArray = reasons;
    }
    this.store.dispatch(
      new CasesActions.SetPhotoStatus({
        decision: {
          approvedStatus: isApproved,
          rejectionReasons: reasons,
          modified,
        },
        caseId: this.caseId,
      })
    );
  }

  public handleNavigatingPhotoReviewPage(): void {
    // Enable submit button if rejected or approved on the photo review page
    if (
      this.documentStatuses &&
      this.documentStatuses[1].status !== null &&
      this.isNavigatingFromPhotoReview
    ) {
      this.enableSubmitBtn = true;
    }
  }

  // Emitted From Case Document Table Component
  //  triggered by updateDocumentStatus event emitter
  //  update store
  // this is triggered when new status for photo or address is selected
  public handleDocumentStatus($document: any): void {
    const { type, status } = $document;
    switch (type) {
      case "ADDRESS":
        this.documentStatuses[0].status = status;
        this.addressStatus = status;
        if (_.isNil(this.addressStatus) || this.addressStatus) {
          this.storeAddressStatus(true);
        }
        break;
      case "PHOTO":
        this.documentStatuses[1].status = status;
        this.photoStatus = status;
        if (_.isNil(this.photoStatus) || this.photoStatus) {
          this.storePhotoStatus(true);
        }
        break;
      default:
        return;
    }
    this.enableSubmitBtn = true;
  }

  // Emitted From Case Confirmation Upload when clicking Approve
  public handleEcoprExists(ecoprExists: boolean): void {
    this.ecoprFileExists = ecoprExists;
  }

  public handleEcoprFileSelected(ecoprFileSelected: any): void {
    this.ecoprFileSelected = ecoprFileSelected;
    if (ecoprFileSelected !== null) {
      this.enableSubmitBtn = true;
    } else {
      this.enableSubmitBtn = false;
    }
  }

  // Emitted From Case Information Component when an error is emitted
  public async handleEditInformation(error: boolean): Promise<void> {
    if (error) {
      this.alertService.danger(this.alertTechnicalError);
    } else {
      // update case
      const updatedCase = await this.caseService.getClientCasebyCaseId(
        this.caseId
      );
      this.case = updatedCase;
    }
  }

  public handleRemoveEcoprError(ecoprTechnicalError: boolean): void {
    this.alertService.danger(this.alertTechnicalError);
  }

  public async handleUpdateCase(): Promise<any> {
    this.isNavigatingAway = true;
    setTimeout(
      () => document.getElementById("btnUpdateCase")?.scrollIntoView(),
      100
    );
    let isPhotoSaved = true;
    // update cropped image if CW cropped
    if (this.croppedImageInfo) {
      try {
        const photo = this.case?.documents?.filter(
          (doc: any) => doc.documentTypeId === 1
        )[0];

        if (photo) {
          this.croppedImageInfo.photoName = photo.documentName.slice(
            photo.documentName.indexOf("/") + 1
          );
        }

        const s3response = await this.caseService.updatePhotoCoordinates(
          this.croppedImageInfo.photoName,
          this.croppedImageInfo.identityId,
          this.croppedImageInfo.image,
          this.croppedImageInfo.metaDataObj
        );
      } catch (error) {
        isPhotoSaved = false;
        this.alertService.danger(this.alertTechnicalError);
        console.error("Error while updateing PhotoCoordinates");
      }
    }
    if (!isPhotoSaved) {
      return;
    }
    // const addressExists = this.addressStatus ? true : false;
    // Address is approved and eCoPR is not uploaded
    // TO DO; Uncomment if statement once UX flow is revisted
    // if (addressExists && !this.ecoprFileExists) {
    // this.alertService.danger(this.alertFileUploadErro})
    // } else
    if (this.ecoprFileSelected !== null) {
      await this.uploadFileAndUpdateCase();
    } else {
      this.updateCase();
    }
  }

  public handleCroppedImageInfo(event: any) {
    this.croppedImageInfo = event;
    this.enableSubmitBtn = true;
  }
  public handleDeleteCase() {
    this.modalService.open(this.deleteCaseModalId);
  }
  public cancelDeleteCaseModal(): void {
    this.modalService.close(this.deleteCaseModalId);
  }
  public acceptDeleteCaseFromModal() {
    this.modalService.close(this.deleteCaseModalId);
    this.store.dispatch(new StartDeleteCurrentCase({ case: this.case }));
    this.isDeletingCase = true;
  }
  public get deleteCaseModalSubheading(): string {
    return this.translate.instant("CASE_DETAILS.MODAL.DELETE_CASE.SUBHEADING", {
      uci: this.case.uci,
    });
  }

  public async uploadFileAndUpdateCase(): Promise<any> {
    try {
      await this.caseService.uploadEcoprFileByCaseId(
        this.caseId,
        this.ecoprFileSelected
      );
      // if the case status is in 1 then theres no documents to update and update case will send a technical error
      if (this.case.caseStatusId === 1) {
        this.router.navigateByUrl(this.currentLang);
        this.alertService.success(this.alertSuccess);
      } else {
        await this.updateCase();
      }
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  public async updateCase(): Promise<any> {
    const address = this.documentStatuses[0];
    address.status = this.addressStatus;
    address.rejectionReasonsArray = this.addressRejectionReasons;
    const photo = this.documentStatuses[1];
    photo.status = this.photoStatus;
    photo.rejectionReasonsArray = this.photoRejectionReasons;
    try {
      await this.caseService.updateDocumentStatus(this.caseId, photo);
      await this.caseService.updateAddressStatus(this.caseId, address);
      await this.caseService.updateAddressRejectionReasonsByCaseId(
        this.caseId,
        address.rejectionReasonsArray
      );
      await this.caseService.updateDocumentRejectionReasonsByDocumentId(
        this.caseId,
        // only photo will have an id, address id is null
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        photo.id!,
        photo.rejectionReasonsArray
      );
      // eslint-disable-next-line max-len
      const newStatus =
        address.status && photo.status && this.clientConfirmation
          ? 4
          : address.status == false || photo.status == false
          ? 3
          : 2;

      // after all updates for individual steps are complete, update the status of the overall case based on decisions
      if (this.case.caseStatusId !== newStatus) {
        await this.caseService.updateCaseStatus(this.caseId, newStatus);
      }

      // update case
      const updatedCase = await this.caseService.getClientCasebyCaseId(
        this.caseId
      );
      this.case = updatedCase;
      this.routeLocalizer.goTo(this.currentLang, "CONFIRMATION_CASES");
      this.alertService.success(this.alertSuccess);
    } catch {
      this.isNavigatingAway = false;
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  private get alertSuccess(): string {
    const displayName =
      this.case.givenName !== null
        ? `${this.case.givenName} ${this.case.surname}`
        : `${this.case.surname}`;
    const translatedLabel = this.translate.instant(this.caseStatusLabel);
    return this.translate.instant("CASE_DETAILS.ALERTS.SUCCESS_LINE", {
      name: displayName,
      uci: this.case.uci,
      label: translatedLabel,
    });
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.TECHNICAL");
  }

  private get alertFileUploadError(): string {
    return this.translate.instant(
      "CASE_DETAILS.CASE_CONFIRMATION.ERRORS.UPLOAD"
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subsc) => subsc.unsubscribe());
  }
}
