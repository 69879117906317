import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "aws-amplify";
import JSZip from "jszip";
import { AlertService } from "lib";
import { isInteger } from "lodash";
import awsconfig from "../../../../../../config/aws-exports";
import { from, of } from "rxjs";
import { retry, switchMap } from "rxjs/operators";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

Storage.configure(awsconfig);
Storage.configure({ level: "private" });
const JSZipUtils = require("../../../../../pr-caseworker/src/assets/script/jszip-utils.js");

@Injectable({
  providedIn: "root",
})

/* Allows cross-origin image downloading */
export class DownloadService {
  bulkDownloadDocs: { fileName: any; fileData: any }[] = [];
  bulkDownloadNumber: number;
  bulkDownloadFailedNumber: number = 0;
  clientName: string;
  apiVersion = environment.API_VERSION;
  apiName = environment.API_NAME;

  constructor(
    private alertService: AlertService,
    private translate: TranslateService,
    private awsService: AWSService,
    private httpClient: HttpClient
  ) {}
  /*
  documentName: full file name of image (identityId + file name)
  downloadName: what to rename image on download (optional)
  */
  public documentDownload(
    documentName: string,
    downloadName?: string,
    isBulkDownload?: boolean
  ) {
    const s3Object = documentName.split("/");
    // if file is in a folder we need to download it from the sub-folder
    // eg. ca-central-1:3c138b2c-acea-42d6-8031-d14ab89b61eb/Test.pdf_1619557298725/Test.pdf
    // file name becomes Test.pdf_1619557298725/Virgin.pdf
    const [identityId, ...rest] = documentName.split("/");
    const filename = rest.join("/");
    const fileDownloadName = downloadName || "download";
    if (filename) {
      return Storage.get(filename, { identityId, download: true })
        .then(
          (res) =>
            // @ts-ignore (something weird about res.Body? ¯\_(ツ)_/¯)
            this.downloadBlob(res.Body, fileDownloadName, isBulkDownload),
          (err) => {
            if (isBulkDownload) this.bulkDownloadFailedNumber++;
          }
        )
        .finally(() => {
          if (
            isBulkDownload &&
            this.bulkDownloadDocs.length > 0 &&
            this.bulkDownloadNumber - this.bulkDownloadFailedNumber ===
              this.bulkDownloadDocs.length
          ) {
            this.bulkDownloadFailedNumber = 0;
            this.zipDownloadedDocs();
          }
        });
    }
    return Promise.resolve(undefined);
  }

  
  public async downloadDocument(caseId: number, documentId: number, downloadName?: string) {
    const path = `/${this.apiVersion}/cases/${caseId}/document/${documentId}/download`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };

    try {
      const downloadUrlResponse = await this.awsService.api.get(
        this.apiName,
        path,
        init
      );
      this.httpClient.get(downloadUrlResponse.data, { responseType: 'blob' }).subscribe(data => {
        this.downloadBlob(data,downloadName || 'download')
      }, error => {throw error})
    } catch (error) {
      console.log(error)
      throw error
    }

  }

  public async setFrontImageForBulkDownload(
    base64: string,
    downloadName: string
  ) {
    try {
      const base64Response = await fetch(base64);
      const blob = await base64Response.blob();
      this.downloadBlob(blob, downloadName, true);
    } catch (error) {
      console.error(error);
      this.alertService.danger(this.bulkDownloadTechnicalError);
    }
  }

  getPhotoUrl(fileName: string, identityId: string) {
    return of("getFileName").pipe(
      switchMap(() => from(Storage.get(fileName, { identityId }))),
      retry(1)
    );
  }

  downloadRenewalPhotoBack(fileName: string, identityId: string) {
    return of("getFileName").pipe(
      switchMap(() =>
        from(Storage.get(fileName, { identityId, download: true }))
      ),
      switchMap((res: any) => this.downloadBlob(res.Body, fileName, false)),
      retry(1)
    );
  }

  private downloadBlob(
    blob: Blob,
    downloadName: string,
    isBulkDownload?: boolean
  ): Promise<any> {
    this.bulkDownloadDocs.forEach((doc) => {
      if (doc.fileName === downloadName) {
        // check if already add numbers at the end (repeated files)
        const numberPart = doc.fileName.match(/\([0-9]+\)\.[a-z]*$/gm);
        if (numberPart && numberPart.length > 0) {
          // if file repeated, get count number and increase
          let [number, ...rest] = numberPart[0].split(".");
          number = number.replace("(", "");
          number = number.replace(")", "");
          let count = parseInt(number, 10);
          const prefix = downloadName.replace(numberPart[0], "");
          count = count + 1;
          const extension = downloadName.split(".").pop();
          downloadName = `${prefix}(${count}).${extension}`;
        } else {
          // fist time repeat, add (1) after the filename
          const count = 1;
          const newName = downloadName.split(".").slice(0, -1);
          const extension = downloadName.split(".").pop();
          downloadName = `${newName.join(".")}(${count}).${extension}`;
        }
      }
    });
    if (isBulkDownload) {
      this.bulkDownloadDocs.push({
        fileName: downloadName,
        fileData: blob,
      });
    } else {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadName;
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener("click", clickHandler);
        }, 150);
      };
      a.addEventListener("click", clickHandler, false);
      a.click();
      return Promise.resolve(a);
    }
    return Promise.resolve(undefined);
  }

  public setBulkDownloadCounter(count: number) {
    this.bulkDownloadNumber = count;
  }

  private get bulkDownloadTechnicalError(): string {
    return this.translate.instant(
      "INTAKE.ALERTS.BULK_DOWNLOAD_TECHNICAL_ERROR"
    );
  }

  private zipDownloadedDocs(): Promise<any> {
    const zippedDocs = new JSZip();
    this.bulkDownloadDocs.forEach((doc) => {
      zippedDocs.file(`${doc.fileName}`, doc.fileData);
    });
    return zippedDocs
      .generateAsync({ type: "blob" })
      .then((content) => {
        const objectUrl: string = URL.createObjectURL(content);
        const link: any = document.createElement("a");
        link.download = `${this.clientName || "documents"}.zip`;
        link.href = objectUrl;
        link.click();
      })
      .catch((error) => {
        console.error(error);
        this.alertService.danger(this.bulkDownloadTechnicalError);
      })
      .finally(() => {
        this.bulkDownloadDocs.length = 0;
      });
  }

  public setClientName(clientName: string) {
    this.clientName = clientName;
  }
}
