import { Component, HostListener, Input, OnInit } from "@angular/core";
import { ModalService } from "../../services/modal/modal.service";

@Component({
  selector: "lib-navigation-warning",
  template: `
    <lib-modal [id]="'navigationModal'" [title]="title">
      <ng-container modalBody>
        <p class="subheading">
          {{ subheading }}
        </p>
        <p>
          {{ description }}
        </p>
      </ng-container>
      <ng-container modalButtons>
        <button
          category="primary"
          (click)="stopNavigationAndClose('navigationModal')"
        >
          {{ stayBtn }}
        </button>
        <button
          category="secondary"
          (click)="continueNavigationAndClose('navigationModal')"
        >
          {{ leaveBtn }}
        </button>
      </ng-container>
    </lib-modal>
  `,
  styles: [
    `
      .modal-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 58px;
      }
      .subheading {
        font-weight: bold;
      }
    `,
  ],
})
export class NavigationWarningComponent {
  // inputs for text labels
  @Input() public title: string;
  @Input() public subheading: string;
  @Input() public description: string;
  @Input() public leaveBtn: string;
  @Input() public stayBtn: string;
  @Input() public enabled: boolean;

  constructor(private modalService: ModalService) {}

  public stopNavigationAndClose(id: string): void {
    this.modalService.close(id);
    this.modalService.navigateAwaySelection$.next(false);
  }

  public continueNavigationAndClose(id: string): void {
    this.modalService.close(id);
    this.modalService.navigateAwaySelection$.next(true);
  }

  // For all page refreshes. This will prevent them from refresh and logging out if it was by accident
  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    if (this.enabled) $event.returnValue = true;
    else delete $event.returnValue;
    return;
  }
}
