<lib-layout-row gap="one-and-half" mt="2">
  <!-- BACK PAGE -->
  <!-- For the form pages that have only one page with back button -->
  <a [routerLink]="['../']" category="secondary">
    {{ "INTAKE.PAGE_BACK" | translate }}
  </a>

  <!-- RETURN BUTTON -->
  <!-- For the form pages with return to home screen buttons, ex: IMM0008 -->
  <a *ngIf="showReturn" [routerLink]="['../../']" category="primary">
    {{ "INTAKE.PAGE_RETURN" | translate }}
  </a>
</lib-layout-row>
