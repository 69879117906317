import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RouteLocalizerService } from "../../routing/route-localizer.service";
import routePaths from "../../routing/route-paths";

@Component({
  selector: "prcw-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavBarComponent implements OnInit {
  public lang: string;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  public isLinkActive(portal: string): boolean {
    const url = this.router.url;
    switch (portal) {
      case "INTAKE":
        return url.indexOf(
          this.lang === "en"
            ? routePaths.en.APPLICATION_CASES
            : routePaths.fr.APPLICATION_CASES
        ) > -1
          ? true
          : false;
      case "CONFIRMATION":
        return url.indexOf(
          this.lang === "en"
            ? routePaths.en.CONFIRMATION_CASES
            : routePaths.fr.CONFIRMATION_CASES
        ) > -1
          ? true
          : false;
      case "RENEWAL":
        return (
          url.indexOf(
            this.lang === "en"
              ? routePaths.en.RENEWAL_TRAVEL_DOC_CASES
              : routePaths.fr.RENEWAL_TRAVEL_DOC_CASES
          ) > -1
        );
      case "HOME":
        return url === `/${this.lang}` ? true : false;
      default:
        return false;
    }
  }
}
