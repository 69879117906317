<table class="cases-table">
  <caption class="visually-hidden">
    {{
      "CASE_DETAILS.DOCUMENTS_TABLE.TABLE_CAPTION" | translate
    }}
  </caption>
  <!-- If this case status in review but they have self declared -->
  <tbody *ngIf="case.status.id === 1 && addressDeclarationTimestampIsChecked">
    <div class="cases-table__body">
      <tr class="cases-table__body__row">
        <td class="cases-table__body__row__label">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <ng-container>
            <span
              >{{
                "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION_TIME"
                  | translate
              }}: {{ newDateStampAddress | date : "shortTime" }} (UTC)</span
            >
            <span
              >{{
                "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION_DATE"
                  | translate
              }}: {{ newDateStampAddress | date : "yyyy/MM/dd" }}</span
            >
          </ng-container>
        </td>
      </tr>
    </div>
  </tbody>

  <!-- If this case status is not initiated, the client has uploded documents -->
  <tbody *ngIf="case.status.id !== 1">
    <!-- documents takes time to be extracted 
      in case details so put the spinner here for better user experience-->
    <div class="spinner" *ngIf="!documents">
      <lib-spinner [showSpinner]="!documents" [fullPage]="false"></lib-spinner>
    </div>
    <div
      class="cases-table__body"
      *ngFor="let doc of documents; trackBy: trackElemnt"
    >
      <!-- Self delclare Address -->
      <tr class="cases-table__body__row" *ngIf="doc?.type === 'ADDRESS'">
        <td class="cases-table__body__row__label">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <ng-container>
            <span
              >{{
                "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION_TIME"
                  | translate
              }}: {{ newDateStampAddress | date : "shortTime" }} (UTC)</span
            >
            <span
              >{{
                "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION_DATE"
                  | translate
              }}: {{ newDateStampAddress | date : "yyyy/MM/dd" }}</span
            >
          </ng-container>
        </td>
      </tr>

      <!-- Address -->
      <tr *ngIf="doc?.type === 'ADDRESS'" class="cases-table__body__row">
        <td
          class="cases-table__body__row__label cases-table__body__row__header"
        >
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.ADDRESS" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <p
            class="cases-table__body__row__details__title cases-table__body__row__details__name"
          >
            {{ addressString }}
          </p>
        </td>
      </tr>

      <!-- Photo -->
      <tr class="cases-table__body__row" *ngIf="doc?.type === 'PHOTO'">
        <td
          class="cases-table__body__row__label cases-table__body__row__header"
          *ngIf="doc?.type === 'PHOTO'"
        >
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.PHOTO" | translate }}
        </td>
        <td
          class="cases-table__body__row__details cases-table__body__row__photo"
        >
          <lib-layout-row>
            <p
              class="cases-table__body__row__details__title cases-table__body__row__details__name"
            >
              {{ photoName }}
            </p>
            <a
              *ngIf="photoName !== undefined"
              category="secondary"
              size="small"
              [routerLink]="routeLocalizer.get(lang, 'PHOTO_REVIEW')"
              [state]="{ from: 'caseDetails', caseId: case.id }"
            >
              {{ "CASE_DETAILS.DOCUMENTS_TABLE.REVIEW" | translate }}
            </a>
            <button
              *ngIf="photoName !== undefined"
              category="secondary"
              size="small"
              (click)="downloadCroppedImg()"
            >
              {{ "CASE_DETAILS.DOCUMENTS_TABLE.DOWNLOAD_PHOTO" | translate }}
            </button>
          </lib-layout-row>
        </td>
      </tr>
      <ngx-photo-editor
        #ngxPhoto
        *ngIf="doc?.type === 'PHOTO'"
        [imageUrl]="selectedPhotoUrl"
        [aspectRatio]="7 / 9"
        [preSetCropperCoordinatesBias]="presetCoordinates"
        (imageCropped)="imageCropped($event)"
        (modalCloseCancel)="modalCloseCancel($event)"
        (cropperCoordinatesBias)="setCoordinatesBias($event)"
      ></ngx-photo-editor>
      <tr class="cases-table__body__row" *ngIf="doc?.type === 'PHOTO'">
        <td class="cases-table__body__row__label">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.PHOTO_REQUIREMENTS" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <div class="cases-table__body__row__details_photo_validation">
            <span
              *ngIf="showValidationFeedback"
              [innerHTML]="
                'CASE_DETAILS.DOCUMENTS_TABLE.PHOTO_VALIDATIONS' | translate
              "
            ></span>
            <ul class="cases-table__body__row__details_photo_validation_list">
              <li *ngIf="!photoDocumentMetadata?.backgroundApproved">
                {{
                  "CASE_DETAILS.DOCUMENTS_TABLE.PHOTO_VALIDATIONS_BACKGROUND"
                    | translate
                }}
              </li>
              <li *ngIf="!photoDocumentMetadata?.exposureApproved">
                {{
                  "CASE_DETAILS.DOCUMENTS_TABLE.PHOTO_VALIDATIONS_EXPOSURE"
                    | translate
                }}
              </li>
              <li *ngIf="!photoDocumentMetadata?.pictureSizeApproved">
                {{
                  "CASE_DETAILS.DOCUMENTS_TABLE.PHOTO_VALIDATIONS_PIXEL_RANGE"
                    | translate
                }}
              </li>
            </ul>
          </div>
        </td>
      </tr>
      <tr class="cases-table__body__row" *ngIf="doc?.type === 'PHOTO'">
        <td class="cases-table__body__row__label">
          {{ "PHOTOS.PHOTO_SOURCE" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          {{ photoSource | translate }}
        </td>
      </tr>
      <tr class="cases-table__body__row" *ngIf="doc?.type === 'PHOTO'">
        <td class="cases-table__body__row__label">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.REVIEW_PHOTO" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <div class="cases-table__body__row__photo_preview_container">
            <lib-spinner
              class="cases-table__body__row__photo_spinner"
              *ngIf="isLoading"
              [showSpinner]="isLoading"
              [fullPage]="false"
            ></lib-spinner>
            <lib-image-preview
              #photoComponent
              *ngIf="!showCropped && !isLoading"
              class="cases-table__body__row__photo_preview"
              [coordinates]="currentPhoto.coordinates"
              [imageSrc]="currentPhoto.url"
              [imageAlt]="'PHOTOS.PREVIEW_ALT' | translate"
              (outputUrl)="updateDownloadImg($event)"
            ></lib-image-preview>
            <img
              *ngIf="showCropped"
              class="cases-table__body__row__photo_preview"
              width="210"
              height="270"
              [src]="croppedImg"
              [alt]="'PHOTOS.PREVIEW_ALT' | translate"
            />
            <button
              class="btn-secondary-editImgButton"
              category="primary"
              [disabled]="disableEditBtn"
              (click)="editPhoto()"
            >
              {{ "CASE_DETAILS.DOCUMENTS_TABLE.EDIT_PHOTO" | translate }}
            </button>
          </div>
        </td>
      </tr>
      <tr class="cases-table__body__row">
        <td
          class="cases-table__body__row__label"
          *ngIf="doc?.type === 'ADDRESS'"
        >
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.ADDRESS_STATUS" | translate }}
        </td>
        <td class="cases-table__body__row__label" *ngIf="doc?.type === 'PHOTO'">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.PHOTO_STATUS" | translate }}
        </td>
        <td
          class="switch-field cases-table__body__row__details"
          *ngIf="doc?.type === 'ADDRESS'"
        >
          <lib-form-select
            class="cases-table__body__row__details_selection"
            identifier="addressStatus"
            [formControl]="documentsStatusForm.controls.addressStatus"
            [label]="'CASE_DETAILS.DOCUMENTS_TABLE.ADDRESS_STATUS' | translate"
            [options]="selectionOptions"
            (sendChanges)="onStatusChanged($event, addressConst)"
            [widthSm]="true"
            [visuallyHiddenLabel]="true"
            [noLabel]="true"
          ></lib-form-select>
        </td>
        <td
          class="switch-field cases-table__body__row__details"
          *ngIf="doc?.type === 'PHOTO'"
        >
          <lib-form-select
            class="cases-table__body__row__details_selection"
            identifier="photoStatus"
            [formControl]="documentsStatusForm.controls.photoStatus"
            [label]="'CASE_DETAILS.DOCUMENTS_TABLE.PHOTO_STATUS' | translate"
            [options]="selectionOptions"
            (sendChanges)="onStatusChanged($event, photoConst)"
            [widthSm]="true"
            [visuallyHiddenLabel]="true"
            [noLabel]="true"
          ></lib-form-select>
        </td>
      </tr>

      <!-- Rejection reasons photos -->
      <tr
        class="cases-table__body__row"
        *ngIf="doc?.status === false && doc?.type === 'PHOTO'"
      >
        <td class="cases-table__body__row__label">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.REJECTIONS_DESC" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <prcw-rejection-input
            [readOnly]="true"
            (reasonToggled)="(null)"
            [documentType]="doc?.type"
            [rejectionsArray]="this.photoRejectionSelections"
          >
          </prcw-rejection-input>
          <button
            [disabled]="!this.canUpdatePhoto"
            type="button"
            (click)="openRejectionModal(doc.type)"
          >
            {{ "CASE_DETAILS.DOCUMENTS_TABLE.EDIT" | translate }}
          </button>
        </td>
      </tr>

      <!-- Rejection reasons address -->
      <tr
        class="cases-table__body__row"
        *ngIf="doc?.status === false && doc?.type === 'ADDRESS'"
      >
        <td class="cases-table__body__row__label">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.REJECTIONS_DESC" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <prcw-rejection-input
            [readOnly]="true"
            (reasonToggled)="(null)"
            [documentType]="doc?.type"
            [rejectionsArray]="this.addressRejectionSelections"
          >
          </prcw-rejection-input>
          <button
            [disabled]="!this.canUpdateAddress"
            class="btn-secondary btn cases-table__body__row__details__button cases-table__body__row__details__edit"
            type="button"
            (click)="openRejectionModal(doc?.type)"
          >
            {{ "CASE_DETAILS.DOCUMENTS_TABLE.EDIT" | translate }}
          </button>
        </td>
      </tr>

      <!-- Self declare timestamp photo -->
      <tr class="cases-table__body__row" *ngIf="doc?.type === 'PHOTO'">
        <td class="cases-table__body__row__label">
          {{ "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION" | translate }}
        </td>
        <td class="cases-table__body__row__details">
          <ng-container>
            <span
              >{{
                "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION_TIME"
                  | translate
              }}: {{ newDateStampPhoto | date : "shortTime" }} (UTC)</span
            >
            <span
              >{{
                "CASE_DETAILS.DOCUMENTS_TABLE.SELF_DECLARATION_DATE"
                  | translate
              }}: {{ newDateStampPhoto | date : "yyyy/MM/dd" }}</span
            >
          </ng-container>
        </td>
      </tr>
    </div>
  </tbody>
</table>
