import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { CasesService } from "@pr-caseworker/app/core/cases-module/cases.service";
import {
  CroppedEvent,
  CropperCoordinatesBias,
  documentTypes,
  ImagePreviewComponent,
  NgxPhotoEditorComponent,
} from "lib";
import { pipe, Subscription } from "rxjs";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { DownloadService } from "../../../shared/services/download.service";
import * as fromApp from "../../../store/app.reducer";
import {
  DocumentForReview,
  Reason,
} from "../../models/document-rejection.model";
import * as _ from "lodash";
import {
  CASE_ADDRESS_CT,
  CASE_PHOTO_CT,
  selectionOptions,
} from "./case_document_models";
/* eslint-disable eqeqeq */
// disabled because sometimes the DB sends back 0 or 1 instead of true or false for case document statuses
@Component({
  selector: "prcw-case-document-table",
  templateUrl: "./case-document-table.component.html",
  styleUrls: ["./case-document-table.component.scss"],
})
export class CaseDocumentTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() public photoName: string;
  @Input() public photoLink: string;
  @Input() public documents: DocumentForReview[];
  @Input() public case: any;

  @Output() public displayRejectionModal: EventEmitter<string> =
    new EventEmitter(true);
  @Output() public updateDocumentStatus: EventEmitter<{}> = new EventEmitter(
    true
  );
  @Output() public newCroppedImageInfo: EventEmitter<any> = new EventEmitter(
    true
  );

  public currentPhoto: {
    image?: File;
    url?: string;
    coordinates?: CropperCoordinatesBias;
  } = {};
  public selectedPhotoUrl: any = null;
  public presetCoordinates: any = null;
  public downloadUrl: string;
  public showCropped: boolean = false;
  public croppedImg: any = null;
  public disableEditBtn: boolean = false;
  public photoDocumentMetadata: any = null;
  public photoSource: string = "";
  public isLoading = false;

  public addressRejectionSelections: Reason[] | null | undefined;
  public photoRejectionSelections: Reason[] | null | undefined;

  public lang: string;
  public addressDeclarationTimestampIsChecked: boolean;
  public newDateStampPhoto: any;
  public newDateStampAddress: any;
  public documentsStatusForm: FormGroup = this.fb.group({
    addressStatus: [null],
    photoStatus: [null],
  });

  // for reject reasons
  public selectionOptions = selectionOptions;

  public canUpdatePhoto: boolean;
  public canUpdateAddress: boolean;
  public addressConst = CASE_ADDRESS_CT;
  public photoConst = CASE_PHOTO_CT;

  private updatedStatus: { type: string; status: boolean | null } = {
    type: "",
    status: null,
  };
  private iniSubscription$: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    public fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    private caseService: CasesService
  ) {}

  ngOnChanges() {
    this.addressDeclarationTimestampIsChecked =
      this.case.addressDeclarationTimestamp === null ? false : true;

    if (this.case && this.case.documents.length > 0) {
      this.photoDocumentMetadata = this.getPhotoDocumentMetadata();
      this.photoSource = this.getPhotoType();
    }

    if (this.addressDeclarationTimestamp !== null) {
      this.newDateStampAddress = this.addressDeclarationTimestamp.replace(
        /\s/g,
        "T"
      );
    }

    if (this.photoDeclarationTimestamp) {
      this.newDateStampPhoto = this.photoDeclarationTimestamp.replace(
        /\s/g,
        "T"
      );
    }
    if (this.documents) {
      this.canUpdatePhoto = true;
      this.canUpdateAddress = true;
    } else {
      this.canUpdatePhoto = false;
      this.canUpdateAddress = false;
    }
    // extract the photo just if you did not do it already
    // waste of resources to be extracted for any on change event...
    if (!!this.photoName && !!this.photoLink && !this.currentPhoto.url) {
      this.getPhoto();
    }
  }

  ngOnInit() {
    const self = this;
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.iniSubscription$ = this.store
      .select("confirmation")
      .pipe()
      .subscribe((casesData) => {
        this.photoRejectionSelections =
          casesData.selectedCase?.photo?.rejectionReasons;
        this.addressRejectionSelections =
          casesData.selectedCase?.address?.rejectionReasons;
        this.documentsStatusForm.controls.photoStatus.setValue(
          casesData.selectedCase?.photo?.approvedStatus
        );
        this.documentsStatusForm.controls.addressStatus.setValue(
          casesData.selectedCase?.address?.approvedStatus
        );
      });
    this.subscriptions.push(this.iniSubscription$);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subsc) => subsc.unsubscribe());
  }

  public get addressString(): string | null {
    if (this.case.mailingAddress1) {
      const { mailingAddress1, mailingAddress2, city, province, postalCode } =
        this.case;
      const line2 = mailingAddress2 ? `, ${mailingAddress2}` : "";
      return `${mailingAddress1}${line2}, ${city}, ${province}, ${postalCode}`;
    } else {
      return null;
    }
  }

  public get addressDeclarationTimestamp(): string {
    return this.case.addressDeclarationTimestamp;
  }

  public get photoDeclarationTimestamp(): string {
    return this.case.documents.find(
      ({ documentTypeId }: any) => documentTypeId === documentTypes.photo.id
    )?.documentDeclarationTimestamp;
  }

  public get showValidationFeedback(): boolean {
    return (
      !!this.photoDocumentMetadata &&
      !(
        this.photoDocumentMetadata?.backgroundApproved &&
        this.photoDocumentMetadata?.exposureApproved &&
        this.photoDocumentMetadata?.pictureSizeApproved
      )
    );
  }

  public getPhotoType() {
    if (!!this.photoDocumentMetadata) {
      switch (this.photoDocumentMetadata.photoType) {
        case 1:
          return "PHOTOS.PHOTO_TYPE_DIGITAL";
        case 2:
          return "PHOTOS.PHOTO_TYPE_PRINTED";
        case 3:
          return "PHOTOS.PHOTO_TYPE_SELF_TAKEN";
        default:
          return "";
      }
    }
    return "";
  }

  public getPhotoDocumentMetadata() {
    return this.case.documents.find(
      ({ documentTypeId }: any) => documentTypeId === documentTypes.photo.id
    )?.documentMetadata;
  }

  public openRejectionModal(type: string): void {
    this.displayRejectionModal.emit(type);
  }

  public modalCloseCancel(event: boolean) {
    this.disableEditBtn = false;
    this.selectedPhotoUrl = null;
    this.presetCoordinates = null;
  }

  public imageCropped(event: CroppedEvent) {
    this.disableEditBtn = false;
    if (event.base64) {
      this.croppedImg = event.base64;
      this.downloadUrl = event.base64;
      this.showCropped = true;
    }
    this.selectedPhotoUrl = null;
    this.presetCoordinates = null;
  }

  public async setCoordinatesBias(coordinates: CropperCoordinatesBias) {
    this.currentPhoto.coordinates = coordinates;
    this.selectedPhotoUrl = null;
    this.presetCoordinates = null;
    const [identityId] = this.photoLink.split("/");
    const metaDataObj = {
      heightCompare: coordinates.heightCompare.toString(),
      leftDiff: coordinates.leftDiff.toString(),
      topDiff: coordinates.topDiff.toString(),
      widthCompare: coordinates.widthCompare.toString(),
    };
    this.newCroppedImageInfo.emit({
      photoName: this.photoName,
      identityId,
      image: this.currentPhoto.image,
      metaDataObj,
    });
  }

  public editPhoto() {
    this.disableEditBtn = true;
    this.presetCoordinates = this.currentPhoto.coordinates;
    this.selectedPhotoUrl = this.currentPhoto.url;
  }

  public updateDownloadImg(downloadUrl: string) {
    this.downloadUrl = downloadUrl;
  }

  public downloadCroppedImg() {
    const a = document.createElement("a");
    a.href = this.downloadUrl;
    a.download = this.photoName;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(this.downloadUrl);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  public onStatusChanged(event: any, itemType: string): void {
    this.updatedStatus.type = itemType;
    const theValue = event.target.value;
    let status = null;
    switch (theValue) {
      case "1: true":
        status = true;
        break;
      case "2: false":
        status = false;
        break;
    }
    this.updatedStatus.status = status;
    if (!_.isNil(status) && !status) {
      this.openRejectionModal(itemType);
    }
    this.updateChangedStatus(this.updatedStatus);
  }

  private updateChangedStatus(changedStatus: any): void {
    this.updateDocumentStatus.emit(changedStatus);
  }

  private async getPhoto() {
    this.isLoading = true;
    const { photoLink, coordinates, previewFile } =
      await this.caseService.getPhoto(this.case);
    this.isLoading = false;
    this.currentPhoto.url = photoLink;
    this.currentPhoto.coordinates = coordinates;
    this.currentPhoto.image = previewFile;
  }

  public trackElemnt(index: number, element: any): any {
    return element ? element.type : null;
  }
}
