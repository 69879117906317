<!-- checkboxes, when active -->
<fieldset *ngIf="!readOnly" tabindex="0" #focusModal>
  <div
    class="checkbox-control"
    *ngFor="let reason of copyRejectionsArray; let i = index"
  >
    <input
      type="checkbox"
      [ngClass]="'clear-' + documentType"
      [id]="reason.id"
      (click)="updateRejectionReasons($event, reason.id)"
      [checked]="reason.selected"
      [value]="reason.id"
    />
    <label [for]="reason.id">
      {{
        currentLang === "en"
          ? reason.rejectionReasonEn
          : reason.rejectionReasonFr
      }}
    </label>
  </div>
</fieldset>
<!-- list, when disabled -->
<ul
  *ngIf="readOnly"
  class="rejection-reasons-list"
  [attr.aria-label]="'CASE_DETAILS.DOCUMENTS_TABLE.REJECTIONS_DESC' | translate"
>
  <li *ngFor="let reason of selectedReasons">
    {{
      currentLang === "en" ? reason.rejectionReasonEn : reason.rejectionReasonFr
    }}
  </li>
</ul>
