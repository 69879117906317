<div id="datepicker" class="form-control" aria-live="assertive">
  <label [for]="identifier" [ngClass]="{ 'label-with-helptext': !!helpText }">
    <span [innerHTML]="label"></span>
    <span *ngIf="required && !hideRequired" class="required">
      ({{ requiredText }})
    </span>
    <p
      *ngIf="!!helpText"
      class="help-text"
      [id]="identifier + '-help-text'"
      [innerHTML]="helpText"
    ></p>
  </label>

  <ng-container *ngIf="!readOnly">
    <mat-form-field appearance="fill">
      <input
        matInput
        [matDatepicker]="picker"
        [ngClass]="{ password: originalType === 'password' }"
        [type]="type"
        [id]="identifier"
        [name]="identifier"
        [formControl]="ngControl.control"
        [value]="value"
        placeholder=""
        (input)="onChange($event.target.value)"
        (blur)="onTouched($event)"
        [required]="required"
        [attr.aria-required]="required"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div *ngIf="ngControl.errors" class="error" style="margin-top: -20px">
      <ng-content>
        <!-- error spans -->
      </ng-content>
    </div>
  </ng-container>

  <ng-container *ngIf="readOnly">
    <span class="plaintext-field">
      {{ _moment(ngControl?.control?.value).format("YYYY/MM/DD") }}
    </span>
    <span *ngIf="!ngControl?.control?.value" class="visually-hidden">
      {{ noDataText }}
    </span>
  </ng-container>
</div>
