import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, ValidationService } from "lib";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import * as AuthActions from "../../store/auth.actions";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import * as fromApp from "../../../../store/app.reducer";
import { AuthService } from "../../services/auth.service";
import { Subscription } from "rxjs";

@Component({
  selector: "prcw-auth-reset",
  templateUrl: "./reset.component.html",
  styleUrls: ["./reset.component.scss"],
})
export class ResetComponent implements OnInit, OnDestroy {
  @Input() userType: string;
  @Input() isFirstLogin: boolean;
  emailFromUrl: string;
  resetPasswordForm: FormGroup;
  currentLang: string;
  verifyPasswordErrors: ValidationErrors | null;
  subscriptions$: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private authService: AuthService,
    private validationService: ValidationService,
    private store: Store<fromApp.State>,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    this.resetPasswordForm = new FormGroup(
      {
        verificationCode: new FormControl(
          null,
          this.isFirstLogin === true ? null : Validators.required
        ),
        password: new FormControl(null, [
          Validators.required,
          this.validationService.validatorMinChars,
          this.validationService.validatorSymbol,
          this.validationService.validatorUppercase,
          this.validationService.validatorLowercase,
          this.validationService.validatorContainNumbers,
        ]),
        passwordConfirm: new FormControl(null, Validators.required),
      },
      this.validationService.validatorPasswordMatching
    );

    // getting url from email if resetting password from email
    const param$ = this.route.queryParams.subscribe((params) => {
      this.emailFromUrl = params.email;
    });
    this.subscriptions$.push(param$);
    this.setVerifyErrors();
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  onSubmit() {
    if (this.resetPasswordForm.status === "INVALID") {
      return;
    } else {
      const password = this.resetPasswordForm.value.password;
      if (this.isFirstLogin) {
        // first login reset calls a special function
        this.firstTimeReset(password);
      } else {
        // call normal reset password and get verification code input field
        const verificationCode = this.resetPasswordForm.value.verificationCode;
        this.forgotPasswordReset(password, verificationCode);
      }
    }
  }

  forgotPasswordReset(password: string, verificationCode: string): void {
    const emailFromUrl = this.emailFromUrl;

    // email must exist as a param in the link/url
    if (emailFromUrl !== undefined) {
      this.authService
        .forgotPasswordReset(emailFromUrl, verificationCode, password)
        .then(() => {
          this.routeLocalizer.goTo(this.currentLang, "LOGIN");
          this.alertService.success(this.alertPasswordSuccess);
        })
        .catch((error: any) => {
          if (error.code === "CodeMismatchException") {
            this.alertService.danger(this.alertVerificationCode);
          } else if (error.code === "InvalidPasswordException") {
            this.alertService.danger(this.alertBadPassword);
          } else {
            this.alertService.danger(this.alertTechnicalError);
          }
        });
    } else {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  firstTimeReset(password: string): void {
    const auth$ = this.store
      .select("auth")
      .pipe(
        first(),
        map((authState) => authState.user)
      )
      .subscribe((user) => {
        const username = user?.username ? user.username : "";
        const session = user?.session ? user.session : "";

        this.authService
          .changeClientPassword(session, password, username)
          .then(() => {
            this.store.dispatch(
              new AuthActions.FirstLogin({
                successReset: true,
                user: null,
              })
            );
            this.alertService.success(this.alertResetSuccess);
          })
          .catch((error: any) => {
            if (error.code === "InvalidPasswordException") {
              this.alertService.danger(this.alertBadPassword);
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          });
      });
    this.subscriptions$.push(auth$);
  }

  private setVerifyErrors() {
    const verifyPasswordErrors$ = this.resetPasswordForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((v) => {
        if (
          this.resetPasswordForm.controls.password.touched &&
          this.resetPasswordForm.controls.passwordConfirm.touched
        ) {
          if (v.password !== v.passwordConfirm) {
            this.verifyPasswordErrors = { matches: true };
          } else {
            this.verifyPasswordErrors = null;
          }
        }
      });
    this.subscriptions$.push(verifyPasswordErrors$);
  }

  private get alertBadPassword(): string {
    return this.translate.instant("LOGIN.ALERTS.PASSWORD_SECURITY");
  }

  private get alertPasswordSuccess(): string {
    return this.translate.instant("LOGIN.ALERTS.SUCCESS");
  }

  private get alertResetSuccess(): string {
    return this.translate.instant("LOGIN.ALERTS.SUCCESSFUL_RESET");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("LOGIN.ALERTS.TECHNICAL_ERROR");
  }

  private get alertVerificationCode(): string {
    return this.translate.instant("RESET.ALERTS.CODE_ERROR");
  }
}
