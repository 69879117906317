import { Lob } from "@pr-caseworker/app/core/models/lob.model";
import { FormSelectOption } from "projects/lib/src/public-api";

export function valueTextFormatter(lob: Lob[]): FormSelectOption[] {
  if (lob.length > 0) {
    return lob.map((lob) => ({
      value: `${lob.id}`,
      text: { en: lob.categoryEn, fr: lob.categoryFr },
    }));
  } else {
    return [];
  }
}
