import { Component } from "@angular/core";

@Component({
  selector: "prcw-webform-view-imm5669-solemn-declaration",
  templateUrl: "./webform-view-imm5669-solemn-declaration.component.html",
  styleUrls: ["./webform-view-imm5669-solemn-declaration.scss"],
})
export class WebformViewIMM5669SolemnDeclarationComponent {
  constructor() {}
}
