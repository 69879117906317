<h2 class="emphasis">
  {{ "INTAKE.IMM5669.DECLARATION.PRIMARY_APPLICANT.HEADER" | translate }}
</h2>
<h3>{{ "INTAKE.IMM5669.DECLARATION.PRIMARY_APPLICANT.LABEL" | translate }}</h3>
<p>{{ "INTAKE.IMM5669.DECLARATION.PRIMARY_APPLICANT.BODY" | translate }}</p>
<h3>
  {{
    "INTAKE.IMM5669.DECLARATION.PRIMARY_APPLICANT.DECLARATION_LABEL" | translate
  }}
</h3>

<div
  [innerHTML]="
    'INTAKE.IMM5669.DECLARATION.PRIMARY_APPLICANT.INFO_STATEMENT' | translate
  "
></div>

<div class="data-item">
  <span class="data-label">
    {{
      "INTAKE.IMM5669.DECLARATION.PRIMARY_APPLICANT.FULL_NAME_LABEL" | translate
    }}
  </span>
  {{ applicantFullName }}
  <span *ngIf="!applicantFullName" class="visually-hidden">{{
    "INTAKE.IMM5669.DECLARATION.EMPTY_NAME" | translate
  }}</span>
</div>

<lib-copy-block
  [body]="'INTAKE.IMM5669.DECLARATION.PRIMARY_APPLICANT.SUBBODY' | translate"
>
</lib-copy-block>
