import { environment } from "@pr-caseworker/environments/environment";
import { Injectable } from "@angular/core";
import axios, { AxiosRequestConfig } from "axios";

// axios version must match the one used by aws-amplify!!!
// https://stackoverflow.com/questions/64488595/interceptor-for-httprequests-made-by-aws-api
// https://github.com/aws-amplify/amplify-js/issues/1069

@Injectable({ providedIn: "root" })
export class Cloud9TunnelInterceptorService {
  constructor() {
    console.log("Cloud9Tunnel is enabled");
  }
  intercept() {
    axios.interceptors.request.use((request: AxiosRequestConfig) => {
      if (environment.env !== "local") return request;

      const isCaseEndpoint =
        request.url!.indexOf("/v2/") !== -1 &&
        request.url!.indexOf("/v2/caseworkers") === -1;
      if (isCaseEndpoint && environment.localServerUrl!) {
        const path = request!.url!.replace(/.*(\/v2.*)/, "$1");
        const newUrl = environment.localServerUrl + path;
        request.url = newUrl;
      }
      return request;
    });
  }
}

export function AxiosConfigFactory(
  axiosIntercept: Cloud9TunnelInterceptorService
): any {
  return () => axiosIntercept.intercept();
}
