<h2 class="emphasis section__heading">
  {{ "INTAKE.INTAKE_CASE_DETAILS.CLIENT_INFORMATION_HEADING" | translate }}
</h2>
<lib-spinner [showSpinner]="loading || !case" [fullPage]="false"></lib-spinner>
<form [formGroup]="clientInfoForm" *ngIf="!loading && case" id="clientInfoForm">
  <lib-layout-row gap="one-and-half">
    <lib-form-input
      identifier="uci"
      [formControl]="clientInfoForm.controls.uci"
      [label]="'INTAKE.INTAKE_CASE_DETAILS.UCI_LABEL' | translate"
      #uci
      [editable]="isEditing ? true : false"
    ></lib-form-input>
    <lib-form-input
      identifier="applicationNumber"
      [formControl]="clientInfoForm.controls.applicationNumber"
      [label]="
        'INTAKE.INTAKE_CASE_DETAILS.APPLICATION_NUMBER_LABEL' | translate
      "
      [editable]="isEditing ? true : false"
    ></lib-form-input>
    <lib-form-input
      identifier="applicationName"
      [formControl]="clientInfoForm.controls.applicationName"
      [label]="'INTAKE.INTAKE_CASE_DETAILS.APPLICATION_NAME_LABEL' | translate"
      [editable]="false"
    ></lib-form-input>
  </lib-layout-row>
  <lib-layout-row gap="one-and-half" mb="2">
    <lib-form-input
      identifier="caseProgramName"
      [formControl]="clientInfoForm.controls.caseProgramName"
      [label]="'INTAKE.INTAKE_CASE_DETAILS.PROGRAM_LABEL' | translate"
      [editable]="false"
    ></lib-form-input>

    <lib-form-input
      identifier="caseProgramName"
      [formControl]="clientInfoForm.controls.caseCategoryName"
      [label]="'INTAKE.INTAKE_CASE_DETAILS.CATEGORY_LABEL' | translate"
      [editable]="false"
    ></lib-form-input>
  </lib-layout-row>
</form>
<div class="case-buttons" *ngIf="!loading">
  <ng-container *ngIf="!isEditing">
    <button
      type="button"
      category="secondary"
      [disabled]="disableEdit"
      (click)="toggleEditing()"
    >
      {{
        "INTAKE.INTAKE_CASE_DETAILS.CLIENT_INFORMATION_FORM_BUTTONS.EDIT"
          | translate
      }}
    </button>
  </ng-container>

  <ng-container *ngIf="isEditing">
    <button
      type="button"
      category="primary"
      (click)="onSubmit()"
      [disabled]="!canSaveForm"
    >
      {{
        "INTAKE.INTAKE_CASE_DETAILS.CLIENT_INFORMATION_FORM_BUTTONS.SAVE"
          | translate
      }}
    </button>
    <button type="button" category="secondary" (click)="toggleEditing()">
      {{
        "INTAKE.INTAKE_CASE_DETAILS.CLIENT_INFORMATION_FORM_BUTTONS.CANCEL"
          | translate
      }}
    </button>
  </ng-container>
</div>

<prcw-submission-time-section
  *ngIf="!hasRep"
  [submittedTime]="case?.submittedAt"
></prcw-submission-time-section>
