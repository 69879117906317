<div class="search-row">
  <prcw-lob-filter
    [copyKey]="'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.LOB_SEARCH'"
    (selectedLob)="lobFiltered($event)"
    [value]="lobFilterValue"
    [flow]="appFlow.INTAKE"
    class="lob-search"
  ></prcw-lob-filter>
  <prcw-status-filter
    [copyKey]="'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.STATUS_SEARCH'"
    (selectedStatus$)="statusFiltered($event)"
    [value]="statusFilterValue"
    class="status-search"
  ></prcw-status-filter>
  <prcw-assignment-filter
    [copyKey]="'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.ASSIGNED_SEARCH'"
    (selectedAssigmentStatus$)="assignmentFilter($event)"
    [value]="assignmentFilterValue"
    class="assignment-search"
  ></prcw-assignment-filter>
  <prcw-cases-search
    [copyKey]="'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.EMAIL_SEARCH'"
    [searchByValue]="searchByValue"
    [value]="searchTerm"
    class="email-search"
  ></prcw-cases-search>
  <div>
    <button
      (click)="clearFilters()"
      category="plain"
      class="clear-all"
      id="clearAll"
    >
      {{ "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CLEAR_ALL" | translate }}
      <span class="visually-hidden">{{
        "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CLEAR_ALL_CONTEXT" | translate
      }}</span>
    </button>
  </div>
</div>
<div class="search-row">
  <prcw-class-filter
    *ngIf="showclassFilter"
    [copyKey]="copyKeyValue"
    class="class-search"
  ></prcw-class-filter>
</div>
<div class="search-row" *ngIf="lobFilterValue === '12'">
  <lib-spinner *ngIf="commCerts.length === 0" [fullPage]="false"></lib-spinner>
  <lib-form-select
    *ngIf="lobFilterValue === '12' && commCerts.length > 0"
    identifier="commCertSearch"
    [formControl]="commCertFilter"
    [label]="'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.COMM_CERT' | translate"
    [options]="commCertOptions"
    class="class-search"
  >
  </lib-form-select>
</div>
<div class="search-row search-checkboxes">
  <lib-form-checkbox
    identifier="assignedToMe"
    [description]="
      'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.ASSIGNED_TO_ME' | translate
    "
    [formControl]="assignedToMe"
    [isRequired]="false"
    (click)="assignedToMeClicked(assignedToMe.value)"
  ></lib-form-checkbox>
  <lib-form-checkbox
    identifier="noApplicationNumber"
    [description]="
      'INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.NO_APPLICATION_NUMBER' | translate
    "
    [formControl]="noApplicationNumber"
    [isRequired]="false"
    (click)="noApplicationNumberClicked()"
  ></lib-form-checkbox>
</div>
<div
  class="search-row bulk-assignment"
  *ngIf="currentUser?.userPool?.toUpperCase() === 'TEAMLEADS'"
>
  <div class="autocomplete-container">
    <label for="assigneeBulkID">
      <span class="visually-hidden">{{ ay18AssignToLabel }}</span>
    </label>
    <lib-form-autocomplete
      [identifier]="'assigneeBulkID'"
      class="with-border"
      [options]="assignToList.slice()"
      [placeholder]="placeHolderWithParam"
      [formControl]="bulkAssignTo"
      [label]="
        'INTAKE.CASE_LIST_PAGE.CASES_TABLE.ASSIGN_TO_HEADING' | translate
      "
      [showLabel]="false"
      (optionSelected)="onSelectBulkAssigned($event)"
    >
    </lib-form-autocomplete>
  </div>
  <div>
    <button
      category="primary"
      (click)="doBulkAssignment()"
      [disabled]="bulkAssignmentDisabled"
    >
      {{ "INTAKE.CASE_LIST_PAGE.BUTTON.APPLY" | translate }}
    </button>
  </div>
</div>

<div class="table-scrolling-wrapper" style="overflow-x: auto">
  <table
    class="prcw-case-list-table table"
    [ngClass]="
      currentUser?.userPool?.toUpperCase() === 'TEAMLEADS'
        ? 'table-tl-active'
        : 'tl-table-inactive'
    "
  >
    <thead>
      <tr>
        <!-- a11y bug with the div.checkbox-control-lib wrapper - had to remove it here. -->
        <th
          class="case-title column-checkbox"
          *ngIf="currentUser?.userPool?.toUpperCase() === 'TEAMLEADS'"
        >
          <span class="visually-hidden">{{
            "INTAKE.CASE_LIST_PAGE.CASES_TABLE.BULK_ASSIGN_HEADING" | translate
          }}</span>
          <input
            [ngClass]="{ indeterminate: hasSomeSelectedForBulkAssign }"
            id="bulkAssignAllCheckBox"
            #bulkAssignAllCheckBox
            [checked]="bulkAssignAll"
            (change)="toggleBulkAssignAll(bulkAssignAllCheckBox.checked)"
            type="checkbox"
          />
          <label for="bulkAssignAllCheckBox">
            <span class="visually-hidden">{{ bulkAssignmentLabel }}</span>
          </label>
        </th>
        <th class="case-title column-category">
          {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.CATEGORY_HEADING" | translate }}
        </th>
        <th class="case-title column-email">
          {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.EMAIL_HEADING" | translate }}
        </th>
        <th
          class="case-title column-rep-id"
          [ngClass]="{
            'case-rep-id-tl-active':
              currentUser?.userPool?.toUpperCase() === 'TEAMLEADS',
            'column-rep-id':
              currentUser?.userPool?.toUpperCase() !== 'TEAMLEADS'
          }"
        >
          {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.REP_ID" | translate }}
        </th>
        <th
          class="case-title column-date-time"
          [attr.aria-sort]="
            sortOrder === 'submittedAt ASC'
              ? 'ascending'
              : sortOrder === 'submittedAt DESC'
              ? 'descending'
              : 'ascending'
          "
        >
          {{
            "INTAKE.CASE_LIST_PAGE.CASES_TABLE.DATE_TIME_SUBMITTED_HEADING"
              | translate
          }}
          <div class="case-title__wrapper__filters">
            <button
              class="case-title__wrapper__filters__button"
              category="plain"
              size="small"
              (click)="sort('submittedAt', 'ASC')"
            >
              <img
                class="cases-filter-icon"
                *ngIf="sortOrder !== 'submittedAt ASC'"
                src="../../../../assets/images/icons/sort-up.svg"
                [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
              />
              <img
                class="cases-filter-icon"
                *ngIf="sortOrder === 'submittedAt ASC'"
                src="../../../assets/images/icons/sort-up-fill.svg"
                [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
              />
            </button>
            <button
              class="case-title__wrapper__filters__button"
              category="plain"
              size="small"
              (click)="sort('submittedAt', 'DESC')"
            >
              <img
                class="cases-filter-icon"
                *ngIf="sortOrder !== 'submittedAt DESC'"
                src="../../../assets/images/icons/sort-down.svg"
                [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
              />
              <img
                class="cases-filter-icon"
                *ngIf="sortOrder === 'submittedAt DESC'"
                src="../../../assets/images/icons/sort-down-fill.svg"
                [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
              />
            </button>
          </div>
        </th>
        <th class="case-title column-case-status">
          {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.STATUS_HEADING" | translate }}
        </th>
        <th class="case-title column-application-number">
          {{
            "INTAKE.CASE_LIST_PAGE.CASES_TABLE.APPLICATION_NUMBER" | translate
          }}
        </th>

        <th
          class="case-title"
          [ngClass]="{
            'assigned-to-tl-active':
              currentUser?.userPool?.toUpperCase() === 'TEAMLEADS',
            'column-case-assigned':
              currentUser?.userPool?.toUpperCase() !== 'TEAMLEADS'
          }"
        >
          {{
            "INTAKE.CASE_LIST_PAGE.CASES_TABLE.ASSIGN_TO_HEADING" | translate
          }}
        </th>
        <th class="case-title column-action">
          {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.ACTION_HEADING" | translate }}
        </th>
      </tr>
    </thead>
    <tbody *ngIf="cases && !isLoadingCases">
      <tr *ngFor="let case of cases; let i = index; trackBy: trackElemnt">
        <td *ngIf="currentUser?.userPool?.toUpperCase() === 'TEAMLEADS'">
          <div class="check-assign" *ngIf="isCaseAssignable(case)">
            <div class="checkbox-control-lib">
              <input
                [checked]="case.bulkAssigned"
                [id]="case.id"
                (change)="toggleBulkAssign(case)"
                type="checkbox"
              />
              <label [for]="case.id">
                <span class="visually-hidden">
                  <ng-container *ngIf="!case.bulkAssigned">{{
                    "INTAKE.CASE_LIST_PAGE.BULK_ASSIGN_CHECK_UNSELECTED"
                      | translate
                  }}</ng-container>
                  <ng-container *ngIf="case.bulkAssigned">{{
                    "INTAKE.CASE_LIST_PAGE.BULK_ASSIGN_CHECK_SELECTED"
                      | translate
                  }}</ng-container>
                </span>
              </label>
            </div>
          </div>
        </td>
        <td>
          {{
            lobService.getCategoryDisplayText(
              case.lob,
              case.referralOrganization
            )
          }}
          <span *ngIf="case.lobId === 12"
            >({{
              case.commCert?.commCert
                ? case.commCert.commCert
                : translate.instant("INTAKE.CASE_LIST_PAGE.CASES_TABLE.NA")
            }})</span
          >
        </td>
        <td class="column-email">
          {{ case.emailAddress }}
        </td>
        <td class="case-rep-id">
          {{ case?.representative?.membershipId }}
        </td>
        <td>
          {{ formatDate(case.submittedAt) | date : "yyyy/MM/dd HH:mm" }}
          (UTC)
        </td>
        <td>
          <ng-container *ngIf="case.status.id !== 9">
            <lib-status-pill
              [text]="case.statusName"
              [type]="case.statusType"
              [minWidth]="7"
              [wordBreak]="true"
            >
            </lib-status-pill>
          </ng-container>
        </td>

        <td class="column-application-number">
          {{ case.applicationNumber }}
        </td>
        <td>
          <div>
            <div
              class="autocomplete-container"
              *ngIf="
                !case.isUpdating &&
                case.isCaseAssignable &&
                case.status.id !== '8' &&
                case.status.id !== '10'
              "
            >
              <div>
                <label for="'assignBulkRow' + case.id">
                  <span class="visually-hidden">{{ ay18AssignToLabel }}</span>
                </label>
                <lib-form-autocomplete
                  [identifier]="'assignBulkRow' + case.id"
                  class="no-border"
                  [options]="
                    case.cognitoCaseWorkerId === null &&
                    currentUser?.userPool?.toUpperCase() === 'CASEWORKERS'
                      ? singleCaseworkerOption
                      : assignToList.slice()
                  "
                  [placeholder]="'INTAKE.CASE_LIST_PAGE.UNASSIGNED'"
                  [formControl]="assignToGroupForm.controls[case.id]"
                  [sortFilteredOptions]="sortAssignToList.bind(this)"
                  [label]="
                    'INTAKE.CASE_LIST_PAGE.CASES_TABLE.ASSIGN_TO_HEADING'
                      | translate
                  "
                  [showLabel]="false"
                  (optionSelected)="assignByCase($event, case)"
                >
                </lib-form-autocomplete>
                <img
                  class="cases-error"
                  *ngIf="case.assignError"
                  src="../../../../assets/images/icons/circle-empty-alert.svg"
                  [alt]="
                    'INTAKE.CASELIST.ASSIGN_ERROR.' + case.assignError
                      | translate
                  "
                />
              </div>
              <div *ngIf="case.assignError">
                <span class="error-message" aria-live="polite">{{
                  "INTAKE.CASELIST.ASSIGN_ERROR." + case.assignError | translate
                }}</span>
              </div>
            </div>
            <div *ngIf="!case.isCaseAssignable" class="column-email">
              {{ case.cognitoCaseWorkerId }}
            </div>
            <div *ngIf="case.isUpdating">
              <lib-spinner
                [showSpinner]="case.isUpdating"
                [fullPage]="false"
              ></lib-spinner>
            </div>
          </div>
        </td>
        <td>
          <a
            [routerLink]="case.id"
            category="primary"
            [attr.aria-label]="setViewLabel(case.emailAddress)"
          >
            {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.VIEW" | translate }}
          </a>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="cases?.length === 0 && !isLoadingCases" class="empty-table">
      <tr>
        <td colspan="9">
          {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.NO_MATCHES_FOUND" | translate }}
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="!cases" class="empty-table">
      <tr>
        <td colspan="9">
          {{ "INTAKE.CASE_LIST_PAGE.CASES_TABLE.TABLE_NO_DATA" | translate }}
        </td>
      </tr>
    </tbody>

    <tbody class="empty-table">
      <tr>
        <td colspan="9">
          <div class="spinner">
            <lib-spinner
              [showSpinner]="isLoadingCases"
              [id]="'LOAD_CASES'"
              [fullPage]="false"
            >
            </lib-spinner>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<prcw-list-buttons
  *ngIf="numberOfCases > 0"
  (checkedPageNumber)="checkedPageNumber($event)"
  [listNumbers]="paginationNumber"
  [pageToReview]="pageToView"
></prcw-list-buttons>
