import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentInit,
  Component,
  HostBinding,
  Input,
  Optional,
  Self,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ValidationService } from "../../services/validation-service/validation.service";

export interface ICompareDate {
  startControl: FormControl;
  direction: string;
  allowEqual?: boolean;
}

@Component({
  selector: "lib-form-datepicker",
  templateUrl: "./form-datepicker.component.html",
})
export class FormDatepickerComponent
  implements OnInit, ControlValueAccessor, AfterContentInit, OnDestroy
{
  @Input() identifier: string;
  @Input() label?: string;
  @Input() required: boolean = false;
  @Input() placeholder?: string = "YYYY/MM/DD";
  @Input() hideRequired?: boolean;
  @Input() readOnly?: boolean;
  @Input() ariaPlaceHolder?: string = "";
  @Input() errors?: ValidationErrors;
  @Input() helpText?: string;
  @Input() pastDate: boolean = false;
  @Input() futureDate: boolean = false;
  @Input() compareDate?: ICompareDate;
  @Input() shortFormat: boolean = false;
  @Input() debug: boolean = false;
  @Output() sendBlurEvent = new EventEmitter<Event>();
  @ViewChild("formInput") theInput: ElementRef;

  public value: any;
  public validators: any;
  public disabled?: boolean = false;
  private valueChangesSubsc: Subscription;
  private asyncSubs: Subscription[] = [];

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public validationService: ValidationService
  ) {
    this.ngControl.valueAccessor = this;
  }

  public handleBlur(ev: Event): void {
    this.sendBlurEvent.emit(ev);
    this.onTouched(ev);
  }

  ngOnInit() {
    this.checkProvidedValueForNonDate();
    let myValidators = !this.shortFormat
      ? [
          this.validationService.validatorDateFormat,
          this.validationService.validatorDate,
          // this.validationService.validatorDateGreaterThan1900,
        ]
      : [
          this.validationService.validatorDateFormatShort,
          //this.validationService.validatorDateGreaterThan1900Short,
        ];
    if (
      (this.required ||
        this.ngControl.control?.hasValidator(Validators.required)) &&
      !this.readOnly
    ) {
      myValidators.push(
        Validators.required,
        this.validationService.validatorDatePickerRequired
      );
    }
    if (this.pastDate) {
      myValidators.push(
        this.validationService.validatorDateCannotBeFuture,
        this.shortFormat
          ? this.validationService.validatorDatePastShort
          : this.validationService.validatorDatePast
      );
    }
    if (this.futureDate) {
      myValidators.push(this.validationService.validatorDateFuture);
    }
    let validators = this.ngControl.control?.validator
      ? [this.ngControl.control?.validator, ...myValidators]
      : myValidators;
    this.ngControl.control?.setValidators(validators);

    if (this.compareDate) {
      this.asyncSubs.push(
        this.compareDate.startControl.statusChanges.subscribe(
          (value: any) =>
            value === "VALID" &&
            this.ngControl.control?.updateValueAndValidity()
        )
      );
      this.ngControl.control?.setAsyncValidators(
        this.validationService.validatorCompareDates(
          this.compareDate.startControl,
          this.compareDate.direction,
          this.compareDate.allowEqual
        )
      );
    }
    this.ngControl.control?.updateValueAndValidity();
    this.validators = Object.values(validators);
  }

  ngAfterContentInit() {
    // Run change detection if the value changes. This method works with the change detection strategy
    // if there hasn't been a change to the @Input values, example optional fields
    if (this.ngControl && this.ngControl.valueChanges) {
      this.valueChangesSubsc = this.ngControl.valueChanges.subscribe(() =>
        this.cdRef.markForCheck()
      );
    }
  }

  // Checks for any value being passed from DB or on init for a valid date, if not then sets to null
  // used because legacy apps did not have date validation and had values of "none", "n/a" or similar
  checkProvidedValueForNonDate() {
    if (
      this.shortFormat &&
      this.validationService.validatorDateFormatShort(this.ngControl.control!)
    ) {
      this.ngControl.control?.setValue(null);
    }
    if (
      !this.shortFormat &&
      this.validationService.validatorDateFormat(this.ngControl.control!)
    ) {
      this.ngControl.control?.setValue(null);
    }
  }

  // do unsubscribe as this is used accorss the project and subscriptions are not cleared
  ngOnDestroy() {
    if (this.valueChangesSubsc) {
      this.valueChangesSubsc.unsubscribe();
    }
    this.asyncSubs.forEach((sub) => sub.unsubscribe());
  }

  onChange(e: any) {} // required; ok to leave empty

  onTouched(e: any) {} // required; ok to leave empty

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  public get lang(): string {
    return this.router?.url?.split("/")[1];
  }

  public setFocusOnInput() {
    this.theInput.nativeElement ? this.theInput.nativeElement.focus() : "";
  }
}

// Prevents event bubbling and performance issues with many event listeners on the longer forms
window.document.addEventListener("keydown", (event: KeyboardEvent) => {
  event.stopPropagation();
});

window.document.addEventListener("keyup", (event: KeyboardEvent) => {
  event.stopPropagation();
});
