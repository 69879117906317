import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "lib";
import * as fromApp from "../../../store/app.reducer";
import { selectRouteData } from "../../../store/app.reducer";
import { CasesService } from "../../../core/cases-module/cases.service";
import { Subscription } from "rxjs";
import { User } from "@pr-caseworker/app/core/auth-module/store/auth.reducer";
import { FormAutoCompleteOption } from "lib";
import { UntypedFormBuilder, FormGroup } from "@angular/forms";
import { Case } from "../../../../app/core/cases-module/models/case.model";
import * as _ from "lodash";
import {
  ConfirmationTableFilterService,
  ConfirmationTableSelections,
} from "../../services/confirmation-table-filter.service";

@Component({
  selector: "prcw-cases-table",
  templateUrl: "./cases-table.component.html",
  styleUrls: ["./cases-table.component.scss"],
})
@Injectable()
export class CasesTableComponent implements OnInit, OnDestroy {
  public isLoadingCases = true;
  public filters: ConfirmationTableSelections;
  public cases: Case[] = [];
  public currentLang: string;
  public tableHeadings = [];
  public showFilter: string;

  public numberOfCases: number;
  public paginationNumber: number;
  public pageToView: number;
  public searchTerm: string;
  private subscriptions: Subscription[] = [];
  public user: User | null;
  public singleCaseworkerOption: FormAutoCompleteOption[];
  public assignToList: FormAutoCompleteOption[] = [
    {
      value: "UNASSIGN",
      text: "RENEWAL.CASE_LIST_PAGE.OPTIONS_UNASSIGNED",
    },
  ];
  public assignToGroupForm: FormGroup;

  constructor(
    private store: Store<fromApp.State>,
    private caseService: CasesService,
    private translate: TranslateService,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private filterService: ConfirmationTableFilterService
  ) {}

  ngOnInit(): void {
    this.getCurrentLang();
    this.store.select("auth").subscribe((auth) => {
      this.user = auth.user;
      this.getCaseWorkerList();
    });
    this.subscriptions.push(
      this.filterService.selections.subscribe(
        (filters: ConfirmationTableSelections) => {
          this.filters = filters;
          this.checkedPageNumber(0);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub$) => sub$.unsubscribe());
  }

  public formatDate(date: string): any {
    return date.substring(0, 10);
  }

  private getCurrentLang(): void {
    this.store.select(selectRouteData).subscribe((data) => {
      this.currentLang = data?.lang;
    });
  }

  async checkedPageNumber($event: number) {
    this.pageToView = $event;
    await this.sortResults(this.pageToView);
  }

  filterAscending(field: string) {
    this.filterService.selections.next({
      ...this.filterService.selections.value,
      sortField: field,
      sortDirection: "ASC",
    });
  }

  filterDescending(field: string) {
    this.filterService.selections.next({
      ...this.filterService.selections.value,
      sortField: field,
      sortDirection: "DESC",
    });
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.TECHNICAL");
  }

  public async changePageAndFilter(
    pageNumber: number,
    searchValue: string
  ): Promise<void> {
    try {
      const { search, filters, paginationLimit } =
        this.caseService.defaults.confirmation;
      const filteredResults = await this.caseService.getAllCases(
        filters,
        pageNumber,
        undefined,
        { value: searchValue, fields: search }
      );
      this.cases = filteredResults[0].cases;
      this.numberOfCases = filteredResults[1];

      this.paginationNumber = Math.ceil(this.numberOfCases / paginationLimit);
      return;
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    } finally {
      this.setIsAssignable();
    }
  }
  public assignByCase(e: {
    assignTo: FormAutoCompleteOption;
    caseToAssign: Case;
  }): void {
    const assignTo = e.assignTo;
    const caseToAssign = e.caseToAssign;

    if (
      !assignTo.value ||
      assignTo.value === caseToAssign.cognitoCaseWorkerId
    ) {
      return;
    }
    caseToAssign.isUpdating = true;
    caseToAssign.assignError = undefined;
    const newCognitoCaseWorkerId =
      assignTo.value !== "UNASSIGN" ? assignTo.value : null;

    const cases = [
      {
        cognitoCaseWorkerId: caseToAssign.cognitoCaseWorkerId,
        id: caseToAssign.id,
      },
    ];
    const bodyForAPI = {
      newCognitoCaseWorkerId,
      cases,
    };
    this.caseService
      .bulkAssignIntakeRenewalCases(bodyForAPI)
      .then((data) => {
        caseToAssign.isUpdating = false;
        if (data) {
          data.forEach((item) => {
            if (item.assignError) {
              this.assignToGroupForm.controls[caseToAssign.id].setValue({
                value: caseToAssign.cognitoCaseWorkerId,
                text: caseToAssign.cognitoCaseWorkerId,
              });
              this.alertService.danger(this.alertTechnicalError);
            } else {
              caseToAssign.cognitoCaseWorkerId = item.cognitoCaseWorkerId;
              const caseIndex = this.cases
                .map((_case: Case) => _case.id)
                .indexOf(item.id);
              this.cases[caseIndex].cognitoCaseWorkerId =
                item.cognitoCaseWorkerId;

              this.assignToGroupForm.controls[caseToAssign.id].setValue({
                value: caseToAssign.cognitoCaseWorkerId,
                text: caseToAssign.cognitoCaseWorkerId,
              });
              caseToAssign.status.id = item.caseStatusId.toString();
              caseToAssign.caseStatusId = item.caseStatusId;
            }
          });
        }
      })
      .catch(() => {
        caseToAssign.isUpdating = false;
        this.assignToGroupForm.controls[caseToAssign.id].setValue({
          value: caseToAssign.cognitoCaseWorkerId,
          text: caseToAssign.cognitoCaseWorkerId,
        });
        this.alertService.danger(
          this.translate.instant(
            "INTAKE.CASE_LIST_PAGE.BULK_ASSIGN_TECHNICAL_ERROR"
          )
        );
      });
  }

  public async sortResults(page?: number): Promise<void> {
    if (page === undefined) {
      page = 0;
    }
    try {
      const { paginationLimit } = this.caseService.defaults.confirmation;
      const filters = {
        ...this.caseService.defaults.confirmation.filters,
        ...this.filters.filters,
      };
      const cognitoCaseWorkerId = filters.assignedToMe
        ? this.user?.username
        : undefined;
      delete filters.assignedToMe;
      const sort = this.filters.sortField
        ? `${this.filters.sortField} ${this.filters.sortDirection}`
        : undefined;
      const search = this.filters.search.searchTerm
        ? {
            value: this.filters.search.searchTerm,
            fields: ["uci", "applicationNumber", "emailAddress"],
          }
        : undefined;
      this.isLoadingCases = true;
      const filteredResults = await this.caseService.getAllCases(
        filters,
        page,
        sort,
        search,
        cognitoCaseWorkerId
      );
      this.cases = filteredResults[0].cases;
      this.numberOfCases = filteredResults[1];
      this.paginationNumber = Math.ceil(this.numberOfCases / paginationLimit);
      this.setIsAssignable();
      this.createAssignToFormGroup();
      window.scroll(0, 150);
      return;
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    } finally {
      this.isLoadingCases = false;
      this.setIsAssignable();
    }
  }

  public getCaseWorkerList() {
    if (
      this.user &&
      (this.user.userPool?.toUpperCase() === "TEAMLEADS" ||
        this.user.userPool?.toUpperCase() === "CASEWORKERS")
    ) {
      if (this.user.usersList) {
        const userList = this.user.usersList.filter((item) => {
          if (this.user?.userPool?.toLocaleUpperCase() === "TEAMLEADS") {
            return true;
          } else {
            return (
              item.userPool?.toLocaleUpperCase() === "TEAMLEADS" ||
              this.user?.username === item.username
            );
          }
        });
        if (userList) {
          userList.sort((o1, o2) => {
            if (o1.username === this.user?.username) {
              return -1;
            } else if (o2.username === this.user?.username) {
              return 1;
            }
            return 0;
          });
          userList.forEach((item) => {
            this.assignToList.push({
              value: item.username,
              text: item.username || "",
            });
          });
        }
      }
    }
  }
  private createAssignToFormGroup() {
    const assignmentControls: any = {};
    this.cases.forEach((cse) => {
      if (cse.cognitoCaseWorkerId) {
        assignmentControls[cse.id] = {
          value: cse.cognitoCaseWorkerId,
          text: cse.cognitoCaseWorkerId,
        };
      } else {
        assignmentControls[cse.id] = { value: null };
      }
    });
    this.singleCaseworkerOption = [
      {
        value: this.user?.username,
        text: this.user?.username?.toString() || "",
      },
    ];
    this.assignToGroupForm = this.fb.group(assignmentControls);
  }

  private setIsAssignable() {
    this.cases.forEach((_case) => {
      _case.isCaseAssignable = this.isCaseAssignable(_case);
    });
  }

  isCaseAssignable(itemCase: Case): boolean {
    if (this.user?.userPool?.toLocaleUpperCase() === "CASEWORKERS") {
      //  if assigned to me or unassigned
      //  and not returned or completed
      if (
        this.user?.username === itemCase.cognitoCaseWorkerId ||
        itemCase.cognitoCaseWorkerId === null
      ) {
        return true;
      }
    } else if (this.user?.userPool?.toLocaleUpperCase() === "TEAMLEADS") {
      return true;
    }
    return false;
  }
}
