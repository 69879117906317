import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { CasesService } from "../../../core/cases-module/cases.service";
import * as ApplicationActions from "@pr-caseworker/app/intake-module/store/selectedApplication/selectedApplication.actions";
import { Case } from "../../../core/cases-module/models/case.model";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";

@Injectable({
  providedIn: "root",
})
export class IntakeCaseDetailsResolver implements Resolve<Case[]> {
  case: any;

  constructor(
    private route: ActivatedRoute,
    private caseService: CasesService,
    private store: Store<fromApp.State>
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const caseId = route.params.id;
    if (caseId) {
      await this.caseService
        .getClientCasebyCaseId(caseId)
        .then((data: Case) => {
          this.store.dispatch(
            new ApplicationActions.SetSelectedApplication(data)
          );
          this.case = data;
        })
        .catch(() => {
          this.case = null;
        });
    }
    return this.case;
  }
}
