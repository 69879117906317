import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LibModule } from "lib";
import { ListButtonsComponent } from "./list-buttons/list-buttons.component";
import { RejectionInputComponent } from "./rejection-input/rejection-input.component";
import { DownloadService } from "./services/download.service";
import { CasesService } from "../core/cases-module/cases.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [RejectionInputComponent, ListButtonsComponent],
  imports: [
    CommonModule,
    LibModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
      isolate: false,
    }),
    // BrowserAnimationsModule,
  ],
  providers: [DownloadService, CasesService],
  exports: [
    TranslateModule,
    LibModule,
    RejectionInputComponent,
    ListButtonsComponent,
  ],
})
export class SharedModule {}
