<prcw-confirmation-cases-search> </prcw-confirmation-cases-search>
<lib-cases-table
  [cases]="cases"
  [currentLang]="currentLang"
  [caption]="'CASELIST.TABLE_CAPTION' | translate"
  [noResults]="'CASELIST.TABLE_HEADINGS.NO_RESULTS' | translate"
  (emitAssignedUser)="assignByCase($event)"
  [user]="user"
  [singleCaseworkerOption]="singleCaseworkerOption"
  [assignToFormGroup]="assignToGroupForm"
  [assignToList]="assignToList"
  [isLoadingCases]="isLoadingCases"
>
  <ng-container tableHeadings>
    <th class="case-title">
      <div class="case-title__wrapper">
        {{ "CONFIRMATION.APPLICATION" | translate }}
      </div>
    </th>
    <th class="case-title">
      <div class="case-title__wrapper">
        {{ "CONFIRMATION.NAME" | translate }}
      </div>
    </th>
    <th
      class="case-title"
      [attr.aria-sort]="
        filters.sortDirection === 'ASC' && filters.sortField === 'createdAt'
          ? 'ascending'
          : filters.sortDirection === 'DESC' &&
            filters.sortField === 'createdAt'
          ? 'descending'
          : 'none'
      "
    >
      <div class="case-title__wrapper">
        {{ "CASELIST.TABLE_HEADINGS.CREATED_ON" | translate }}
        <div class="case-title__wrapper__filters">
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterAscending('createdAt')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'ASC' &&
                  filters.sortField === 'createdAt'
                )
              "
              src="../../../assets/images/icons/sort-up.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'ASC' &&
                filters.sortField === 'createdAt'
              "
              src="../../../assets/images/icons/sort-up-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
          </button>
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterDescending('createdAt')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'DESC' &&
                  filters.sortField === 'createdAt'
                )
              "
              src="../../../assets/images/icons/sort-down.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'DESC' &&
                filters.sortField === 'createdAt'
              "
              src="../../../assets/images/icons/sort-down-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
          </button>
        </div>
      </div>
    </th>
    <th
      class="case-title"
      [attr.aria-sort]="
        filters.sortDirection === 'ASC' && filters.sortField === 'officeEn'
          ? 'ascending'
          : filters.sortDirection === 'DESC' && filters.sortField === 'officeEn'
          ? 'descending'
          : 'none'
      "
    >
      <div class="case-title__wrapper">
        {{ "CONFIRMATION.OFFICE" | translate }}
        <div class="case-title__wrapper__filters">
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterAscending('officeEn')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'ASC' &&
                  filters.sortField === 'officeEn'
                )
              "
              src="../../../assets/images/icons/sort-up.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'ASC' &&
                filters.sortField === 'officeEn'
              "
              src="../../../assets/images/icons/sort-up-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
          </button>
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterDescending('officeEn')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'DESC' &&
                  filters.sortField === 'officeEn'
                )
              "
              src="../../../assets/images/icons/sort-down.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'DESC' &&
                filters.sortField === 'officeEn'
              "
              src="../../../assets/images/icons/sort-down-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
          </button>
        </div>
      </div>
    </th>
    <th
      class="case-title"
      [attr.aria-sort]="
        filters.sortDirection === 'ASC' &&
        filters.sortField === 'addressDeclarationTimestamp'
          ? 'ascending'
          : filters.sortDirection === 'DESC' &&
            filters.sortField === 'addressDeclarationTimestamp'
          ? 'descending'
          : 'none'
      "
    >
      <div class="case-title__wrapper">
        {{ "CASELIST.TABLE_HEADINGS.DECLARATION" | translate }}
        <div class="case-title__wrapper__filters">
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterAscending('addressDeclarationTimestamp')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'ASC' &&
                  filters.sortField === 'addressDeclarationTimestamp'
                )
              "
              src="../../../assets/images/icons/sort-up.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'ASC' &&
                filters.sortField === 'addressDeclarationTimestamp'
              "
              src="../../../assets/images/icons/sort-up-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
          </button>
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterDescending('addressDeclarationTimestamp')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'DESC' &&
                  filters.sortField === 'addressDeclarationTimestamp'
                )
              "
              src="../../../assets/images/icons/sort-down.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'DESC' &&
                filters.sortField === 'addressDeclarationTimestamp'
              "
              src="../../../assets/images/icons/sort-down-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
          </button>
        </div>
      </div>
    </th>
    <th class="case-title">
      <div class="case-title__wrapper">
        {{ "CONFIRMATION.ASSIGNMENT" | translate }}
      </div>
    </th>
    <th
      class="case-title"
      [attr.aria-sort]="
        filters.sortDirection === 'ASC' && filters.sortField === 'caseStatusId'
          ? 'ascending'
          : filters.sortDirection === 'DESC' &&
            filters.sortField === 'caseStatusId'
          ? 'descending'
          : 'none'
      "
    >
      <div class="case-title__wrapper">
        {{ "CASELIST.TABLE_HEADINGS.STATUS" | translate }}
        <div class="case-title__wrapper__filters">
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterAscending('caseStatusId')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'ASC' &&
                  filters.sortField === 'caseStatusId'
                )
              "
              src="../../../assets/images/icons/sort-up.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'ASC' &&
                filters.sortField === 'caseStatusId'
              "
              src="../../../assets/images/icons/sort-up-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_UP' | translate"
            />
          </button>
          <button
            category="plain"
            size="small"
            class="case-filter__button"
            (click)="filterDescending('caseStatusId')"
          >
            <img
              class="cases-filter-icon"
              *ngIf="
                !(
                  filters.sortDirection === 'DESC' &&
                  filters.sortField === 'caseStatusId'
                )
              "
              src="../../../assets/images/icons/sort-down.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
            <img
              class="cases-filter-icon"
              *ngIf="
                filters.sortDirection === 'DESC' &&
                filters.sortField === 'caseStatusId'
              "
              src="../../../assets/images/icons/sort-down-fill.svg"
              [alt]="'CASELIST.TABLE_HEADINGS.FILTER_DOWN' | translate"
            />
          </button>
        </div>
      </div>
    </th>
  </ng-container>
</lib-cases-table>

<prcw-list-buttons
  *ngIf="numberOfCases > 0"
  (checkedPageNumber)="checkedPageNumber($event)"
  [listNumbers]="paginationNumber"
></prcw-list-buttons>
