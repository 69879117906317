<div class="list">
  <button
    [attr.aria-label]="'CASELIST.TABLE_PAGINATION_ARIA_PREVIOUS' | translate"
    (click)="goToPrevious()"
    [disabled]="pageSetInView === 0"
    *ngIf="lastSet !== 0"
    category="plain"
    size="small"
  >
    <img
      [src]="
        pageSetInView === 0
          ? '../../../assets/images/icons/chevron-left-disabled.svg'
          : '../../../assets/images/icons/chevron-left.svg'
      "
    />
  </button>
  <nav [attr.aria-label]="'CASELIST.TABLE_PAGINATION_ARIA_LIST' | translate">
    <ul class="list__list">
      <li class="list__list__item" *ngFor="let page of pageSets[pageSetInView]">
        <button
          *ngIf="pageInView === page"
          category="primary"
          size="small"
          (click)="changePage(page)"
          [attr.aria-current]="pageInView === page"
          [attr.aria-label]="page"
        >
          {{ page }}
        </button>
        <button
          *ngIf="pageInView !== page"
          category="plain"
          size="small"
          (click)="changePage(page)"
          [attr.aria-current]="pageInView === page"
          [attr.aria-label]="page"
        >
          {{ page }}
        </button>
      </li>
    </ul>
  </nav>
  <button
    [attr.aria-label]="'CASELIST.TABLE_PAGINATION_ARIA_NEXT' | translate"
    (click)="goToNext()"
    [disabled]="pageSetInView === lastSet"
    *ngIf="lastSet !== 0"
    category="plain"
    size="small"
  >
    <img
      [src]="
        pageSetInView === lastSet
          ? '../../../assets/images/icons/chevron-right-disabled.svg'
          : '../../../assets/images/icons/chevron-right.svg'
      "
    />
  </button>
</div>
