import { DependentInfoFlattenedItem } from "../barcodes.model";
import {
  PropertyType,
  DateConversionOption,
} from "../../../core/models/data-retrieval.model";

export const barcode6Config: DependentInfoFlattenedItem[] = [
  {
    // 3 - this is the Barcode field order #
    //     in the BarcodeOrdering_For_IMM0008_06_2018 attached to https://ddcmtl.atlassian.net/browse/IPI-1014
    index: 1,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "nationalIdentityDocument",
    },
    targetField: "nationalIdentityDocument",
  },
  {
    // 4
    index: 1,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "nationalIdentityNumber",
    },
    targetField: "nationalIdentityNumber",
  },
  {
    // 5
    index: 1,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "countryOfIssue" },
    targetField: "nationalIdentityCountryOfIssue",
  },
  {
    // 6
    index: 1,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: { type: PropertyType.Date, sourceField: "issueDate" },
    targetField: "nationalIdentityIssueDate",
  },
  {
    // 7
    index: 1,
    type: PropertyType.Object,
    sourceField: "nationalIdentityDetails",
    subfield: { type: PropertyType.Date, sourceField: "expiryDate" },
    targetField: "nationalIdentityExpiryDate",
  },
  {
    // 8
    index: 1,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "educationLevel" },
    targetField: "educationLevel",
  },
  {
    // 9
    index: 1,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "numberOfYear" },
    targetField: "numberOfYear",
  },
  {
    // 10
    index: 1,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "currentOccupation",
    },
    targetField: "currentOccupation",
  },
  {
    // 11
    index: 1,
    type: PropertyType.Object,
    sourceField: "educationOccupationDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "intendedOccupation",
    },
    targetField: "intendedOccupation",
  },
  {
    // 12
    index: 1,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "nativeLanguage" },
    targetField: "nativeLanguage",
  },
  {
    // 14: 14 and 13 are swapped in order
    index: 1,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "preferredLanguage",
    },
    targetField: "preferredLanguage",
  },
  {
    // 13
    index: 1,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "language" },
    targetField: "language",
  },
  {
    // 15
    index: 1,
    type: PropertyType.Object,
    sourceField: "languageDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "takenTest" },
    targetField: "takenTest",
  },
  {
    // 16
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "familyName" },
    targetField: "familyName",
  },
  {
    // 17
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "givenName" },
    targetField: "givenName",
  },
  {
    // 18
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "usedOtherName" },
    targetField: "usedOtherName",
  },
  {
    // 19
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "otherFamilyName" },
    targetField: "otherFamilyName",
  },
  {
    // 20
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "otherGivenName" },
    targetField: "otherGivenName",
  },
  {
    // 21
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "uci" },
    targetField: "uci",
  },
  {
    // 22
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "sex" },
    targetField: "sex",
  },
  {
    // 23
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "heightInCM" },
    targetField: "heightInCM",
  },
  {
    // 24
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "eyeColour" },
    targetField: "eyeColour",
  },
  {
    // 25
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "dob",
      dateConversionOption: DateConversionOption.Year,
    },
    targetField: "dobYear",
  },
  {
    // 26
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "dob",
      dateConversionOption: DateConversionOption.Month,
    },
    targetField: "dobMonth",
  },
  {
    // 27
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Date,
      sourceField: "dob",
      dateConversionOption: DateConversionOption.Day,
    },
    targetField: "dobDay",
  },
  {
    // 28
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "cityOfBirth" },
    targetField: "cityOfBirth",
  },
  {
    // 29
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "countryOfBirth" },
    targetField: "countryOfBirth",
  },
  {
    // 30
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "citizenship1" },
    targetField: "citizenship1",
  },
  {
    // 31
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "citizenship2" },
    targetField: "citizenship2",
  },
  {
    // 32
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "relationshipToPA" },
    targetField: "relationshipToPA",
  },
  {
    // 33
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "otherRelationshipToPA",
    },
    targetField: "otherRelationshipToPA",
  },
  {
    // 34
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "accompanyingPA" },
    targetField: "accompanyingPA",
  },
  {
    // 35
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "reasonNotAccompanying",
    },
    targetField: "reasonNotAccompanying",
  },
  {
    // 36
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "dependantType" },
    targetField: "dependantType",
  },
  {
    // 37
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Date, sourceField: "dateOfLastEntry" },
    targetField: "dateOfLastEntry",
  },
  {
    // 38
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: { type: PropertyType.Primitive, sourceField: "placeOfLastEntry" },
    targetField: "placeOfLastEntry",
  },
  {
    // 39
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: { type: PropertyType.Primitive, sourceField: "country" },
    },
    targetField: "CORCountry",
  },
  {
    // 40
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "immigrationStatus",
      },
    },
    targetField: "CORImmigrationStatus",
  },
  {
    // 41
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Primitive,
        sourceField: "otherImmigrationStatus",
      },
    },
    targetField: "COROtherImmigrationStatus",
  },
  {
    // 42
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Date,
        sourceField: "startDateOfImmigrationStatus",
      },
    },
    targetField: "CORCountryStartDateOfImmigrationStatus",
  },
  {
    // 43
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Object,
      sourceField: "currentCountry",
      subfield: {
        type: PropertyType.Date,
        sourceField: "endDateOfImmigrationStatus",
      },
    },
    targetField: "CORCountryEndDateOfImmigrationStatus",
  },
  {
    // 44
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Primitive,
      sourceField: "hasPreviousCountries",
    },
    targetField: "hasPreviousCountries",
  },
  {
    // 45
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 0,
        sourceField: "country",
      },
    },
    targetField: "PCOR1Country",
  },
  {
    // 46
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 0,
        sourceField: "immigrationStatus",
      },
    },
    targetField: "PCOR1ImmigrationStatus",
  },
  {
    // 47
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Primitive,
        index: 0,
        sourceField: "otherImmigrationStatus",
      },
    },
    targetField: "PCOR1OtherImmigrationStatus",
  },
  {
    // 48
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Date,
        index: 0,
        sourceField: "startDateOfImmigrationStatus",
      },
    },
    targetField: "PCOR1StartDateOfImmigrationStatus",
  },
  {
    // 49
    index: 2,
    type: PropertyType.Object,
    sourceField: "personalDetails",
    subfield: {
      type: PropertyType.Array,
      sourceField: "previousCountries",
      subfield: {
        type: PropertyType.Date,
        index: 0,
        sourceField: "endDateOfImmigrationStatus",
      },
    },
    targetField: "PCOR1EndDateOfImmigrationStatus",
  },
];
