import { Injectable } from "@angular/core";
import { Lob } from "@pr-applicant/app/core/models/lob.model";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { environment } from "../../../../../pr-applicant/src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { LovService } from "../lov/lov.service";
import { LobIds } from "lib";

@Injectable({
  providedIn: "root",
})
export class LobService {
  private apiName = environment.API_NAME;
  private apiVersion = environment.API_VERSION;
  public lobs: Lob[];

  constructor(
    private awsService: AWSService,
    private translate: TranslateService,
    private lovService: LovService
  ) {}

  public async updateLobs(): Promise<void> {
    const path = `/${this.apiVersion}/lobs`;
    const options = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    const response = await this.awsService.api.get(this.apiName, path, options);
    this.lobs = response.data;
  }

  public async fetchAllLobs(): Promise<Lob[]> {
    if (this.lobs) {
      return this.lobs;
    } else {
      await this.updateLobs();
      return this.lobs;
    }
  }

  public get staticLobs() {
    return this.lobs;
  }

  public async fetchLob(lobId: number) {
    const lobs = await this.fetchLobsByPhase(1);
    return lobs.find((lob) => lob.id === lobId);
  }

  public async fetchLobsByPhase(phaseId: number): Promise<Lob[]> {
    return (await this.fetchAllLobs()).filter(
      (data) => data.phaseId === phaseId
    );
  }

  public async fetchPrograms(lobId: number) {
    const fetched = await this.fetchLobsByPhase(lobId);
    return Array.from(new Set(fetched.map((lob) => lob.programId))).map(
      (id) => {
        const prog = fetched.find((lob) => lob.programId === id);
        return {
          value: prog!.programId,
          text: { en: prog!.programEn, fr: prog!.programFr },
        };
      }
    );
  }

  public async fetchCategoriesByProgram(programId: number): Promise<Lob[]> {
    const filteredLobs = (await this.fetchAllLobs())
      .filter((data) => data.programId === programId)
      .filter((data) => !data.categoryId)
      .sort((a, b) => a.sortOrder - b.sortOrder);
    return filteredLobs;
  }

  public async fetchCategories(phaseID: number): Promise<Lob[]> {
    const filteredLobs = (await this.fetchLobsByPhase(phaseID)).filter(
      (data) => !data.categoryId
    );
    return filteredLobs;
  }

  public fetchCategoryViaSubcategory(categoryId: number): Lob {
    const filteredLob = this.staticLobs.find((data) => data.id === categoryId);
    return filteredLob!;
  }

  public fetchSubcategory(categoryId: number): Lob[] {
    const filteredLob = this.staticLobs.filter(
      (data) => data.categoryId === categoryId
    );

    const renameSpouseCommonLawLobs = filteredLob.map((lob) => {
      if ([41, 43].includes(lob.id)) {
        return {
          ...lob,
          categoryEn: this.translate.instant("INTAKE.IN_CANADA_CATEGORY"),
          categoryFr: this.translate.instant("INTAKE.IN_CANADA_CATEGORY"),
        };
      }
      if ([42, 44].includes(lob.id)) {
        return {
          ...lob,
          categoryEn: this.translate.instant("INTAKE.OUTSIDE_CANADA_CATEGORY"),
          categoryFr: this.translate.instant("INTAKE.OUTSIDE_CANADA_CATEGORY"),
        };
      }
      return lob;
    });
    return renameSpouseCommonLawLobs;
  }

  public getTranslatedProperty(object: any, prefix: string): string {
    const lang = this.translate.currentLang;
    const suffix = lang.slice(0, 1).toUpperCase() + lang.slice(1, 2);
    const property = prefix + suffix;
    return object[property];
  }

  public getCategoryDisplayText(
    lob: Lob,
    referralOrganization?: number
  ): string {
    const lobCategory = this.getTranslatedProperty(lob, "category");
    const colonObj = {
      colonEn: ": ",
      colonFr: " : ",
    };
    const referralOrganizationList =
      this.lovService.lovs.referralPartnerCaseworkerList;
    if (lob.categoryId) {
      const parentLob = this.fetchCategoryViaSubcategory(lob.categoryId);
      const parentCategory = this.getTranslatedProperty(parentLob, "category");
      const colon = this.getTranslatedProperty(colonObj, "colon");
      if ([41, 42, 43, 44].includes(lob.id)) {
        return parentCategory;
      }
      return parentCategory + colon + lobCategory;
    }
    if (referralOrganization) {
      const colon = this.getTranslatedProperty(colonObj, "colon");
      const referralOrganizationObj = referralOrganizationList.filter(
        (org) => org.value === referralOrganization.toString()
      );
      const lang = this.translate.currentLang;
      const referralOrganizationName =
        lang === "fr"
          ? referralOrganizationObj[0].text.fr
          : referralOrganizationObj[0].text.en;

      return lobCategory + colon + referralOrganizationName;
    }
    return lobCategory;
  }
}
