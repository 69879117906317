import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { InterFormsService } from "../../services/inter-forms/inter-forms.service";
import * as _ from "lodash";
@Component({
  selector: "lib-spinner",
  template: ` <div
    *ngIf="showSpinner || runningReasons.length > 0"
    class="spinner"
    [ngClass]="{
      'spinner-full-page': !!fullPage,
      'spinner-overlay': !!useOverlay
    }"
  >
    <div
      class="sk-circle"
      [ngClass]="{
        'sk-circle-under-overlay': !!useOverlay,
        'sk-circle-button': isButton
      }"
    >
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>`,
  styles: [
    `
      .spinner {
        display: flex;
        align-items: center;
      }
      .spinner-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
      }
      .spinner-full-page {
        height: 100vh;
      }
      .sk-circle {
        margin: 100px auto;
        width: 40px;
        height: 40px;
        position: relative;
      }
      .sk-circle.sk-circle-button {
        margin: 0;
        height: 16px;
        width: 16px;
        position: relative;
      }
      .sk-circle.sk-circle-under-overlay {
        margin: auto;
      }
      .sk-circle .sk-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .sk-circle .sk-child:before {
        content: "";
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #333;
        border-radius: 100%;
        -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      }
      .sk-circle .sk-circle2 {
        -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        transform: rotate(30deg);
      }
      .sk-circle .sk-circle3 {
        -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        transform: rotate(60deg);
      }
      .sk-circle .sk-circle4 {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      .sk-circle .sk-circle5 {
        -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        transform: rotate(120deg);
      }
      .sk-circle .sk-circle6 {
        -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        transform: rotate(150deg);
      }
      .sk-circle .sk-circle7 {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      .sk-circle .sk-circle8 {
        -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        transform: rotate(210deg);
      }
      .sk-circle .sk-circle9 {
        -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        transform: rotate(240deg);
      }
      .sk-circle .sk-circle10 {
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
      }
      .sk-circle .sk-circle11 {
        -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
        transform: rotate(300deg);
      }
      .sk-circle .sk-circle12 {
        -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
        transform: rotate(330deg);
      }
      .sk-circle .sk-circle2:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
      .sk-circle .sk-circle3:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
      .sk-circle .sk-circle4:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
      .sk-circle .sk-circle5:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
      .sk-circle .sk-circle6:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
      }
      .sk-circle .sk-circle7:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
      }
      .sk-circle .sk-circle8:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
      .sk-circle .sk-circle9:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
      }
      .sk-circle .sk-circle10:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }
      .sk-circle .sk-circle11:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
      }
      .sk-circle .sk-circle12:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }
      @-webkit-keyframes sk-circleBounceDelay {
        0%,
        80%,
        100% {
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        40% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      @keyframes sk-circleBounceDelay {
        0%,
        80%,
        100% {
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        40% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    `,
  ],
})
export class SpinnerComponent implements OnDestroy, AfterViewInit {
  @Input() showSpinner = true;
  @Input() fullPage = true;
  @Input() id = "GLOBAL";
  @Input() useOverlay = false;
  @Input() isButton = false;

  runningReasons: Array<string> = [];
  spinnerSubscription: Subscription;

  constructor(private interForm: InterFormsService) {}

  ngAfterViewInit() {
    const self = this;
    self.spinnerSubscription = self.interForm.spinnerEmitter.subscribe(
      (data) => {
        if (data.actionType === "START" && data.spinnerId === self.id) {
          setTimeout(() => {
            if (_.indexOf(self.runningReasons, data.actionName) === -1) {
              self.runningReasons.push(data.actionName);
            }
          }, 10);
        } else if (data.actionName === "ALL") {
          setTimeout(() => (self.runningReasons = []), 0);
        } else {
          setTimeout(() => {
            self.runningReasons = self.runningReasons.filter(
              (x) => x !== data.actionName
            );
          }, 10);
        }
      }
    );
  }

  ngOnDestroy(): any {
    if (this.spinnerSubscription) {
      this.spinnerSubscription.unsubscribe();
    }
  }
}
