import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import routePaths from "./route-paths";
import { UserService } from "../shared/services/user/user.service";
const utf8 = require("utf8");
@Injectable({
  providedIn: "root",
})
export class RouteLocalizerService {
  private translateSvcSubs: Subscription;
  private repPrefixedKeys = [
    "LOGIN",
    "FIRST_RESET",
    "FORGOT_PASSWORD",
    "RESET_PASSWORD",
    "CLIENT_SIGNUP",
    "SIGNUP_VERIFICATION",
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private title: Title,
    private user: UserService
  ) {}

  /* set the document title; to be used on all "page" components
  pageTitle: use a constant from the language files
  params: optional ngx-translate params (empty if none provided)
  -------------------------------------------------------------- */
  public setTranslatedTitle(pageTitle: string, params: any = {}): void {
    // don't create a subscription inside of subscription,
    // as it is memory leack, lets chain them
    if (this.translateSvcSubs) {
      this.translateSvcSubs.unsubscribe();
    }
    /*
    this.translate.get(pageTitle, params).subscribe(title => {
      this.translate.get('SITE_NAME').subscribe(site => {
        this.title.setTitle(`${title} | ${site}`);
      });
    });
    */
    let translatedTitle = "";
    this.translateSvcSubs = this.translate
      .get(pageTitle, params)
      .pipe(
        switchMap((title) => {
          translatedTitle = title;
          return this.translate.get("SITE_NAME");
        })
      )
      .subscribe((site) => {
        this.title.setTitle(`${translatedTitle} | ${site}`);
      });
  }

  /* access routePaths object
  --------------------------- */
  public getRoutes(): any {
    return routePaths;
  }

  /* get the lang string from the current url
  ------------------------------------------- */
  public getCurrentRouteLang(url?: string): string {
    const lang = url ? url.split("/")[1] : this.router.url?.split("/")[1];
    const fallbackLang = "en"; // incase route has no 'en' or 'fr'
    return lang ? lang : fallbackLang;
  }

  /* get a localized path name by key; use in [routerLink].
  key: routePaths object key or null
  relativePath (optional): 'relative' prefix string as e.g.: '/', './', or '../'
  -------------------------------------------------------- */
  public get(lang: string, key: string | null, relativePath?: string): any {
    const rel = relativePath ? relativePath : "";
    return key === null
      ? `${rel}`
      : // @ts-ignore (typing issue with routePaths[lang]?)
        `${rel}${routePaths[lang][key]}`;
  }

  /* get a localized path name by list of keys; use in [routerLink].
   * keys: list of key routes that will build out the route. The sequence
   * of string passed will be based on routePaths
   * relativePath (optional): 'relative' prefix string as e.g.: '/', './', or '../'
   * -------------------------------------------------------- */

  public getNestedPath(
    lang: string,
    keys: string[],
    relativePath?: string
  ): any {
    const rel = relativePath ? relativePath : "";
    let path = `${rel}`;
    if (keys !== null && keys.length > 0) {
      keys.map((key: string) => {
        // @ts-ignore (typing issue with routePaths[lang]?)
        path += `${routePaths[lang][key]}/`;
      });
    }
    return path;
  }

  /* navigate to any localized route, using a path key
  ---------------------------------------------------- */
  public goTo(lang: string, key: string): void {
    const isRep = this.user.isRepAccount() || this.isUrlRepPrefixed;
    const repUrlPrefix =
      isRep && this.repPrefixedKeys.includes(key) ? "rep/" : "";
    this.router.navigateByUrl(
      // @ts-ignore (typing issue with routePaths[lang]?)
      `/${lang}/${repUrlPrefix}${routePaths[lang][key]}`
    );
  }

  /* navigate to equivalent of current route; for the language toggle
  ------------------------------------------------------------------- */
  public goToLocalizedCurrentRoute(lang: string): void {
    let path = this.router.url;
    const queryIndex = path.indexOf("?");
    if (queryIndex > 0) {
      path = path.slice(0, queryIndex);
    }
    if (path.includes("#")) path = path.split("#")[0];
    const currentUrlSegments = path.split("/"); // creates array of current url segments as: ["", "en", "case", "42"]
    const localUrlSegments = currentUrlSegments.slice(2); // ["case", "42"]
    let queryParams = {};
    this.route.queryParams
      .pipe(first())
      .subscribe((params) => (queryParams = params));
    this.translate.use(lang);
    this.goToLocalizedRoute(localUrlSegments, queryParams, lang);
  }

  public isPrcRoute(): boolean {
    let path = decodeURIComponent(this.router.url);
    const lang = this.getCurrentRouteLang();
    let queryIndex;
    if (lang === "en") {
      queryIndex = path.indexOf(routePaths.en.RENEWAL_PERMANENT_RESIDENT_CARD);
    } else {
      queryIndex = path.indexOf(routePaths.fr.RENEWAL_PERMANENT_RESIDENT_CARD);
    }

    return queryIndex > -1;
  }

  public isPrtdRoute(): boolean {
    let path = decodeURIComponent(this.router.url);
    const lang = this.getCurrentRouteLang();
    let queryIndex;
    if (lang === "en") {
      queryIndex = path.indexOf(routePaths.en.RENEWAL_TRAVEL_DOCUMENTS);
    } else {
      queryIndex = path.indexOf(routePaths.fr.RENEWAL_TRAVEL_DOCUMENTS);
    }
    return queryIndex > -1;
  }
  /* navigate to any localized route, using given url segments
  ------------------------------------------------------------ */
  public goToLocalizedRoute(url: any, queryParams: object, lang: string): void {
    const newSegments = url.map((seg: string) =>
      this.getTranslatedSegment(decodeURIComponent(seg), lang)
    );
    this.router.navigate([lang, ...newSegments], { queryParams });
  }

  private getTranslatedSegment(seg: string, lang: string): any {
    const currentRouteLang = this.router.url.split("/")[1]; // "en" from ["", "en", ...]
    const key = this.getKeyFor(currentRouteLang, seg);
    // @ts-ignore (typing issue with routePaths[lang]?)
    return key ? routePaths[lang][key] : seg; // return the value for this key in [lang] obj; if no such key, return the segment itself
  }

  private getKeyFor(lang: string, term: string): any {
    // @ts-ignore (typing issue with routePaths[lang]?)
    const filteredRoutes = Object.entries(routePaths[lang]).filter(
      ([k, v]) => v === term
    );
    return filteredRoutes[0] ? filteredRoutes[0][0] : null;
  }

  public get isUrlRepPrefixed(): boolean {
    return (
      this.router.url.includes("en/rep") || this.router.url.includes("fr/rep")
    );
  }
}
