import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "prcw-webform-raw-html-display",
  templateUrl: "./webform-raw-html-display.component.html",
  styleUrls: ["./webform-raw-html-display.component.scss"],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class WebformRawHtmlDisplayComponent implements OnInit {
  @Input() rawHtmlData: string;

  constructor() {}

  ngOnInit(): void {}
}
