import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { CaseService } from "@pr-applicant/app/shared/case-module/case.service";
import { FormSelectOption } from "lib";
import { from, Observable } from "rxjs";
import { CasesService } from "../../cases.service";

@Component({
  selector: "prcw-status-filter",
  templateUrl: "./status-filter.component.html",
  styleUrls: ["./status-filter.component.scss"],
})
export class StatusFilterComponent implements OnInit, OnChanges {
  @Input() copyKey: string;
  @Input() value: number | string;
  @Output() selectedStatus$: EventEmitter<number> = new EventEmitter<number>();

  public statusFilter: FormControl;
  public statuses: FormSelectOption[] = [
    {
      value: 7,
      text: {
        en: "1. Ready for Review",
        fr: "1. Prêt pour révision",
      },
    },
    {
      value: 11,
      text: {
        en: "2. RPA Upload Fail",
        fr: "2. Échec du téléchargement ARP",
      },
    },
    {
      value: 12,
      text: {
        en: "3. Ready for Supporting Doc Upload",
        fr: "3. Prêt pour le téléchargement des pièces justificatives",
      },
    },
    {
      value: 13,
      text: {
        en: "4. Supporting Doc Upload Failed",
        fr: "4. Échec du téléchargement des pièces justificatives",
      },
    },
    {
      value: 14,
      text: {
        en: "5. Ready for Check",
        fr: "5. Prêt pour la vérification",
      },
    },
    {
      value: 8,
      text: {
        en: "6. Returned",
        fr: "6. Retourné",
      },
    },
    {
      value: 10,
      text: {
        en: "7. Complete",
        fr: "7. Complété",
      },
    },
  ];

  constructor() {}

  ngOnInit() {
    this.statusFilter = new FormControl(null);
    if (!!this.value) {
      this.statusFilter.setValue(this.value);
      this.statusFilter.updateValueAndValidity();
    }
  }

  //  resets value of control in UI to default when Clear All clicked
  ngOnChanges(): void {
    if (this.statusFilter) {
      this.statusFilter.setValue(this.value);
      this.statusFilter.updateValueAndValidity();
    }
  }

  statusFiltered() {
    this.selectedStatus$.emit(this.statusFilter.value);
  }
}
