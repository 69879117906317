<header class="page-header">
  <h1 class="emphasis">
    {{ "PHOTO_PREVIEW.PAGE_HEADING" | translate }} - {{ photoName }}
  </h1>
  <button
    type="button"
    class="btn btn-secondary download-btn"
    (click)="downloadImg()"
  >
    {{ "PHOTO_PREVIEW.DOWNLOAD_BUTTON" | translate }}
  </button>
</header>

<div class="image-container">
  <lib-image-preview
    class="image_preview_container"
    [imageSrc]="photoSrc"
    [imageAlt]="'PHOTO_PREVIEW.PHOTO_ALT' | translate"
    [coordinates]="photoCoordinates"
    [width]="280"
    [height]="360"
    [canvasId]="'previewPhoto'"
    [sourceImgId]="'sourcePhoto'"
    (outputUrl)="setDownloadUrl($event)"
  ></lib-image-preview>
</div>

<div class="switch-field">
  <lib-form-select
    class="status-input"
    identifier="photoStatus"
    [formControl]="photoStatusForm.controls.photoStatus"
    [options]="selectionOptions"
  ></lib-form-select>
</div>

<section
  *ngIf="photoStatus === false && rejectionOptions"
  class="rejection-options"
>
  <h2 class="emphasis">
    {{ "PHOTO_PREVIEW.REJECTION_REASONS_HEADING" | translate }}
  </h2>
  <prcw-rejection-input
    #photoRejection
    [caseId]="this.caseId"
    [readOnly]="!canUpdatePhoto"
    [documentType]="'PHOTO'"
    [rejectionsArray]="rejectionOptions"
    (reasonToggled)="watchRejection()"
  ></prcw-rejection-input>
</section>

<div class="page-actions">
  <button
    type="button"
    [routerLink]="routeLocalizer.get(lang, null, '../')"
    [state]="{ from: 'photoReview', caseId: caseId }"
    class="btn btn-secondary"
  >
    {{ "PHOTO_PREVIEW.BACK_LINK" | translate }}
  </button>
  <button
    type="button"
    [disabled]="!canContinue"
    [routerLink]="routeLocalizer.get(lang, null, '../')"
    [state]="{ from: 'photoReview', caseId: caseId }"
    (click)="navigateAndUpdateStore()"
    class="btn btn-primary"
  >
    {{ "PHOTO_PREVIEW.SUBMIT_BUTTON" | translate }}
  </button>
</div>
