<header class="page-header">
  <h1 class="emphasis">{{ "CASELIST.PAGE_HEADING" | translate }}</h1>

  <a
    [routerLink]="routeLocalizer.get(currentLang, 'CREATE_CASE')"
    category="primary"
  >
    <lib-icon-chooser icon="add"></lib-icon-chooser>
    {{ "CASELIST.NEW_CASE_BUTTON" | translate }}
  </a>
</header>

<prcw-cases-table></prcw-cases-table>
