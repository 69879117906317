import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
} from "@angular/core";
import { BARCODE_CONFIG } from "../barcode-config/draw-barcodes.config";
const PDF417 = require("pdf417-generator");
const utf8 = require("utf8");
@Component({
  selector: "prcw-barcode",
  templateUrl: "./barcode.component.html",
  styleUrls: ["./barcode.component.scss"],
})
export class BarcodeComponent implements OnChanges, AfterViewInit {
  // Inputs
  @Input() barCodeData: string;
  @Input() barcodeId: string;
  @Input() barcodeAltText: string;

  // Parameters
  private aspectRatio = BARCODE_CONFIG.ASPECT_RATIO; // the width to height of the symbol
  private devicePixelRatio = BARCODE_CONFIG.DEVICE_PIXEL_RATIO; // devicePixelRatio (int) allow for a sharper image;

  // Image
  public dataURL: string; // data URI containing a representation of the image, used in the src of the image

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    this.renderBarcode();
  }

  ngAfterViewInit() {
    this.renderBarcode();
    this.cdr.detectChanges();
  }

  public renderBarcode(): void {
    // 1. grab the canvas we will use to draw our barcode
    const canvas = document.getElementById(this.barcodeId) as HTMLCanvasElement;
    this.splitBarCodeID(this.barcodeId);

    if (canvas) {
      // Sets error correction based on size of barcode data, ref: https://www.barcodefaq.com/2d/pdf417/
      // If barcode length exceeds max chars recommended (800 characters) we need to set a lower EC level
      // NOTE: this is already accomplished by setting EC to -1 (Auto).

      // 2. use PDF417 generator to generate a barcode on the canvas with the parameters
      const encodedFormData = utf8.encode(this.barCodeData);
      PDF417.draw(
        encodedFormData,
        canvas,
        this.aspectRatio,
        BARCODE_CONFIG.ERROR_CORRECTION_LEVEL,
        this.devicePixelRatio
      );
      // PDF417.draw('test', canvas, this.aspectRatio, BARCODE_CONFIG.ERROR_CORRECTION_LEVEL, this.devicePixelRatio);

      // 4. convert canvas into image
      // returns a data URI containing a representation of the image in the format specified by the type
      this.dataURL = canvas.toDataURL(
        BARCODE_CONFIG.IMAGE_TYPE,
        BARCODE_CONFIG.QUALITY
      );

      // Hide the canvas used to create the barcode, only display the final image
      canvas.style.display = "none";
    }
  }

  public splitBarCodeID(barcode: string): void {
    this.barcodeAltText = barcode?.split("bc")[1];
  }
}
