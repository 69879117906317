<div class="form">
  <p>{{ this.verificationTextLabel }}</p>
  <form
    [formGroup]="multiFactorAuthForm"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <lib-form-input
      identifier="verificationCode"
      type="text"
      [formControl]="multiFactorAuthForm.controls.verificationCode"
      [label]="'MFA.FORM_INPUT_LABEL' | translate"
      required="true"
    >
      <span
        *ngIf="
          multiFactorAuthForm.controls.verificationCode.touched &&
          multiFactorAuthForm.controls.verificationCode.errors?.required
        "
      >
        {{ "MFA.ERRORS.REQUIRED_CODE" | translate }}
      </span>
      <span
        *ngIf="
          multiFactorAuthForm.controls.verificationCode.touched &&
          (multiFactorAuthForm.controls.verificationCode.errors?.minlength ||
            multiFactorAuthForm.controls.verificationCode.errors?.maxlength ||
            multiFactorAuthForm.controls.verificationCode.errors?.pattern)
        "
      >
        {{ "MFA.ERRORS.VALID_CODE" | translate }}
      </span>
    </lib-form-input>
    <div>
      {{ "MFA.ERRORS.RESEND_TEXT" | translate }}
      <button class="btn resend-btn" (click)="resendVerificationCode()">
        {{ "MFA.RESEND_BUTTON" | translate }}
      </button>
    </div>
    <button
      [disabled]="!multiFactorAuthForm.valid"
      class="form-submit btn btn-primary"
      type="submit"
    >
      {{ "MFA.FORM_SUBMIT" | translate }}
    </button>
  </form>
</div>
