import { Action } from "@ngrx/store";
import { Case } from "@pr-caseworker/app/core/cases-module/models/case.model";

export const GET_APPLICATIONS = "GET_APPLICATIONS";
export class GetApplications implements Action {
  readonly type = GET_APPLICATIONS;
  constructor(public payload: Case[]) {}
}

export type AllApplicationsActions = GetApplications;
