import { WebformFlattened } from "../webform-view-table.model";
import { getImm0008DependantConfigs } from "./imm0008-dep-configs";
import { DOC_LIMITS } from "lib";

// This config file is a function that can accept the number of dependants available
// and generate the proper number of dependants configs
export function IMM0008AdditionalDependentsConfigs(
  depNumber: number = -1
): WebformFlattened {
  // Start with an empty config table
  const configTables = [];

  // Based on the number of dependants passed to the function,
  // include any additional dependants (6+)
  for (
    let index = DOC_LIMITS.IMM0008.VIEW_DEPENDANTS_BARCODE_LIMIT;
    index < depNumber;
    index++
  ) {
    configTables.push(getImm0008DependantConfigs(index));
  }

  return {
    metaData: {
      form: "imm0008",
      documentTypeId: 15,
      pageTitle: "INTAKE.IMM0008.PAGES.MAIN_HEADING",
      // Set to true if the form has multiple pages (ex: immxxxx/barcodes and immxxxx/form-data) to set the proper navigation buttons
      multiPageForm: true,
      prevPageKey: "WEBFORM_FORMDATA",
    },
    tables: configTables,
  };
}
