<h1 class="title emphasis" *ngIf="!loading">
  {{ "LOGIN.PAGE_HEADING" | translate }}
</h1>

<prcw-login
  *ngIf="!displayMFA"
  (mfaTrigger)="handleMFA($event)"
  (loadingVal)="checkLoadingValue($event)"
></prcw-login>
<prcw-multi-factor-code
  *ngIf="displayMFA"
  [cellphone]="mfaContactNumber"
></prcw-multi-factor-code>
