<div class="checkbox-control-lib">
  <div class="checkbox">
    <!-- do not render input element for readonly forms -->
    <label [for]="identifier" class="checkbox__label">
      <span *ngIf="isRequired" class="checkbox__required-icon">
        <lib-icon-chooser icon="asterisk"></lib-icon-chooser>
      </span>
      <span class="checkbox__shift">
        {{ label }}
        <!-- <span *ngIf="isRequired" class="checkbox__required">
          ({{ requiredText }})
        </span> -->
      </span>
      <span *ngIf="this.ngControl.value" class="visually-hidden"
        >(checked)</span
      >
      <span *ngIf="!this.ngControl.value" class="visually-hidden"
        >(unchecked)</span
      >
    </label>

    <!-- regular checkbox -->
    <ng-container *ngIf="editable">
      <div class="checkbox__layout">
        <input
          type="checkbox"
          class="checkbox__input"
          [id]="identifier"
          [name]="nameVal"
          [value]="ngControl.value"
          [formControl]="ngControl.control"
          [checked]="ngControl.control ? ngControl.control.value : false"
          [required]="isRequired"
          [attr.aria-required]="isRequired"
        />
        <p class="checkbox__description" *ngIf="description !== ''">
          {{ description }}
        </p>
      </div>
    </ng-container>

    <!-- readonly checkbox -->
    <ng-container *ngIf="!editable">
      <div class="checkbox__layout">
        <input
          type="checkbox"
          class="checkbox__input"
          [id]="identifier"
          [name]="nameVal"
          [value]="this.ngControl.value"
          [formControl]="ngControl.control"
          [checked]="ngControl.control ? ngControl.control.value : false"
          [attr.disabled]="true"
        />
        <p class="checkbox__description" *ngIf="description !== ''">
          {{ description }}
        </p>
      </div>
    </ng-container>

    <div *ngIf="ngControl.errors && ngControl.touched" class="checkbox__error">
      <ng-content>
        <!-- error spans -->
      </ng-content>
    </div>
  </div>
</div>
