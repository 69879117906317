import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormSelectOption } from "lib";
import { LobService } from "../../../../../../../lib/src/lib/services/lob/lob.service";
import { AppFlow } from "@pr-caseworker/app/core/models/common.model";
import { CasesService } from "@pr-caseworker/app/core/cases-module/cases.service";
import { valueTextFormatter } from "@pr-caseworker/app/shared/helpers/valueTextFormatter";

@Component({
  selector: "prcw-lob-filter",
  templateUrl: "./lob-filter.component.html",
  styleUrls: ["./lob-filter.component.scss"],
})
export class LobFilterComponent implements OnInit, OnChanges {
  public lobs: FormSelectOption[] = [];
  public lobFilter: FormControl;
  lang: string;
  @Input() copyKey: string;
  @Input() value: string;
  @Input() flow?: AppFlow;
  @Output() selectedLob = new EventEmitter<string>();

  constructor(
    private lobService: LobService,
    private caseService: CasesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lobs = valueTextFormatter(
      await this.lobService.fetchCategories(this.getDefaultFilter()!)
    );

    this.lobFilter = new FormControl(null);
    if (!!this.value) {
      this.lobFilter.setValue(this.value);
      this.lobFilter.updateValueAndValidity();
    }
  }

  ngOnChanges(): void {
    if (this.lobFilter) {
      this.lobFilter.setValue(this.value);
      this.lobFilter.updateValueAndValidity();
    }
  }

  lobFiltered() {
    this.selectedLob.emit(this.lobFilter.value);
  }

  private getDefaultFilter() {
    if (this.flow) {
      return this.caseService.defaults[this.flow].filters?.phaseId;
    } else {
      return;
    }
  }
}
