import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, tap } from "rxjs/operators";
import * as fromAuthActions from "./auth.actions";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import * as fromApp from "../../../store/app.reducer";
import * as CasesActions from "../../../confirmation-module/store/cases.actions";
import * as IntakeSearchActions from "@pr-caseworker/app/intake-module/store/searchTerms/searchTerms.actions";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions<fromAuthActions.AuthActions>,
    private routeLocalizer: RouteLocalizerService,
    private router: Router,
    private store: Store<fromApp.State>,
    private authService: AuthService
  ) {}
  // changes to use create effect
  Login$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromAuthActions.LOGIN),
        map((action) => action.payload),
        tap((payload) => {
          if (payload.isFirstLogin) {
            this.routeLocalizer.goTo(this.currentLang, "FIRST_RESET");
          } else {
            this.router.navigateByUrl(`${this.currentLang}`);
          }
          this.authService.getUsersList(payload.username);
        })
      ),
    { dispatch: false }
  );

  FirstLogin$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromAuthActions.FIRST_LOGIN_COMPLETE),
        tap(() => this.routeLocalizer.goTo(this.currentLang, "LOGIN"))
      ),
    { dispatch: false }
  );

  Logout$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromAuthActions.LOGOUT),
        tap(() => {
          this.store.dispatch(new CasesActions.ResetCaseInfo());
          this.store.dispatch(
            new IntakeSearchActions.ResetAllFiltersAndSorting()
          );
          this.routeLocalizer.goTo(this.currentLang, "LOGIN");
        })
      ),
    { dispatch: false }
  );

  private get currentLang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }
}
