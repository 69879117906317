import { Component, Input } from "@angular/core";

export type PillStatusType =
  | "blank"
  | "ready"
  | "submitted"
  | "incomplete"
  | "complete"
  | "initiated"
  | "returned"
  | "review"
  | "revision"
  | "accepted"
  | "ready7"
  | "rpa11"
  | "upload12"
  | "failed13"
  | "check14"
  | "returned8"
  | "complete10"
  | "notStarted"
  | null
  | undefined
  | "expired"
  | "missingInformation";
/*

Form/Document Statuses:
blank
ready
submitted
incomplete
complete

-------
cases statuses:
7 - Ready for review - "--review7"
11 - RPA Upload Failed - "--rpa11"
12 - Ready for support docs upload - "--upload12"
13 - Supporting docs upload fail - "--failed13"
14 - Ready for check - "--check14"
8 - Returned - "returned8"
10 - Completed - "--complete10"
-----------

Case Status Confirmation:
initiated
review
revision
accepted

*/
@Component({
  selector: "lib-status-pill",
  templateUrl: "./status-pill.component.html",
  styleUrls: ["./status-pill.component.scss"],
})
export class StatusPillComponent {
  @Input() type: PillStatusType;
  @Input() text: string;
  @Input() minWidth = 12;
  @Input() fontSize: string = "0.6rem";
  @Input() wordBreak?: boolean = false;
  constructor() {}
}
