<h2 class="emphasis section__heading">
  {{ "INTAKE.CASE_DETAILS_PAGE.PRIMARY_SPONSOR_SECTION_TITLE" | translate }}
</h2>

<div class="data-container">
  <div class="data-item">
    <div class="data-label">
      {{ "INTAKE.CASE_DETAILS_PAGE.REP_INFO_NAME" | translate }}
    </div>
    <div class="data-content">
      {{ name }}
    </div>
  </div>

  <div class="data-item break-all">
    <div class="data-label">
      {{ "INTAKE.CASE_DETAILS_PAGE.REP_INFO_EMAIL" | translate }}
    </div>
    <div class="data-content">
      {{ email }}
    </div>
  </div>

  <!-- these are here to preserve equal spacing with the rep section equivalent -->
  <div class="data-item"></div>
  <div class="data-item"></div>
</div>

<prcw-submission-time-section
  [submittedTime]="submittedTime"
></prcw-submission-time-section>
