import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Alert } from "../../components/alert/alert.model";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private subject = new Subject<Alert | null>();

  public alert(alert: Alert): void {
    this.subject.next(alert);
  }

  public clear(): void {
    this.subject.next(null);
  }

  public danger(message: string): void {
    this.alert({ type: "danger", message });
  }

  public success(message: string): void {
    this.alert({ type: "success", message });
  }

  public warning(message: string): void {
    this.alert({ type: "warning", message });
  }

  public info(message: string): void {
    this.alert({ type: "info", message });
  }

  public onAlert(): Observable<any> {
    return this.subject.asObservable();
  }
}
