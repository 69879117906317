export const BARCODE_CONFIG = {
  ASPECT_RATIO: 2, // width to height
  ERROR_CORRECTION_LEVEL: -1, // high error correction level
  BARCODE_DATA_MAX_CHARS: 800, // max barcode characters recommended
  DEVICE_PIXEL_RATIO: 20, // handles the sharpness of the barcode
  IMAGE_TYPE: "image/png",
  QUALITY: 1,

  BARCODES_PER_PAGE: 8,
  BARCODE_WIDTH: 99,
  BARCODE_HEIGHT: 55,
  MARGIN_Y: 15,
  MARGIN_X: 4,
  GAP_X: 6,
  GAP_Y: 8,
};
