<div *ngIf="applicationMode !== 'outage'">
  <div class="app__page-container" #container>
    <prcw-ng-idle-logout></prcw-ng-idle-logout>

    <lib-navigation-warning
      [title]="'INTAKE.NAVIGATION_MODAL.TITLE' | translate"
      [subheading]="'INTAKE.NAVIGATION_MODAL.SUBHEADING' | translate"
      [description]="'INTAKE.NAVIGATION_MODAL.DESCRIPTION' | translate"
      [leaveBtn]="'INTAKE.NAVIGATION_MODAL.LEAVE_ACTION' | translate"
      [stayBtn]="'INTAKE.NAVIGATION_MODAL.STAY_ACTION' | translate"
      [enabled]="isAuthenticated"
    ></lib-navigation-warning>

    <prcw-header>
      <ng-container skipLinks>
        <a [href]="skipLinkPath" class="skip-link">
          {{ "HEADER.SKIP_LINK" | translate }}
        </a>
      </ng-container>
    </prcw-header>
    <prcw-navbar *ngIf="isAuthenticated"></prcw-navbar>

    <lib-alert></lib-alert>

    <main id="main" class="app">
      <lib-spinner [showSpinner]="loading"></lib-spinner>
      <div
        class="app__container"
        [ngStyle]="{ display: loading ? 'none' : 'block' }"
      >
        <lib-banner-box
          [title]="bannerBoxHeading | translate"
          [type]="bannerBoxType"
          *ngIf="!loading && applicationMode === 'banner'"
        >
          <div [innerHTML]="bannerBoxBody"></div>
        </lib-banner-box>
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>
<div *ngIf="applicationMode === 'outage'">
  <lib-maintenance></lib-maintenance>
</div>
