import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { LibModule } from "lib";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { WebformViewTableComponent } from "./webform-view-table/webform-view-table.component";
import { WebformViewTablePageComponent } from "./webform-view-table-page/webform-view-table-page.component";
import { WebformViewImm5669DisclosureDeclarationComponent } from "./webform-view-table-page/webform-view-imm5669-disclosure-declaration/webform-view-imm5669-disclosure-declaration.component";
import { WebformViewIMM5669SolemnDeclarationComponent } from "./webform-view-imm5669-solemn-declaration/webform-view-imm5669-solemn-declaration.component";

import { Webform5444TableViewComponent } from "./webform-5444-table-view/webform-5444-table-view.component";
import { Webform5444SubTablesViewComponent } from "./webform-view-imm5444-sub-tables-view/webform-view-imm5444-sub-tables-view.component";
import { WebformViewimm0008PageComponent } from "./webform-view-imm0008-page/webform-view-imm0008-page.component";
import { WebformViewImm5406PageComponent } from "./webform-view-imm5406-page/webform-view-imm5406-page.component";
import { WebformViewImm5562PageComponent } from "./webform-view-imm5562-page/webform-view-imm5562-page.component";
import { WebformViewImm5669PageComponent } from "./webform-view-imm5669-page/webform-view-imm5669-page.component";
import { WebformRawHtmlDisplayComponent } from './webform-raw-html-display/webform-raw-html-display.component';
import { WebformNavButtonsComponent } from './webform-nav-buttons/webform-nav-buttons.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    WebformViewTableComponent,
    WebformViewTablePageComponent,
    WebformViewImm5669DisclosureDeclarationComponent,
    Webform5444TableViewComponent,
    WebformViewIMM5669SolemnDeclarationComponent,
    Webform5444SubTablesViewComponent,
    WebformViewimm0008PageComponent,
    WebformViewImm5406PageComponent,
    WebformViewImm5562PageComponent,
    WebformViewImm5669PageComponent,
    WebformRawHtmlDisplayComponent,
    WebformNavButtonsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LibModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
  ],
})
export class WebformViewTableModule {}
