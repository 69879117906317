<div class="spinner" *ngIf="loading">
  <lib-spinner [showSpinner]="loading" [fullPage]="true"></lib-spinner>
</div>

<div class="wrapper">
  <div class="form">
    <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <fieldset>
        <lib-form-input
          identifier="username"
          type="email"
          [formControl]="loginForm.controls.username"
          [label]="'LOGIN.USERNAME_INPUT_LABEL' | translate"
          required="true"
        >
          <span
            *ngIf="
              loginForm.controls.username.touched &&
              loginForm.controls.username.errors?.email
            "
          >
            {{ "LOGIN.ERRORS.INVALID_EMAIL" | translate }}
          </span>
          <span
            *ngIf="
              loginForm.controls.username.touched &&
              loginForm.controls.username.errors?.required
            "
          >
            {{ "LOGIN.ERRORS.REQUIRED_EMAIL" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          identifier="password"
          type="password"
          [formControl]="loginForm.controls.password"
          [label]="'LOGIN.PASSWORD_INPUT_LABEL' | translate"
          required="true"
        >
          <span
            *ngIf="
              loginForm.controls.password.touched &&
              loginForm.controls.password.errors?.required
            "
          >
            {{ "LOGIN.ERRORS.REQUIRED_PASSWORD" | translate }}
          </span>
        </lib-form-input>
      </fieldset>
      <div>
        <a [routerLink]="forgotPasswordRouteName" class="forgot-password">{{
          "LOGIN.FORGOT_PASSWORD" | translate
        }}</a>
        <button
          [disabled]="autofill === true ? false : !loginForm.valid"
          class="form-submit btn btn-primary"
          type="submit"
        >
          {{ "LOGIN.SUBMIT" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
