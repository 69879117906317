import { PropertyType } from "../../models/data-retrieval.model";
import { ConfigTableData, TableRowDataItem } from "../webform-view-table.model";
import { WebformFlattened } from "../webform-view-table.model";

const getLegendForSectionA = (
  relationSectionA: "applicant" | "partner" | "mother" | "father"
) => {
  if (relationSectionA === "applicant") {
    return "INTAKE.IMM5406.FORMS.SECTION_A.APPLICANT_SUBHEADING";
  } else if (relationSectionA === "partner") {
    return "INTAKE.IMM5406.FORMS.SECTION_A.PARTNER_SUBHEADING";
  } else if (relationSectionA === "mother") {
    return "INTAKE.IMM5406.FORMS.SECTION_A.MOTHER_SUBHEADING";
  } else if (relationSectionA === "father") {
    return "INTAKE.IMM5406.FORMS.SECTION_A.FATHER_SUBHEADING";
  }
  return undefined;
};

const getFieldsForSectionA = (
  relationSectionA: "applicant" | "partner" | "mother" | "father"
) => {
  const formName =
    relationSectionA === "applicant"
      ? "INTAKE.IMM5406.FORMS.SECTION_A.MAIN_HEADING"
      : undefined;
  const fieldsetLegend = getLegendForSectionA(relationSectionA);
  let souceListName = "otherMaritalStatus";
  if (relationSectionA === "applicant") {
    souceListName = "principalMaritalStatus";
  } else if (relationSectionA === "partner") {
    souceListName = "partnerMaritalStatus";
  }
  return [
    // Section A - Full name
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        formName,
        fieldsetLegend,
        fieldLabel: "INTAKE.IMM5406.FORMS.FULL_NAME_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: relationSectionA,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "fullName",
        },
      },
    },
    // Section A - Dob
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5406.FORMS.DOB_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: relationSectionA,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "dob",
        },
      },
    },
    // Section A - Country of birth
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5406.FORMS.BIRTHPLACE_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: relationSectionA,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "countryOfBirth",
        },
      },
    },
    // Section A - Marital status
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      complexSourceList: [souceListName],
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5406.FORMS.MARITAL_STATUS_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: relationSectionA,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "maritalStatus",
        },
      },
    },
    // Section A - Email
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel: "INTAKE.IMM5406.FORMS.EMAIL_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: relationSectionA,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "emailAddress",
        },
      },
    },
    // Section A - Address
    {
      type: PropertyType.Object,
      sourceField: "sectionA",
      sourceLabels: {
        fieldLabel:
          relationSectionA === "applicant"
            ? "INTAKE.IMM5406.FORMS.SECTION_A.ADDRESS_LABEL"
            : "INTAKE.IMM5406.FORMS.SECTION_A.ADDRESS_PARENT_LABEL",
      },
      subfield: {
        type: PropertyType.Object,
        sourceField: relationSectionA,
        subfield: {
          type: PropertyType.Primitive,
          sourceField: "address",
        },
      },
    },
  ];
};

const getSectionAConfig = (): TableRowDataItem[] => {
  return [
    ...getFieldsForSectionA("applicant"),
    ...getFieldsForSectionA("partner"),
    ...getFieldsForSectionA("mother"),
    ...getFieldsForSectionA("father"),
  ];
};

export const getSectionConfig = (
  section: "a" | "b" | "c"
): TableRowDataItem[] => {
  if (section === "a") {
    return getSectionAConfig();
  }
  const sourceSection = section === "b" ? "sectionB" : "sectionC";
  const sourceFieldInSection = section === "b" ? "children" : "siblings";
  const fieldsetLegend =
    section === "b"
      ? "INTAKE.IMM5406.FORMS.SECTION_B.CHILDREN_LEGEND"
      : "INTAKE.IMM5406.FORMS.SECTION_C.SIBLING_LEGEND";
  return [
    {
      type: PropertyType.Object,
      sourceField: sourceSection,
      complexSubfield: {
        type: PropertyType.Array,
        sourceField: sourceFieldInSection,
        complexSubfield: [
          {
            // Relation
            type: PropertyType.Primitive,
            sourceField: "relationship",
            sourceLabels: {
              fieldsetLegend,
              fieldLabel: "INTAKE.IMM5406.FORMS.RELATIONSHIP_LABEL",
            },
          },
          {
            // Full name
            type: PropertyType.Primitive,
            sourceField: "fullName",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5406.FORMS.FULL_NAME_LABEL",
            },
          },
          {
            // DOB
            type: PropertyType.Primitive,
            sourceField: "dob",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5406.FORMS.DOB_LABEL",
            },
          },
          {
            // Country of origin
            type: PropertyType.Primitive,
            sourceField: "countryOfBirth",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5406.FORMS.BIRTHPLACE_LABEL",
            },
          },
          {
            // Marital status
            type: PropertyType.Primitive,
            sourceField: "maritalStatus",
            complexSourceList: ["otherMaritalStatus"],
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5406.FORMS.MARITAL_STATUS_LABEL",
            },
          },
          {
            // Email
            type: PropertyType.Primitive,
            sourceField: "emailAddress",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5406.FORMS.EMAIL_LABEL",
            },
          },
          {
            // Address
            type: PropertyType.Primitive,
            sourceField: "address",
            sourceLabels: {
              fieldLabel: "INTAKE.IMM5406.FORMS.SECTION_A.ADDRESS_PARENT_LABEL",
            },
          },
        ],
      },
    },
  ];
};

export const IMM5406Configs = (): WebformFlattened => {
  return {
    metaData: {
      form: "imm5406",
      documentTypeId: 28,
      pageTitle: "INTAKE.IMM5406.PAGES.PAGE_HEADING",
      // Set to false if the form has only one page (ex: immxxxx/) to set the proper back navigation buttons
      multiPageForm: false,
      hasNextPageButton: false,
    },
    tables: [],
  };
};

export const paTableHeading = "INTAKE.IMM5406.TABLE_TITLES.PRIMARY_APPLICANT";
export const depTableHeading = "INTAKE.IMM5406.TABLE_TITLES.DEPENDANT";
export const numberOfRowsPerDependant = 7;
