import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  Component,
  OnInit,
  AfterContentChecked,
  EventEmitter,
  Output,
} from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { AlertService, ValidationService, UserGroup } from "lib";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as AuthActions from "../../store/auth.actions";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import routePaths from "../../../../routing/route-paths";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "prcw-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterContentChecked {
  public currentLang = "en";
  public loginForm: FormGroup;
  public forgotPasswordRouteName: string;
  public autofill = false;

  public loading = false;

  @Output() loadingVal: EventEmitter<any> = new EventEmitter();

  // Event emitter
  @Output() public mfaTrigger: EventEmitter<any> = new EventEmitter();

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private authService: AuthService,
    private translate: TranslateService,
    private validationService: ValidationService,
    private store: Store<fromApp.State>,
    private router: Router
  ) {}

  ngOnInit() {
    // get language
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    // initializing forgot password route
    // TODO: use RouteLocalizer service
    this.forgotPasswordRouteName = `/${this.currentLang}/${
      this.currentLang === "fr"
        ? routePaths.fr.FORGOT_PASSWORD
        : routePaths.en.FORGOT_PASSWORD
    }`;

    // checks if already logged in to route them to home page if they hit the login page
    this.store
      .select("auth")
      .pipe(map((authState) => authState.user))
      .subscribe((user) => {
        const authenticated = !!user;
        if (authenticated) {
          this.router.navigateByUrl("/");
          return;
        }
      });

    this.loginForm = new FormGroup({
      username: new FormControl(null, [
        Validators.required,
        this.validationService.validatorEmail,
      ]),
      password: new FormControl(null, Validators.required),
    });
  }

  ngAfterContentChecked() {
    /* CHROME AUTO FILL FIX */
    this.autofill = this.authService.checkAutofillOnLogin();
  }

  public onSubmit() {
    if (this.loginForm.status === "INVALID") {
      return;
    }

    this.loading = true;
    this.loadingVal.emit(this.loading);

    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    this.authService
      .login(username, password)
      .then((response: any) => {
        // IF MULTI FACTOR AUTHENTICATION ENABLED, DELAY LOGIN
        // ONLY CW have MFA enabled
        if (response.challengeName === "SMS_MFA") {
          this.mfaTrigger.emit(response);
        } else {
          const groups = response.signInUserSession.idToken.payload[
            "cognito:groups"
          ] as string[] | undefined;
          // Otherwise log them in normally if they are part of the Case workers client user pool
          if (
            groups?.includes(UserGroup.CW) ||
            groups?.includes(UserGroup.TL)
          ) {
            this.store.dispatch(
              new AuthActions.Login({
                username,
                session: response.Session,
                isFirstLogin: false,
                token: response.signInUserSession.accessToken.jwtToken,
                userPool: groups.includes(UserGroup.TL)
                  ? UserGroup.TL
                  : UserGroup.CW,
              })
            );
          } else {
            this.alertService.danger(this.alertWrongCredentials);
          }
        }
      })
      .catch((error: any) => {
        this.loading = false;

        switch (error.code) {
          case "NotAuthorizedException":
          case "UserNotFoundException":
          case "PasswordResetRequiredException":
            this.alertService.danger(this.alertWrongCredentials);
            break;
          default:
            this.alertService.danger(this.alertTechnicalError);
            break;
        }
      });
  }

  private get alertWrongCredentials(): string {
    return this.translate.instant("LOGIN.ALERTS.FORGOT_PASSWORD");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("LOGIN.ALERTS.TECHNICAL_ERROR");
  }
}
