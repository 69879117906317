import {
  Case,
  ReturnReason,
  UpdatedPhoto,
} from "@pr-caseworker/app/core/cases-module/models/case.model";
import { Action, createReducer, on } from "@ngrx/store";

import * as SelectedApplicationActions from "./selected-application.actions";
import {
  ApplicationDocument,
  ApplicationFormDocs,
  Document,
} from "@pr-caseworker/app/core/models/document.model";
import { RenewalReturnReasonsById } from "@pr-caseworker/app/renewal-module/models/renewal-photo.model";

export interface SelectedApplicationState {
  case: Case;
  updatedDocs: Document[];
  appDocTypesList: ApplicationDocument[];
  urgentAppDocTypesList: ApplicationDocument[];
  appFormTypesList: ApplicationFormDocs[];
  selectedReturnReasons: ReturnReason[];
  updatedPhoto: UpdatedPhoto;
  photoFrontReturnReasons: RenewalReturnReasonsById;
  photoBackReturnReasons: RenewalReturnReasonsById;
  toggleClientInfoEditing: boolean;
  bulkDownloadsIsLoading: boolean;
}

const initialState: SelectedApplicationState = {
  case: {} as Case,
  updatedDocs: [],
  appDocTypesList: [],
  urgentAppDocTypesList: [],
  appFormTypesList: [],
  selectedReturnReasons: [],
  updatedPhoto: {} as UpdatedPhoto,
  photoFrontReturnReasons: {} as RenewalReturnReasonsById,
  photoBackReturnReasons: {} as RenewalReturnReasonsById,
  toggleClientInfoEditing: false,
  bulkDownloadsIsLoading: false,
};

const selectedApplicationReducer = createReducer(
  initialState,
  on(
    SelectedApplicationActions.setSelectedApplication,
    (state, { payload }) => {
      return {
        ...state,
        case: payload,
      };
    }
  ),
  on(SelectedApplicationActions.setCurrentCaseError, (state, { payload }) => {
    return {
      ...state,
      selectedCase: Object.assign({}, state.case, {
        id: payload?.caseId,
        apiLoadingError: payload.apiLoadingError,
      }),
    };
  }),
  on(SelectedApplicationActions.updateDocs, (state, { payload }) => {
    return {
      ...state,
      updatedDocs: payload,
    };
  }),
  on(SelectedApplicationActions.setAppDocsList, (state, { payload }) => {
    return {
      ...state,
      appDocTypesList: payload,
    };
  }),
  on(SelectedApplicationActions.setUrgentAppDocsList, (state, { payload }) => {
    return {
      ...state,
      urgentAppDocTypesList: payload,
    };
  }),
  on(SelectedApplicationActions.setAppFormsList, (state, { payload }) => {
    return {
      ...state,
      appFormTypesList: payload,
    };
  }),
  on(
    SelectedApplicationActions.setSelectedReturnReasons,
    (state, { payload }) => {
      return {
        ...state,
        selectedReturnReasons: payload,
      };
    }
  ),
  on(SelectedApplicationActions.updatePhoto, (state, { payload }) => {
    return {
      ...state,
      updatedPhoto: {
        ...state.updatedPhoto,
        photoName: payload.photoName,
        photoMetaData: payload.photoMetaData,
        identityId: payload.identityId,
        image: payload.image,
      },
    };
  }),
  on(SelectedApplicationActions.updatePhotoDownload, (state, { payload }) => {
    return {
      ...state,
      updatedPhoto: {
        ...state.updatedPhoto,
        downloadUrl: payload,
      },
    };
  }),
  on(
    SelectedApplicationActions.setPhotoFrontReturnReasons,
    (state, { payload }) => {
      return {
        ...state,
        photoFrontReturnReasons: payload,
      };
    }
  ),
  on(
    SelectedApplicationActions.setPhotoBackReturnReasons,
    (state, { payload }) => {
      return {
        ...state,
        photoBackReturnReasons: payload,
      };
    }
  ),
  on(
    SelectedApplicationActions.toggleClientInfoEditing,
    (state, { payload }) => {
      return {
        ...state,
        toggleClientInfoEditing: payload,
      };
    }
  ),
  on(SelectedApplicationActions.clearSelectedCaseState, () => {
    return {
      ...initialState,
    };
  }),
  on(
    SelectedApplicationActions.updateBulkDownloadIsLoading,
    (state, { payload }) => {
      return {
        ...state,
        bulkDownloadsIsLoading: payload,
      };
    }
  )
);

export function reducer(
  state: SelectedApplicationState | undefined,
  action: Action
): SelectedApplicationState {
  return selectedApplicationReducer(state, action);
}
