<section>
  <!-- Interpreter declaration -->
  <h2 class="emphasis">
    {{ "INTAKE.IMM5669.DECLARATION.INTERPRETER.LABEL" | translate }}
  </h2>
  <div
    [innerHTML]="'INTAKE.IMM5669.DECLARATION.INTERPRETER.BODY' | translate"
  ></div>
</section>

<section>
  <!-- Solemn declaration -->
  <h2 class="emphasis">
    {{ "INTAKE.IMM5669.DECLARATION.SOLEMN.LABEL" | translate }}
  </h2>

  <div [innerHTML]="'INTAKE.IMM5669.DECLARATION.SOLEMN.BODY' | translate"></div>

  <div
    class="solemn"
    [innerHTML]="'INTAKE.IMM5669.DECLARATION.SOLEMN.SIGNATURE' | translate"
  ></div>
</section>
