import { Injectable } from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { isValid, isBefore, isAfter } from "date-fns";
import { Observable, Subscription, of } from "rxjs";

const dateFormatRegex: RegExp = new RegExp(
  "([0-9]{4}/[0-9]{2}/[0-9]{2}$)|(____/__/__$)"
);
const dateFormatShortRegex: RegExp = new RegExp(
  "([0-9]{4}/[0-9]{2}$)|(____/__$)"
);
const frenchChars = "çéâêîôûàèùëïü";

/* Does the date exist in real life? */
function isValidDate(dateStr: string): boolean {
  const [year, month, day] = dateStr.split("/");

  /* Check that it coerces to a valid date?
  (BUT: this will accept 31 days in any month, and force a rollover during coercion!) */
  const monthIndex = +month - 1; // bc months are 0-indexed
  const date = day
    ? new Date(+year, monthIndex, +day)
    : new Date(+year, monthIndex);
  const isADate = !isNaN(date.getTime());

  /* Check that the month of the date object is the same as the calculated month index */
  const doesNotRollOver = date.getMonth() === monthIndex;

  return isADate && doesNotRollOver;
}

function convertDateFromSlashToHyphen(date: string): number {
  return Date.parse(date.split("/").join("-"));
}

function checkForDateMaskOrNull(
  control: FormControl | AbstractControl
): boolean {
  return (
    !control.value ||
    control.value === "____/__/__" ||
    control.value === "____/__"
  );
}

@Injectable()
export class ValidationService {
  // To be used with reactive form controls and supplied to a reactive form group validator
  // Returns null if there is no error present, i.e passes validation

  /* contains at least one lowercase letter */
  public validatorProperName(control: FormControl): ValidationErrors | null {
    const regex = new RegExp(
      "^[a-zA-Za-zéàèùâêîôûëïöüæçœÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ]?[a-zA-Z-'a-zéàèùâêîôûëïöüæçœîôÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ ]*$",
      "gi"
    );
    return control.value?.match(regex) || !control.value
      ? null
      : { properName: true };
  }

  /* Checks that the control date is AFTER a provided date */
  public isDateAfter(dateStr: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (checkForDateMaskOrNull(control)) {
        return null;
      }
      if (isNaN(control.value) && control.value <= dateStr) {
        return { isDateAfter: true };
      }
      return null;
    };
  }

  /* Checks that the control date is EQUAL OR AFTER a provided date */
  public isDateEqualOrAfter(
    dateStr: string,
    equalTo: boolean = false
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (checkForDateMaskOrNull(control)) {
        return null;
      }
      if (isNaN(control.value) && control.value < dateStr) {
        return { isDateEqualOrAfter: true };
      }
      return null;
    };
  }

  public validatorCompareDates(
    startControl: FormControl,
    direction: string = "after",
    allowEqual: boolean = false
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (
        checkForDateMaskOrNull(control) ||
        checkForDateMaskOrNull(startControl)
      ) {
        return of(null);
      }

      const d1 = convertDateFromSlashToHyphen(control.value);
      const d2 = convertDateFromSlashToHyphen(startControl.value);

      if (direction === "after") {
        if (allowEqual) {
          return d1 >= d2 ? of(null) : of({ isDateAfterCompareEqualTo: true });
        } else {
          return d1 > d2 ? of(null) : of({ isDateAfterCompare: true });
        }
      } else if (direction === "before") {
        if (allowEqual) {
          return d1 <= d2 ? of(null) : of({ isDateBeforeCompareEqualTo: true });
        } else {
          return d1 < d2 ? of(null) : of({ isDateBeforeCompare: true });
        }
      } else {
        return of(null);
      }
    };
  }

  /* Checks that the control text is not null */
  public isValueEmpty(text: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || text === null) {
        return null;
      }
      if (text !== "") {
        return { isValueEmpty: true };
      }
      return null;
    };
  }

  /* checks if radio control has "required" validator */
  public isRadioFieldRequired(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.validator) {
      return null;
    } else {
      const validator = control.validator({} as AbstractControl);
      return validator?.required;
    }
  }

  /* Check the string is alphanumeric (numbers, letters and spaces only) */
  public validatorAlphaNumeric(control: FormControl) {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(`^[a-z${frenchChars} 0-9]*?$`, "i");
    return control.value?.match(regex) ? null : { alphaNum: true };
  }

  /* Check the string is following the expanded regex in the original imm00080-logic PDF file
  (special chars, symbols, numbers, letters and spaces)
  */
  public validatorAlphaNumericPlusAllSymbols(control: FormControl) {
    if (!control.value) {
      return null;
    }
    const r =
      /^[""a-zA-Z0-9\s\r\~\!\@\#\$\%\^\&\*\(\)\_\+\{\}\:\<\>\?\`\-\=\[\]\\\;\'\,\.\/éàèùâêîôûëïöüæçœîôÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ    ]+$/;
    const regex = new RegExp(r, "i");
    return control.value?.match(regex) ? null : { invalidChars: true };
  }

  /* Checks:
  - alphabetic (any case) and numeric allowed
  - space, apostrophe and hyphen allowed
  */
  public validatorAlphaNumPlusFew(
    control: FormControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(`^[a-z${frenchChars} 0-9\'-]*$`, "i");
    return control.value?.match(regex) ? null : { alphaNumPlusFew: true };
  }

  /* Checks:
  - alphabetic (any case) allowed
  - space, apostrophe, hyphen allowed
  */
  public validatorAlphaPlusFew(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(`^[a-z${frenchChars} \'-]*$`, "i");
    return control.value?.match(regex) ? null : { alphaPlusFew: true };
  }

  /* Checks:
  - alphabetic (any case) allowed
  - slash, hyphen, dbl quotes, period, ampersand, comma allowed
  */
  public validatorAlphaPlusMany(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(`^[a-z${frenchChars}/ ".&,-]*$`, "i");
    return control.value?.match(regex) ? null : { alphaPlusMany: true };
  }

  /* Checks:
  - alphabetic (any case) allowed
  - slash and space allowed
  */
  public validatorAlphaNumPlusBackslash(
    control: FormControl
  ): ValidationErrors | null {
    const regex = new RegExp(`^[a-z${frenchChars}0-9 /]*$`, "i");
    if (control.value?.length > 0) {
      return control.value?.match(regex)
        ? null
        : { alphaNumPlusBackslash: true };
    } else {
      return null;
    }
  }

  /* is valid Canadian postal code matching format 'A1A 1A1'*/
  public validatorCanadianPostalCode(
    control: FormControl
  ): ValidationErrors | null {
    const regex = new RegExp(
      "^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$",
      "i"
    );
    return control.value?.match(regex) ? null : { CAPostalCode: true };
  }

  /* Specific for country code number - contains only numbers , not starting with '11' */
  public validatorCountryCodeWithout11(
    control: FormControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp("^(?!11)[0-9]*$");
    return control.value?.match(regex) ? null : { numbersWithout11: true };
  }

  /* Checks that date is valid IRL date */
  public validatorDate(control: FormControl): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    return isValidDate(control.value) ? null : { isADate: true };
  }

  /* Checks for empty or masked dates on date fields regular Validator.required doesn't work */
  public validatorDatePickerRequired(
    control: FormControl | AbstractControl
  ): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return { requiredDatePicker: true };
    }
    return null;
  }

  /* checks the string format of the date: YYYY/MM/DD */
  public validatorDateFormat(
    control: FormControl | AbstractControl
  ): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    if (control.value?.length != 10) {
      return { dateFormat: true };
    } else if (!control.value?.match(dateFormatRegex)) {
      return { dateFormat: true };
    } else if (isNaN(convertDateFromSlashToHyphen(control.value))) {
      return { isADate: true };
    }
    return null;
  }

  /* checks the string format of the date: YYYY/MM */
  public validatorDateFormatShort(
    control: FormControl | AbstractControl
  ): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    if (control.value?.length != 7) {
      return { dateFormatShort: true };
    } else if (!control.value?.match(dateFormatShortRegex)) {
      return { dateFormatShort: true };
    } else if (isNaN(convertDateFromSlashToHyphen(control.value))) {
      return { isADate: true };
    }
    return null;
  }

  /* check the date is a moment object */
  public validatorDateMoment(control: FormControl): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    if (control.value?._isAMomentObject) {
      return null;
    } else {
      return { dateFormat: true };
    }
  }

  /* date is in the future */
  public validatorDateFuture(control: FormControl): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    const date = new Date(convertDateFromSlashToHyphen(control.value));
    const today = new Date();
    return date > today ? null : { futureDate: true };
  }

  /* date is between 1900/01/01 and today/now, if not empty */
  public validatorDatePast(control: FormControl): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    const date = new Date(convertDateFromSlashToHyphen(control.value));
    const earliestDate = new Date("1900/01/01");
    const today = new Date();
    return date > earliestDate && date < today ? null : { pastDate: true };
  }

  /* date is between 1900/01 and today/now, if not empty */
  public validatorDatePastShort(control: FormControl): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    const date = new Date(convertDateFromSlashToHyphen(control.value));
    const earliestDate = new Date("1900/01");
    const today = new Date();
    return date > earliestDate && date < today ? null : { pastDate: true };
  }

  /* date is between 1900/01/01 if not empty */
  public validatorDateGreaterThan1900(
    control: FormControl | AbstractControl
  ): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    const date = new Date(convertDateFromSlashToHyphen(control.value));
    const earliestDate = new Date("1900/01/01");
    return date > earliestDate ? null : { greaterThan1900: true };
  }

  /* date is between 1900/01 if not empty */
  public validatorDateGreaterThan1900Short(
    control: FormControl | AbstractControl
  ): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    const date = new Date(convertDateFromSlashToHyphen(control.value));
    const earliestDate = new Date("1900/01");
    return date > earliestDate ? null : { greaterThan1900: true };
  }

  /* the latest the date can be is today's, if not empty */
  public validatorDateCannotBeFuture(
    control: FormControl
  ): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    const date = new Date(convertDateFromSlashToHyphen(control.value));
    const today = new Date();
    return date < today ? null : { invalidFutureDate: true };
  }

  /* For weird IRCC DOB requirements where month and day are optional using '*' */
  public validatorDateWithWildcards(
    control: FormControl
  ): ValidationErrors | null {
    if (checkForDateMaskOrNull(control)) {
      return null;
    }
    const regex = new RegExp("[0-9]{4}/[0-9*]{2}/[0-9*]{2}$");
    if (control.value.match(regex)) {
      const str = control.value.split("*").join("1"); // allow wildcards, replacing as '1' for validation
      return isValidDate(str) ? null : { wildcardDate: true };
    } else {
      return { wildcardDate: true };
    }
  }

  /* check email pattern */
  public validatorEmail(control: FormControl): ValidationErrors | null {
    return Validators.email(control);
  }

  /* contains at least one lowercase letter */
  public validatorLowercase(control: FormControl): ValidationErrors | null {
    const regex = new RegExp("[a-z]", "g");
    return control.value?.match(regex) ? null : { lowercase: true };
  }

  /* must have 8 chars */
  public validatorMinChars(control: FormControl): ValidationErrors | null {
    return control.value?.length >= 8 ? null : { minChars: true };
  }

  /* does not contain a pipe character (for IMM0008) */
  public validatorNoPipe(control: FormControl): ValidationErrors | null {
    const regex = new RegExp("[|]");
    return control.value?.match(regex) ? { pipe: true } : null;
  }

  /* does not contain a plus character (for contact email) */
  public validatorNoPlus(control: FormControl): ValidationErrors | null {
    const regex = new RegExp("[+]");
    return control.value?.match(regex) ? { plus: true } : null;
  }

  /* is not just whitespace */
  public validatorNotWhitespace(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const ctrlVal = control.value.toString();
    const regex = new RegExp("^ +$");
    return ctrlVal.match(regex) ? { whitespace: true } : null;
  }

  /* contains at least one number */
  public validatorContainNumbers(
    control: FormControl
  ): ValidationErrors | null {
    const regex = new RegExp("[0-9]", "g");
    return control.value?.match(regex) ? null : { numbers: true };
  }

  /* contains only numbers */
  public validatorOnlyNumbers(control: FormControl): ValidationErrors | null {
    const regex = new RegExp("^[0-9]+$", "g");
    if (control.value?.length > 0) {
      return control.value?.match(regex) ? null : { onlyNumbers: true };
    } else {
      return null;
    }
  }

  /* only numbers and spaces */
  public validatorNumericPlusSpace(
    control: FormControl
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp("^[ 0-9]*$");
    return control.value?.match(regex) ? null : { numPlusSpace: true };
  }

  public validatorOnlyLetters(control: FormControl) {
    const regex = new RegExp("^[a-zA-Z]*$");
    if (control.value?.match(regex)) {
      return null;
    } else {
      return control.value === null ? null : { onlyLetters: true };
    }
  }

  /* password control values are an exact match with each other */
  public validatorPasswordMatching(fGroup: FormGroup): ValidationErrors | null {
    if (fGroup.value.password !== null) {
      if (fGroup.value.password === fGroup.value.passwordConfirm) {
        fGroup.controls.passwordConfirm.setErrors(null);
        return null;
      } else {
        fGroup.controls.passwordConfirm.setErrors({ matches: true });
        return { matches: true };
      }
    } else {
      return { matches: true };
    }
  }

  /* email control values are an exact match with each other */
  public validatorEmailMatching(fGroup: FormGroup): ValidationErrors | null {
    if (fGroup.value.emailConfirm === "") {
      return { required: true };
    }
    if (fGroup.value.emailAddress !== "" && fGroup.value.emailConfirm !== "") {
      if (fGroup.value.emailAddress === fGroup.value.emailConfirm) {
        fGroup.controls.emailConfirm.setErrors(null);
        return null;
      } else {
        fGroup.controls.emailConfirm.setErrors({ matches: true });
        return { matches: true };
      }
    } else {
      return { matches: true };
    }
  }

  /* checks cognito for user is existing in the rep group*/
  public validatorCheckEmailForPaidRep(control: FormControl) {
    const email = control.value.toString();
    console.log("email", email);
    if (!email) {
      return null;
    } else {
      return control.value === null ? null : { isPaidRep: true };
    }
    //return control.value === null ? null : { isPaidRep: true };
  }

  /* checks cognito to find if the email belongs in either reps or clients group, also returns
  error if no email provided in form, this is set on a form group and requires that the inputs be called email
  emailConfirm and paidRep as a radio */
  public validatorCheckGroupByEmailAndRadioSync(
    fGroup: FormGroup
  ): ValidationErrors | null {
    const email = fGroup.value.email;
    if (email === "") {
      fGroup.controls.paidRep.setErrors({ missingEmail: true });
      return { missingEmail: true };
    }
    fGroup.controls.paidRep.setErrors({ required: true });
    return { required: true };
  }

  /* contains all numbers in this format ###-###-#### */
  public validatorPhoneNumber(control: FormControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp("^[0-9]{3}-[0-9]{3}-[0-9]{4}$");
    return control.value?.match(regex) ? null : { phoneNumberFormat: true };
  }

  // check phone number area code parttern
  // input boolean value: true - check first area code valid
  //                      false - check second group code valid
  public validatorPhoneNumberAreaCode(isFirstAreaCode: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isFirstAreaCode) {
        if (!control.value) {
          return null;
        }
        const regex = new RegExp("^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$");
        return control.value?.match(regex) ? null : { invalidAreaCode: true };
      } else {
        if (!control.value) {
          return null;
        }
        const regex = new RegExp("^[0-9]{3}-[2-9]{1}[0-9]{2}-[0-9]{4}$");
        return control.value?.match(regex)
          ? null
          : { invalidSecondGroupAreaCode: true };
      }
    };
  }

  /* contains at least one specified symbol */
  public validatorSymbol(control: FormControl): ValidationErrors | null {
    const regex = new RegExp(
      "[!,%,&,@,#,$,*,?,`,',\",_,~,\\],/,[,},{,\\\\,>,<,:,;,.,|,_,,(,),^]",
      "g"
    );
    return control.value?.match(regex) ? null : { symbol: true };
  }

  /* is valid United States postal code matching format 99999 OR 99999-9999*/
  public validatorUnitedStatesPostalCode(
    control: FormControl
  ): ValidationErrors | null {
    const regex = new RegExp("^[0-9]{5}([-]?[0-9]{4})?$");
    return control.value?.match(regex) ? null : { USPostalCode: true };
  }

  /* contains at least one uppercase letter */
  public validatorUppercase(control: FormControl): ValidationErrors | null {
    const regex = new RegExp("[A-Z]", "g");
    return control.value?.match(regex) ? null : { uppercase: true };
  }

  public validatorCheckNickName(fGroup: FormGroup): ValidationErrors | null {
    if (!fGroup.value) {
      return null;
    }
    if (!fGroup.value.otherFamilyName || !fGroup.value.otherGivenName) {
      return null;
    } else if (
      fGroup.value.familyName?.toLowerCase() ===
        fGroup.value.otherFamilyName?.toLowerCase() &&
      fGroup.value.givenName?.toLowerCase() ===
        fGroup.value.otherGivenName?.toLowerCase()
    ) {
      return { matches: true };
    } else {
      return null;
    }
  }

  // TAKEN FROM R7.0.1; WILL CAUSE MERGE CONFLICT WHEN R7 MERGES INTO DEVELOPMENT.
  // DELETE THIS CODE BLOCK.
  public isValidFileNameForUpload(fileName: string): boolean {
    const regex = new RegExp(`^[A-Za-z0-9-_. ]*$`, "g");
    const matchResult = fileName.match(regex);
    if (matchResult) {
      return true;
    }
    return false;
  }

  // assuming that you know what you pass to this method
  // it is resolving ok these : ['2020/01/01', '2020-01-01', '2019-5-13', '30-03-2020', '22/02/2020'];
  public convertFromStringToDate(inputDate: string | null | undefined): Date {
    if (!inputDate) {
      return new Date(NaN);
    }
    let inputFormated = inputDate.replace(/-/g, "/");
    inputFormated = inputFormated.replace(/\\/g, "/");
    if (!isNaN(Date.parse(inputFormated))) {
      return new Date(inputFormated);
    } else {
      let datePieces = inputFormated.split("/");
      return new Date(+datePieces[2], +datePieces[1] - 1, +datePieces[0]);
    }
  }

  public isBeforeOrInvalidDate(
    inputDate: string | null | undefined,
    dateToCompare: Date
  ): boolean {
    const toDate = this.convertFromStringToDate(inputDate);
    return isBefore(toDate, dateToCompare) || !isValid(toDate);
  }

  public isAfterOrInvalidDate(inputDate: string, dateToCompare: Date): boolean {
    const toDate = this.convertFromStringToDate(inputDate);
    return isAfter(toDate, dateToCompare) || !isValid(toDate);
  }

  public autoFormatPhoneNumber = (countryCodeControlName: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      if (control?.parent?.get(countryCodeControlName)?.value !== "1")
        return null;
      const phoneNumber = control.value;
      let inputValueToString = phoneNumber.toString();

      //only allow numbers to be an input
      let updatedInputValue = inputValueToString.replace(/[^0-9 ]/g, "");
      if (updatedInputValue?.length < 3) {
        updatedInputValue = updatedInputValue?.replace(/^(\d{0,3})/g, "$1");
      } else if (
        updatedInputValue?.length > 3 &&
        updatedInputValue?.length <= 6
      ) {
        updatedInputValue = updatedInputValue?.replace(
          /^(\d{0,3})-?(\d{0,3})/g,
          "$1-$2"
        );
      } else if (updatedInputValue.length >= 7) {
        updatedInputValue = updatedInputValue?.replace(
          /^(\d{0,3})-?(\d{0,3}-?)-?(\d{0,4})/g,
          "$1-$2-$3"
        );
      }
      if (control.value !== updatedInputValue)
        control.setValue(updatedInputValue);
      return null;
    };
  };

  //check if input is already in the proper format - if not, format the input
  public autoFormatValidateCanadianPostalCode = (
    checkParentCountryIsCanada: boolean = false
  ) => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (checkParentCountryIsCanada) {
        const canadaLOV = "511";
        const isCanada = control?.parent?.value.country === canadaLOV;
        if (!isCanada) return null;
      }
      if (!control.value || control.value.length === 0) return null;
      const postalCode = control.value;
      let postalCodeToString = postalCode.toString().toUpperCase();

      //only allow numbers and letters to be an input
      let updatedpostalCode = postalCodeToString?.replace(/[^a-zA-Z0-9]/g, "");
      if (updatedpostalCode?.length >= 4) {
        updatedpostalCode = updatedpostalCode?.replace(
          /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
          "$1 $2"
        );
      }
      if (updatedpostalCode !== control.value)
        control.setValue(updatedpostalCode);
      return null;
    };
  };
  public dateNotNull(
    control: FormControl | AbstractControl
  ): ValidationErrors | null {
    return control.value !== "____/__/__" && control.value !== "____/__"
      ? null
      : { required: true };
  }
}
