import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { Router } from "@angular/router";
import { FormSelectOption } from "../../models/form-select.model";
import * as _ from "lodash";
@Component({
  selector: "lib-form-select",
  templateUrl: "./form-select.component.html",
})
export class FormSelectComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() identifier: string;
  @Input() label: string;
  @Input() helpText?: string;
  @Input() placeholder?: string;
  @Input() options: FormSelectOption[];
  @Input() required?: boolean;
  @Input() hideRequired?: boolean;
  @Input() ariaLabel?: string;
  @Input() useEmptyOption = true;
  @Input() useFadingEffect = false;
  @Input() visuallyHiddenLabel? = false;
  @Input() newEmptyOption?: boolean = false;
  @Input() isDocUploadFailed?: boolean | null = null;
  @Input() eraseMarginBottom?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() noLabel?: boolean = false;
  @Output() sendChanges: EventEmitter<any> = new EventEmitter();

  // adds class to component conditionally based on width attr
  @HostBinding("class.select-sm") @Input() widthSm?: boolean;
  @HostBinding("class.select-md") @Input() widthMd?: boolean;

  public id: string;
  public value: string;
  public disabled?: boolean;
  public hasFocus = false;
  public rotateChevron = false;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.id = this.identifier.replace(/\s/g, "_");
    this.ngControl?.valueChanges?.subscribe(() => this.cdr.detectChanges()); // very important: required to manually trigger the changes of form element on init to fetch all values of a table
  }

  ngOnChanges(): void {
    /* only call when options are available, and the empty option hasn't yet been added: */
    if (this.options && this.useEmptyOption) {
      this.addEmptyOption();
    }
    // return options with null value as [0] position of options Array
    if (this.options && this.options.length > 0) {
      const nullOptions = this.options
        ? this.options.filter((opt: FormSelectOption) => _.isNull(opt.value))
        : [];
      const notNullptions = this.options
        ? this.options.filter((opt: FormSelectOption) => !_.isNull(opt.value))
        : [];
      if (nullOptions.length > 0) {
        this.options = [...nullOptions, ...notNullptions];
      } else {
        this.options = [...notNullptions];
      }
    }
    /// if you want to order them in specific way....why you do not order and insert the empty one after ?
    /// instaed to sore having empty one in place??????
    if (
      this.lang === "fr" &&
      this.identifier !== "situation" &&
      this.identifier !== "lobCategory" &&
      this.identifier !== "lobSubcategory"
    ) {
      // Does not sort when the form is "situation" from renewal-landing-page so the order of the options meet requirments
      this.options = this.options?.sort(function (a, b) {
        var textA = a.text?.fr === undefined ? "" : a.text?.fr.toUpperCase();
        var textB = b.text?.fr === undefined ? "" : b.text?.fr.toUpperCase();
        return new Intl.Collator("fr").compare(textA, textB);
      });

      // console.log(this.options?.some(ele => ele.text?.fr === 'Autre'));

      if (this.options?.some((ele) => ele.text?.fr === "Autre")) {
        const optOther = this.options.find((ele) => {
          return ele.text?.fr === "Autre";
        });
        const optOtherFromSelectOption: FormSelectOption = {
          value: optOther?.value,
          text: {
            en: optOther?.text?.en === undefined ? " " : optOther?.text?.en,
            fr: optOther?.text?.fr === undefined ? " " : optOther?.text?.fr,
          },
        };
        this.options = this.options?.filter((ele) => ele.text?.fr !== "Autre");
        this.options.push(optOtherFromSelectOption);
      }

      if (this.options?.some((ele) => ele.text?.fr === "Sélectionner")) {
        const optSelect = this.options.find((ele) => {
          return ele.text?.fr === "Sélectionner";
        });
        const optSelectFromSelectOption: FormSelectOption = {
          value: optSelect?.value,
          text: {
            en: optSelect?.text?.en === undefined ? " " : optSelect?.text?.en,
            fr: optSelect?.text?.fr === undefined ? " " : optSelect?.text?.fr,
          },
        };
        this.options = this.options?.filter(
          (ele) => ele.text?.fr !== "Sélectionner"
        );
        this.options.unshift(optSelectFromSelectOption);
      }

      if (this.options?.some((ele) => ele.text?.fr === "Sélectionnez")) {
        const optSelect = this.options.find((ele) => {
          return ele.text?.fr === "Sélectionnez";
        });
        const optSelectFromSelectOption: FormSelectOption = {
          value: optSelect?.value,
          text: {
            en: optSelect?.text?.en === undefined ? " " : optSelect?.text?.en,
            fr: optSelect?.text?.fr === undefined ? " " : optSelect?.text?.fr,
          },
        };
        this.options = this.options?.filter(
          (ele) => ele.text?.fr !== "Sélectionnez"
        );
        this.options.unshift(optSelectFromSelectOption);
      }
      // this block is important !!!!!
      if (this.options && this.options.length > 0) {
        const nullOptions = this.options
          ? this.options.filter((opt: FormSelectOption) => _.isNull(opt.value))
          : [];
        const notNullptions = this.options
          ? this.options.filter((opt: FormSelectOption) => !_.isNull(opt.value))
          : [];
        if (nullOptions.length > 0) {
          this.options = [...nullOptions, ...notNullptions];
        } else {
          this.options = [...notNullptions];
        }
      }
    }
  }

  handleOnchanges(event: any) {
    this.sendChanges.emit(event);
  }

  /* PUBLIC ------------------------------------------------------------------------------- */
  public get lang(): string {
    return this.router.url?.split("/")[1];
  }

  public get requiredText(): string {
    return this.lang === "fr" ? "requis" : "required";
  }

  public get selectedOptionText(): string | undefined {
    if (!this.options) {
      return "";
    }
    const result = this.options?.find(
      (item) => item.value === this.ngControl.value
    );
    return result
      ? this.lang === "fr"
        ? result.text?.fr
        : result.text?.en
      : "";
  }

  /* PRIVATE ------------------------------------------------------------------------------ */
  private addEmptyOption(): void {
    /* check for an option with a `null` value */
    const nullOptions = this.options
      ? this.options.filter((opt: FormSelectOption) => opt.value === null)
      : [];
    if (nullOptions.length === 0) {
      this.options.unshift(this.emptyOption);
    }
  }

  private get emptyOption(): FormSelectOption {
    if (this.newEmptyOption) {
      return {
        value: null,
        text: {
          en: this.placeholder ? this.placeholder : "Select (New)",
          fr: this.placeholder ? this.placeholder : "Sélectionner (Nouveau)",
        },
      };
    } else {
      return {
        value: null,
        text: {
          en: this.placeholder ? this.placeholder : "Select",
          fr: this.placeholder ? this.placeholder : "Sélectionner",
        },
      };
    }
  }

  onFocus() {
    this.hasFocus = true;
  }

  onClick(e: any) {
    this.rotateChevron = !this.rotateChevron;
  }

  onLabelClick(e: any) {
    e.preventDefault();
  }

  onBlur(e: any) {
    this.hasFocus = false;
    this.rotateChevron = false;
  }

  onChange(e: any) {} // required; ok to leave empty

  onTouched(e: any) {} // required; ok to leave empty

  registerOnChange(fn: any): void {
    // required
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // required
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    // required
    this.value = value;
  }

  public trackElemnt(index: number, element: any): any {
    return index;
  }
}
